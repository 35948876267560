import React, { useState, useContext, useEffect } from "react";
import { Form, Input, Select, Row, Col, Button, Upload, Modal } from "antd";
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { UserCredsContext } from "../../../../../utils/UserCredsContext";
import { getRoles } from "@testing-library/react";
import { api_call_token, formDataApi } from "../../../../../utils/Network";
import ContactInfo from "./ContactInfo";
import { alertMessage } from "../../../../../utils/helperFunctions";
import ModalPops from "./ModalPops";

const { Option } = Select;

const SupplierInternFormStatic = () => {
  let navigate = useNavigate();
  const { base_items, set_Base } = useContext(UserCredsContext);
  const [contactInfo, setContactInfo] = useState([]);
  const [valueSelected, setValueSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [viewData, setView] = useState(null);
  const [handleSelect, setHandleSelect] = useState(null)
  const [form] = Form.useForm();


  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  useEffect(() => {
    // getRoles();
  }, []);

  // const getRoles = () => {
  //     api_call_token
  //     .get('roles')
  //     .then((res) => {

  //     })
  //     .catch(err => console.log(err))
  // }

  const onFinish = (values) => {
    console.log("Form values:", values);
    // Handle form submission logic here
    let countrys
    if(values?.countryId){
      countrys = base_items["countryList"].find((countryobj) => countryobj?.id == values?.countryId )
      console.log(countrys)
    }

    let data = {
      country: countrys?.name || "",
      international: 1,
      ...values
    }
    
    const { copyUploadComapanyRegistrationCertificate, copyUploadCompanyBrochure, copyUploadImportExportLicenseCertificate, copyUploadBankDetail, ...rest } = data;

    console.log(data, contactInfo, rest, "ThisData")

    let forms = {
      copyUploadComapanyRegistrationCertificate,
      copyUploadCompanyBrochure,
      copyUploadImportExportLicenseCertificate,
      copyUploadBankDetail
    }

    api_call_token
    .post(`supplier`, {...rest})
    .then((res) => {
      if(res.status == 200 || res.status == 201 || res.status == 204){
        let submitted = 1;
          if (contactInfo.length > 0) {
            submitted = 0;
            submitted = submitSupplier(res.data.id)
          }

          const allNullValues = Object.values(forms).every(value => value === null);

          if (!allNullValues) {
            submitted = 0;
            submitted = submitForm(forms, res.data.id)
          }

          if(submitted){
            alertMessage('Supplier Added ')
            setTimeout(() => {
                navigate(`/master/Supplier`)
            }, 2000)
          }
      }
    })
    .catch((err) => console.log(err))
  };

  const submitForm = async (datas, id) => {
    const fmDatas = new FormData();
    for (const key in datas) {
      if (datas.hasOwnProperty(key)) {
        const value = datas[key];
        if (value !== undefined) {
          if (Array.isArray(value)) {
            // Handle arrays separately
            value.forEach((item, index) => {
              if (item !== undefined && item.originFileObj !== undefined) {
                fmDatas.append(key, item.originFileObj, `${key}_${index}`);
              }
            });
          } else {
            fmDatas.append(key, value);
          }
        }
      }
    }
    let apiEnds = "";
    console.log(...fmDatas, "DDD");
    const res = await formDataApi(
      apiEnds,
      fmDatas,
      false,
      `supplier/uploadSupplierFiles/${id}`
    );
    console.log(res, "ressss");
    if (res.status == 200 || res.status == 201) {
      return 1;
      // submitProduct(res)
      // alertMessage("Container Created ")
      // setTimeout(() => {
      //     navigate('/container/addProducts', { state: res?.data })
      // }, 2000);
      // navigate('/container/addProducts', { state: res?.data })
    }
  }

  const handleSelected = (vals, num) => {
    setOpen(true)
    setValueSelected(vals)
    setView(1)
    setHandleSelect(num)
  }

  const handleDelete = () => {

    if (handleSelect >= 0 && handleSelect < contactInfo.length) {
      contactInfo.splice(handleSelect, 1);
    }
    setContactInfo(contactInfo);
    setOpen(false)
  }

  const submitSupplier = (id) => {
    contactInfo.map((data, idx) => {
      let value = {
        ...data,
        supplierId: id
      }

      api_call_token
        .post(`suppliercontactinfo`, value)
        .then((res) => {
          if(res.status == 200 || res.status == 201){
            return 1;
          }
        })
        .catch((err) => console.log(err))
    })
  }


  console.log(contactInfo, "ContactInfo")
  return (
    <div style={{ padding: "20px" }}>
      <div>
        <h2>
          <ArrowLeftOutlined
            onClick={() => navigate("/master/Supplier")}
            style={{ marginRight: "10px" }}
          />
          Create Supplier International
        </h2>
      </div>
      <div
        style={{ background: "white", padding: "30px" }}
        className={styles.containerMain}
      >
        <Form form={form} onFinish={onFinish}>
          <div style={{ textAlign: 'right' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>

          <div className={styles.lightBg}>
            <Row gutter={[24, 5]}>
              <Col xs={24} md={14} lg={12}>
                <span>Supplier Name</span>
                <Form.Item
                  name="supplierName"
                  rules={[
                    { required: true, message: "Please enter Supplier Name" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={14} lg={12}>
                <span>Currency</span>
                <Form.Item
                  name="currencyId"
                  // rules={[
                  //   { required: true, message: "Please select currency" },
                  // ]}
                >
                  {base_items && (
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={
                        base_items["currencyList"] &&
                        base_items["currencyList"]?.map((obj) => {
                          const keys = Object.keys(obj);
                          const convertedObj = {
                            value: obj[keys[0]],
                            label: obj[keys[1]],
                          };

                          for (let i = 2; i < keys.length; i++) {
                            convertedObj[keys[i]] = obj[keys[i]];
                          }

                          return convertedObj;
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <span>CRN</span>
                <Form.Item
                  name="companyRegistrationNumber"
                  // rules={[
                  //   { required: true, message: "Please enter CRN" },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <span>LTN</span>
                <Form.Item
                  name="localTaxationNumber"
                  // rules={[
                  //   { required: true, message: "Please enter LTN" },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={14} lg={12}>
                <span>Supplier CIN</span>
                <Form.Item name="supplierCIN">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <span>Annual Turnover</span>
                <Form.Item
                  name="annualTurnover"
                  // rules={[
                  //   { required: true, message: "Please enter annual turnover" },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <span>Manufacturing / trading</span>
                <Form.Item
                  name="manufacturing"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <span>Import/Export Licence Certificate</span>
                <Form.Item
                  name="copyUploadImportExportLicenseCertificate"
                  // label={val.fieldName}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload name="logo" listType="picture">
                    <Button icon={<UploadOutlined />}>
                      Click to upload
                    </Button>
                  </Upload>
                </Form.Item></Col>
              <Col span={12}>
                <span>Company Registration Certificate</span>
                <Form.Item
                  name="copyUploadComapanyRegistrationCertificate"
                  // label={val.fieldName}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload name="logo" listType="picture">
                    <Button icon={<UploadOutlined />}>
                      Click to upload
                    </Button>
                  </Upload>
                </Form.Item></Col><Col span={12}>
                <span>Company Brochure</span>
                <Form.Item
                  name="copyUploadCompanyBrochure"
                  // label={val.fieldName}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload name="logo" listType="picture">
                    <Button icon={<UploadOutlined />}>
                      Click to upload
                    </Button>
                  </Upload>
                </Form.Item></Col><Col span={12}>
                <span>Bank Details</span>

                <Form.Item
                  name="copyUploadBankDetail"
                  // label={val.fieldName}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload name="logo" listType="picture">
                    <Button icon={<UploadOutlined />}>
                      Click to upload
                    </Button>
                  </Upload>
                </Form.Item></Col>
            </Row>
          </div>

          <div className={styles.lightBg}>
            <Row gutter={[24, 5]}>
              <Col sm={24} lg={24}>
                Address
              </Col>

              <Col xs={24} md={14} lg={12}>
                <span>Office Address</span>
                <Form.Item
                  name="address"
                  rules={[
                    { required: true, message: "Please enter annual turnover" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={14} lg={12}>
                <span>Country</span>
                <Form.Item
                  name="countryId"
                  rules={[
                    { required: true, message: "Please select Country" },
                  ]}
                >
                  {base_items && (
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={
                        base_items["countryList"] &&
                        base_items["countryList"]?.map((obj) => {
                          const keys = Object.keys(obj);
                          const convertedObj = {
                            value: obj[keys[0]],
                            label: obj[keys[1]],
                          };

                          for (let i = 2; i < keys.length; i++) {
                            convertedObj[keys[i]] = obj[keys[i]];
                          }

                          return convertedObj;
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={14} lg={12}>
                <span>State/Province</span>
                <Form.Item name="state">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={14} lg={12}>
                <span>City</span>
                <Form.Item name="city">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={14} lg={12}>
                <span>Zip Code</span>
                <Form.Item name="pincode">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={14} lg={12}>
                <span>Supplier Contact Name</span>
                <Form.Item name="supplierContactName">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={14} lg={12}>
                <span>Supplier Phone</span>
                <Form.Item name="supplierPhone">
                  <Input type="number" />
                </Form.Item>
              </Col>

              <Col xs={24} md={14} lg={12}>
                <span>Supplier Email</span>
                <Form.Item name="supplierEmail">
                  <Input />
                </Form.Item>
              </Col>

              {/* <Col xs={24} md={14} lg={12}>
                <span>GSTIN No</span>
                <Form.Item name="gstin">
                  <Input />
                </Form.Item>
              </Col> */}
            </Row>
          </div>
          {/* Add the remaining fields similarly with the desired layout */}
          {/* For example, you can add the Address fields here */}
        </Form>

        <div className={styles.lightBg}>
            <ContactInfo setContactInfo={setContactInfo}  contactInfo={contactInfo} />
        </div>

        {open && <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
          {viewData == 1 && <ModalPops selected={valueSelected} setContactInfo={setContactInfo} setOpen={setOpen} contactInfo={contactInfo} handleSelect={handleSelect} />}
          {viewData == 2 && <div>
            <h2>Are You sure you want to delete ?</h2>
            <p>The Data will be delete once you confirm</p>
            <Button style={{ marginRight: '10px' }} onClick={() => handleDelete()}>Yes</Button>
            <Button onClick={() => {setOpen(false)}}>Cancel</Button>
          </div>}
        </Modal>}
        <div>
          {contactInfo.length > 0 && contactInfo.map((val, idx) => (
            <div className={styles.boxaddress}>
              <div className={styles.flexData}>
                <div>Contact Person {idx + 1}</div>
                <div><EditOutlined onClick={() => handleSelected(val, idx)} style={{ marginRight: '10px' }} /><DeleteOutlined onClick={() => { setOpen(true); setView(2); setHandleSelect(idx) }} /></div>
              </div>
              <Row>
                <Col lg={12}><p>Full Name: {val?.supplierContactInfoName}</p></Col>
                <Col lg={12}><p>Email ID: {val?.supplierContactInfoEmail}</p></Col>
                <Col lg={12}><p>Designation: {val?.supplierContactInfoDesignation}</p></Col>
                <Col lg={12}><p>Landline/Mobile: {val?.supplierContactInfoName}</p></Col>
                <Col lg={12}><p>GIN No: {val?.supplierContactInfoGIN}</p></Col>
                <Col lg={12}><p>Mobile No: {val?.supplierContactInfoMobile}</p></Col>
                <Col lg={12}><p>Address: {val?.supplierContactInfoAddress}</p></Col>
              </Row>
            </div>

          ))}
        </div>
      </div>

    </div>
  );
};

export default SupplierInternFormStatic;
