import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../../../utils/Network';
import styles from "./index.module.css";
// import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Modals from '../../../../../Components/Modals/Modals';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Modal
} from "antd";
import { UserCredsContext } from "../../../../../utils/UserCredsContext";
import NumericInput from "../../../../../Components/NumericInput/NumericInput";
import MyForm from './MyForm';
import SupplierForm from './SupplierForm';



function BuyerView() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [value, setValue] = useState("");
  const { base_items } = useContext(UserCredsContext);
  const [viewPurchase, setViewPurchase] = useState({});
  const [viewSupplierAddres, setViewSupplierAddress] = useState([])
  const [viewData, setView] = useState(0);
  const [open, setOpen] = useState(false);
  const [fieldSupplier, setFieldSupplier] = useState([]);
  const [fieldSupplierAddress, setFieldSupplierAddress] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedIDVal, setSelectedIDVal] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    getListForm();
    getListAddr();
    getViewofPurchase();
    getViewAddress();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`buyer/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data[0])
      })
      .catch(err => console.log(err));
  }

  const getViewAddress = () => {
    api_call_token
      .get(`buyeraddress/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewSupplierAddress(res.data)
      })
      .catch(err => console.log(err));
  }

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleOpen = (val) => {
    if (val == 0) {
      setSelected({})
      getViewofPurchase();
    } else if (val == 1) {
      getViewAddress();
      setSelected({})
    }
    setView(val)
    setOpen(true)
  }

  const handleDatas = (vals) => {
    let data = vals
    setSelected(data)
    setSelectedIDVal(1)
    setView(1)
    setOpen(true)
  }

  const getListForm = () => {
    api_call_token
      .get(`product/field?search=buyerEdit&formtype=create`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setFieldSupplier(res.data.data);
        }
      })
      .catch(err => console.log(err))
  }

  const getListAddr = () => {
    api_call_token
      .get(`product/field?search=buyerAddress&formtype=create`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setFieldSupplierAddress(res.data.data);
        }
      })
      .catch(err => console.log(err))
  }

  const handleform = () => {
    form.resetFields()
    setSelected({});
  }

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        for (const key in values) {
          if (values.hasOwnProperty(key)) {
            const value = values[key];
            if (typeof value === 'object' && (value.$d instanceof Date || value._d instanceof Date)) {
              if (value.$d) {
                values[key] = moment(value.$d).format('YYYY-MM-DD');
              } else {
                values[key] = moment(value._d).format('YYYY-MM-DD');
              }
            } else if (typeof value === 'string' && !isNaN(value)) {
              values[key] = Number(value);
            }
          }
        }
        if (values?.supplierContactName) {
          if (selectedIDVal == 1) {
            console.log(values, "ddddddddddd")
            api_call_token
              .patch(`buyeraddress/${selected?.addressID}`, { ...values, buyerID: +id })
              .then((res) => {
                form.resetFields();
                setOpen(false)
                getViewofPurchase();
                getViewAddress();
              })
              .catch((err) => console.log(err))
          } else {
            api_call_token
              .post(`buyeraddress`, { ...values,buyerID: +id })
              .then((res) => {
                form.resetFields();
                setOpen(false)
                getViewofPurchase();
                getViewAddress();
              })
              .catch((err) => console.log(err))
          }

        } else {
          api_call_token
            .patch(`buyer/${id}`, { ...values, active: (values?.active) ? 1 : 0 })
            .then((res) => {
              form.resetFields();
              setOpen(false)
              getViewofPurchase();
              getViewAddress();
            })
            .catch((err) => console.log(err))
        }
        console.log(values, "DDDEAT")
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  }

  console.log(selected, "VALS")

  return (


    <div style={{ width: "100%" }}>
      <div style={{padding: '20px', boxSizing: 'border-box'}}>
        <h2 className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate("/master/Buyer")} /> Buyer Details</h2>

        <div className={styles.wrapperContainer}>

          <div style={{ textAlign: 'right', padding: '10px' }}>
            <EditOutlined onClick={() => handleOpen(0)} />
          </div>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Buyer Id</p>
              <p>{viewPurchase?.buyerID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Buyer Name</p>
              <p>{viewPurchase?.buyerName}</p>
            </div>
            {/* <div className={styles.contentWrapper}>
              <p>Buyer About</p>
              <p>{viewPurchase?.buyerAbout}</p>
            </div> */}
          </div>
        </div>
        <div className={styles.wrapperContainer}>
          <p style={{ padding: '20px' }}>Address</p>
          <div className={styles.mainAddress}>
            <div className={`${styles.mainAddress}`}>
              {viewSupplierAddres.map((v, i) => (
                <div className={styles.mainAdr}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>{v.addressLine1}</p>
                    <EditOutlined onClick={() => handleDatas(v)} />
                  </div>

                  <p>{v.addressLine2}</p>
                  <p>{v?.state}, {v?.city} {v?.country}</p>
                  <p>{v?.supplierEmail}</p>
                  <p>{v?.supplierPhone}</p>
                </div>
              ))}
            </div>
            <div className={styles.addSupplier}>
              <PlusOutlined onClick={() => handleOpen(1)} />
            </div>
          </div>
        </div>

      </div>



      {open && 
        <Modals open={open} handleCancel={() => {setOpen(false); handleform(); setView(null)}} handleOk={handleOk}>
          {viewData == 0 && <SupplierForm fieldSupplierAddress={fieldSupplier} selected={viewPurchase} getViewAddress={getViewofPurchase} id={id} setOpen={setOpen}/>}
          {viewData == 1 && <MyForm fieldSupplierAddress={fieldSupplierAddress} selected={selected} getViewAddress={getViewAddress} id={id} setOpen={setOpen}/>}
        </Modals>
      }
    </div>
  )
}

export default BuyerView