import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { api_call } from "../../../utils/Network";
import NumericInput from "../../../Components/NumericInput/NumericInput";
import { MainContext } from "../../../utils/context";
import styles from "./index.module.css";
import { checkEmptyObject } from "../../../utils/Utils";
import { alertMessage } from "../../../utils/helperFunctions";
import { UserCredsContext } from "../../../utils/UserCredsContext";

function ProductsCompo({
  products,
  productsDetails,
  setProductList = () => { },
  productListing,
}) {
  const { base_items } = useContext(UserCredsContext);

  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [dataValues, setDataValues] = useState({});
  const [productsData, setProductsData] = useState({});
  const [productID, setProductData] = useState("");
  const [unitOfMeasurement, setUnitofMeaseure] = useState("");
  const [productsList, setProductsList] = useState({
    unitOfMeasurement: null,
    productID: null,
  })


  useEffect(() => {
    getProductData();
  }, [productsDetails]);


  const getProductData = () => {
    let data = {}
    productsDetails.map((v, i) => {
      if (v.isForm) {
        data[v.apiName] = "";
      }
    })
    setProductsData(data);
    console.log(data, "DDDDDDdd")
  }

  const handleData = () => {
    console.log(productsData, "productData")
    let data = Object.keys(productsData).length === 0;
    if (data) {
      alertMessage("Please add all details", 2);
      return
    }
    let prod = {
      hsnCode: productsData.hsnCode,
      quantity: productsData.quantity,
      productID: productsData.productID,
      pricePerUnit: productsData.pricePerUnit,
    }
    const checkProducts = checkEmptyObject(prod);
    if (!checkProducts) {
      alertMessage("Please add all details", 2);
      return
    }
    const { totalProductAmount, hsnCode, pricePerUnit, productName, quantity, ...restValues } = productsData;
    const productNames = findProductById(restValues.productID);
    const newArr = [...productListing, { ...restValues, productName: productNames, hsnCode: +hsnCode, pricePerUnit: +pricePerUnit, quantity: +quantity, totalProductAmount: +totalProductAmount, structureListInternal: [] }];
    setProductList(newArr);
    setProductsData({})
    setProductsList({
      unitOfMeasurement: null,
      productID: null
    })
  };

  function findProductById(id) {
    const index = products.findIndex((product) => product.productID === id);
    if (index !== -1) {
      return products[index].productName;
    }
    return null; // Return null if product with given id is not found
  }

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleAuto = (value, data) => {
    console.log(value, data, "FFFF");
    if (data.productID) {
      let hsn = data.hsnCode;
      setProductsData({ ...productsData, hsnCode: hsn, productID: value })
      setProductsList({ ...productsList, productID: value })
    } else {
      setProductsData({ ...productsData, unitOfMeasurement: value })
      setProductsList({ ...productsList, unitOfMeasurement: value })

    }
    setDataValues(data);
  };

  const changePrice = (e) => {
    let data = e * productsData.quantity;
    setProductsData({ ...productsData, pricePerUnit: e, totalProductAmount: data })
  }

  const Details = (e, data) => {
    console.log(e.target.value, data)
    setProductsData({ ...productsData, [data]: e.target.value })
  }
  const onChangeNumber = (e) => {
    console.log(e, "value")
    let data = e * productsData.pricePerUnit;
    setProductsData({ ...productsData, quantity: e, totalProductAmount: data })
  }

  console.log(productListing, "productsDataproductsData");
  return (
    <div>
      <div><h2>
        Description Of Goods</h2></div>
      <div>
        <div className={styles.productHead}>
          <Row gutter={[16, 16]}>
            {productsDetails &&
              productsDetails.map(
                (val, i) =>
                  val?.isForm && (
                    <>{
                      (val?.fieldType == "picklist_fk") ? <Col xs={24} md={14} lg={(val?.apiName == "productID") ? 4 : 2}>
                        <p className={styles.cssClass}>
                          {val?.fieldName} {val?.required && `*`}
                        </p>
                      </Col> :
                        <Col xs={24} md={14} lg={2}>
                          <p className={styles.cssClass}>
                            {val?.fieldName} {val?.required && `*`}
                          </p>
                        </Col>}</>))}
            <Col xs={24} md={14} lg={3}><p className={styles.cssClass}>Action</p></Col>
          </Row>
        </div>
        <div className={styles.textData}>
          <Row gutter={[16, 16]}>
            {productsDetails &&
              productsDetails.map(
                (val, i) =>
                  val?.isForm && (
                    <>
                      {(val?.fieldType == "int" && val?.apiName == "quantity") ? (
                        <Col xs={12} md={14} lg={2}>
                          {/* <span className={styles.cssClassMob}>
                          {val?.fieldName} {val?.required && `*`}
                        </span> */}

                          <NumericInput
                            style={{
                              width: "100%",
                            }}
                            value={productsData[val?.apiName]}
                            onChange={onChangeNumber}
                            name={productsData[val?.apiName]}
                            placeholder={val?.label}
                          />
                        </Col>
                      ) : (val?.fieldType == "int" && val?.apiName == "pricePerUnit") ?
                        (
                          <Col xs={12} md={14} lg={2}>
                            {/* <span className={styles.cssClassMob}>
                            {val?.fieldName} {val?.required && `*`}
                          </span> */}

                            <NumericInput
                              style={{
                                width: "100%",
                              }}
                              value={productsData[val?.apiName]}
                              onChange={changePrice}
                              name={productsData[val?.apiName]}
                              placeholder={val?.label}
                            />
                          </Col>
                        ) :
                        (val?.fieldName == "int") ?
                          (
                            <Col xs={12} md={14} lg={2}>
                              {/* <span className={styles.cssClassMob}>
                              {val?.fieldName} {val?.required && `*`}
                            </span> */}

                              <NumericInput
                                style={{
                                  width: "100%",
                                }}
                                placeholder={val?.label}
                                value={productsData[val?.apiName]}
                                onChange={setValue}
                                name={productsData[val?.apiName]}
                              />
                            </Col>
                          ) : val?.fieldType == "picklist_fk" ? (
                            <>
                              <Col xs={(val?.apiName == "productID") ? 24 : 12} md={14} lg={(val?.apiName == "productID") ? 4 : 2}>
                                {/* <span className={styles.cssClassMob}>
                                {val?.fieldName} {val?.required && `*`}
                              </span> */}

                                <Select
                                  showSearch
                                  value={productsList[val?.apiName]}
                                  style={{
                                    width: "100%",
                                  }}
                                  name={productsData[val?.apiName]}
                                  placeholder={val?.label}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                      .toLowerCase()
                                      .localeCompare(
                                        (optionB?.label ?? "").toLowerCase()
                                      )
                                  }
                                  onChange={handleAuto}
                                  options={
                                    (val?.apiName == "productID")
                                      ? products.map((obj) => {
                                        const convertedObj = {
                                          value: obj.productID,
                                          label: obj.productName,
                                          ...obj
                                        }
                                        return convertedObj
                                      }) // Add your condition for productID here
                                      : (base_items[val?.picklistData] &&
                                        base_items[val?.picklistData]?.map((obj) => {
                                          const keys = Object.keys(obj);
                                          const convertedObj = {
                                            value: obj[keys[0]],
                                            label: obj[keys[1]],
                                          };

                                          for (let i = 2; i < keys.length; i++) {
                                            convertedObj[keys[i]] = obj[keys[i]];
                                          }

                                          return convertedObj;
                                        }))
                                  }
                                />
                              </Col>
                            </>
                          ) : val?.fieldType == "date" ? (
                            <Col xs={12} md={14} lg={2}>
                              {/* <span className={styles.cssClassMob}>
                              {val?.fieldName} {val?.required && `*`}
                            </span> */}

                              <DatePicker
                                name={productsData[val?.apiName]}
                                onChange={onChange}
                                style={{ width: "100%" }}
                              />
                            </Col>
                          ) : (val?.fieldType == "text") ? (
                            <Col xs={12} md={14} lg={2}>
                              {/* <span className={styles.cssClassMob}>
                              {val?.fieldName} {val?.required && `*`}
                            </span> */}

                              <Input name={productsData[val?.apiName]} value={productsData[val?.apiName]} placeholder={val?.label} onChange={(e) => Details(e, val?.apiName)} />
                            </Col>
                          ) : (
                            <Col xs={12} md={14} lg={2}>
                              {/* <span className={styles.cssClassMob}>
                              {val?.fieldName} {val?.required && `*`}
                            </span> */}

                              <Input name={productsData[val?.apiName]} placeholder={val?.label} value={productsData[val?.apiName]} />
                            </Col>
                          )}
                    </>
                  )
              )}
            <Col xs={24} md={14} lg={3}> <Form.Item>
              <Button type="primary" onClick={handleData} className={styles.btnsgreen} >
                +
              </Button>
            </Form.Item></Col>
          </Row>

        </div>

      </div>
    </div>
  );
}

export default ProductsCompo;