import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../../../utils/Network';
import styles from "./index.module.css";
// import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';


function CurrencyView() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`currency/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data[0])
      })
      .catch(err => console.log(err));
  }
  console.log(viewPurchase, "ViewPurchase")


  return (


    <div style={{ width: "100%" }}>
      <div>
        <h2 className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate("/master/Currency")} style={{marginRight: '10px'}}/> Currency Details</h2>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Currency Id</p>
              <p>{viewPurchase?.currencyID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Currency Name</p>
              <p>{viewPurchase?.currencyName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Currency Country</p>
              <p>{viewPurchase?.currencyCountry}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Currency Symbol</p>
              <p><div dangerouslySetInnerHTML={{__html: viewPurchase?.currencySymbol}}></div></p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Currency Code</p>
              <p>{viewPurchase?.currencyCode}</p>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default CurrencyView