import { Modal } from "antd";
import React, { Children } from "react";

function Modals({
  title,
  open,
  handleOk = () => {},
  handleCancel = () => {},
  children,
}) {
  return (
    <Modal
      title={title}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {children}
    </Modal>
  );
}

export default Modals;
