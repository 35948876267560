import React, { useState } from 'react'
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Switch,
    Modal,
    TimePicker
} from "antd";


function DownloadeFile({ data }) {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const handleData = (value) => {
        console.log(value, "0989hjkkhjgjb");
    }
    return (
        <>
            {/* <div>DownloadeFile{data}</div> */}

            <Form form={form}  onFinish={handleData}>
                <Row gutter={[26, 7]}>
                    <Col xs={24} md={14} lg={12}>
                        <span>Values for Download</span>
                        <Form.Item
                            name="values"
                            rules={[
                                { required: true, message: "Please enter In Time" },
                            ]}
                        >
                            <Input type='number'/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={14} lg={12}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ margin: "20px" }}
                            loading={isLoading}>
                            Download
                        </Button>
                    </Form.Item>
                    </Col>
                </Row>
                <Row>
                    
                </Row>

            </Form>
        </>

    )
}

export default DownloadeFile