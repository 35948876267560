import React, { useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Modal,
  TimePicker
} from "antd";
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { api_call_token } from '../../../utils/Network';

function ConOutForm({ data,handelClose,getContainerData  }) {
  console.log(data, "ConInForm");
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const today = moment();

  const handleData = (values) => {
    console.log(values, "handleData");
    let dateString = values?.containerOutDate?._i
      ? values?.containerOutDate?._i
      : moment(values.containerOutDate?.$d).format("YYYY-MM-DD");
    let timeString = values?.containerOutTime?._i ? values?.containerOutTime?._i : moment(values?.containerOutTime?.$d).format("hh:mm")

    const { containerOutDate, containerOutTime, containerWeightAtOutTime, ...rest } = values
    let newDatas = {
      ...rest,
      containerOutDate: dateString,
      containerOutTime: timeString,
      containerWeightAtOutTime: containerWeightAtOutTime,
      containerEntryId: data?.containerEntryId,
      containerId: data?.containerId,

    }
    console.log(newDatas, "dnaudh9676567tfgvbsdakj");
    api_call_token
        .patch(`containerentry/${id}`, newDatas)
        .then((res) =>{
            console.log(res?.data?.data);
            if(res?.status == 200 || res?.status == 201 || res?.status == 204){
                // alert('update')
                handelClose();
                getContainerData();
            }
        })
        .catch((err) =>{
            console.log(err);
        })

  }

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <>
      <div>Container Out Details </div>

      <Form form={form} initialValues={{
        ...data,
        containerOutDate: data?.containerOutDate ? moment(data?.containerOutDate, "YYYY-MM-DD") : moment(today, "YYYY-MM-DD"),
        containerOutTime: data?.containerOutTime ? moment(data?.containerOutTime, "HH:mm:ss") : moment(today, "hh:mm"),
        containerWeightAtOutTime: data?.containerWeightAtOutTime
      }} onFinish={handleData}>
        <Row gutter={[26, 7]}>
          <Col xs={24} md={14} lg={12}>
            <span>Out Date</span>
            <Form.Item
              name="containerOutDate"
              rules={[
                { required: true, message: "Please enter In Time" },
              ]}
            >
              <DatePicker
                onChange={onChange}
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={12}>
            <span>Out Time</span>
            <Form.Item
              name="containerOutTime"
              rules={[
                { required: true, message: "Please enter In Time" },
              ]}
            >
              <TimePicker
                format="HH:mm " />
            </Form.Item>
          </Col>
          <Col xs={24} md={14} lg={12}>
            <span>Out Time Weight in kgs</span>
            <Form.Item
              name="containerWeightAtOutTime"
              rules={[
                { required: true, message: "Please enter In Time" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row> */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ margin: "20px" }}
              loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        {/* </Row> */}
      </Form>
    </>
  )
}

export default ConOutForm