import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../utils/Network';
import styles from "./index.module.css";
import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';


function ViewBoe() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`billofentry/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data)
      })
      .catch(err => console.log(err));
  }
  console.log(viewPurchase, "ViewPurchase")


  return (


    <div style={{ width: "100%" }}>
      <div>
        <p className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate(-1)} /> BOE Details</p>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Boe Id</p>
              <p>{viewPurchase?.boeId}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Boe Number</p>
              <p>{viewPurchase?.boeNo}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>CHA</p>
              <p>{viewPurchase?.cha}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Consignee</p>
              <p>{viewPurchase?.consignee}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Boe Date</p>
              <p>{moment(viewPurchase?.boeDate).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Assets Value</p>
              <p>{viewPurchase?.assessValue}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Quantity</p>
              <p>{viewPurchase?.quantity}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Currency</p>
              <p>{viewPurchase?.currencyName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Port</p>
              <p>{viewPurchase?.portName}</p>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default ViewBoe