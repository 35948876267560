import React, { useEffect, useState } from 'react'
import styles from "./index.module.css"
import { Button, Input, Pagination, Table } from "antd";
import { PlusCircleTwoTone, SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { alertMessage } from '../../utils/helperFunctions';
import { useNavigate } from 'react-router-dom';
import EditIcon from "../../Assets/ICONS/edit.png";
import { api_call_token, base_url } from '../../utils/Network';

function DeliveryChallan() {
    let navigate = useNavigate();
    const [dataList, setDataList] = useState([]);
    const [filterObj, setFilterObj] = useState({
        page: 1,
    });
    const [values, setValues] = useState({});
    const [open, setOpen] = useState(false);  // modal opne
    const [selectedValue, setSelectedValue] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberofPage, setTotalNumberofPage] = useState(null);

    const pageSize = 10; // Set your page size here
    const currentPages = currentPage || 1; // Current page from pagination object

    const onSearch = (event) => {
        let value = event.target.value;
        let obj = filterObj;
        if (value) {
            obj.search = value;
            obj.page = 1;
            setCurrentPage(1);
        } else delete obj.search;
        setFilterObj({ ...obj });
    };

    const handleData = (v) => {
        console.log(v, "handleDatav");
        navigate(`challanform`, { state: v })
    }

    useEffect(() => {
        getDataValues();
    }, [filterObj]);

    const getDataValues = (paramObj = { ...filterObj }) => {
        api_call_token
            .get(`deliveryChallan`, { params: { ...paramObj } })
            .then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setValues(res.data)
                    setDataList(res.data.data)
                    setCurrentPage(res.data.currentPage)
                    setTotalNumberofPage(res.data.totalRowCount)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getRowClassName = (record)=>{
        switch (record?.productPresent){
            case true:
                return `${styles.green}`;
            case false:
                return `${styles.red}`;
            default:
                return '';
        }
    }

    const columns = [
        {
            title: 'Sr No',
            dataIndex: 'deliveryChallanId',
            // render: (_, __, index) => (currentPages - 1) * pageSize + index + 1,
            // dataIndex: 'containerID',
            // sorter: {
            //     compare: (a, b) => a.containerID - b.containerID,
            //     multiple: 3,
            // },
            render: (_, record, index) => {
                console.log(_, record, index, "98387bfbhffhb")
                return <div style={{ cursor: "pointer" }} onClick={() => handleData(record)}>{(currentPages - 1) * pageSize + index + 1}</div>
            }
        },
        // {
        //     title: "",
        //     dataIndex: "productPresent",
        //     render: (text) => {
        //         let backgroundColor = "";
        //         let borderRadius = "20px";
        //         let padding = "7px";
        //         let color = "white";
        //         let textAlign = "center";

        //         if (text === true) {
        //             color = "#087A17";
        //             borderRadius = "20.727px";
        //             // backgroundColor = "#DAEBDB";
        //             backgroundColor = "#087A17";
        //         }
        //          else if (text === false) {
        //             // backgroundColor = "#FCE6E8";
        //             backgroundColor = "#DE2326";
        //             borderRadius = "20.727px";
        //             color = "#DE2326";
        //         }

        //         const style = {
        //             backgroundColor,
        //             borderRadius,
        //             padding,
        //             color,
        //             width: "3px",
        //             textAlign,
        //         };
        //         console.log(text, "fjf8997");
        //         return <div style={style}></div>;
        //     },
        // },
        {
            title: 'Delivery Challan Container',
            dataIndex: 'deliveryChallanContainer',
            sorter: {
                compare: (a, b) => a.containerNo - b.containerNo,
                multiple: 3,
            },
            render: (_, record, index) => {
                console.log(_)
                return <div style={{ cursor: "pointer" }} onClick={() => handleData(record)}>{_}</div>
            }
        },
        {
            title: 'Voucher No',
            dataIndex: 'deliveryChallanVoucherNo',
            render: (_, record, index) => {
                console.log(_)
                return <div>{_ ? _ : "N.A"}</div>
            }
        },
        // {
        //   title: 'Port Of Loading',
        //   dataIndex: 'portOfLoadingName',
        // },
        // {
        //   title: 'Final Destination',
        //   dataIndex: 'portOfDepartureName',
        // },
        {
            title: 'BOE',
            dataIndex: 'deliveryChallanBOE',
            render: (_, record, index) => {
                console.log(_)
                return <div>{_ ? _ : "N.A"}</div>
            }
        },

        // {
        //     title: 'In Date',
        //     dataIndex: 'deliveryChallanBOE',
        //     render: (_, { containerInDate }) => {
        //         console.log(_, { containerInDate }, "containerInDate");
        //         const formattedDate = moment(containerInDate).format('YYYY-MM-DD');
        //         return (
        //             <div>
        //                 {formattedDate}
        //                 {/* {_} */}
        //             </div>
        //         );
        //     },

        // },
        // {
        //     title: 'In Time',
        //     dataIndex: 'containerInTime',
        //     render: (_, { containerInTime }) => {
        //         console.log(_, { containerInTime }, "containerInDate");
        //         const formattedDate = containerInTime ? moment(containerInTime, "HH:mm:ss").format('hh:mm a') : "N.A";

        //         return (
        //             <div>
        //                 {formattedDate}
        //                 {/* {_} */}
        //             </div>
        //         );
        //     },

        // },
        {
            title: 'Challan Date',
            dataIndex: 'deliveryChallanDate',
            render: (_, { containerOutDate }) => {
                const formattedDate = moment(_).format('DD-MMMM-YYYY');
                return (
                    <div>
                        {formattedDate}
                        {/* {_} */}
                    </div>
                );
            },

        },
        // {
        //     title: 'Out Time',
        //     dataIndex: 'containerOutTime',
        //     render: (_, { containerOutTime }) => {
        //         console.log(_, { containerOutTime }, "containerInDate");
        //         const formattedDate = containerOutTime ? moment(containerOutTime, "HH:mm:ss").format('hh:mm a') : "N.A";
        //         return (
        //             <div>
        //                 {formattedDate}
        //                 {/* {_} */}
        //             </div>
        //         );
        //     },

        // },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                const { containerEntryId } = record;

                return (
                    <div>
                        <div style={{ width: 'max-content', cursor: 'pointer', display: "flex", gap: "10px", alignItems: "center" }}>
                            <img onClick={() => handleData(record)} src={EditIcon} alt='consignment' />
                            <DownloadOutlined
                                // onClick={() => download(record)}
                                onClick={() => handleViewClick(record)}
                                style={{ fontSize: "30px" }}
                            />
                        </div>
                        {/* <button onClick={handleViewClick}>Edit</button> */}
                    </div>
                );
            },
        },
    ];

    const handlePageChange = (page) => {
        console.log(page, "aaaaaaaaa")
        setFilterObj({ ...filterObj, page: page })
        setCurrentPage(page);
    };

    const paginationConfig = {
        current: currentPage,
        pageSize: 10, // Number of items per page
        total: totalNumberofPage, // Total number of items
        onChange: handlePageChange,
    };
    console.log(dataList, "dataList")

    const handleViewClick = (data) => {
        api_call_token
            .get(`pdf/generateDeliveryChallanPdf/${data?.deliveryChallanContainerId}`)
            .then((res) => {
                console.log(res?.data?.data);
                if (!res.data.filepath) {
                    alertMessage("File Not Present", 2)
                    return;
                }
                console.log(res, "RESDAFATA");
                let data = res.data.filepath;
                var inputString = data;
                // var resultString = inputString.substring(inputString.indexOf("/") + 2);

                window.open(`${base_url}${inputString}`);
            })
            .catch((err) => {
                console.log(err);
            })
    };
    return (
        <>
            <div className={styles.mainContainer}>
                <div className={styles.rowContainer}>
                    <h2>Delivery Challan</h2>
                    <div className={styles.searchData}>
                        <Input
                            size="medium"
                            placeholder="Search By BOE, PO Number, Supplier Name, Port Name"
                            prefix={<SearchOutlined />}
                            onChange={(e) => onSearch(e)}
                            style={{ width: "70%" }}
                        />
                    </div>
                </div>
                {/* <div className={styles.productsIndicator}>
                    <p>Status Indicator</p>
                    <div>
                        <div className={styles.indicatortext} style={{ display: "flex", gap: '20px', alignItems: 'center' }}>
                            <div className={styles.indicatorStyles} style={{ backgroundColor: "#087A17" }}></div>
                            <p>Container has Products</p>
                        </div>
                        <div className={styles.indicatortext}>
                            <div className={styles.indicatorStyles} style={{ backgroundColor: "#DE2326" }}></div>
                            <p>Container has No Products</p>
                        </div>
                    </div>
                </div> */}
                <Table
                    rowClassName={getRowClassName}
                    columns={columns}
                    dataSource={dataList}
                    pagination={false} // Adjust as needed
                    scroll={{ x: true }} // Enable horizontal scrolling if needed
                />

                <div style={{ textAlign: "right", margin: "10px" }}>
                    <Pagination {...paginationConfig} />
                </div>
            </div>
        </>
    )
}

export default DeliveryChallan