import { FilterOutlined, PlusCircleTwoTone, AudioOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Tag } from 'antd';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../Components/CustomTable/CustomTable';
import { api_call, api_call_token } from '../../utils/Network';
import styles from './index.module.css';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import EditIcon from "../../Assets/ICONS/edit.png";

const suffix = (
    <AudioOutlined
        style={{
            fontSize: 16,
            color: '#1677ff',
        }}
    />
);

function InvoiceList() {
    let navigate = useNavigate();
    const [fieldValues, setFieldValue] = useState([]);
    const [values, setValues] = useState({});
    const [dataList, setDataList] = useState([]);
    const [filterObj, setFilterObj] = useState({
        page: 1,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberofPage, setTotalNumberofPage] = useState(null);

    useEffect(() => {
        getDataValues();
    }, [filterObj, currentPage]);

    const getDataValues = (paramObj = { ...filterObj}) => {
        api_call_token
            .get(`invoice`, { params: { ...paramObj } })
            .then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setValues(res.data)
                    setDataList(res.data.data)
                    setCurrentPage(res.data.currentPage)
                    setTotalNumberofPage(res.data.totalRowCount)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onSearch = (event) => {
        let value = event.target.value;
    let obj = filterObj;
    if (value) {
      obj.search = value;
      obj.page = 1;
      setCurrentPage(1);
    } else delete obj.search;
    setFilterObj({ ...obj });
    }

    const navigateToCreateForm = () => {
        navigate('createInvoice')
    }

    const redirectTo = (value) => {
        api_call_token
            .get(`invoice/${value}`)
            .then((res) => {
                console.log(res.data);
                if (res.status == 200 || res.status == 201) {
                    navigate('createInvoice', { state: { data: res.data, id: value } })
                }
            })
            .catch(err => console.log(err))
    }

    const handleData = (v) => {
        navigate(`${v}`)
    }

    const columns = [
        {
            title: 'Invoice ID',
            dataIndex: 'invoiceID',
            sorter: {
                compare: (a, b) => a.invoiceID - b.invoiceID,
                multiple: 3,
            },
            render: (_, { invoiceID }) => {
                console.log(_)
                return <div style={{cursor:"pointer"}} onClick={() => handleData(invoiceID)}>{_}</div>}
        },
        {
            title: 'Invoice No',
            dataIndex: 'invoiceNo',
            sorter: {
                compare: (a, b) => a.invoiceNo - b.invoiceNo,
                multiple: 3,
            },
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoiceDate',
            render: (_, { invoiceDate }) => {
                const formattedDate = moment(invoiceDate).format('DD-MMMM-YYYY');
                return (
                    <div>
                        {formattedDate}
                    </div>
                );
            },
        },
        {
            title: 'Supplier Name',
            dataIndex: 'supplierName',
        },
        {
            title: 'Buyer Name',
            dataIndex: 'buyerName',

        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                const { invoiceID } = record;
                const handleViewClick = () => {
                    redirectTo(invoiceID);
                };
                return (
                    <div>
                        <div onClick={handleViewClick} style={{ width: 'max-content', cursor: 'pointer' }}>
                            <img src={EditIcon} alt='consignment' />
                        </div>
                    </div>
                );
            },
        },
    ];

    const handlePageChange = (page) => {
        console.log(page, "aaaaaaaaa")
        setFilterObj({...filterObj, page: page})
        setCurrentPage(page);
    };

    const paginationConfig = {
        current: currentPage,
        pageSize: 10, // Number of items per page
        total: totalNumberofPage, // Total number of items
        onChange: handlePageChange,
    };

    console.log(totalNumberofPage, "filterObjfilterObj")

    return (
        <div className={styles.mainContainer}>
            <div className={styles.rowContainer}>
                <h2>Purchase Invoice</h2>
                <div className={styles.searchData}>

                    <Input size="medium" placeholder="Search By Invoice Name" prefix={<SearchOutlined />} onChange={(e) => onSearch(e)} style={{ width: '70%' }} />
                    <Button type="primary" onClick={navigateToCreateForm} style={{ margin: '0 10px' }}> <PlusCircleTwoTone style={{ marginRight: '10px' }} /> Create Purchase Invoice</Button>
                </div>
            </div>
            <div>
                <CustomTable columns={columns} data={dataList} />
                <div style={{ textAlign: 'right', margin: '10px' }}>
                    <Pagination {...paginationConfig} showSizeChanger={false}/>
                </div>
            </div>
        </div>
    )
}

export default InvoiceList