import { Button, Dropdown, Layout, Menu, theme } from 'antd';
import React, { useState } from 'react';
import Logos from "../../Assets/logo/logo.png";
// import Logos from "../../Assets/logo/logoPhoolchand.png";

import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import CreateField from '../../Pages/FieldList/CreateField';
import Dash from '../../Assets/SideBar/Icon-2.png';
import PurchaseImg from '../../Assets/SideBar/Purchase.png';
import ConsignmentImg from '../../Assets/SideBar/Consignment.png';
import DsrImg from '../../Assets/SideBar/dsr.png';
import ContainerIcon from '../../Assets/SideBar/Container-2.png';
import Shipping from '../../Assets/SideBar/Shipping.png';
import BOE from '../../Assets/SideBar/Boe.png';
import Master from '../../Assets/SideBar/Master.png';
import Heat from '../../Assets/SideBar/Heat.png';
import ConsignmentList from '../../Pages/Consignment/ConsignmentList';
import Container from '../../Pages/Container/Container';
import ShippingList from '../../Pages/Shipping/ShippingList';
import { getLocalData } from '../../utils/helperFunctions';
import MasterList from '../../Pages/Master/MasterList/MasterList';
import Invoice from '../../Pages/Invoice/Invoice';
import InvoiceImg from '../../Assets/SideBar/invoice.png';
import { CloseOutlined, LineOutlined } from '@ant-design/icons';
import SupplierList from '../../Pages/Master/MasterData/Supplier/SupplierList/SupplierList';
import ProductList from '../../Pages/Master/MasterData/Product/ProductList/ProductList';
import HeatMain from '../../Pages/Heat/HeatMain';
import Dsr from '../../Pages/DSR/Dsr';
import ContainerInOutList from '../../Pages/ContainerInOut/ContainerInOutList';
import ConINOUTImg from "../../Assets/SideBar/containerInout.png"
import deliverychallan from "../../Assets/SideBar/Delivery Challan.png"
import DeliveryChallan from '../../Pages/DeliveryChallan/DeliveryChallan';


const { Header, Content, Footer, Sider } = Layout;

function Sidenav({ visible, setVisible = () => { }, children }) {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(true);
    let data = getLocalData("phoolchand-user");

    console.log(data, "DDDDD")

    const items = [
        {
            key: '1',
            label: (
                <div onClick={() => handleLogout()}>
                    Logout
                </div>
            ),
        },
    ]

    const handleLogout = () => {
        localStorage.removeItem("phoolchand-access-token");
        localStorage.removeItem("phoolchand-user");
        window.location.reload();
    }

    return (
        <div className={styles.positionSide}>
            {/* <Layout style={{ minHeight: "100vh" }}> */}
            {visible && <div className={styles.sideHead}>
                <Sider
                    theme="light"
                    style={{
                        overflow: "auto",
                        height: "100vh",
                        position: "fixed",
                        zIndex: 999,
                        left: 0,
                    }}
                    // collapsible
                    // collapsed={collapsed}
                    // onCollapse={() => setCollapsed(!collapsed)}
                >
                    <div className={styles.logoDiv} >
                        <CloseOutlined className={styles.close} onClick={() => setVisible(!visible)} />
                        <img src={Logos} alt="logo" className={styles.images} />
                    </div>
                    <Menu theme="light" mode="inline" defaultSelectedKeys={["/"]} className={styles.lists} >
                        {allRoutes?.map((m) => {
                            return (
                                <Menu.Item
                                    onClick={() => navigate(m?.path)}
                                    // key={m.path}
                                    icon={<img src={m.icon} alt={m.icon} style={{ width: '25px' }} />}
                                    style={{ fontSize: '18px', marginBottom: '15px' }}
                                // className={styles.menu_item}
                                >
                                    {m.label}
                                </Menu.Item>
                            );
                        })}
                    </Menu>

                    <div className={styles.users}>

                        <Dropdown
                            menu={{
                                items,
                            }}
                            placement="topRight"
                            arrow
                        >
                            <Button style={{ width: '100%', zIndex: 999 }}>{data?.firstName}</Button>
                        </Dropdown>
                    </div>
                </Sider>
            </div>}

        </div>
    )
}

export default Sidenav

const allRoutes = [
    {
        key: 1,
        path: "/",
        label: "Dashboard",
        component: CreateField,
        icon: Dash,
    },
    {
        key: 1,
        path: "/master",
        label: "Master",
        component: MasterList,
        icon: Master,
    },
    {
        key: 3,
        path: "/purchaseMain",
        label: "Purchase order",
        component: CreateField,
        icon: PurchaseImg,
    },
    // {
    //     key: 4,
    //     path: "/consignment",
    //     label: "Consignment",
    //     component: ConsignmentList,
    //     icon: ConsignmentImg,
    // },
    {
        key: 5,
        path: "/container",
        label: "Containers",
        component: Container,
        icon: ContainerIcon,
    },
    {
        key: 6,
        path: "/boe",
        label: "Bill of Entry",
        component: CreateField,
        icon: BOE,
    },
    
    // {
    //     key: 7,
    //     path: "/shipping",
    //     label: "Shipping",
    //     component: ShippingList,
    //     icon: Shipping,
    // },
    {
        key: 8,
        path: "/Invoice",
        label: "Purchase Invoice",
        component: Invoice,
        icon: InvoiceImg,
    },
    {
        key: 7,
        path: "/heat",
        label: "Batch",
        component: HeatMain,
        icon: Heat,
    },
    {
        key: 8,
        path: "/master/Products",
        label: "Products",
        component: ProductList,
        icon: PurchaseImg,
    },
    {
        key: 8,
        path: "/master/Supplier",
        label: "Supplier",
        component: SupplierList,
        icon: ConsignmentImg,
    },
    {
        key: 9,
        path: "/dsr",
        label: "DSR",
        component: Dsr,
        icon: DsrImg,
    },
    {
        key: 10,
        path: "/deliverychallan",
        label: "Delivery Challan",
        component: DeliveryChallan,
        icon: deliverychallan,
    },
    {
        key: 11,
        path: "/inout",
        label: "Container In/Out",
        component: ContainerInOutList,
        icon: ConINOUTImg,
    },
];

