import React, { useEffect, useState } from 'react';
import { Steps, Button, Form, Input, Select } from 'antd';
import styles from './index.module.css';
import HeatGeneration from './HeatForms/HeatGeneration';
import HeatContainer from './HeatForms/HeatContainer';
import HeatCharging from './HeatForms/HeatCharging';
import HeatIngot from './HeatForms/HeatIngot';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { api_call_token } from '../../utils/Network';
import { alertMessage } from '../../utils/helperFunctions';
import HeatElements from './HeatForms/HeatElements';

const { Step } = Steps;
const { Option } = Select;

const HeatMain = () => {
  let { state } = useLocation();
  let navigate = useNavigate();
  let { id } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [heatValues, setHeatValues] = useState({});
  const [heatdata, setHeatData] = useState({});
  const [containerData, setContainerData] = useState({});
  const [consumableData, setConsumableData] = useState({});
  const [ingots, setIngots] = useState({});
  const [fillings, setFilling] = useState({});

  useEffect(() => {
    if (state?.heatID) {
      // setHeatData(state?.data)
      api_call_token
        .get(`heat/${state?.heatID}`)
        .then((res) => {
          setHeatData(res.data[0]);
          setHeatValues({
            id: res?.data[0]?.heatId,
            heatNumber: res?.data[0]?.heatNumber,
            fetchFromXml: res?.data[0]?.fetchFromXml
          })
        })
        .catch((err) => console.log(err))
      getContainer(state?.heatID);
      getConsumable(state?.heatID);
      getIngot(state?.heatID);
      getFillings(state?.heatID);
      return;
    }

    if (heatValues?.id) {
      api_call_token
        .get(`heat/${heatValues?.id}`)
        .then((res) => {
          setHeatData(res.data[0]);
          setHeatValues({
            id: res?.data[0]?.heatId,
            heatNumber: res?.data[0]?.heatNumber
          })
        })
        .catch((err) => console.log(err))
      getContainer(heatValues?.id);
      getConsumable(heatValues?.id);
      getIngot(heatValues?.id);
      getFillings(heatValues?.id);
    }
  }, [currentStep])

  const getHeat = () => {
    api_call_token
        .get(`heat/${state?.heatID}`)
        .then((res) => {
          setHeatData(res.data[0]);
          setHeatValues({
            id: res?.data[0]?.heatId,
            heatNumber: res?.data[0]?.heatNumber,
            fetchFromXml: res?.data[0]?.fetchFromXml
          })
        })
        .catch((err) => console.log(err))
  }

  const getConsumable = (vals) => {
    api_call_token
      .get(`AppHeatConsumableNew/${vals}`)
      .then((res) => {
        setConsumableData(res.data)
      })
      .catch((err) => console.log(err))
  }

  const getContainer = (vals) => {
    api_call_token
      // .get(`heatcontainer/${vals}`)
      .get(`heatcontainer/heatproduct/${vals}`)
      .then((res) => {
        console.log(res.data, "DATA")
        setContainerData(res.data);
      })
      .catch((err) => console.log(err))
  }

  const getIngot = (vals) => {
    api_call_token
      .get(`heatingot/${vals}`)
      .then((res) => {
        setIngots(res.data)
      })
      .catch((err) => console.log(err))
  }

  const getFillings = (vals) => {
    api_call_token
      .get(`heatfilling/${vals}`)
      .then((res) => {
        setFilling(res.data);
      })
      .catch((err) => console.log(err))
  }


  const steps = [
    {
      id: 1,
      title: 'Basic Details',
      content: (
        <HeatGeneration setCurrentStep={setCurrentStep} id={id} setHeatValues={setHeatValues} heatdata={heatdata} heatid={state?.heatID} />
      ),
    },
    {
      id: 2,
      title: 'Container Details',
      content: (
        <HeatContainer setCurrentStep={setCurrentStep} id={id} heatValues={heatValues} heatdata={heatdata} containerDatas={containerData} heatid={state?.heatID} />
      ),
    },
    {
      id: 3,
      title: 'Charging',
      content: (
        <HeatCharging setCurrentStep={setCurrentStep} id={id} heatValues={heatValues} heatdata={heatdata} consumableData={consumableData} heatid={state?.heatID} />
      ),
    },
    {
      id: 4,
      title: 'Ingot Value',
      content: (
        <HeatIngot setCurrentStep={setCurrentStep} id={id} heatValues={heatValues} heatdata={heatdata} ingots={ingots} fillings={fillings} heatid={state?.heatID} />
      ),
    },
    {
      id: 5,
      title: 'Elements',
      content: (
        <HeatElements setCurrentStep={setCurrentStep} id={id} heatValues={heatValues} heatdata={heatdata} getHeat={getHeat}/>
        // <HeatIngot setCurrentStep={setCurrentStep} id={id} heatValues={heatValues} heatdata={heatdata} ingots={ingots} fillings={fillings} heatid={state?.heatID} />
      ),
    },
  ];

  const handleBack = () => {
    navigate(`/heat/list/${id}`)
  }

  const handleChange = (val) => {
    console.log(val, "VVVV")
    if (!heatValues?.id && !state?.heatID) {
      alertMessage("Please add Basic Details first", 2);
      return;
    }
    setCurrentStep(val)
  }

  console.log(heatdata, containerData, consumableData, ingots, fillings, "State")
  return (
    <div className={styles.mainHeat}>
      <h2 style={{ marginBottom: "30px", marginLeft: '10px' }}><ArrowLeftOutlined onClick={handleBack} /> Batch</h2>
      <p style={{marginLeft: '12px'}}>Batch Number : {heatValues?.heatNumber || heatdata?.heatNumber}</p>
      {/* <Steps current={currentStep}>
        {steps.map((step, index) => (
          <Step key={index} title={step.title} />
        ))}
      </Steps> */}

      <div className={styles.selectorMain}>
        {
          datas.map((v, i) => (
            <div className={`${styles.selector} ${(v.id == steps[currentStep]?.id) ? styles.selectedData: ''}`} onClick={() => handleChange(i)}>{v.text}</div>
          ))
        }
      </div>



      <div className="steps-content">{steps[currentStep].content}</div>
    </div>
  );
};

export default HeatMain


const datas = [
  {
    id: 1,
    text: "Basic Details",
  },
  {
    id: 2,
    text: 'Containers',
  },
  {
    id: 3,
    text: 'Consumables',
  },
  {
    id: 4,
    text: 'Ingots'
  },
  {
    id: 5,
    text: 'Analysis'
  }
]



