import { FilterOutlined, PlusCircleTwoTone, AudioOutlined, SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Tag } from 'antd';
import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import moment from 'moment';
import EditIcon from "../../../../../Assets/ICONS/edit.png";
import { api_call_token } from '../../../../../utils/Network';
import CustomTable from '../../../../../Components/CustomTable/CustomTable';

const suffix = (
    <AudioOutlined
        style={{
            fontSize: 16,
            color: '#1677ff',
        }}
    />
);

function AgentList() {
    let navigate = useNavigate();
    const [fieldValues, setFieldValue] = useState([]);
    const [values, setValues] = useState({});
    const [dataList, setDataList] = useState([]);
    const [filterObj, setFilterObj] = useState({
        page: 1,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberofPage, setTotalNumberofPage] = useState(null);

    const pageSize = 10; // Set your page size here
    const currentPages = currentPage || 1; // Current page from pagination object

    useEffect(() => {
        getDataValues();
    }, [filterObj, currentPage]);

    const getDataValues = (paramObj = { ...filterObj, page: currentPage || 1 }) => {
      api_call_token
            .get(`agent`, { params: { ...paramObj } })
            .then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setValues(res.data)
                    setDataList(res.data.data)
                    setCurrentPage(res.data.currentPage)
                    setTotalNumberofPage(res.data.totalRowCount)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onSearch = (event) => {
        let value = event.target.value;
        let obj = filterObj;
        if (value) {obj.search = value; setCurrentPage(1)}
        else delete obj.search;
        setFilterObj({ ...obj });
    }

    const navigateToCreateForm = () => {
        navigate('agentform', { state: {data: [{active: true}] }})
    }

    const redirectTo = (value) => {
        api_call_token
            .get(`agent/${value}`)
            .then((res) => {
                console.log(res.data);
                if (res.status == 200 || res.status == 201) {
                    navigate('agentform', { state: { data: res.data, id: value } })
                }
            })
            .catch(err => console.log(err))
    }

    const handleData = (v) => {
        navigate(`${v}`)
    }

    const columns = [
        {
            title: 'Sr no',
            dataIndex: 'agentID',
            render: (_, { agentID }, index) => {
                console.log(_)
                return <div style={{cursor:"pointer"}} onClick={() => handleData(agentID)}>{(currentPages - 1) * pageSize + index + 1}</div>}
            // render: (_, __, index) => (currentPages - 1) * pageSize + index + 1,
        },
        {
            title: 'Agent Name',
            dataIndex: 'agentName',
            sorter: {
                compare: (a, b) => a.agentName - b.agentName,
                multiple: 3,
            },
        },
        {
            title: 'Agent Email',
            dataIndex: 'agentEmail',
        },
        {
            title: 'Status',
            dataIndex: 'active',
            render: (demo) => {
                console.log(demo, "DEEm")
                return (
                    <div>
                        {(demo) ? "Active": "Inactive"}
                    </div>
                )
            }
            
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                const { agentID } = record;
                const handleViewClick = () => {
                    redirectTo(agentID);
                };
                return (
                    <div>
                        <div onClick={handleViewClick} style={{ width: 'max-content', cursor: 'pointer' }}>
                            <img src={EditIcon} alt='consignment' />
                        </div>
                    </div>
                );
            },
        },
    ];

    const handlePageChange = (page) => {
        console.log(page, "aaaaaaaaa")
        setFilterObj({...filterObj, page: page})
        setCurrentPage(page);
    };

    const paginationConfig = {
        current: currentPage,
        pageSize: 10, // Number of items per page
        total: totalNumberofPage, // Total number of items
        onChange: handlePageChange,
    };

    console.log(totalNumberofPage, "filterObjfilterObj")

    return (
        <div className={styles.mainContainer}>
            <div className={styles.rowContainer}>
                <h2><ArrowLeftOutlined onClick={() => navigate("/master")} style={{marginRight: '10px'}}/>Agent</h2>
                <div className={styles.searchData}>

                    <Input size="medium" placeholder="Search By Agent Name" prefix={<SearchOutlined />} onChange={(e) => onSearch(e)} style={{ width: '70%' }} />
                    <Button type="primary" onClick={navigateToCreateForm} style={{ margin: '0 10px' }}> <PlusCircleTwoTone style={{ marginRight: '10px' }} /> Create Agent</Button>
                </div>
            </div>
            <div>
                <CustomTable columns={columns} data={dataList} />
                <div style={{ textAlign: 'right', margin: '10px' }}>
                    <Pagination {...paginationConfig} />
                </div>
            </div>

            <Outlet />
        </div>
    )
}

export default AgentList