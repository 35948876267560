import {
  FilterOutlined,
  PlusCircleTwoTone,
  AudioOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Button, Input, Pagination, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import moment from "moment";
import EditIcon from "../../../../../Assets/ICONS/edit.png";
import { api_call_token } from "../../../../../utils/Network";
import CustomTable from "../../../../../Components/CustomTable/CustomTable";

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1677ff",
    }}
  />
);

function SupplierList() {
  let navigate = useNavigate();
  const [fieldValues, setFieldValue] = useState([]);
  const [values, setValues] = useState({});
  const [dataList, setDataList] = useState([]);
  const [filterObj, setFilterObj] = useState({
    page: 1,
    isInternational: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);

  useEffect(() => {
    getDataValues();
  }, [filterObj, currentPage]);

  const getDataValues = (
    paramObj = { ...filterObj, page: currentPage || 1 }
  ) => {
    api_call_token
      .get(`supplier`, { params: { ...paramObj } })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setValues(res.data);
          setDataList(res.data.data);
          setCurrentPage(res.data.currentPage);
          setTotalNumberofPage(res.data.totalRowCount);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSearch = (event) => {
    let value = event.target.value;
    let obj = filterObj;
    if (value) {
      obj.search = value;
      setCurrentPage(1);
    } else delete obj.search;
    setFilterObj({ ...obj });
  };

  const navigateToCreateForm = () => {
    navigate("supplierform");
  };

  const redirectTo = (value) => {
    api_call_token
      .get(`supplier/${value}`)
      .then((res) => {
        console.log(res.data);
        if (res.status == 200 || res.status == 201) {
          // navigate('createboe', { state: { data: res.data, id: value } })
        }
      })
      .catch((err) => console.log(err));
  };

  const handleData = (v) => {
    navigate(`${v}`);
  };

  const columns = [
    {
      title: "Supplier ID",
      dataIndex: "supplierID",
      sorter: {
        compare: (a, b) => a.supplierID - b.supplierID,
        multiple: 3,
      },
      render: (_, { supplierID }) => {
        console.log(_);
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleData(supplierID)}
          >
            {_}
          </div>
        );
      },
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      sorter: {
        compare: (a, b) => a.boeNo - b.boeNo,
        multiple: 3,
      },
    },
    {
      title: "Country",
      dataIndex: "country",
    },
  ];

  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };

  console.log(totalNumberofPage, "filterObjfilterObj");

  return (
    <div className={styles.mainContainer}>
      <div className={styles.rowContainer}>
        {/* <h2><ArrowLeftOutlined onClick={() => navigate("/master")} style={{marginRight: '10px'}}/>Supplier</h2> */}
        <div className={styles.searchData}>
          <Input
            size="medium"
            placeholder="Search By Supplier Name"
            prefix={<SearchOutlined />}
            onChange={(e) => onSearch(e)}
            style={{ width: "70%" }}
          />
          <Button
            type="primary"
            onClick={navigateToCreateForm}
            style={{ margin: "0 10px" }}
          >
            {" "}
            <PlusCircleTwoTone style={{ marginRight: "10px" }} /> Create Domestic
            Supplier
          </Button>
        </div>
      </div>
      <div>
        <CustomTable columns={columns} data={dataList} />
        <div style={{ textAlign: "right", margin: "10px" }}>
          <Pagination {...paginationConfig} showSizeChanger={false}/>
        </div>
      </div>

      <Outlet />
    </div>
  );
}

export default SupplierList;
