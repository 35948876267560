import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../utils/Network';
import styles from "./index.module.css";
import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';


function ViewConsignment() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`consignment/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data)
      })
      .catch(err => console.log(err));
  }
  console.log(viewPurchase, "ViewPurchase")


  return (


    <div style={{ width: "100%" }}>
      <div>
        <p className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate(-1)} /> Consignment Details</p>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Consignment Id</p>
              <p>{viewPurchase?.consignmentID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Container Id</p>
              <p>{viewPurchase?.containerID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Custom Currency</p>
              <p>{viewPurchase?.customCurrencyName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Assessment Value</p>
              <p>{viewPurchase?.assessmentValue}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Bill Of Entry</p>
              <p>{moment(viewPurchase?.billOfEntry).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Boe Passing Date</p>
              <p>{moment(viewPurchase?.boePassingDate).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Bond Number</p>
              <p>{viewPurchase?.bondNumber}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Cfs Charges</p>
              <p>{viewPurchase?.cfsCharges}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Cha Charges</p>
              <p>{viewPurchase?.chaCharges}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Duty</p>
              <p>{viewPurchase?.duty}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>E Invoice Date</p>
              <p>{moment(viewPurchase?.eInvoiceDate).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Igm Date</p>
              <p>{moment(viewPurchase?.igmDate).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Igm Number</p>
              <p>{viewPurchase?.igmNumber}</p>
            </div>

            <div className={styles.contentWrapper}>
              <p>Last Date</p>
              <p>{moment(viewPurchase?.lastDate).format("YYYY-MM-DD")}</p>
            </div>

            <div className={styles.contentWrapper}>
              <p>Misc Charges</p>
              <p>{viewPurchase?.miscCharges}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Shipping Charges</p>
              <p>{viewPurchase?.shippingCharges}</p>
            </div>
            <div className={styles.contentWrapper}>
            <p>Total Quantity</p>
            <p>{viewPurchase?.totalQuantity}</p>
          </div>
            <div className={styles.contentWrapper}>
              <p>Transport Charges</p>
              <p>{viewPurchase?.transportCharges}</p>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default ViewConsignment