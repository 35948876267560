import { Col, Row } from 'antd'
import React from 'react'
import styles from './index.module.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

function PortMain() {
    let navigate = useNavigate();

    const goTo = (v) => {
        navigate(v.redirect)
    }
  return (
    <div style={{width: '87%', padding: '20px'}}>
      <div style={{display: 'flex', alignItems: 'center'}}><ArrowLeftOutlined onClick={() => navigate("/master")} style={{marginRight: '10px', fontWeight: '900', width: '20px'}} /><h2>Ports</h2></div>
      <div style={{marginTop: '30px'}}>
        <Row gutter={[16, 16]}>
          {masterList && masterList.map((v,i) => 
          <Col xs={24} md={14} lg={8} >
              <div className={styles.mainData} onClick={() => goTo(v)}>
                {/* <img src={`http://64.227.152.233:8080/${v.masterImage}`} alt={v.masterImage}/>  */}
                <p>{v.masterName}</p>
              </div>
          </Col>)}
          

        </Row>
      </div>

      <Outlet />
    </div>
  )
}

export default PortMain


const masterList = [
    {
        masterName: "Port",
        masterCount: '2',
        redirect: "port"
    },
    {
        masterName: "Place of receipt",
        masterCount: '2',
        redirect: 'pr'
    }
]