import Axios from "axios";
import { base_url, setApiToken } from "./Network";
export var token = "";

const tinyMceApiKey = "c9lqtp6dnvv4l0xhq129x4gehnwfy9l573k7u97ccn7q0z2y";

function falseChecker(value) {
    if (value !== "" && value !== undefined && value !== null) {
        return true
    }
    return false
}


function isAnyKeyTruthy(obj) {
    for (const key in obj) {
      if (obj[key]) {
        return true; // If any value is truthy, return true
      }
    }
    return false; // If no truthy value found, return false
  }

function handleImageAutoupload(blobInfo, success, failure, progress, _token) {  // image auto upload function

    // console.log("The iMage upload handler ", blobInfo, success, failure, progress);
    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    Axios({
        method: "POST",
        url: `${base_url}base/file/`,
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
        headers: { 'Authorization': `Bearer ${falseChecker(_token) ? _token : token}` }
    })
        .then(response => {

            if (response.status === 201) {
                success(response.data.data.file);
            }
        })
        .catch(error => {
            failure(error)
        })



}

function indexToAlphabet(index) {
    switch (index) {
        case 0: return "A";
        case 1: return "B";
        case 2: return "C";
        case 3: return "D";
        default: return undefined
    }
}


function debuglog(boolean, message, ...vars) {  // degugger 
    if (boolean) {
        console.log(message, ...vars);
    }
}

function checkEmptyObject(params) {
    return [...Object.values(params)].every(Boolean);
}

export {
    falseChecker,
    indexToAlphabet,
    tinyMceApiKey,
    handleImageAutoupload,
    debuglog,
    checkEmptyObject,
    isAnyKeyTruthy
};