import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useContext } from 'react'
import { UserCredsContext } from "../../../../../utils/UserCredsContext";

function ContactInfo({ contactInfo, setContactInfo = () => { }, valueSelected }) {
    const [form] = Form.useForm();
    const { base_items, set_Base } = useContext(UserCredsContext);
    const onFinish = (value) => {
        console.log(value, "SetValuesssssss")
        setContactInfo([...contactInfo, value])
        form.resetFields();
    }
    console.log(valueSelected, "VALSSSSSSSS")

    return (
        <div>
            <Form form={form} onFinish={onFinish}>
                <Row gutter={[24, 5]}>
                    <Col sm={24} lg={24}>
                        Contact Information
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Full Name</span>
                        <Form.Item
                            name="supplierContactInfoName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Email Id</span>
                        <Form.Item name="supplierContactInfoEmail">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Designation</span>
                        <Form.Item
                            name="supplierContactInfoDesignation"
                            rules={[
                                { required: true, message: "Please enter annual turnover" },
                            ]}
                        >
                            <Select
                                allowClear
                                options={[
                                    {
                                        value: 'Partner',
                                        label: 'Partner',
                                    },{
                                        value: 'Owner',
                                        label: 'Owner',
                                    },{
                                        value: 'Director',
                                        label: 'Director',
                                    },{
                                        value: 'Sales Department',
                                        label: 'Sales Department',
                                    },{
                                        value: 'Accounts Department & Finance Department',
                                        label: 'Accounts Department & Finance Department',
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Landline/Mobile</span>
                        <Form.Item name="supplierContactInfoLandlineNo">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>GIN</span>
                        <Form.Item name="supplierContactInfoGIN">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Mobile</span>
                        <Form.Item name="supplierContactInfoMobile">
                            <Input type="number" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Address</span>
                        <Form.Item name="supplierContactInfoAddress">
                            <Input />
                        </Form.Item>
                    </Col>

                </Row>
                <div style={{ textAlign: 'right' }}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save and Add another
                        </Button>
                    </Form.Item>

                </div>
            </Form>

        </div>
    )
}

export default ContactInfo