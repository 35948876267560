import { react, useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import styles from './HeatView.module.css';
import { api_call_token, base_url } from "../../utils/Network";
import { formatDate, formatTime } from "../../utils/helperFunctions";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";

function HeatView() {
  let { id } = useParams();
  let navigate = useNavigate();

  console.log(id, "id")

  const [totalIngot, setTotalIngot] = useState(0);
  const [totalIngotQuantity, setTotalIngotQuantity] = useState(0);
  const [heatData, setHeatData] = useState({})
  const [heatCon, setHeatConainer] = useState({});
  const [Chargeable, setChargeable] = useState({});
  const [ingotList, setingotList] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});
  const [visible, setVisible] = useState(true);
  const [pdfLink, setPDFLink] = useState("");

  function heatfunc() {
    api_call_token.get(`/heat/${id}`).
      then(response => {
        setHeatData(response?.data[0])
      }).catch(err => {
        console.log(err)
      })
  }

  function heatconfunc() {
    api_call_token.get(`/heatcontainer/${id}`).
      then(response => {
        setHeatConainer(response?.data);
        console.log(response.data, "responsedara")
      }).catch(err => {
        console.log(err)
      })
  }

  function chargeablefunc() {
    api_call_token.get(`/AppHeatConsumableNew/${id}`).
      then(response => {
        setChargeable(response?.data)
      }).catch(err => {
        console.log(err)
      })
  }

  function ingotfunc() {
    api_call_token.get(`/heatingot/${id}`).
      then(response => {
        setingotList(response?.data)
      }).catch(err => {
        console.log(err)
      })
  }

  function getOtherDetails() {
    api_call_token.get(`/heatfilling/${id}`).
      then(response => {
        setOtherDetails(response?.data[0])
      }).catch(err => {
        console.log(err)
      })
  }


  useEffect(() => {
    heatfunc();
    heatconfunc();
    chargeablefunc();
    ingotfunc();
    getOtherDetails();
    getPdf();

  }, [])


  useEffect(() => {
    if (ingotList.length > 0) {
      const ingotTotal = ingotList.reduce((total, ingotItem) => {
        return total + parseInt(ingotItem.ingotPiece, 10);
      }, 0);

      const ingotQuantityTotal = ingotList.reduce((total, ingotItem) => {
        return total + parseInt(ingotItem.ingotWeight, 10);
      }, 0);

      setTotalIngot(ingotTotal);
      setTotalIngotQuantity(ingotQuantityTotal);
    }
  }, [ingotList]);


  const getPdf = () => {
    api_call_token
      .get(`/pdf/getHeatPDF/${id}`)
      .then((res) => {

        if (res.status == 200 || res.status == 201) {
          setVisible(false)
          setPDFLink(res.data?.filepath)
        }

        console.log(res, "DATASSSSSSSSSS")
      })
      .catch(err => console.log(err))
  }
  const handleOpen = () => {
    window.open(`${base_url}${pdfLink}`)
  }

  const initialFormValues = {};
  Chargeable.length > 0 && Chargeable.forEach(item => {
    initialFormValues[item.heatConsumableName] = item.heatConsumableAmount;
  });
  console.log(`${base_url}${pdfLink}`,initialFormValues, "FFFFFFfff")

  return (
    <div className={styles.app}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>

        <h2> <ArrowLeftOutlined onClick={() => navigate(-1)} style={{ marginRight: '10px' }} />Batch</h2>
        <Button disabled={visible} onClick={() => handleOpen()}>Download Pdf</Button>
      </div>
      <embed
            src={`${base_url}${pdfLink}`}
            // src="http://staging.paronline.in:8080/heatpdf/F02119.pdf"
            // src="https://d31j8vyj7gdpxq.cloudfront.net/data/files/content_notes/gess1ps_merged.pdf"
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="900px"
            width="100%"
            toolbar="0"
            // hidecontrols="1"
          ></embed>
      
    </div>
  );
}

export default HeatView;


/**
 * 
 * 
 * 
 <div className={styles.flex}>

 <div className={`${styles.width} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom} ${styles.borderLeft}  `}>
   <p className={`${styles.textAlignCenter}  ${styles.borderBottom} ${styles.paddingBottom} ${styles.paddingTop} `} >Heat No</p>
   <p className={`${styles.textAlignCenter}  ${styles.paddingthirty}  `} >{heatData?.heatNumber ?? ""}</p>
 </div>

 <div className={`${styles.width} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom}   `}>
   <p className={`${styles.textAlignCenter}  ${styles.borderBottom} ${styles.paddingBottom} ${styles.paddingTop}  `} >Date</p>
   <p className={`${styles.textAlignCenter}  ${styles.paddingthirty}  `} >{heatData?.heatDate ? formatDate(heatData?.heatDate) : ""}</p>
 </div>


 <div className={`${styles.width} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom}   `}>
   <p className={`${styles.textAlignCenter}  ${styles.borderBottom} ${styles.paddingBottom} ${styles.paddingTop}  `} >Furnace Name</p>
   <p className={`${styles.textAlignCenter}  ${styles.paddingthirty}  `}  >{heatData?.furnaceName ?? ""}</p>
 </div>


 <div className={`${styles.width} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom}   `}>
   <p className={`${styles.textAlignCenter}  ${styles.borderBottom} ${styles.paddingBottom} ${styles.paddingTop}  `} >Start Time</p>
   <p className={`${styles.textAlignCenter}  ${styles.paddingthirty}  `} > {formatTime(heatData?.castingTimeStart) ?? <>&nbsp;</>} </p>
 </div>



 <div className={`${styles.width} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom}   `}>
   <p className={`${styles.textAlignCenter}  ${styles.borderBottom} ${styles.paddingBottom} ${styles.paddingTop}  `} >Recovery </p>
   <p className={`${styles.textAlignCenter}  ${styles.paddingthirty}  `} ></p>
 </div>



 <div className={`${styles.width} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom}   `}>
   <p className={`${styles.textAlignCenter}  ${styles.borderBottom} ${styles.paddingBottom} ${styles.paddingTop}  `} >Oil Start</p>
   <p className={`${styles.textAlignCenter}  ${styles.paddingthirty}  `} >{heatData?.oilUsage ?? ""} ince</p>
 </div>


 <div className={`${styles.width} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom}  `}>

   <div className={`${styles.flex} ${styles.widthHund}`}   >
     <div className={` ${styles.borderRight} ${styles.borderBottom}  ${styles.widthTwenty} ${styles.paddingTop} ${styles.paddingBottom}   `}   >
       <p className={`${styles.marginZero} ${styles.textAlignCenter} `} >Al</p>
     </div>
     <div className={`   ${styles.borderBottom}   ${styles.widthEight}   ${styles.paddingTop} ${styles.paddingBottom}  `}   >
       <p className={`${styles.marginZero}  ${styles.textAlignCenter}`}  ></p>
     </div>
   </div>


   <div className={`${styles.flex} ${styles.widthHund}`}   >
     <div className={` ${styles.borderRight} ${styles.borderBottom}  ${styles.widthTwenty} ${styles.paddingTop} ${styles.paddingBottom}   `}   >
       <p className={`${styles.marginZero} ${styles.textAlignCenter} `} >Si</p>
     </div>
     <div className={`   ${styles.borderBottom}  ${styles.widthEight}  ${styles.paddingTop} ${styles.paddingBottom}   `}   >
       <p className={`${styles.marginZero}  ${styles.textAlignCenter}`}  ></p>
     </div>
   </div>


   <div className={`${styles.flex} ${styles.widthHund}`}   >
     <div className={` ${styles.borderRight}  ${styles.widthTwenty} ${styles.paddingTop} ${styles.paddingBottom}   `}   >
       <p className={`${styles.marginZero} ${styles.textAlignCenter} `} >Fe</p>
     </div>
     <div className={`    ${styles.widthEight}  ${styles.paddingTop} ${styles.paddingBottom}   `}   >
       <p className={`${styles.marginZero}  ${styles.textAlignCenter}`}  ></p>
     </div>
   </div>
 </div>



 <div className={`${styles.width} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom}  `}>
   <div className={`${styles.flex} ${styles.widthHund}`}   >
     <div className={` ${styles.borderRight} ${styles.borderBottom}  ${styles.widthTwenty} ${styles.paddingTop} ${styles.paddingBottom}   `}   >
       <p className={`${styles.marginZero} ${styles.textAlignCenter} `} >Mg</p>
     </div>
     <div className={`   ${styles.borderBottom}   ${styles.widthEight}  ${styles.paddingTop} ${styles.paddingBottom}  `}   >
       <p className={`${styles.marginZero}  ${styles.textAlignCenter}`}  ></p>
     </div>
   </div>
   <div className={`${styles.flex} ${styles.widthHund}`}   >
     <div className={` ${styles.borderRight} ${styles.borderBottom}  ${styles.widthTwenty} ${styles.paddingTop} ${styles.paddingBottom}    `}   >
       <p className={`${styles.marginZero} ${styles.textAlignCenter} `} >Zn</p>
     </div>
     <div className={`   ${styles.borderBottom}  ${styles.widthEight}  ${styles.paddingTop} ${styles.paddingBottom}   `}   >
       <p className={`${styles.marginZero}  ${styles.textAlignCenter}`}  ></p>
     </div>
   </div>
   <div className={`${styles.flex} ${styles.widthHund}`}   >
     <div className={` ${styles.borderRight}  ${styles.widthTwenty} ${styles.paddingTop} ${styles.paddingBottom}    `}   >
       <p className={`${styles.marginZero} ${styles.textAlignCenter} `} >Mn</p>
     </div>
     <div className={`    ${styles.widthEight}  ${styles.paddingTop} ${styles.paddingBottom}   `}   >
       <p className={`${styles.marginZero}  ${styles.textAlignCenter}`}  ></p>
     </div>
   </div>
 </div>
</div>


<div className={`${styles.flex}  ${styles.widthHund}  ${styles.borderLeft}  ${styles.borderRight}   `}  >
 <div className={`${styles.widthFifty}  ${styles.borderRight} `} >
   <p className={`${styles.textAlignCenter}  ${styles.paddingBottom}  ${styles.paddingTop} ${styles.fontBold}`}>Container Details</p>
   {
     heatCon?.containerList?.map((info, index) => {
       return (
         <div className={`${styles.flex} ${styles.widthHund} ${styles.borderBottom}  `} style={{ marginBottom: index === heatCon.containerList.length - 1 ? '0px' : '20px' }}>

           <div className={`${styles.widthTwenty} ${styles.borderTop} ${styles.borderRight}  `}>
             <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}  >
               <div>
                 <p className={`${styles.textAlignCenter}  ${styles.marginZero} ${styles.paddingBottom} ${styles.paddingTop}   `}>Container No</p>
                 <p className={`${styles.textAlignCenter}   ${styles.marginZero}  `}>{info?.containerNumber ?? ""}</p>
               </div>
             </div>
           </div>

           <div className={`${styles.widthEight} ${styles.flex}`}>
             <div className={` ${styles.widthFifty} ${index === 0 && styles.borderTop} ${styles.borderRight}`}>
               {index === 0 && (
                 <p className={`${styles.textAlignCenter} ${styles.paddingTop}  ${styles.paddingBottom}  `}>Product Name</p>
               )}
               {info?.productList?.map((product, index) => (
                 <p key={index} className={`${styles.textAlignCenter} ${styles.borderTop} ${styles.paddingTop} ${styles.paddingBottom} ${styles.marginZero}`}>
                   {product?.productName ?? <>&nbsp;</>}
                 </p>
               ))}
             </div>

             <div className={` ${styles.widthFifty} ${index === 0 && styles.borderTop}`}>
               {index === 0 && (
                 <p className={`${styles.textAlignCenter} ${styles.paddingTop}  ${styles.paddingBottom}  `}>Selected QTY</p>
               )}

               {info?.productList?.map((product, index) => (
                 <p key={index} className={`${styles.textAlignCenter} ${styles.borderTop} ${styles.paddingTop}  ${styles.paddingBottom}  ${styles.marginZero}`}>
                   {product?.quantity !== undefined ? `${product.quantity} Kg` : <>&nbsp;</>}
                 </p>
               ))}
             </div>
           </div>
         </div>
       );
     })
   }
 </div>


 <div className={`${styles.widthFifty} ${styles.flex}  ${styles.widthHund} `} >
   <div className={`${styles.widthFifty}   ${styles.borderRight} ${styles.borderBottom}`}>
     <p className={`${styles.textAlignCenter} ${styles.paddingBottom}  ${styles.paddingTop} ${styles.fontBold}`}  >Output</p>
     <div className={`${styles.widthHund}   ${styles.flex}`} >


       <div style={{ width: "20%" }} className={`${styles.borderBottom}  ${styles.borderTop} ${styles.borderRight}`}  >
         <p className={`${styles.textAlignCenter} ${styles.marginZero}  ${styles.paddingBottom} ${styles.paddingTop}  `}>Sr no</p>
         {ingotList.map((info, index) => <p className={`${styles.textAlignCenter} ${styles.borderTop} ${styles.marginZero}  ${styles.paddingTop} ${styles.paddingBottom} `}> {index + 1}</p>)}
       </div>

       <div style={{ width: "40%" }} className={`${styles.borderBottom}  ${styles.borderTop} ${styles.borderRight}`} >
         <p className={`${styles.textAlignCenter}  ${styles.marginZero}   ${styles.paddingBottom} ${styles.paddingTop} `}>Ingots</p>
         {ingotList.map((info, index) => <p className={`${styles.textAlignCenter} ${styles.borderTop}   ${styles.marginZero}  ${styles.paddingTop} ${styles.paddingBottom} `}> {info?.ingotWeight ?? <>&nbsp;</>}</p>)}
       </div>

       <div style={{ width: "40%" }} className={`${styles.borderBottom}  ${styles.borderTop}`} >
         <p className={`${styles.textAlignCenter}   ${styles.marginZero}  ${styles.paddingBottom} ${styles.paddingTop} `}>Quantity</p>
         {ingotList.map((info, index) => <p className={`${styles.textAlignCenter} ${styles.borderTop}   ${styles.marginZero}  ${styles.paddingTop} ${styles.paddingBottom} `}> {info?.ingotPiece ?? <>&nbsp;</>} Kg</p>)}
       </div>
     </div>


     <div className={`${styles.widthHund} ${styles.flex} `} style={{ marginTop: "79px" }} >
       <div className={`${styles.widthFifty} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom}`}>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `}>In dross</p>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom}  ${styles.marginZero}  `}  >Rough</p>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom}  ${styles.marginZero}  `} >Part</p>
         <p className={`${styles.textAlignCenter}  ${styles.paddingTop}  ${styles.paddingBottom}  ${styles.marginZero}  `} >Other</p>
       </div>

       <div className={`${styles.widthFifty}  ${styles.borderTop}  ${styles.borderBottom}`}>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `} >{otherDetails?.dross ?? <>&nbsp;</>}</p>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `} >{otherDetails?.rough ?? <>&nbsp;</>}</p>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `} >{otherDetails?.part ?? <>&nbsp;</>}</p>
         <p className={`${styles.textAlignCenter}  ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `} >{otherDetails?.other ?? <>&nbsp;</>}</p>
       </div>

     </div>
   </div>

   <div className={`${styles.widthFifty}`}>
     <p className={`${styles.textAlignCenter} ${styles.paddingTop}  ${styles.paddingBottom} ${styles.fontBold} `}  >Other Details</p>

     <div className={`${styles.widthHund} ${styles.flex} `}>
       <div className={`${styles.widthFifty} ${styles.borderTop} ${styles.borderRight}  ${styles.borderBottom}`}>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `}>Master Name</p>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom}  ${styles.marginZero}  `}  >Labourers</p>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom}  ${styles.marginZero}  `} >Electricity Used</p>
         <p className={`${styles.textAlignCenter}  ${styles.paddingTop}  ${styles.paddingBottom}  ${styles.marginZero}  `}  >Oil End</p>
       </div>

       <div className={`${styles.widthFifty}  ${styles.borderTop}  ${styles.borderBottom}`}>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `} >{heatData?.masterName ?<div>{heatData?.masterName}</div> :<p>-</p>}</p>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `} >{heatData?.labourCount ?? <>&nbsp;</>}</p>
         <p className={`${styles.textAlignCenter} ${styles.borderBottom} ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `} >{heatData?.electricityUsed !== undefined ? `${heatData.electricityUsed} %` : <p>0 %</p>}</p>
         <p className={`${styles.textAlignCenter}  ${styles.paddingTop}  ${styles.paddingBottom} ${styles.marginZero} `} >{heatData?.oilEnd ? `${heatData.oilEnd} ince` : <>&nbsp;</>}</p>
       </div>

     </div>
     <div style={{ margin: "10px" }} >
       <p className={`${styles.paddingBottom} ${styles.paddingBottom} `} >Remarks</p>
       <p>{Chargeable?.remark}</p>
     </div>
   </div>

 </div>

</div>

<div className={`${styles.flex} ${styles.widthHund} `}>

 <div className={`${styles.widthFifty} ${styles.borderRight}  `}>
   <p className={`${styles.textAlignCenter} ${styles.marginZero} ${styles.borderLeft}  ${styles.paddingTop} ${styles.paddingBottom} ${styles.fontBold} `}   >Chargeable</p>
   <div className={`${styles.widthHund} ${styles.flex} `}>
     <div className={`${styles.widthFifty}   ${styles.borderRight} ${styles.borderTop} ${styles.borderBottom}`}>
       <p className={`${styles.textAlignCenter}  ${styles.paddingTop} ${styles.paddingBottom} ${styles.borderLeft} `}  >Product</p>
       {Object.keys(initialFormValues).length !== 0 && Object.keys(initialFormValues).map(key => (
         <p className={`${styles.textAlignCenter} ${styles.borderTop} ${styles.marginZero} ${styles.paddingTop} ${styles.paddingBottom}  ${styles.borderLeft} `} key={key} >{key}</p>

       ))}
       {Object.keys(initialFormValues).length === 0 &&
         <p className={`${styles.textAlignCenter} ${styles.borderTop} ${styles.borderLeft} ${styles.marginZero} ${styles.paddingTop} ${styles.paddingBottom} `} >-</p>

       }</div>
     <div className={`${styles.widthFifty} ${styles.borderTop} ${styles.borderBottom}  `}>
       <p className={`${styles.textAlignCenter}   ${styles.paddingTop} ${styles.paddingBottom}`}  >Amount</p>
       
       {Object.keys(initialFormValues).length !== 0 && Object.values(initialFormValues).map((values, index) => (
         <p className={`${styles.textAlignCenter} ${styles.borderTop} ${styles.marginZero} ${styles.paddingTop} ${styles.paddingBottom} `} key={index} >{values}</p>

       ))}
       {Object.keys(initialFormValues).length === 0 &&
         <p className={`${styles.textAlignCenter} ${styles.borderTop} ${styles.marginZero} ${styles.paddingTop} ${styles.paddingBottom} `} >-</p>

       }</div>
   </div>
 </div>


 <div className={`${styles.widthFifty}   ${styles.borderRight} ${styles.borderBottom} `}>
   <div style={{ height: "100%" }} className={`${styles.widthFifty} ${styles.borderRight}   `}>
     <p style={{ height: "16.4%" }} className={`${styles.textAlignCenter} ${styles.marginZero}   ${styles.paddingTop} ${styles.paddingBottom} ${styles.fontBold} `} >Total</p>

     <div style={{ height: "84.6%" }} className={`${styles.widthHund} ${styles.flex} ${styles.borderTop}`}>
       <div className={`${styles.widthFifty} ${styles.borderRight} `} >
         <p className={`${styles.textAlignCenter} ${styles.paddingTop} ${styles.paddingBottom}`}>Ingots</p>
         <div className={`${styles.borderTop}`} >
           <p className={`${styles.textAlignCenter} ${styles.marginZero} ${styles.paddingthirty}  ${styles.fontBold}`}>{totalIngotQuantity ? totalIngotQuantity : ""}</p>
         </div>
       </div>

       <div className={`${styles.widthFifty} `}>
         <p className={`${styles.textAlignCenter} ${styles.paddingTop} ${styles.paddingBottom}`}>Quantity</p>
         <div className={`${styles.borderTop}`}>
           <p className={`${styles.textAlignCenter} ${styles.marginZero} ${styles.paddingthirty}  ${styles.fontBold}`}>{totalIngot ? totalIngot : ""}</p>
         </div>
       </div>
     </div>

   </div>
 </div>
</div>
 */