import logo from './logo.svg';
import './App.css';
import CustomTable from './Components/CustomTable/CustomTable';
import Mainroutes from './Approutes/Mainroutes';
import { MainContextProvider } from './utils/context';
import { getLocalData } from './utils/helperFunctions';
import Login from './Auth/Login';
import UserCredsContextProvider, { UserCredsContext } from './utils/UserCredsContext';

const token = getLocalData("phoolchand-access-token");
function App() {

  return (
    <div>
      {token ?
        <UserCredsContextProvider>
          <Mainroutes />
        </UserCredsContextProvider>
        // <MainContextProvider>
        //   <Mainroutes />
        // </MainContextProvider>
        :
        <Login />
      }
    </div>
  );
}

export default App;
