import React, { useEffect, useState } from "react";
import { api_call_token, base_url, formDataApi } from "../../../utils/Network";
import { useNavigate } from "react-router-dom";
import { alertMessage, formatDate } from "../../../utils/helperFunctions";
import {
  Button,
  Input,
  Modal,
  Pagination,
  Form,
  Select,
  Table,
  Upload,
} from "antd";
import styles from "./index.module.css";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import EditIcon from "../../../Assets/ICONS/edit.png";
import Packing from "./Packing";
import moment from 'moment';

const ExpandedIngotTable = ({ ingotList }) => {
  console.log(ingotList, "IngotList");
  const columns = [
    // { title: "", dataIndex: "ingotId", key: "ingotId", width: 0 },
    // ingotName
    {
      title: "Ingot Number",
      dataIndex: "ingotName",
      key: "ingotName",
      width: 100,
    },
    {
      title: "No. of Ingot",
      dataIndex: "ingotNumber",
      key: "ingotNumber",
      width: 100,
    },
    {
      title: "Ingot Weight (kg)",
      dataIndex: "grossWeight",
      key: "grossWeight",
      width: 100,
    },
    { title: "Bags", dataIndex: "bagNumber", key: "bagNumber", width: 100 },
    // { title: 'Net Wt', dataIndex: 'netWeight', key: 'netWeight', width: 100 },
  ];
  const scrollConfig = {
    y: "auto", // Set your desired max height for vertical scrolling
    x: "auto", // Set if you want horizontal scrolling
  };

  return <Table columns={columns} dataSource={ingotList} pagination={false} />;
};

function PackagingList() {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [packages, setPackage] = useState([]);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [expandedRowData, setExpandedRowData] = useState(null);
  const [paginationData, setPaginationData] = useState({});
  const [visible, setVisible] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [Instock, setInstock] = useState([]);
  const [packingmodal, setPackingModal] = useState(false);
  const [packingData, setPackingData] = useState({});
  const [packingID, setPackingId] = useState(null)
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });

  useEffect(() => {
    getPackaging();
    getStatus();
  }, [filterObj]);

  // useEffect(() => {
  //   if (packingData && Object.keys(packingData).length > 0) {
  //     setPackingModal(true);
  //   }
  // }, [packingData]);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setPackingData({});
    setVisible(false);
    setPackingModal(false);
  };

  const getStatus = () => {
    api_call_token
      .get(`/zipcode/status?statusName=Heat Transport`)
      .then((res) => {
        setInstock(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const handleEditClickIngot = (records) => {
    console.log(records, "REcords");
    form.resetFields();
    setPackingData({});
    form.setFieldsValue({
      // Assuming packingData has properties like 'fieldName'
      vehicleNumber: records?.vehicleNumber || "",
      status: records?.status || null,
      packingPartyName: records?.packingPartyName || "",
      // Add other fields as needed
    });
    let data = records;
    setPackingData(data);
    setPackingModal(true);
    console.log(packingData, "PackingDatassss");
  };
  console.log(packingData, "PackingDatassss");

  const getPackaging = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`packing`, { params: paramObj })
      .then((res) => {
        console.log(res.data);
        setDataList(res.data.data);
        setPaginationData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleData = (val) => {
    navigate(`${val}`);
  };

  const columns = [
    {
      title: "Packing Id",
      dataIndex: "packingId",
      render: (_, { packingId }) => {
        console.log(_);
        return (
          <div
            style={{ width: "100px" }}
            // onClick={() => handleData(packingId)}
          >
            {_}
          </div>
        );
      },
    },
    {
      title: "Vehical No",
      dataIndex: "vehicleNumber",
      render: (_) => {
        return <div style={{ cursor: "pointer", width: "200px" }}>{_}</div>;
      },
    },
    {
      title: "Packing date",
      dataIndex: "packingDateTime",
      render: (abc) => {

        const formattedDate = moment(abc).format('DD-MMMM-YYYY');
        return (
          <div style={{ cursor: "pointer", width: "200px" }}>
            {formattedDate}
          </div>
        );
      },
    },
    {
      title: "Party Name",
      dataIndex: "packingPartyName",
      render: (_) => {
        return <div style={{ cursor: "pointer", width: "200px" }}>{_}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "statusName",
      render: (_) => {
        return <div style={{ cursor: "pointer", width: "200px" }}>{_}</div>;
      },
    },
    {
      title: "View",
      dataIndex: "",
      render: (_) => {
        return <Button onClick={() => onView(_)}>View image</Button>;
      },
    },
    {
      title: "Download Excel",
      dataIndex: "",
      render: (_) => {
        return <Button onClick={() => onExcelDownload(_)}>Download</Button>;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      fixed: "right",
      render: (_, record) => {
        return (
          <div
            onClick={() => handleEditClickIngot(record)}
            style={{ width: "max-content", cursor: "pointer", width: "55px" }}
          >
            <img src={EditIcon} alt="edit" />
          </div>
        );
      },
    },
  ];

  const onView = (_) => {
    setPackingId(_?.packingId)
    api_call_token
      .get(`pdf/readfile?search=packing/${_?.packingId}`)
      .then((res) => {
        console.log(res.data, "RRRRRsdfs");
        // if (res.data.length > 0) {
        setVisible(true);
        setImageArray(res.data);
        // } else {
        //   alertMessage("No image available", 2);
        // }
      })
      .catch((err) => console.log(err));
  };

  const onExcelDownload = (_) => {
    api_call_token
      .get(`pdf/getPackingExcel/${_?.packingId}`)
      .then((res) => {
        const filePath = res.data.FilePath;
        const parts = filePath.split("/");

        if (parts.length >= 2) {
          // Get the part from the second-to-last index
          const trimmedPath = parts.slice(-2).join("/");

          console.log("Trimmed file path:", trimmedPath);
          if (res.status == 200 || res.status == 201) {
            window.open(`${base_url}${trimmedPath}`, "_blank");
          }
        } else {
          alertMessage(
            "The path does not contain at least two directories.",
            2
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const onSearch = (e) => {
    let value = e.target.value;
    let obj = filterObj;
    if (value) {
      obj.search = value;
      obj.page = 1;
    }
    else delete obj.search;
    setFilterObj({ ...obj });
  };

  const handleBranch = (event) => {
    console.log(event, "DDDDDsss");
    let value = event;
    let obj = filterObj;
    if (value) {
      obj.status = value;
      obj.page = 1;
    } else delete obj.status;
    setFilterObj({ ...obj });
  };

  const handleExpand = (record) => {
    if (expandedRowKey === record.key) {
      // If the row is already expanded, collapse it
      setExpandedRowKey(null);
    } else {
      // Fetch data from the API and store it in state
      api_call_token
        .get(`/packing/detailList/app/${record.packingId}`)
        .then((res) => {
          if (res.status === 200) {
            const ingotData = res.data.data;
            setExpandedRowData(ingotData);
            setExpandedRowKey(record.key);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleCheckoutSubmit = (values) => {
    console.log(values, packingData);
    api_call_token
      .patch(`packing/${packingData?.packingId}`, values)
      .then((res) => {
        if (res.status == 204 || res.status == 200 || res.status == 201) {
          setPackingModal(false);
          getPackaging();
          
        }
      })
      .catch((err) => alertMessage("Error filling data", 2));
  };

  const handlDatas = (vals) => {
    console.log(vals, "DFDDFD");
    const formData = new FormData();

    vals?.files?.fileList.forEach((file, index) => {
      formData.append(`packingFiles`, file.originFileObj);
    });
    if (formData.has("packingFiles")) {
      formData.append(`packingId`, packingID);
      getFunction(formData);
    }
  };

  const getFunction = async (val) => {
    console.log(...val, "VALSSSSSSSSSs");
    let apiEnd = "uploadDocumentsToPacking";
    try {
      const res = await formDataApi(apiEnd, val, false, "packing/");
      console.log(res, "ressss");
      if (res.status == 200 || res.status == 201) {
        forms.resetFields();
        setVisible(false);
        getPackaging();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const expandedRowRender = (record) => {
    if (expandedRowKey === record.key && expandedRowData) {
      return (
        <div
          className={styles.expandContainer}
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            position: "relative !important",
          }}
        >
          <ExpandedIngotTable ingotList={expandedRowData} />
        </div>
      );
    }
    return null;

    // console.log(record, "RECORD")
    // return expandedRowKey === record.key ? <div className={styles.expandContainer} style={{ maxHeight: '200px', overflowY: 'auto', position: 'relative !important'}}>
    //     <ExpandedIngotTable ingotList={record.ingotList} />
    // </div> : null;
  };

  const scrollConfig = {
    y: "auto", // Set your desired max height for vertical scrolling
    x: "auto", // Set if you want horizontal scrolling
  };

  return (
    <div>
      <div className={styles.searchData} style={{ display: "flex" }}>
        <Input
          size="medium"
          placeholder="Search By Vehicle No."
          prefix={<SearchOutlined />}
          onChange={(e) => onSearch(e)}
          style={{ width: "70%" }}
        />

        <div>
          {
            <Select
              showSearch
              style={{
                width: "100%",
                margin: "0 10px",
              }}
              placeholder="Filter By Status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              // bordered={false}
              onChange={handleBranch}
              options={
                Instock &&
                Instock?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["statusID"],
                    label: obj["statusName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          }
          {/* {Instock.map((v, i) => (
            <div>{v?.statusName}</div>
          ))} */}
        </div>
      </div>
      <Table
        columns={columns}
        scroll={scrollConfig}
        expandable={{
          expandedRowRender,
          expandedRowKeys: [expandedRowKey],
          onExpand: (_, record) => handleExpand(record),
          expandIcon: ({ expanded, onExpand, record }) => (
            <span onClick={(e) => onExpand(record, e)}>
              {expanded ? (
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "550",
                    cursor: "pointer",
                  }}
                >
                  -
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "550",
                    cursor: "pointer",
                  }}
                >
                  +
                </span>
              )}
            </span>
          ),
        }}
        dataSource={dataList.map((item, index) => ({ ...item, key: index }))}
        pagination={{
          pageSize: 10, // Number of items per page
          total: paginationData?.totalRowCount, // Total number of items
          showSizeChanger:false,
          onChange: (page, pageSize) => {
            setFilterObj({ ...filterObj, page: page });
            // Handle page change here, e.g., fetch data for the new page
          },
        }}
      />

      <Modal open={visible} onOk={forms.submit} onCancel={hideModal}>

            <Form form={forms} onFinish={handlDatas}>
              <Form.Item label="Upload Files" name="files">
                <Upload name="logo" listType="picture">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </Form>
          {imageArray &&
            imageArray.map((imagePath, index) => (
              <div key={index} style={{ margin: "10px", textAlign: "center" }}>
                <img
                  src={`${base_url}packing/${imagePath.split("packing/")[1]}`}
                  alt={`Image ${index}`}
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </div>
            ))}
          {!imageArray.length > 0 && (
            <div style={{ padding: "20px", textAlign: "center" }}>
              No Images Available
            </div>
          )}
      </Modal>

      <Modal open={packingmodal} onOk={form.submit} onCancel={hideModal}>
        <Packing
          Instock={Instock}
          handleCheckoutSubmit={handleCheckoutSubmit}
          // packingData={packingData}
          form={form}
        />
      </Modal>
    </div>
  );
}

export default PackagingList;

/**
 * 
 const data = [
    {
        ingot: 'ROT1A',
        qty: 5000,
        soldqty: 0,
        Batch: ROT1,
        vehical: [
            {
                qty: 500,
                Package: Pack1,
                VehicalNo:  R756 29
            },
            {
                qty: 600,
                Package: Pack2,
                VehicalNo:  R756 29
            }
        ]
    },
    {
        ingot: 'ROT1B',
        qty: 6000,
        soldqty: 0,
        Batch: ROT1,
        vehical: [ ]
    }
 ]
 * */
