import React, { useEffect, useState } from "react";
import styles from "./import.module.css";
import { Button, DatePicker, Form, Input } from "antd";
import { api_call_token } from "../../../utils/Network";
import NumericInput from "../../../Components/NumericInput/NumericInput";
import { alertMessage } from "../../../utils/helperFunctions";
import moment from "moment";

function ContainerComp({
  contaienrInnerfrom,
  state,
  setProductList = () => {},
  productListing,
}) {
  console.log(contaienrInnerfrom, "contaienrInnerfrom");
  const [sameVal, setSameValue] = useState(false);
  const [value, setValue] = useState("");
  const [productsData, setProductsData] = useState({});
  const [containerNo, setContainerNo] = useState("");
  const [errors, setErrors] = useState({});
  const [productsList, setProductsList] = useState({
    sealNo: null,
    outOfCharge: null,
    containerNo: null,
    grossWeight: null,
  });
  const [dataArray, setDataArray] = useState([]); // State to hold array of objects

  //   useEffect(() => {
  //     getProductData();
  //   }, [contaienrInnerfrom]);

  //   const getProductData = () => {
  //     let data = {};
  //     contaienrInnerfrom.map((v, i) => {
  //       if (v.isForm) {
  //         data[v.apiName] = "";
  //       }
  //     });
  //     setProductsData(data);
  //     console.log(data, "DDDDDDdd");
  //   };

  const validateField = async (value) => {
    console.log(value, "validateField");
    try {
      // Perform the API call
      const res = await api_call_token.get(
        `container/containerDuplicate/${value}`
      );
      if (containerNo == value) {
        return Promise.resolve();
      }
      // Assuming the API returns a boolean response
      const isDuplicate = !!res?.data;
      setSameValue(isDuplicate);

      if (!isDuplicate) {
        // Validation passes if the API response is false
        return Promise.resolve();
      } else {
        // Validation fails if the API response is true
        return Promise.reject("Container Number should be unique");
      }
    } catch (err) {
      console.error("Error fetching API:", err);
      return Promise.reject(
        "Validation failed: An error occurred while fetching data."
      );
    }
  };

  const handleContainerNoChange = async (e) => {
    // const value = e.target.value;

    const value = e.target.value;
    setContainerNo(value);

    // Check if container number already exists in productListing
    if (productListing.some((product) => product.containerNo === value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        containerNo: "Container Number already exists in the list",
      }));
      return;
    }

    // Regex validation
    if (!/^[A-Z]{4}\d{7}\d?[A-Z]?$/i.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        containerNo: "Invalid Container Identification Format",
      }));
      return;
    }
    console.log(containerNo, "containerNo");

    // API validation
    if (value) {
      const apiError = await validateField(value);
      if (apiError) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          containerNo: apiError,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          containerNo: null,
        }));
        setProductsData((prevData) => ({
          ...prevData,
          containerNo: value,
        }));
      }
    }
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setProductsData({
      ...productsData,
      outOfCharge: dateString,
    });
  };
  const handleSeal = (e, value) => {
    console.log(e, "onChangeNumber");
    setProductsData({
      ...productsData,
      sealNo: e?.target?.value,
    });
  };

  const onChangeNumber = (e) => {
    // console.log(e, "onChangeNumber");
    // const value = e.target.value
    setProductsData({
      ...productsData,
      grossWeight: e,
    });
  };
  const onChangetareNumber = (e) => {
    // console.log(e, "onChangeNumber");
    // const value = e.target.value
    setProductsData({
      ...productsData,
      tareWeight: e,
    });
  };

  const handleData = () => {
    // debugger;
    // console.log(productsData);
    // const newDataObject = { ...productsData };
    const { containerNo, ...restdata } = productsData;
    // Check if there's an error in the container number field
    if (errors.containerNo) {
        // If there's an error, show an alert message and return
        alertMessage("Please fix the errors before adding data.", 2);
        return;
    }
    if(!containerNo){
      alertMessage("Please add the Conatiner Number", 2);
      return;
    } 
    let vals = { ...restdata };
    let dataArr = [];
    contaienrInnerfrom.map((v, i) => {
      if (v.required) {
        dataArr.push(v.apiName);
      }
    });

    let isDataValid = true;
    for (let i = 0; i < dataArr.length; i++) {
      const property = dataArr[i];
      if (property === "containerNo") {
        continue;
      }

      if (!vals[property]) {
        isDataValid = false;
        alertMessage("Please add all field ", 2);
        return;
      }
    }

    //  // Push the updated data object into dataArray
    //  setDataArray([...dataArray, newDataObject]);

    //  // Clear the input fields after adding data
    //  setProductsData({});
    //  setProductsList({});

    const {
      //   containerNo,
      tareWeight,
      grossWeight,
      ...restValues
    } = productsData;
    // const productNames = findProductById(restValues.productID);
    // const unitMeasure = findUnitMeasure(unitofmeasureId);
    console.log(dataArray, "PRODDDD");
    let newArr = [];
    if (productListing?.length > 0) {
      newArr = [
        ...productListing,
        {
          ...restValues,
            grossWeight: +grossWeight || null,
            tareWeight: +tareWeight || null,
          //   containerNo: "ABCD2323232",
          //   invoiceproductName: productNames,
          //   invoiceproductquantity: +invoiceproductquantity,
          //   pricePerUnit: +pricePerUnit,
          //   amount: +amount,
          //   unitofmeasure: unitMeasure,
          //   unitofmeasureId: +unitofmeasureId,
        },
      ];
    } else {
      newArr = [
        {
          ...restValues,
            grossWeight: +grossWeight || null,
            tareWeight: +tareWeight || null,
          //   containerNo:"ABCD2323232hii",
          //   invoiceproductName: productNames,
          //   invoiceproductquantity: +invoiceproductquantity,
          //   pricePerUnit: +pricePerUnit,
          //   amount: +amount,
          //   unitofmeasure: unitMeasure,
          //   unitofmeasureId: +unitofmeasureId,
        },
      ];
    }
    setProductList(newArr);
    setProductsData({});
    setContainerNo();
    setProductsList({
      //   invoiceproductName: null,
      //   unitofmeasureId: null,
      sealNo: null,
      outOfCharge: null,
      containerNo: null,
      grossWeight: null,
      //   purchaseOrderNumber: null,
    });
  };

  console.log(productListing, "productsDataproductsData");
  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleHeading}>
        {contaienrInnerfrom &&
          contaienrInnerfrom?.map((v, i) => {
            return (
              <>
                {v?.isForm && (
                  <div
                    key={i}
                    style={{
                      width: "100%",
                    }}
                  >
                    <p className={styles.cssClass}>
                      {v?.fieldName} {v?.required && `*`}
                    </p>
                  </div>
                )}
              </>
            );
          })}
        <div style={{ flex: "0 0 auto" }}>
          <p className={styles.cssClass}>Action</p>
        </div>
      </div>
      <div className={styles.textData}>
        <div className={styles.titleHeading}>
          {contaienrInnerfrom &&
            contaienrInnerfrom?.map(
              (val, i) =>
                val?.isForm && (
                  <>
                    {val?.fieldType == "text" &&
                    val?.apiName == "containerNo" ? (
                      <div style={{ width: "100%" }}>
                        <Input
                          name={productsData[val?.apiName]}
                          value={containerNo}
                          onChange={(e) =>
                            handleContainerNoChange(e, val?.apiName)
                          }
                        />
                        {errors.containerNo && (
                          <p style={{ color: "red" }}>{errors.containerNo}</p>
                        )}
                      </div>
                    ) : val?.fieldType == "text" && val?.apiName == "sealNo" ? (
                      <div style={{ width: "100%" }}>
                        <Input
                          name={productsData[val?.apiName]}
                          placeholder={productsData[val?.fieldName]}
                          onChange={(e) => handleSeal(e, val?.apiName)}
                          value={productsData[val?.apiName]}
                        />
                      </div>
                    ) : val?.fieldType == "date" &&
                      val?.apiName == "outOfCharge" ? (
                      <div style={{ width: "100%" }}>
                        <DatePicker
                          name={productsData[val?.apiName]}
                          onChange={onChange}
                          style={{ width: "100%" }}
                          // value={productsData[val?.apiName]}
                          value={
                            productsData[val?.apiName]
                              ? moment(productsData[val?.apiName].split("T")[0], "YYYY-MM-DD")
                              : null
                          }
                        />
                      </div>
                    ) : val?.fieldType == "int" &&
                      val?.apiName == "grossWeight" ? (
                      <div style={{ width: "100%" }}>
                        <NumericInput
                          style={{
                            width: "100%",
                          }}
                          name={productsData[val?.apiName]}
                          value={productsData[val?.apiName]}
                          // onChange={setValue}
                          onChange={onChangeNumber}
                        />
                      </div>
                    ) : val?.fieldType == "int" &&
                      val?.apiName == "tareWeight" ? (
                      <div style={{ width: "100%" }}>
                        <NumericInput
                          style={{
                            width: "100%",
                          }}
                          name={productsData[val?.apiName]}
                          value={productsData[val?.apiName]}
                          // onChange={setValue}
                          onChange={onChangetareNumber}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "100%" }}>
                        <Input />
                      </div>
                    )}
                  </>
                )
            )}
          <div>
            {" "}
            <Form.Item>
              <Button
                type="primary"
                onClick={handleData}
                className={styles.btnsgreen}
              >
                +
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContainerComp;

const data = {
  blNo: "",
  blDAta: "",

  Container: [
    {
      containerNo: "",
      sealNo: "",
    },
    {
      containerNo: "",
      sealNo: "",
    },
    {
      containerNo: "",
      sealNo: "",
    },
  ],
};
