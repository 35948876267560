import React, {useState} from "react";
import { Tabs } from "antd";
import ListingData from "../ListingData";
import { useNavigate, useParams } from "react-router-dom";
import IngotList from "../IngotList/IngotList";
import PackagingList from "../PackagingList/PackagingList";
import { ArrowLeftOutlined } from "@ant-design/icons";
import BatchListing from "../BatchListing/BatchListing";
const { TabPane } = Tabs;
function ListingHeat() {
  const { id } = useParams();
  let navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('1');
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const items = [
    // {
    //   key: "1",
    //   label: "Batch List",
    //   children: <ListingData id={id} />,
    // },
    {
      key: "1",
      label: "Batch List",
      children: <BatchListing id={id}/>
    },
    {
      key: "2",
      label: "Ingot",
      children: <IngotList id={id} setActiveTab={setActiveTab} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>,
    },
    {
      key: "3",
      label: "Packing List",
      children: <PackagingList />,
    },
    // {
    //   key: "4",
    //   label: "Batch List New",
    //   children: <BatchListing id={id}/>
    // },
  ];

  const handleClick = () => {
    navigate(`/heat`);
  };
  return (
    <div>
      <div style={{ margin: "50px" }}>
        <h2>
          <ArrowLeftOutlined onClick={() => handleClick()} /> Batch Generation
        </h2>
        {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          {items.map((v,i) => (<TabPane tab={v.label} key={v.key}>
            {/* {v.children} */}
            {activeTab === v.key ? v.children : null}
          </TabPane>))}
        </Tabs>
      </div>
    </div>
  );
}

export default ListingHeat;
