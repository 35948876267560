import React from "react";
import { Alert, Button, Col, Form, Input } from "antd";

import LOGO from "../../Assets/logo/logo.png";
import { api_call } from "../../utils/Network";
import { saveLocalData } from "../../utils/helperFunctions";

export default function Login() {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const loginHandler = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        api_call
          .post("login", values)
          .then((r) => {
            if (r.status === 200 || r.status == 201) {
              if (r?.data?.data?.token) {
                saveLocalData("phoolchand-access-token", r.data?.data?.token);
                saveLocalData("phoolchand-user", r.data?.data?.user);
                setLoading(false);
                window.location.reload();
              }
            } else {
              throw new Error("Invalid response status: " + r.status);
            }
          })
          .catch((e) =>{
            setError(true);
            setLoading(false);
          });
      })
      .catch((info) => console.log("Validate Failed:", info));
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Col xs={18} md={12} lg={8} xl={6}>
        <div style={{ width: "100%", textAlign: "center", marginBottom: 10 }}>
          <img
            src={LOGO}
            alt="logo"
            style={{ objectFit: "contain" }}
            width="40%"
          />
        </div>
        <h2 style={{ textAlign: "center" }}>Welcome</h2>
        <Form layout="vertical" form={form} requiredMark={false} size="large">
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: "Please Enter User Name",
              },
            ]}
            labelCol={{ style: { marginBottom: -12 } }}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please Enter Password",
              },
            ]}
            labelCol={{ style: { marginBottom: -12, marginTop: -15 } }}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
        </Form>
        <Button
          onClick={loginHandler}
          style={{ width: "100%" }}
          size="large"
          type="primary"
          loading={loading}
        >
          LOGIN
        </Button>
        {error && <Alert
          style={{ marginTop: 10 }}
          type="error"
          message="Incorrect Username or Password !"
        />}
      </Col>
    </div>
  );
}
