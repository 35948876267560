import { FilterOutlined, PlusCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Select, Table, Tag } from 'antd';
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../Components/CustomTable/CustomTable';
import { api_call, api_call_token } from '../../utils/Network';
import styles from './index.module.css';
import EditIcon from "../../Assets/ICONS/edit.png";
import { UserCredsContext } from '../../utils/UserCredsContext';
import moment from 'moment';

const ExpandedIngotTable = ({ ingotList }) => {
    const columns = [
        { title: '', dataIndex: 'heatingotIds', key: 'heatingotId', width: 100 },
        // { title: 'Heat ID', dataIndex: 'heatId', key: 'heatId' },
        // { title: 'Heat Number', dataIndex: 'heatNumber', key: 'heatNumber' },
        { title: 'Product Name', dataIndex: 'productName', key: 'productName', width: 200 },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
        { title: 'UOM', dataIndex: 'unitOfMeasureName', key: 'unitOfMeasureName' },
        { title: 'HSN Code', dataIndex: 'hsnCode', key: 'hsnCode' },
        { title: 'Price/unit', dataIndex: 'pricePerUnit', key: 'pricePerUnit' },
        { title: 'Quotation Period', dataIndex: 'note', key: 'note' },
        { title: 'Packing', dataIndex: 'packing', key: 'packing' },
        { title: 'Good Details', dataIndex: 'goodsDetail', key: 'goodsDetail' },
        { title: 'Total', dataIndex: 'totalProductAmount', key: 'totalProductAmount' },
    ];
    const scrollConfig = {
        y: 'auto', // Set your desired max height for vertical scrolling
        x: 'auto', // Set if you want horizontal scrolling
    };

    return <Table columns={columns} dataSource={ingotList} pagination={false} />;
};

const { Option } = Select;

function FieldList() {
    const { base_items } = useContext(UserCredsContext);
    let navigate = useNavigate();
    const [fieldValues, setFieldValue] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberofPage, setTotalNumberofPage] = useState(null);
    const [filterObj, setFilterObj] = useState({
        page: 1,
    });
    const [branch, setBranch] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [poStatus, setPoStatus] = useState([]);

    useEffect(() => {
        getBranch();
        getPurchaseOrderFilter();

    }, [])

    useEffect(() => {
        getDataValues();
    }, [filterObj, currentPage]);

    const getPurchaseOrderFilter = () => {
        api_call_token
            .get(`/zipcode/status?statusName=Purchase Order`)
            .then((res) => {
                setPoStatus(res.data.Data)
            })
            .catch(err => console.log(err))
    }

    const getBranch = () => {
        api_call_token
        .get(`buyeraddress/branches`)
        .then((res) => {
            setBranch(res.data);
        })
        .catch(err => {
            console.log(err)
        })
    }
    const onSearch = (event) => {
        let value = event.target.value;
        let obj = filterObj;
        if (value) {
            obj.search = value;
            setCurrentPage(1)
        }
        else delete obj.search;
        setFilterObj({ ...obj });
    }

    const handleBranch = (event) => {
        console.log(event, "DDDDDsss")
        let value = event;
        let obj = filterObj;
        if (value) {
            obj.filterbranch = value;
            setCurrentPage(1)
        }
        else delete obj.filterbranch;
        setFilterObj({ ...obj });
    }



    const handleChanges = (e) => {
        console.log(e, "DDDDDD")
        let value = e;
        let obj = filterObj;
        if (value) {
            obj.filterCompany = value;
            setCurrentPage(1)
        }
        else delete obj.filterCompany;
        setFilterObj({ ...obj });
    }
    const getDataValues = (paramObj = { ...filterObj }) => {
        api_call_token
            .get(`/domesticPurchaseOrder`, { params: paramObj })
            .then((res) => {
                if (res.data.structure) {
                    setFieldValue(res.data.structure)
                    setDataList(res.data.data);
                    setCurrentPage(res.data.currentPage)
                    setTotalNumberofPage(res.data.totalRowCount)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const navigateToCreate = () => {
        navigate('create')
    }

    const navigateToCreateForm = () => {
        navigate('createform', { state: { type: "DRAFT", addPO: "0" } })
    }

    const navigateToCreateForms = () => {
        navigate('createforms', { state: { type: "DRAFT", addPO: "0" } })
    }


    const handleExpand = (record) => {
        // setExpandedRowKey(record.key);
        const newExpandedRowKey = expandedRowKey === record.key ? null : record.key;
        setExpandedRowKey(newExpandedRowKey);
    };

    const expandedRowRender = (record) => {
        return expandedRowKey === record.key ? <div className={styles.expandContainer} style={{ maxHeight: '200px', overflowY: 'auto', position: 'relative !important' }}>
            <ExpandedIngotTable ingotList={record.productList} />
        </div> : null;
    };



    const redirectTo = (value) => {
        api_call_token
            .get(`domesticPurchaseOrder/${value}`)
            .then((res) => {
                console.log(res.data);
                if (res.status == 200 || res.status == 201) {
                    navigate('createform', { state: { data: res.data, id: value, type: "PO", addPO: "0" } })
                }
            })
            .catch(err => console.log(err))
    }

    const handlePageChange = (page) => {
        console.log(page, "aaaaaaaaa")
        setFilterObj({ ...filterObj, page: page })
        setCurrentPage(page);
    };

    const paginationConfig = {
        current: currentPage,
        pageSize: 10, // Number of items per page
        total: totalNumberofPage, // Total number of items
        onChange: handlePageChange,
    };

    const handleData = (v) => {
        console.log(v, "dadata")
        navigate(`purchaseforms/${v}`)
    }

    const handleStatusChange = (val, data) => {
        console.log(val, data)

        api_call_token
        .get(`purchaseOrder/changeMyStatus/${data.purchaseOrderID}/0/${val}`)
        .then((res) => {
            getDataValues();

        })
        .catch(err => console.log(err))
    }



    const columns = [
        {
            title: 'Purchase Order Number',
            dataIndex: 'purchaseOrderNumber',
            width: "15%",
            sorter: {
                compare: (a, b) => a.purchaseOrderNumber - b.purchaseOrderNumber,
                multiple: 3,
            },
            render: (_, record,{ purchaseOrderID }) => {
                console.log(_)
                return (
                // <>
                // <div style={{ cursor: "pointer" }} onClick={() => handleData(record?.purchaseOrderID)}>{_}</div>
                // <div>{moment(record?.purchaseOrderDate).format('DD-MMMM-YYYY')}</div>
                // </>
                <>
                <div style={{ cursor: "pointer" }} onClick={() => handleData(record?.purchaseOrderID)}>
                    <div  >{_}</div>
                    <div>{moment(record?.purchaseOrderDate).format('DD-MMMM-YYYY')}</div>
                </div>
            </>
            )
            }
        },
        // {
        //     title: 'Purchase Order Date',
        //     dataIndex: 'purchaseOrderDate',
        //     width: "12%",
        //     render: (_) => {
        //         return <div>{moment(_).format('DD-MMMM-YYYY')}</div>
        //     }
        // },
        // {
        //     title: 'Buyer',
        //     dataIndex: 'buyerName',
        // },
        {
            title: 'Supplier Name',
            dataIndex: 'supplierName',
        },
        {
            title: 'Country Of Origin of Goods',
            dataIndex: 'countryName',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_, record) => (
                <Select
                    value={_}
                    style={{ width: 120 }}
                    onChange={(value) => handleStatusChange(value, record)}
                >
                    {poStatus.map(option => (
                        <Option key={option.statusID} value={option.statusID}>
                            {option.statusName}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                const { purchaseOrderID } = record;
                const handleViewClick = () => {
                    redirectTo(purchaseOrderID);
                };
                return (
                    <div>
                        <div onClick={handleViewClick} style={{ width: 'max-content', cursor: 'pointer' }}>
                            <img src={EditIcon} alt='consignment' />
                        </div>
                    </div>
                );
            },
        },
    ];

    console.log(base_items, branch, "dataList")

    return (
        <div className={styles.mainContainer}>
            {/* <h2 style={{ marginBottom: '30px' }}>Purchase Order</h2> */}
            <div className={styles.searchData}>

                <Input size="medium" placeholder="Search By PO, Buyer Name" prefix={<SearchOutlined />} onChange={(e) => onSearch(e)} style={{ width: '70%' }} />

                {base_items && <Select
                    showSearch
                    style={{
                        width: "50%",
                        margin: '0 10px'
                    }}
                    placeholder="Filter By Company"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                            )
                    }
                    allowClear
                    // bordered={false}
                    onChange={handleChanges}
                    options={

                        base_items.buyerList?.map(
                            (obj) => {
                                const keys = Object.keys(obj);
                                const convertedObj = {
                                    value: obj[keys[0]],
                                    label: obj[keys[1]],
                                };

                                for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                }

                                return convertedObj;
                            }
                        )
                    }
                />}

                {poStatus && <Select
                    showSearch
                    style={{
                        width: "50%",
                    }}
                    placeholder="Filter By Status"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                            )
                    }
                    allowClear
                    // bordered={false}
                    onChange={handleBranch}
                    options={
                        poStatus &&
                        poStatus?.map(
                            (obj) => {
                                const keys = Object.keys(obj);
                                const convertedObj = {
                                    value: obj["statusID"],
                                    label: obj["statusName"],
                                };

                                for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                }

                                return convertedObj;
                            }
                        )
                    }
                />}
                {/* <FilterOutlined style={{ margin: '10px', cursor: 'pointer' }} /> */}
                <Button type="primary" onClick={navigateToCreateForm} style={{ margin: '0 10px' }}> <PlusCircleTwoTone style={{ marginRight: '10px' }} /> Create PO Design</Button>
                {/* <Button type="primary" onClick={navigateToCreateForms} style={{ margin: '0 10px' }}> <PlusCircleTwoTone style={{ marginRight: '10px' }} /> Add Purchase Order</Button> */}
                {/* <Button type="primary" onClick={navigateToCreate}>+ Add Field</Button> */}
            </div>
            <div>

            </div>
            <div>

                <Table
                    columns={columns}
                    // scroll={scrollConfig}
                    expandable={{
                        expandedRowRender,
                        expandedRowKeys: [expandedRowKey],
                        onExpand: (_, record) => handleExpand(record),
                        expandIcon: ({ expanded, onExpand, record }) => (
                            <span onClick={(e) => onExpand(record, e)}>{expanded ? <span style={{ fontSize: "20px", fontWeight: "550", cursor: "pointer" }} >-</span> : <span style={{ fontSize: "20px", fontWeight: "550", cursor: "pointer" }}  >+</span>}</span>
                        ),
                    }}
                    dataSource={dataList.map((item, index) => ({ ...item, key: index }))}
                    // tableLayout='fixed'
                    pagination={false}
                />

                {/*                     
                <CustomTable columns={columns} data={dataList} />*/}
                <div style={{ textAlign: 'right', margin: '10px' }}>
                    <Pagination {...paginationConfig} showSizeChanger={false}/>
                </div>
            </div>
        </div>
    )
}

export default FieldList