import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { api_call_token } from "../../../utils/Network";
// import styles from './index.module.css';
import { DeleteFilled } from "@ant-design/icons";
import { alertMessage } from "../../../utils/helperFunctions";
import styles from "./heatContainer.module.css";

function HeatContainer({
  setCurrentStep = () => { },
  id,
  heatValues,
  containerDatas,
  heatid,
}) {
  const [containerList, setContainerList] = useState([]);
  const [form] = Form.useForm();
  const [containerData, setContainerData] = useState([]);
  const [contains, setContain] = useState({});
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [transformedArray, setTransformedArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productDetails, setProductDetails] = useState({});
  const [vals, setVals] = useState("");
  const [filterObj, setFilterObj] = useState({
    page: 1,
    size: 300,
  });

  useEffect(() => {
    console.log(heatid, "HeatID");
    getContainerList();
    if (heatid) {

      let arr = [];
      containerDatas?.productList.map((val, idx) => {
        let {containerId,containerNumber, partyName, ...rest  } = val;
        let data = {};
        data.containerId = containerId;
        data.containerNumber = containerNumber;
        data.partyName = partyName;
        data.productList = [{...rest}];
        arr.push(data);
      })
      setTransformedArray(arr);
      // setTransformedArray(containerDatas?.containerList);
    }
    if (containerDatas?.productList?.length > 0) {
      let arr = [];
      containerDatas?.productList.map((val, idx) => {
        let {containerId,containerNumber, partyName, ...rest  } = val;
        let data = {};
        data.containerId = containerId;
        data.containerNumber = containerNumber;
        data.partyName = partyName;
        data.productList = [{...rest}];
        arr.push(data);
      })
      setTransformedArray(arr);
      // setTransformedArray(containerDatas?.containerList);
    }
    getContainers(heatValues?.id);
  }, []);

  useEffect(() => {
    getproduct();
  }, [filterObj])

  const getproduct = (paramObj = { ...filterObj, page: currentPage || 1, size: 300 }) => {
    api_call_token
      .get("product", { params: { ...paramObj } })
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getContainers = (vals) => {
    api_call_token
      .get(`heatcontainer/heatproduct/${vals}`)
      .then((res) => {
        console.log(res.data, "DATAsssssssssssss");
        setContain(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getContainerList = () => {
    api_call_token
      .get(`container`)
      .then((res) => {
        if (res.data.totalRowCount) {
          getContainer(res.data.totalRowCount);
        }
      })
      .catch((err) => console.log(err));
  };

  const getContainer = (v) => {
    api_call_token
      .get(`container?size=25`)
      .then((res) => {
        // setContainerList(res.data.data);
      })
      .catch((err) => {
        alertMessage(`Error fetching container data please reload the page`, 2);
      });
  };

  const SubmitData = () => {
    let data = {
      heatId: heatValues?.id,
      heatNumber: heatValues?.heatNumber,
      containerList: transformedArray,
    };

    setIsLoading(true);
    console.log(data, "DATA");
    // api_call_token
    //     .post(`heatcontainer`, data)
    //     .then((res) => {
    //         if (res.status == 200 || res.status == 201) {
    //             setIsLoading(false)
    //             setCurrentStep(prev => prev + 1)
    //         }
    //     })
    //     .catch(err => {
    //         if (err?.response?.data?.error) {
    //             alertMessage(`${err?.response?.data?.error}, try add   `, 2)
    //         } else {
    //             alertMessage("Something went wrong", 2)
    //         }
    //     })
    //Api

    if (heatid) {
      if (transformedArray?.length > 0) {
        api_call_token
          .patch(`heatcontainer/${heatid}`, data)
          .then((res) => {
            if (res.status == 204 || res.status == 201 || res.status == 200) {
              setIsLoading(false);
              setCurrentStep((prev) => prev + 1);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.error) {
              alertMessage(`${err?.response?.data?.error}, try add   `, 2);
            } else {
              alertMessage("Something went wrong", 2);
            }
          });
      } else {
        api_call_token
          .patch(`heatcontainer/${heatid}`, data)
          .then((res) => {
            if (res.status == 204 || res.status == 201 || res.status == 200) {
              setIsLoading(false);
              setCurrentStep((prev) => prev + 1);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.error) {
              alertMessage(`${err?.response?.data?.error}, try add   `, 2);
            } else {
              alertMessage("Something went wrong", 2);
            }
          });
        // setCurrentStep(prev => prev + 1)
      }
    } else {
      if (!contains?.heatId) {
        api_call_token
          .post(`heatcontainer`, data)
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              setIsLoading(false);
              setCurrentStep((prev) => prev + 1);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.error) {
              alertMessage(`${err?.response?.data?.error}, try add   `, 2);
            } else {
              alertMessage("Something went wrong", 2);
            }
          });
      } else {
        api_call_token
          .patch(`heatcontainer/${contains?.heatId}`, data)
          .then((res) => {
            if (res.status == 204 || res.status == 201 || res.status == 200) {
              setIsLoading(false);
              setCurrentStep((prev) => prev + 1);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.error) {
              alertMessage(`${err?.response?.data?.error}, try add   `, 2);
            } else {
              alertMessage("Something went wrong", 2);
            }
          });
      }
    }
  };

  const handleList = (e, val) => {
    form.resetFields(["rate", "quantity", "amount"]);
    api_call_token
      .get(`container/${e}`)
      .then((res) => {
        console.log(res, "DDDDDDDDD");
        setProductList(res.data.productList);

        if (res?.data?.productList?.length > 0) {
          const supplierNames = res?.data?.productList?.map(
            (obj) => obj.supplierName
          );
          console.log(supplierNames, "Supplier");
          form.setFieldsValue({
            partyName: supplierNames[0],
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleProd = (e, val) => {
    console.log(val, "DDDDDdd");
    form.setFieldsValue({
      rate: val?.pricePerUnit || 0,
      quantity: val?.invoiceproductquantity || 0,
      amount: val?.amount || 0,
    });
  };

  const handleProduct = (e, val) => {
    form.resetFields(["container", "partyName", "rate", "amount", "quantity"])
    console.log(e, val, "datasssss");
    if (val?.keys) {
      api_call_token
        .get(`product/getcontainer/${val?.keys}`)
        .then((res) => {
          console.log(res, "res.dsssssss")
          let data = { keys: val?.productCode, names: val?.keys };
          setProductDetails(data)
          setContainerList(res.data);
        })
        .catch(err => console.log(err))
    }

  };

  const submitFinalData = (val) => {

    form.resetFields();
    // Extract the container ID and name
    const { productname, container, partyName, ...rest } = val;
    const containerId = val.container;
    const containerName = containerList.find(
      (item) => item.containerID === containerId
    )?.containerNo;

    // Extract the product ID and name
    const productId = productname;
    // const productNames = productList.find(
    //   (item) => item.invoiceproductId === productId
    // )?.invoiceproductName;
    const productNames = productDetails?.names;

    const datas = {
      productId: productId,
      productName: productNames,
      containerNumber: containerName,
      containerId: container,
      productCode: productDetails?.keys,
      ...rest,
    };

    console.log(datas, "DATAflow");

    setTransformedArray((prevArray) => {
      debugger;
      // const updatedArray = [...prevArray];

      const updatedArray = Array.isArray(prevArray) ? [...prevArray] : [];

      let containerExists = false;

      updatedArray.forEach((item) => {
        if (item.containerId === container && container) {
          containerExists = true;
          const productExists = item.productList.some(
            (product) => product.productId === productId
          );

          if (!productExists) {
            item.productList.push({
              productName: datas?.productName,
              productCode: datas?.productCode,
              productId: datas?.productId,
              quantity: datas.quantity,
              rate: datas.rate,
              amount: datas.amount,
            });
          }
        }
      });

      if (!containerExists) {
        const product = {
          productName: datas?.productName,
          productId: datas?.productId,
          productCode: datas?.productCode,
          quantity: datas.quantity,
          rate: datas.rate,
          amount: datas.amount,
        };

        updatedArray.push({
          containerId: container,
          containerNumber: containerName,
          partyName: partyName,
          productList: [product],
        });
      }
      setProductDetails({})
      return updatedArray;
    });
  };

  const onDelete = (v, x, ind, i) => {
    console.log(v, x, ind);
    const productExists = transformedArray.findIndex(
      (product) => product.containerId === v
    );

    console.log(transformedArray[i], productExists, "ProductExist");

    if (transformedArray[i].productList.length > 1) {
      transformedArray[i].productList.splice(ind, 1);
    } else {
      transformedArray.splice(i, 1);
    }
    setTransformedArray([...transformedArray]);
  };

  const handleQuantity = (e) => {
    console.log(e.target.value, "Edfdfd");
    const demo = form.getFieldValue();
    console.log(demo, "Edfdfd");
    form.setFieldsValue({
      amount: demo?.quantity * demo?.rate,
    });
  };

  const handleQuick = (e, mainIndex, innerIndex) => {
    const { name, value } = e.target;
    let data = [...transformedArray];

    data[mainIndex].productList[innerIndex][name] = value;

    // Calculate the new amount based on the updated quantity and rate
    if (name !== "amount") {
      data[mainIndex].productList[innerIndex].amount =
        data[mainIndex].productList[innerIndex].quantity *
        data[mainIndex].productList[innerIndex].rate;
    }

    setTransformedArray(data);
  };

  const handleRate = (e) => {
    console.log(e.target.value, "Edfdfd");
    const demo = form.getFieldValue();
    console.log(demo, "Edfdfd");
    form.setFieldsValue({
      amount: demo?.quantity * demo?.rate,
    });
  };

  console.log(
    transformedArray,
    containerData,
    id,
    heatValues,
    heatid,
    "Transformed"
  );
  console.log(transformedArray, "TRANSGOR");
  console.log(products, "SSSSSSSSet")
  // console.log(containerList, containerData, heatValues, "containerList")
  return (
    <div style={{ padding: "20px 11px" }}>
      <Form form={form} onFinish={submitFinalData}>
        <table style={{ borderSpacing: "0", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#e9e9e9" }}>
              <th
                className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}
                style={{ width: "5%", padding: "10px" }}
              >
                Sr No
              </th>
              <th
                className={`${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                Product Name
              </th>
              <th
                className={`${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                Container Id

              </th>
              <th
                className={`${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                Party Name
              </th>
              <th
                className={`${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                Select Qty
              </th>
              <th
                className={` ${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                Rate
              </th>
              <th
                className={` ${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                Amt
              </th>
              <th
                className={`${styles.right} ${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={`${styles.left} ${styles.bottom} ${styles.widthTwenty} ${styles.padding}`}
                style={{ width: "8%", padding: "10px" }}
              ></td>
              <td
                className={` ${styles.bottom}  ${styles.widthTwentyPercent} ${styles.padding}`}
                style={{ width: "18%" }}
              >
                <Form.Item name="productname">
                  <Select
                    showSearch
                    allowClear={true}
                    style={{
                      width: "170px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? "").toLowerCase()
                        )
                    }
                    // onSearch={(event) => {
                    //   let value = event;
                    //   let obj = filterObj;
                    //   if (value) {
                    //     obj.search = value;
                    //     setCurrentPage(1);
                    //     // onSearchProduct(value)
                    //     setFilterObj({ ...obj });
                    //   } else {
                    //     delete obj.search;
                    //     setFilterObj({ ...obj });
                    //   }
                    // }}
                    // onChange={(e, val) => handleProd(e, val)}
                    onChange={(e, val) => handleProduct(e, val)}
                    options={
                      products && products.length > 0 &&
                      products?.map((obj) => {
                        const keys = Object.keys(obj);
                        const convertedObj = {
                          value: obj["productID"],
                          label: obj["productCode"],
                          keys: obj["productName"]
                        };

                        for (let i = 2; i < keys.length; i++) {
                          convertedObj[keys[i]] = obj[keys[i]];
                        }

                        return convertedObj;
                      })
                    }
                  />
                </Form.Item>
              </td>
              <td
                className={` ${styles.bottom} ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                <Form.Item name="container">
                  <Select
                    showSearch
                    style={{
                      width: "160px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    onChange={(e, val) => handleList(e, val)}
                    options={
                      containerList &&
                      containerList?.map((obj) => {
                        const keys = Object.keys(obj);
                        const convertedObj = {
                          value: obj["containerID"],
                          label: obj["containerNo"],
                        };

                        for (let i = 2; i < keys.length; i++) {
                          convertedObj[keys[i]] = obj[keys[i]];
                        }

                        return convertedObj;
                      })
                    }
                  />
                </Form.Item>
              </td>
              <td
                className={` ${styles.bottom} ${styles.widthTwentyPercent} ${styles.padding}`}
                style={{ padding: "10px" }}
              >
                <Form.Item name="partyName">
                  <Input />
                </Form.Item>
              </td>

              <td
                className={` ${styles.bottom}  ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                <Form.Item name="quantity">
                  <Input onChange={(e) => handleQuantity(e)} />
                </Form.Item>
              </td>
              <td
                className={` ${styles.bottom}  ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                <Form.Item name="rate">
                  <Input onChange={(e) => handleRate(e)} />
                </Form.Item>
              </td>
              <td
                className={` ${styles.bottom}  ${styles.widthTwentyPercent} ${styles.padding}`}
              >
                <Form.Item name="amount">
                  <Input />
                </Form.Item>
              </td>
              <td
                className={`${styles.right} ${styles.bottom} ${styles.widthTwentyPercent} ${styles.padding}`}
                style={{ paddingBottom: "39px" }}
              >
                <Button type="primary" htmlType="submit">
                  +
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </Form>

      <table style={{ width: "100%", borderSpacing: "0" }}>
        <tbody>
          <tr>
            <td
              style={{ width: "5%", padding: '10px' }}
              className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty} `}
            >
              Sr no
            </td>
            <td
              style={{ width: "2%", padding: '10px' }}
              className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty} `}
            >
              Product Code
            </td>
            <td
              style={{ width: "2%", padding: '10px' }}
              className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty} `}
            >
              Container
            </td>
            <td
              style={{ width: "2%", padding: '10px' }}
              className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty} `}
            >
              Party Name
            </td>
            <td
              style={{ width: "5%" }}
              className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty}`}
            >
              <div className={styles.tables}>

                <div
                  className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthHundred}`}
                  style={{ width: "35%", padding: '10px' }}
                >
                  Selected Qty
                </div>
                <div
                  className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthHundred}`}
                  style={{ width: "35%", padding: '10px' }}
                >
                  Rate
                </div>
                <div
                  className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthHundred}`}
                  style={{ width: "35%", padding: '10px' }}
                >
                  Amt
                </div>
                <div
                  className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthHundred}`}
                  style={{ width: "10%", padding: '10px' }}
                >
                  Action
                </div>
              </div>
            </td>
          </tr>
          {transformedArray?.length > 0 &&
            transformedArray?.map((v, i) => (
              <tr key={i}>
                <td
                  style={{ width: "5%" }}
                  className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}
                >
                  {i + 1}
                </td>
                <td
                  style={{ width: "8%" }}
                  className={`${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
                >
                  {v?.productList[0]?.productCode}
                </td>
                <td
                  style={{ width: "8%" }}
                  className={`${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
                >
                  {v?.containerNumber}
                </td>
                <td
                  style={{ width: "10%" }}
                  className={`${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwentyPercent} ${styles.padding}`}
                >
                  {v?.partyName}
                </td>
                <td
                  style={{ width: "40%" }}
                  className={`${styles.right} ${styles.bottom}  ${styles.widthTwentyPercent}`}
                >
                  {v?.productList.map((val, indx) => (
                    <div className={styles.tables}>
                      <div
                        className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthHundred} ${styles.padding}`}
                      >
                        {/* {val?.quantity} */}

                        <Input
                          name="quantity"
                          value={val?.quantity}
                          onChange={(e) => handleQuick(e, i, indx)}
                        />
                      </div>
                      <div
                        className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthHundred} ${styles.padding}`}
                      >
                        {/* {val?.rate} */}
                        <Input
                          name="rate"
                          value={val?.rate}
                          onChange={(e) => handleQuick(e, i, indx)}
                        />
                      </div>
                      <div
                        className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthHundred} ${styles.padding}`}
                      >
                        <Input
                          name="amount"
                          value={val?.amount}
                          onChange={(e) => handleQuick(e, i, indx)}
                        />

                        {/* {val?.amount} */}
                      </div>
                      <div
                        className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthHundred} ${styles.padding}`}
                        style={{ width: "5%" }}
                      >
                        <DeleteFilled
                          onClick={() =>
                            onDelete(v?.containerId, val?.productName, indx, i)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div>
        <Button
          onClick={() => SubmitData()}
          loading={isLoading}
          type="primary"
          style={{ marginTop: "20px" }}
        >
          Save & Next
        </Button>
      </div>
    </div>
  );
}

export default HeatContainer;

/**
 *     const SubmitData = () => {
        let data = {
            heatId: heatValues?.id,
            heatNumber: heatValues?.heatNumber,
            containerList: containerData
        }

        setIsLoading(true)


        console.log(data, "DATA")

        //Api

        if (heatid) {

            if (containerData?.length > 0) {
                api_call_token
                    .patch(`heatcontainer/${heatid}`, data)
                    .then((res) => {
                        if (res.status == 204 || res.status == 201 || res.status == 200) {
                            setIsLoading(false)
                            setCurrentStep(prev => prev + 1)
                        }
                    })
                    .catch(err => {
                        if (err?.response?.data?.error) {
                            alertMessage(`${err?.response?.data?.error}, try add   `, 2)
                        } else {
                            alertMessage("Something went wrong", 2)
                        }
                    })
            } else {
                setCurrentStep(prev => prev + 1)

            }

        } else {
            api_call_token
                .post(`heatcontainer`, data)
                .then((res) => {
                    if (res.status == 200 || res.status == 201) {
                        setIsLoading(false)
                        setCurrentStep(prev => prev + 1)
                    }
                })
                .catch(err => {
                    if (err?.response?.data?.error) {
                        alertMessage(`${err?.response?.data?.error}, try add   `, 2)
                    } else {
                        alertMessage("Something went wrong", 2)
                    }
                })
        }
    }

    const handleData = (value) => {
        debugger;

        console.log(value, "VALES")

        let findContainer = containerData && containerData?.findIndex((vals) => vals?.containerId == value?.container
        )

        console.log(findContainer, "FindContainer")

        if (findContainer == -1 || findContainer === undefined || findContainer === null) {
            form.resetFields();
            console.log(value)
            api_call_token
                .get(`container/${value?.container}`)
                .then((res) => {
                    console.log(res, "DDDDDDDDD")
                    // setContainerList(res.data.data)
                    const { containerID, containerNo, productList } = res.data;

                    let productData = [];

                    productList.map((v, i) => {
                        let prod = {
                            productName: v.invoiceproductName,
                            productId: v.invoiceproductId,
                            quantity: null,
                        }
                        productData.push(prod);
                    })

                    let container = {
                        containerId: containerID,
                        containerNumber: containerNo,
                        partyName: "",
                        productList: productData,
                    }

                    console.log(containerData, "FindContainer")

                    if (!containerData || !containerData?.length > 0) {
                        setContainerData([container])
                    } else {
                        setContainerData([...containerData, container])
                    }
                })
                .catch((err) => console.log(err))
        } else {
            alertMessage("Data already Present", 2)
        }

    }

    const handleChangeData = (e, prod) => {
        console.log(e.target.value, "fffff")

        let findContainer = containerData.findIndex((val) =>
            val?.containerId == prod
        )

        console.log(findContainer, "FindContainer")

        if (findContainer !== -1) {
            //Update particular product value for multiple container
            containerData[findContainer].partyName = e.target.value;
            setContainerData([...containerData]);
        }
    }


    const DeleteData = (val) => {
        debugger;
        console.log(val)

        let findContainer = containerData.findIndex((vals) =>
            vals?.containerId == val
        )

        if (findContainer >= 0 && findContainer < containerData.length) {
            containerData.splice(findContainer, 1); // Removes one element at the specified index
        }
        setContainerData([...containerData])
    }
 */
