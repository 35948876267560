import React from 'react'
import { Outlet } from 'react-router-dom'

export function BOELayout() {
  return (
    <Outlet />
  )
}


export  function ShippingLayout() {
    return (
      <Outlet />
    )
  }


export function MasterLayout() {
  return (
    <Outlet />
  )
}

export function CurrencyLayout() {
  return(
    <Outlet />
  )
}

export function BuyerLayout() {
  return(
    <Outlet />
  )
}

export function SupplierLayout() {
  return(
    <Outlet />
  )
}

export function PortLayout() {
  return(
    <Outlet />
  )
}

export function ProductLayout() {
  return(
    <Outlet />
  )
}

export function DispatchLayout() {
  return(
    <Outlet />
  )
}

export function PaymentLayout() {
  return(
    <Outlet />
  )
}

export function IntercomLayout() {
  return(
    <Outlet />
  )
}

export function TermsLayout() {
  return(
    <Outlet />
  )
}

export function ShippmentLayout() {
  return(
    <Outlet />
  )
}

export function InvoiceLayout() {
  return(
    <Outlet />
  )
}

export function DsrLayout() {
  return(
    <Outlet />
  )
}

export function UmoLayout() {
  return(
    <Outlet />
  )
}

export function HeatConLayout() {
  return(
    <Outlet />
  )
}

export function ShippingLineLayout() {
  return(
    <Outlet />
  )
}

export function TCLayout() {
  return(
    <Outlet />
  )
}

export function CODLayout() {
  return(
    <Outlet />
  )
}

export function TransporterLayout() {
  return(
    <Outlet />
  )
}

export function InsuranceLayout() {
  return(
    <Outlet />
  )
}

export function ElementLayout() {
  return(
    <Outlet />
  )
}

export function CHALayout() {
  return(
    <Outlet />
  )
}

export function AgentLayout() {
  return(
    <Outlet />
  )
}

export function PortInnerLayout() {
  return(
    <Outlet />
  )
}

export function PlaceOfrecLayout() {
  return(
    <Outlet />
  )
}

export function LocalLayout() {
  return(
    <Outlet />
  )
}

export function PoMainLayout() {
  return(
    <Outlet />
  )
}


export function HeatMainLayout() {
  return(
    <Outlet />
  )
}

export function ContainerInOutLayout() {

  return(
    <Outlet />
  )
}
export function DeliveryChallanLayout() {

  return(
    <Outlet />
  )
}