import { Button, Col, DatePicker, Form, Input, Row, Select, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { api_call_token } from '../../../utils/Network';
import moment from 'moment';
import styles from './index.module.css';
import NumericInput from '../../../Components/NumericInput/NumericInput';
import { alertMessage } from '../../../utils/helperFunctions';

function HeatGeneration({ setCurrentStep = () => { }, id, setHeatValues = () => { }, heatdata }) {
    const [form] = Form.useForm();
    const [furnance, setFurnance] = useState([]);
    const [getHeatNumber, setHeatNumber] = useState("");
    const [headIn, setHeatIn] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState("");

    const [checkHeatNumber, setcheckHeatNumber] = useState(false);
    const today = moment();

    useEffect(() => {
        getFurnance();
    }, [])

    useEffect(() => {

        if (heatdata?.heatId) {
            const { active, createdAt, createdBy, deleted, status, updatedAt, updatedBy, userId, heatIncValue, castingTimeEnd, castingTimeStart, heatTime, ...rest } = heatdata;
            setHeatIn({ ...rest });
            setHeatNumber(heatdata?.heatNumber)
        }

    }, [heatdata])

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    const getFurnance = () => {
        api_call_token
            .get(`furnace`)
            .then((res) => {
                setFurnance(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleChange = (e) => {
        console.log(e, "EEEEE")

        api_call_token
            .post(`heat/getHeatNumberWithFurnace/${id}`, { furnaceName: e })
            .then((res) => {
                setHeatNumber(res.data.heatNumber)
            })
            .catch(err => console.log(err))
    }

    const submitDataPatch = (vals) => {
        setIsLoading(true)
        for (const key in vals) {
            if (vals[key] === undefined) {
                delete vals[key];
            }
        }
        const { heatDate, heatTime, castingTimeStart, castingTimeEnd, furnaceTimeEnd, furnaceTimeStart, ...rest } = vals;
        let heatdate = (heatDate?.$d) ? moment(heatDate.$d).format('YYYY-MM-DD') : (heatDate?._d) ? moment(heatDate?._d).format('YYYY-MM-DD') : null;
        let time = (heatTime?.$d) ? moment(heatTime.$d).format('HH:mm:ss') : (heatTime?._d) ? moment(heatTime?._d).format('HH:mm:ss') : null;
        let castStarttime = (castingTimeStart?.$d) ? moment(castingTimeStart.$d).format('HH:mm:ss') : (castingTimeStart?._d) ? moment(castingTimeStart._d).format('HH:mm:ss') : null;
        let castEndtime = (castingTimeEnd?.$d) ? moment(castingTimeEnd.$d).format('HH:mm:ss') : (castingTimeEnd?._d) ? moment(castingTimeEnd._d).format('HH:mm:ss') : null;
        let furnaceStart = (furnaceTimeStart?.$d) ? moment(furnaceTimeStart?.$d).format('HH:mm:ss') : (furnaceTimeStart?._d) ? moment(furnaceTimeStart?._d).format('HH:mm:ss') : null;
        let furnaceEnd = (furnaceTimeEnd?.$d) ? moment(furnaceTimeEnd?.$d).format('HH:mm:ss') : (furnaceTimeEnd?._d) ? moment(furnaceTimeEnd?._d).format('HH:mm:ss') : null;

        const newObj = {
            companyId: +id,
            heatId: heatdata?.heatId,
            furnaceName: heatdata?.furnaceName,
            heatDate: heatdate,
            heatTime: time,
            castingTimeStart: castStarttime,
            castingTimeEnd: castEndtime,
            furnaceTimeStart: furnaceStart,
            furnaceTimeEnd: furnaceEnd,
            heatNumber: getHeatNumber,
            ...rest
        }

        console.log(newObj, "NEWER")

        if (checkHeatNumber) {
            alertMessage("Heat Number should be unique", 2);
            setIsLoading(false);
        } else {
            if (getHeatNumber) {
                api_call_token
                    .patch(`heat/${heatdata?.heatId}`, newObj)
                    .then((res) => {
                        if (res.status == 200 || res.status == 201 || res.status == 204) {
                            setIsLoading(false)
                            setHeatValues({
                                id: res?.data?.id,
                                heatNumber: res?.data?.heatNumber
                            })
                            setCurrentStep(prev => prev + 1)
                        }
                        console.log(res, "dee")
                    })
            } else {
                alertMessage("Heat Number should not be empty", 2)
                setIsLoading(false);

            }

            // alertMessage("Done ")
        }

    }

    const handleHeat = (e) => {
        console.log(e.target.value)
        setHeatNumber(e.target.value)

        if (heatdata?.heatNumber == e.target.value) {

        } else {
            api_call_token
                .get(`heat/heatDuplicate/${e.target.value}/${id}`)
                .then((res) => {
                    console.log(res.data, "RRRRRR")
                    if (!res.data.status) {
                        setcheckHeatNumber(false)
                        setHeatNumber(e.target.value)
                    } else {
                        setcheckHeatNumber(true);
                        alertMessage("Heat Number Already Exist", 2)

                    }
                })
                .catch(err => console.log(err))
        }
    }

    const submitData = (values) => {
        setIsLoading(true)
        console.log(values)
        const { heatDate, heatTime, castingTimeStart, castingTimeEnd, furnaceTimeStart, furnaceTimeEnd, ...rest } = values
        let heatdate = (heatDate?.$d) ? moment(heatDate.$d).format('YYYY-MM-DD') : moment(today?._d).format("YYYY-MM-DD");
        let time = (heatTime?.$d) ? moment(heatTime.$d).format('HH:mm:ss') : null;
        let castStarttime = (castingTimeStart?.$d) ? moment(castingTimeStart.$d).format('HH:mm:ss') : null;
        let castEndtime = (castingTimeEnd?.$d) ? moment(castingTimeEnd.$d).format('HH:mm:ss') : null;
        let furnacetimeStart = (furnaceTimeStart?.$d) ? moment(furnaceTimeStart.$d).format('HH:mm:ss') : null;
        let furnacetimeEnd = (furnaceTimeEnd?.$d) ? moment(furnaceTimeEnd.$d).format('HH:mm:ss') : null;


        // console.log(heatdate, time)

        const newObj = {
            companyId: +id,
            heatDate: heatdate,
            heatTime: time,
            castingTimeStart: castStarttime,
            castingTimeEnd: castEndtime,
            furnaceTimeStart: furnacetimeStart,
            furnaceTimeEnd: furnacetimeEnd,
            heatNumber: getHeatNumber,
            ...rest
        }
        console.log(newObj, "DDDDd")

        if (checkHeatNumber) {
            alertMessage("Heat Number should be unique", 2);
            setIsLoading(false);
        } else {
            if (getHeatNumber) {
                api_call_token
                    .post(`heat`, newObj)
                    .then((res) => {
                        if (res.status == 200 || res.status == 201) {
                            setIsLoading(false)
                            setHeatValues(res.data)
                            setCurrentStep(prev => prev + 1)
                        }
                        console.log(res, "dee")
                    })
            } else {
                alertMessage("Heat Number should not be empty", 2)
                setIsLoading(false);

            }

            // alertMessage("Done ")
        }

    }

    console.log(heatdata, getHeatNumber, "furnance")
    return (
        <div style={{ padding: '40px' }} className={styles.mainHeatGen}>
            {heatdata?.heatId && <Form onFinish={submitDataPatch} form={form}

                initialValues={{
                    furnaceName: heatdata?.furnaceName,
                    oilUsage: heatdata?.oilUsage,
                    heatDate: heatdata?.heatDate ? moment(heatdata?.heatDate, "YYYY-MM-DD") : undefined,
                    heatTime: heatdata?.heatTime ? moment(heatdata?.heatTime, "HH:mm:ss") : undefined,
                    strfoil: heatdata?.strfoil,
                    etrfoil: heatdata?.etrfoil,
                    ssklroil: heatdata?.ssklroil,
                    esklroil: heatdata?.esklroil,
                    castingTimeStart: heatdata?.castingTimeStart ? moment(heatdata?.castingTimeStart, "HH:mm:ss") : undefined,
                    castingTimeEnd: heatdata?.castingTimeEnd ? moment(heatdata?.castingTimeEnd, "HH:mm:ss") : undefined,
                    furnaceTimeStart: heatdata?.furnaceTimeStart ? moment(heatdata?.furnaceTimeStart, "HH:mm:ss") : undefined,
                    furnaceTimeEnd: heatdata?.furnaceTimeEnd ? moment(heatdata?.furnaceTimeEnd, "HH:mm:ss") : undefined,
                    masterName: heatdata?.masterName,
                    labourCount: heatdata?.labourCount,
                    oilEnd: heatdata?.oilEnd,
                    electricityUsed: heatdata?.electricityUsed,
                }}>
                <div>

                    {/* <table style={{ borderSpacing: '0' }}>
                        <tbody>
                            <tr>
                                <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Furnance Name</p>
                                    <Input
                                        disabled
                                        bordered={false}
                                        value={heatdata?.furnaceName}
                                        style={{ marginBottom: '15px' }} />
                                    {/* <Form.Item name="furnaceName" rules={[
                                    {
                                        required: true,
                                        message: `Please Select Furnance Name!`,
                                    },
                                ]}>
                                <Form.Item name="furnaceName" rules={[
                                    {
                                        required: true,
                                        message: `Please Select Furnance Name!`,
                                    },
                                ]}>
                                    <Input bordered={false}/>
                                    {/* <Select
                                    bordered={false}
                                        showSearch
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Select Furnance Name"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").includes(input)
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                    (optionB?.label ?? "").toLowerCase()
                                                )
                                        }
                                        onChange={handleChange}
                                        options={
                                            furnance &&
                                            furnance?.map(
                                                (obj) => {
                                                    const keys = Object.keys(obj);
                                                    const convertedObj = {
                                                        value: obj["furnaceName"],
                                                        label: obj["furnaceName"],
                                                    };

                                                    for (let i = 2; i < keys.length; i++) {
                                                        convertedObj[keys[i]] = obj[keys[i]];
                                                    }

                                                    return convertedObj;
                                                }
                                            )
                                        }
                                    /> 
                                </td>
                                <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p> Heat Number</p>
                                    <Input
                                        bordered={false}

                                        placeholder='Enter Heat Number'
                                        value={getHeatNumber}
                                        disabled
                                        style={{ marginBottom: '15px' }}
                                    />

                                </td>

                                <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Master Name</p>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter Name`,
                                            },
                                        ]}
                                        name="masterName"
                                    >
                                        <Input bordered={false} placeholder='Master Name' />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>
                                        Labours
                                    </p>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter labours!`,
                                            },
                                        ]}
                                        name="labourCount"
                                    >
                                        <Input bordered={false} placeholder='No. of Labours' type='number' />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left} ${styles.right} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Oil Usage</p>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter Oil!`,
                                            },
                                        ]}
                                        name="oilUsage"
                                    >
                                        <Input bordered={false} placeholder="Start Oil Usage (cm)" type='number' step={1} />
                                    </Form.Item>
                                </td>

                            </tr>
                            <tr>

                                <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Heat Date</p>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: `Please Heat Date!`,
                                            },
                                        ]}
                                        name="heatDate"
                                    >
                                        <DatePicker bordered={false}
                                            placeholder='Heat Date'
                                            onChange={onChange}
                                            // showTime
                                            format="YYYY-MM-DD"
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Heat Start Time</p>
                                    <Form.Item
                                        name="heatTime"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <TimePicker bordered={false} placeholder='Select Heat Time' style={{ width: "100%" }} />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left}  ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Casting Start Time</p>
                                    <Form.Item
                                        name="castingTimeStart"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <TimePicker bordered={false} placeholder='Select Casting Start Time' style={{ width: "100%" }} />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left}  ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Casting End Time</p>
                                    <Form.Item
                                        name="castingTimeEnd"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <TimePicker bordered={false} placeholder='Select Casting End Time' style={{ width: "100%" }} />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left} ${styles.right} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Furnance Start Time</p>
                                    <Form.Item
                                        name="furnaceTimeStart"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <TimePicker bordered={false} placeholder='Select Furnance Start Time' style={{ width: "100%" }} />
                                    </Form.Item>
                                </td>

                            </tr>
                            <tr>
                                <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Furnance End Time</p>
                                    <Form.Item
                                        name="furnaceTimeEnd"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <TimePicker bordered={false} placeholder='Select Furnance End Time' style={{ width: "100%" }} />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left}  ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>strfoil</p>
                                    <Form.Item
                                        name="strfoil"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input bordered={false} placeholder='Enter strf oil' type='number' step={1} />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left}  ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>etrfoil</p>
                                    <Form.Item
                                        name="etrfoil"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input bordered={false} placeholder='Enter etrf oil' type='number' step={1} />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>ssklroil</p>
                                    <Form.Item
                                        name="ssklroil"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input bordered={false} placeholder='Enter ssklr oil' type='number' step={1} />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>esklroil</p>
                                    <Form.Item
                                        name="esklroil"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input bordered={false} placeholder='Enter esklr oil' type='number' step={1} />
                                    </Form.Item>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${styles.left} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Oil End</p>
                                    <Form.Item
                                        name="oilEnd"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input bordered={false} placeholder='Enter oil End' type='number' step={1} />
                                    </Form.Item>
                                </td>
                                <td className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>Electricity Used</p>
                                    <Form.Item
                                        name="electricityUsed"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input bordered={false} placeholder='Enter Electricity Used' type='number' step={1} />
                                    </Form.Item>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}

                </div>

                < div className={styles.basicDetailFormMain} >
                    <div className={styles.basicsForm}>
                        <Row gutter={[26, 0]}>

                            <Col xs={24} md={14} lg={24}>
                                <span>Furnace Name</span>
                                <Form.Item name="furnaceName" rules={[
                                    {
                                        required: true,
                                        message: `Please Select Furnance Name!`,
                                    },
                                ]}>

                                    <Select
                                        showSearch
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Select Furnance Name"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                    (optionB?.label ?? "").toLowerCase()
                                                )
                                        }
                                        onChange={handleChange}
                                        options={
                                            furnance &&
                                            furnance?.map(
                                                (obj) => {
                                                    const keys = Object.keys(obj);
                                                    const convertedObj = {
                                                        value: obj["furnaceName"],
                                                        label: obj["furnaceName"],
                                                    };

                                                    for (let i = 2; i < keys.length; i++) {
                                                        convertedObj[keys[i]] = obj[keys[i]];
                                                    }

                                                    return convertedObj;
                                                }
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Batch Number</span>

                                <Input

                                    placeholder='Enter Batch Number'
                                    value={getHeatNumber}
                                    style={{ marginBottom: '23px' }}
                                    onChange={(e) => handleHeat(e)}
                                />
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Heat Date</span>

                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: `Please Heat Date!`,
                                        },
                                    ]}
                                    name="heatDate"
                                >
                                    <DatePicker
                                        placeholder='Batch Date'
                                        onChange={onChange}
                                        // showTime
                                        format="YYYY-MM-DD"
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Heat Time</span>

                                <Form.Item
                                    name="heatTime"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Batch Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={14} lg={24}>
                            <span>Oil Usage</span>

                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter Oil!`,
                                        },
                                    ]}
                                    name="oilUsage"
                                // label="Start Oil Usage (cm)"
                                >
                                    <Input placeholder="Start Oil Usage (cm)" type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Oil End</span>

                                <Form.Item
                                    name="oilEnd"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='End oil Usage (cm)' type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Master Name</span>

                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter Name`,
                                        },
                                    ]}
                                    name="masterName"
                                >
                                    <Input placeholder='Master Name' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Labour Count</span>

                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter labours!`,
                                        },
                                    ]}
                                    name="labourCount"
                                >
                                    <Input placeholder='No. of Labours' type='number' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Electricity Used</span>

                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter electricity`,
                                        },
                                    ]}
                                    name="electricityUsed"
                                // label="Start Oil Usage (cm)"
                                >
                                    <Input placeholder='Electricity Used' type='number' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.basicsForm}>

                        <Row gutter={[26, 0]}>
                            <Col xs={24} md={14} lg={24}>
                            <span>strf oil</span>

                                <Form.Item
                                    name="strfoil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='strf oil' type='number' step={1} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={14} lg={24}>
                            <span>etrf oil</span>

                                <Form.Item
                                    name="etrfoil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='etrf oil' type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>ssklr oil</span>

                                <Form.Item
                                    name="ssklroil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='ssklr oil' type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>esklr oil</span>

                                <Form.Item
                                    name="esklroil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='esklr oil' type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Casting Start Time</span>

                                <Form.Item
                                    name="castingTimeStart"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Casting Start Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Casting End Time</span>

                                <Form.Item
                                    name="castingTimeEnd"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Casting End Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Furnace Start Time</span>

                                <Form.Item
                                    name="furnaceTimeStart"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Furnance Start Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Furnace End Time</span>

                                <Form.Item
                                    name="furnaceTimeEnd"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Furnance End Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div >

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Save & Next
                    </Button>
                </Form.Item>
            </Form>}


            {!heatdata?.heatId && <Form onFinish={submitData}
                initialValues={{
                    heatDate: moment(today, "YYYY-MM-DD")
                }}
            >
                {/* <table style={{ borderSpacing: '0' }}>
                    <tbody>
                        <tr>
                            <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Furnance Name</p>
                                <Form.Item name="furnaceName" rules={[
                                    {
                                        required: true,
                                        message: `Please Select Furnance Name!`,
                                    },
                                ]}>

                                    <Select
                                        bordered={false}
                                        showSearch
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Select Furnance Name"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").includes(input)
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                    (optionB?.label ?? "").toLowerCase()
                                                )
                                        }
                                        onChange={handleChange}
                                        options={
                                            furnance &&
                                            furnance?.map(
                                                (obj) => {
                                                    const keys = Object.keys(obj);
                                                    const convertedObj = {
                                                        value: obj["furnaceName"],
                                                        label: obj["furnaceName"],
                                                    };

                                                    for (let i = 2; i < keys.length; i++) {
                                                        convertedObj[keys[i]] = obj[keys[i]];
                                                    }

                                                    return convertedObj;
                                                }
                                            )
                                        }
                                    />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p> Heat Number</p>
                                <Input
                                    bordered={false}
                                    placeholder='Enter Heat Number'
                                    value={getHeatNumber}
                                    style={{ marginBottom: '15px' }}
                                    onChange={(e) => handleHeat(e)}
                                />
                            </td>

                            <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Master Name</p>
                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter Name`,
                                        },
                                    ]}
                                    name="masterName"
                                >
                                    <Input bordered={false} placeholder='Master Name' />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>
                                    Labours
                                </p>
                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter labours!`,
                                        },
                                    ]}
                                    name="labourCount"
                                >
                                    <Input bordered={false} placeholder='No. of Labours' type='number' />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left} ${styles.right} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Oil Usage</p>
                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter Oil!`,
                                        },
                                    ]}
                                    name="oilUsage"
                                // label="Start Oil Usage (cm)"
                                >
                                    <Input bordered={false} placeholder="Start Oil Usage (cm)" type='number' step={1} />
                                </Form.Item>
                            </td>

                        </tr>
                        <tr>

                            <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Heat Date</p>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: `Please Heat Date!`,
                                        },
                                    ]}
                                    name="heatDate"
                                >
                                    <DatePicker bordered={false}
                                        placeholder='Heat Date'
                                        onChange={onChange}
                                        // showTime
                                        format="YYYY-MM-DD"
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Heat Start Time</p>
                                <Form.Item
                                    name="heatTime"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker bordered={false} placeholder='Select Heat Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left}  ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Casting Start Time</p>
                                <Form.Item
                                    name="castingTimeStart"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker bordered={false} placeholder='Select Casting Start Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left}  ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Casting End Time</p>
                                <Form.Item
                                    name="castingTimeEnd"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker bordered={false} placeholder='Select Casting End Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left} ${styles.right} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Furnance Start Time</p>
                                <Form.Item
                                    name="furnaceTimeStart"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker bordered={false} placeholder='Select Furnance Start Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </td>

                        </tr>
                        <tr>
                            <td className={`${styles.left} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Furnance End Time</p>
                                <Form.Item
                                    name="furnaceTimeEnd"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker bordered={false} placeholder='Select Furnance End Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left}  ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>strfoil</p>
                                <Form.Item
                                    name="strfoil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input bordered={false} placeholder='Enter strf oil' type='number' step={1} />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left}  ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>etrfoil</p>
                                <Form.Item
                                    name="etrfoil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input bordered={false} placeholder='Enter etrf oil' type='number' step={1} />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>ssklroil</p>
                                <Form.Item
                                    name="ssklroil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input bordered={false} placeholder='Enter ssklr oil' type='number' step={1} />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>esklroil</p>
                                <Form.Item
                                    name="esklroil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input bordered={false} placeholder='Enter esklr oil' type='number' step={1} />
                                </Form.Item>
                            </td>
                        </tr>
                        <tr>
                            <td className={`${styles.left} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Oil End</p>
                                <Form.Item
                                    name="oilEnd"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input bordered={false} placeholder='Enter oil End' type='number' step={1} />
                                </Form.Item>
                            </td>
                            <td className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                <p>Electricity Used</p>
                                <Form.Item
                                    name="electricityUsed"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input bordered={false} placeholder='Enter Electricity Used' type='number' step={1} />
                                </Form.Item>
                            </td>
                        </tr>
                    </tbody>
                </table> */}

                < div className={styles.basicDetailFormMain} >
                    <div className={styles.basicsForm}>
                        <Row gutter={[26, 0]}>

                            <Col xs={24} md={14} lg={24}>
                            <span>Furnace Name</span>
                                <Form.Item name="furnaceName" rules={[
                                    {
                                        required: true,
                                        message: `Please Select Furnance Name!`,
                                    },
                                ]}>

                                    <Select
                                        showSearch
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Select Furnance Name"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").includes(input)
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                    (optionB?.label ?? "").toLowerCase()
                                                )
                                        }
                                        onChange={handleChange}
                                        options={
                                            furnance &&
                                            furnance?.map(
                                                (obj) => {
                                                    const keys = Object.keys(obj);
                                                    const convertedObj = {
                                                        value: obj["furnaceName"],
                                                        label: obj["furnaceName"],
                                                    };

                                                    for (let i = 2; i < keys.length; i++) {
                                                        convertedObj[keys[i]] = obj[keys[i]];
                                                    }

                                                    return convertedObj;
                                                }
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span >Batch Number</span>
                                <Input

                                    placeholder='Enter Batch Number'
                                    value={getHeatNumber}
                                    style={{ marginBottom: '23px' }}
                                    onChange={(e) => handleHeat(e)}
                                />
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Heat Date</span>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: `Please Heat Date!`,
                                        },
                                    ]}
                                    name="heatDate"
                                >
                                    <DatePicker
                                        placeholder='Batch Date'
                                        onChange={onChange}
                                        // showTime
                                        format="YYYY-MM-DD"
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Heat Time</span>
                                <Form.Item
                                    name="heatTime"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Batch Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={14} lg={24}>
                            <span>Oil Usage</span>
                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter Oil!`,
                                        },
                                    ]}
                                    name="oilUsage"
                                // label="Start Oil Usage (cm)"
                                >
                                    <Input placeholder="Start Oil Usage (cm)" type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Oil End</span>

                                <Form.Item
                                    name="oilEnd"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='End oil Usage (cm)' type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Master Name</span>

                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter Name`,
                                        },
                                    ]}
                                    name="masterName"
                                >
                                    <Input placeholder='Master Name' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Labour Count</span>

                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter labours!`,
                                        },
                                    ]}
                                    name="labourCount"
                                >
                                    <Input placeholder='No. of Labours' type='number' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Electricity Used</span>

                                <Form.Item
                                    rules={[
                                        {
                                            required: false,
                                            message: `Please Enter electricity`,
                                        },
                                    ]}
                                    name="electricityUsed"
                                // label="Start Oil Usage (cm)"
                                >
                                    <Input placeholder='Electricity Used' type='number' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.basicsForm}>

                        <Row gutter={[26, 0]}>
                            <Col xs={24} md={14} lg={24}>
                            <span>strf oil</span>

                                <Form.Item
                                    name="strfoil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='strf oil' type='number' step={1} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={14} lg={24}>
                            <span>etrf oil</span>

                                <Form.Item
                                    name="etrfoil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='etrf oil' type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>ssklr oil</span>

                                <Form.Item
                                    name="ssklroil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='ssklr oil' type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>esklr oil</span>

                                <Form.Item
                                    name="esklroil"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='esklr oil' type='number' step={1} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Casting Start Time</span>

                                <Form.Item
                                    name="castingTimeStart"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Casting Start Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Casting End Time</span>

                                <Form.Item
                                    name="castingTimeEnd"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Casting End Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Furnace Start Time</span>

                                <Form.Item
                                    name="furnaceTimeStart"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Furnance Start Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14} lg={24}>
                            <span>Furnace End Time</span>

                                <Form.Item
                                    name="furnaceTimeEnd"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a time!',
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='Select Furnance End Time' style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div >


                <Form.Item>
                    <Button type="primary" htmlType="submit"
                        // loading={isLoading} 
                        style={{ margin: '10px' }}>
                        Save & Next
                    </Button>
                </Form.Item>
            </Form>}
        </div>
    )
}

export default HeatGeneration

// < div className = { styles.basicDetailFormMain } >
//                 <div className={styles.basicsForm}>
//                     <Row gutter={[26, 7]}>

//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item name="furnaceName" rules={[
//                                 {
//                                     required: true,
//                                     message: `Please Select Furnance Name!`,
//                                 },
//                             ]}>

//                                 <Select
//                                     showSearch
//                                     style={{
//                                         width: "100%",
//                                     }}
//                                     placeholder="Select Furnance Name"
//                                     optionFilterProp="children"
//                                     filterOption={(input, option) =>
//                                         (option?.label ?? "").includes(input)
//                                     }
//                                     filterSort={(optionA, optionB) =>
//                                         (optionA?.label ?? "")
//                                             .toLowerCase()
//                                             .localeCompare(
//                                                 (optionB?.label ?? "").toLowerCase()
//                                             )
//                                     }
//                                     onChange={handleChange}
//                                     options={
//                                         furnance &&
//                                         furnance?.map(
//                                             (obj) => {
//                                                 const keys = Object.keys(obj);
//                                                 const convertedObj = {
//                                                     value: obj["furnaceName"],
//                                                     label: obj["furnaceName"],
//                                                 };

//                                                 for (let i = 2; i < keys.length; i++) {
//                                                     convertedObj[keys[i]] = obj[keys[i]];
//                                                 }

//                                                 return convertedObj;
//                                             }
//                                         )
//                                     }
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Input
//                                 placeholder='Enter Heat Number'
//                                 value={getHeatNumber}
//                                 style={{ marginBottom: '15px' }}
//                             />
//                         </Col>

//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: `Please Enter Oil!`,
//                                     },
//                                 ]}
//                                 name="oilUsage"
//                             // label="Start Oil Usage (cm)"
//                             >
//                                 <Input placeholder="Start Oil Usage (cm)" type='number' step={1}/>
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: `Please Enter Name`,
//                                     },
//                                 ]}
//                                 name="masterName"
//                             >
//                                 <Input placeholder='Master Name' />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: `Please Enter labours!`,
//                                     },
//                                 ]}
//                                 name="labourCount"
//                             >
//                                 <Input placeholder='No. of Labours' type='number' />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: `Please Enter electricity`,
//                                     },
//                                 ]}
//                                 name="electricityUsed"
//                             // label="Start Oil Usage (cm)"
//                             >
//                                 <Input placeholder='Electricity Used' type='number' />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 rules={[
//                                     {
//                                         required: true,
//                                         message: `Please Heat Date!`,
//                                     },
//                                 ]}
//                                 name="heatDate"
//                             >
//                                 <DatePicker
//                                     placeholder='Heat Date'
//                                     onChange={onChange}
//                                     // showTime
//                                     format="YYYY-MM-DD"
//                                     style={{ width: "100%" }}
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 name="heatTime"
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: 'Please select a time!',
//                                     },
//                                 ]}
//                             >
//                                 <TimePicker placeholder='Select Heat Time' style={{ width: "100%" }} />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 name="strfoil"
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: 'Please select a time!',
//                                     },
//                                 ]}
//                             >
//                                 <Input placeholder='Enter strf oil' type='number' step={1} />
//                             </Form.Item>
//                         </Col>
//                     </Row>
//                 </div>
//                 <div className={styles.basicsForm}>

//                     <Row gutter={[26, 7]}>

//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 name="etrfoil"
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: 'Please select a time!',
//                                     },
//                                 ]}
//                             >
//                                 <Input placeholder='Enter etrf oil' type='number' step={1} />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 name="ssklroil"
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: 'Please select a time!',
//                                     },
//                                 ]}
//                             >
//                                 <Input placeholder='Enter ssklr oil' type='number' step={1} />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 name="esklroil"
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: 'Please select a time!',
//                                     },
//                                 ]}
//                             >
//                                 <Input placeholder='Enter esklr oil' type='number' step={1}/>
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 name="castingTimeStart"
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: 'Please select a time!',
//                                     },
//                                 ]}
//                             >
//                                 <TimePicker placeholder='Select Casting Start Time' style={{ width: "100%" }} />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={14} lg={24}>
//                             <Form.Item
//                                 name="castingTimeEnd"
//                                 rules={[
//                                     {
//                                         required: false,
//                                         message: 'Please select a time!',
//                                     },
//                                 ]}
//                             >
//                                 <TimePicker placeholder='Select Casting End Time' style={{ width: "100%" }} />
//                             </Form.Item>
//                         </Col>
//                     </Row>
//                 </div>
//             </div >



/**
 * 
 * <div className={styles.basicsForm}>
                        <Row gutter={[26, 7]}>
                            <Col xs={24} md={14} lg={24}>
                                <Input
                                    disabled
                                    value={heatdata?.furnaceName}
                                    style={{ marginBottom: '15px' }} />
                                {/* <Form.Item name="furnaceName" rules={[
                                    {
                                        required: true,
                                        message: `Please Select Furnance Name!`,
                                    },
                                ]}>
                                    
                                </Form.Item> 
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Input
                                        placeholder='Enter Heat Number'
                                        value={getHeatNumber}
                                        disabled
                                        style={{ marginBottom: '15px' }}
                                    />
                                </Col>
    
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter Oil!`,
                                            },
                                        ]}
                                        name="oilUsage"
                                    // label="Start Oil Usage (cm)"
                                    >
    
                                        <Input placeholder="Start Oil Usage (cm)" type="number" step={1} />
                                    </Form.Item>
                                </Col>
    
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter Name`,
                                            },
                                        ]}
                                        name="masterName"
                                    >
                                        <Input placeholder='Master Name' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter labours!`,
                                            },
                                        ]}
                                        name="labourCount"
                                    >
                                        <Input placeholder='No. of Labours' type='number' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter electricity`,
                                            },
                                        ]}
                                        name="electricityUsed"
                                    // label="Start Oil Usage (cm)"
                                    >
                                        <Input placeholder='Electricity Used' type='number' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: `Please Heat Date!`,
                                            },
                                        ]}
                                        name="heatDate"
                                    >
                                        <DatePicker
                                            placeholder='Heat Date'
                                            onChange={onChange}
                                            // showTime
                                            format="YYYY-MM-DD"
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        name="heatTime"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <TimePicker placeholder='Select Heat Time' style={{ width: "100%" }} format="HH:mm:ss" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        name="strfoil"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter strf oil' type='number' step={1} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.basicsForm}>
    
                            <Row gutter={[26, 7]}>
    
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        name="etrfoil"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter etrf oil' type='number' step={1} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        name="ssklroil"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter ssklr oil' type='number' step={1} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        name="esklroil"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter esklr oil' type='number' step={1} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        name="castingTimeStart"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <TimePicker placeholder='Select Casting Start Time' style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={24}>
                                    <Form.Item
                                        name="castingTimeEnd"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select a time!',
                                            },
                                        ]}
                                    >
                                        <TimePicker placeholder='Select Casting End Time' style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
 */