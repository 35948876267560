import React, { useEffect, useState } from 'react'
import { ExclamationCircleFilled, PlusCircleTwoTone, SearchOutlined } from "@ant-design/icons";
import Input from "antd/es/input/Input";
import { formatDate } from '../../../utils/helperFunctions';
import styles from "./index.module.css";
import { Button, Pagination, Select, Table, message, Spin, } from "antd";
import { useNavigate } from "react-router-dom";
import { Slider, Switch } from "antd";
import EditIcon from "../../../Assets/ICONS/edit.png";
import DeleteIcon from "../../../Assets/Delete.png";
import { api_call_token, base_url } from '../../../utils/Network';
import { objectToSearchString } from 'serialize-query-params';
import Modals from '../../../Components/Modals/Modals';

const ExpandedIngotTable = ({ ingotList }) => {
    const columns = [
        { title: "", dataIndex: "heatingotIds", key: "heatingotId", width: 0 },
        {
            title: "Ingot Number",
            dataIndex: "ingotName",
            key: "ingotName",
            width: 200,
        },
        {
            title: "Ingot Quantity",
            dataIndex: "ingotPiece",
            key: "ingotPiece",
            width: 200,
        },
        { title: "Sold Qty", dataIndex: "ingotPieceSold", key: "ingotPieceSold" },
        {
            title: "Remaining Qty",
            dataIndex: "ingotPieceRemainig",
            key: "ingotPieceRemainig",
        },

        { title: "Ingot Weight", dataIndex: "ingotWeight", key: "ingotWeight" },
        { title: "Sold Wt", dataIndex: "ingotWeightSold", key: "ingotWeightSold" },
        {
            title: "Remaining Wt",
            dataIndex: "ingotWeightRemainig",
            key: "ingotWeightRemainig",
        },
        { title: "Status", dataIndex: "statusName", key: "statusName" },
    ];
    const scrollConfig = {
        y: "auto", // Set your desired max height for vertical scrolling
        x: "auto", // Set if you want horizontal scrolling
    };

    return <Table columns={columns} dataSource={ingotList} pagination={false} />;
};

function BatchListing(props) {
    let navigate = useNavigate();
    const [dataList, setDataList] = useState([]);
    const [filterObj, setFilterObj] = useState({
        page: 1,
        sortCompany: props?.id,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberofPage, setTotalNumberofPage] = useState(null);
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [furnanceName, setFurnanceName] = useState([]);
    const [Instock, setInstock] = useState([]);
    const [rangeData, setRangeData] = useState([]);
    const [rangedValue, setRangedValue] = useState([]);
    const [renageValErr, setRenageValErr] = useState();
    const [paginationData, setPaginationData] = useState({});
    const [open, setOpen] = useState(false);  // modal opne
    const [valueforDelete, setValueforDelete] = useState()
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        // getHeatList();
        getHeat();
        getGrade();
        getFurnance();
        getStatus();
    }, [filterObj, currentPage, props?.id]);

    const getGrade = () => {
        api_call_token
            .get(`/grade`)
            .then((res) => {
                console.log(res);
                const data = res.data.data;

                let arr = [];
                let last = data[0]?.gradeName;
                let first = data[data.length - 1]?.gradeName;

                arr.push(first);
                arr.push(last);
                console.log(arr, last, first, "dfasdads");
                setRangeData(arr);
            })
            .catch((err) => console.log(err));
    };
    const getFurnance = () => {
        api_call_token
            .get(`/furnace`)
            .then((res) => {
                setFurnanceName(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    const getRowClassName = (record) => {
        switch (record?.heatStatusName) {
            case "In Stock":
                return `${styles.green}`;
            case "Out of Stock":
                return `${styles.red}`;
            case "Partially Sold":
                return `${styles.yellow}`;

            default:
                return '';
        }
    }

    const getHeat = (paramObj = { ...filterObj }) => {
        setIsLoading(true);
        const obj = objectToSearchString(paramObj);
        api_call_token
            .get(`heatlisting/allHeatwithelementlist?${obj}`)
            .then((res) => {
                console.log(res.data, "RRRRRRRR");
                let inputArray = res.data.data;

                // const transformedArray =
                //     inputArray &&
                //     inputArray.map((item) => {
                //         const result = [];
                //         let currentOrder = "A";

                //         item?.ingotList?.forEach((items, index) => {
                //             const heatNumber = items.heatNumber;
                //             items.heatNumber = heatNumber + currentOrder;
                //             result.push(items);
                //             currentOrder = String.fromCharCode(
                //                 currentOrder.charCodeAt(0) + 1
                //             );
                //         });

                //         const summarizedElement = {};
                //         item.elementList.forEach((element) => {
                //             summarizedElement[element.elementName] = element.heatValueSecond;
                //         });

                //         return {
                //             heatDate: item?.heatDate,
                //             heatId: item?.heatId,
                //             ingotList: result,
                //             heatNumber: item?.heatNumber,
                //             totalIngotPiece: item?.totalIngotPiece,
                //             totalIngotWeight: item?.totalIngotWeight,
                //             costTotal: item?.costTotal,
                //             heatRecovery: item?.heatRecovery,
                //             ruff: item?.ruff,
                //             operatorName: item?.operatorName,
                //             oilUsage: item?.oilUsage,
                //             status: item?.heatStatusName,
                //             ...summarizedElement,
                //         };
                //     });
                if (inputArray) {
                    // setPaginationData(res?.data);
                    setDataList(inputArray);
                    setIsLoading(false)
                    setCurrentPage(res.data.currentPage)
                    setTotalNumberofPage(res.data.totalRowCount)
                } else {
                    setIsLoading(false);
                }

            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err)
            });
    };

    const onSearch = (e) => {
        let value = e.target.value;
    let obj = filterObj;
    if (value) {
      obj.search = value;
      obj.page = 1;
      setCurrentPage(1);
    } else delete obj.search;
    setFilterObj({ ...obj });
    };

    const handleData = (val) => {
        navigate(`/heat/${val}`);
    };

    const navigateToCreateForm = () => {
        navigate(`/heat/create/${props.id}`);
    };

    const redirectTo = (id) => {
        api_call_token
            .get(`heat/${id}`)
            .then((res) => {
                //   setHeatData(res.data[0]);
                navigate(`/heat/create/${props.id}`, {
                    state: { heatID: id, data: res.data[0] },
                });
            })
            .catch((err) => console.log(err));
    };

    const columns = [
        // {
        //   title: "Batch ID",
        //   dataIndex: "heatId",
        //   render: (_, { heatId }) => {
        //     console.log(_);
        //     return (
        //       <div
        //         style={{ cursor: "pointer", width: "100px" }}
        //         onClick={() => handleData(heatId)}
        //       >
        //         {_}
        //       </div>
        //     );
        //   },
        // },
        // {
        //     title: "",
        //     dataIndex: "heatStatusName",
        //     render: (text) => {
        //         let backgroundColor = "";
        //         let borderRadius = "20px";
        //         let padding = "7px";
        //         let color = "white";
        //         let textAlign = "center";

        //         if (text === "In Stock") {
        //             color = "#087A17";
        //             borderRadius = "20.727px";
        //             // backgroundColor = "#DAEBDB";
        //             backgroundColor = "#087A17";
        //         } else if (text === "Partially Sold") {
        //             // backgroundColor = "#FCEECC";
        //             backgroundColor = "#F3AF0D";
        //             borderRadius = "20.727px";
        //             color = "#F3AF0D";
        //         } else if (text === "Out of Stock") {
        //             // backgroundColor = "#FCE6E8";
        //             backgroundColor = "#DE2326";
        //             borderRadius = "20.727px";
        //             color = "#DE2326";
        //         }

        //         const style = {
        //             backgroundColor,
        //             borderRadius,
        //             padding,
        //             color,
        //             width: "3px",
        //             textAlign,
        //         };
        //         console.log(text, "fjf8997");
        //         return <div style={style}></div>;
        //     },
        // },
        {
            title: "Batch Number",
            dataIndex: "heatNumber",
            render: (_, record) => {
                const { heatId } = record;
                console.log(_, record, "newuser");
                return (
                    <div
                        style={{ cursor: "pointer", width: "100%" }}
                        onClick={() => handleData(heatId)}
                    >
                        {/* {_ ? _ : " "} */}
                        {
                            _ ? <>
                                <div>{_}</div>
                                <div style={{ whiteSpace: "nowrap" }}>{formatDate(record?.heatDate)}</div>
                            </> : ""
                        }

                    </div>
                );
            },
        },
        // {
        //     title: "Batch Date",
        //     dataIndex: "heatDate",
        //     render: (abc) => {
        //         return (
        //             <div style={{ cursor: "pointer", width: "80px" }}>
        //                 {formatDate(abc)}
        //             </div>
        //         );
        //     },
        // },
        {
            title: "Total Ingot Number",
            dataIndex: "totalIngotPiece",
            render: (_, { totalIngotPiece }) => {
                return (
                    <div style={{ cursor: "pointer", width: "80px" }}>{_ ? _ : " "}</div>
                );
            },
        },
        {
            title: "Total Ingot Weight",
            dataIndex: "totalIngotWeight",
            render: (_) => {
                return (
                    <div style={{ cursor: "pointer", width: "80px" }}>
                        {_ ? _.toFixed(2) : " "}
                    </div>
                );
            },
        },
        {
            title: "Total Cost",
            dataIndex: "costTotal",
            render: (_) => {
                return <div style={{ cursor: "pointer", width: "50px" }}>{_}</div>;
            },
        },
        {
            title: "Heat Recovery",
            dataIndex: "heatRecovery",
            render: (abc) => {
                return <div style={{ cursor: "pointer", width: "70px" }}>{abc}</div>;
            },
        },
        {
            title: "Ruff",
            dataIndex: "ruff",
            render: (abc) => {
                return <div style={{ cursor: "pointer", width: "70px" }}>{abc}</div>;
            },
        },
        {
            title: "Operator Name",
            dataIndex: "masterName",
            render: (abc) => {
                return <div style={{ cursor: "pointer", width: "70px" }}>{abc}</div>;
            },
        },
        {
            title: "Oil Usage",
            dataIndex: "oilUsage",
            render: (abc) => {
                return <div style={{ cursor: "pointer", width: "50px" }}>{abc}</div>;
            },
        },

        {
            title: "Al",
            dataIndex: "al",
            render: (abc) => {
                console.log(abc, "mjkahsd90980");
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Fe",
            dataIndex: "fe",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Cu",
            dataIndex: "cu",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Mn",
            dataIndex: "mn",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Mg",
            dataIndex: "mg",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Zn",
            dataIndex: "zn",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Pb",
            dataIndex: "pb",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Si",
            dataIndex: "si",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Bg",
            dataIndex: "bg",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Cr",
            dataIndex: "cr",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Ni",
            dataIndex: "ni",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Ti",
            dataIndex: "ti",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Sn",
            dataIndex: "sn",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Ag",
            dataIndex: "ag",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "B",
            dataIndex: "b",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Be",
            dataIndex: "be",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Bi",
            dataIndex: "bi",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Ca",
            dataIndex: "ca",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Cd",
            dataIndex: "cd",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Co",
            dataIndex: "co",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Ga",
            dataIndex: "ga",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Hg",
            dataIndex: "hg",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "In",
            dataIndex: "indium",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "La",
            dataIndex: "la",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Li",
            dataIndex: "li",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Na",
            dataIndex: "na",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Sb",
            dataIndex: "sb",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "V",
            dataIndex: "v",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Zr",
            dataIndex: "zr",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "Sc",
            dataIndex: "sc",
            render: (abc) => {
                return (
                    <div style={{ cursor: "pointer", width: "50px" }}>
                        {abc?.toFixed(2)}
                    </div>
                );
            },
        },

        {
            title: "Action",
            dataIndex: "",
            width: 100, // Set the width for this column
            fixed: "right",
            render: (_, record) => {
                const { heatId } = record;
                const handleViewClick = () => {
                    redirectTo(heatId);
                };
                return (
                    <div style={{ display: "flex", gap: "5px" }}>
                        <div
                            onClick={handleViewClick}
                            style={{ width: "max-content", cursor: "pointer", width: "25px" }}
                        >
                            <img src={EditIcon} alt="consignment" />
                        </div>
                        <div onClick={() => { setOpen(true); setValueforDelete(heatId) }}
                            style={{ width: "max-content", cursor: "pointer", width: "25px" }}
                        >
                            <img src={DeleteIcon} alt='delete' />
                        </div>
                    </div>
                );
            },
        },
    ];

    const handelDeleteBatch = (id) => {
        console.log(id);
        api_call_token
            .delete(`heat/${id}`)
            .then((res) => {
                if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
                    getHeat()
                    setOpen(false)
                    success();
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Record Deleted Successfully',
            className: 'custom-class',
            style: {
                marginTop: '20vh',
            },
        });
    };

    const handlePageChange = (page) => {
        console.log(page, "aaaaaaaaa");
        setFilterObj({ ...filterObj, page: page });
        setCurrentPage(page);
    };

    const handleExpand = (record) => {
        const newExpandedRowKey = expandedRowKey === record.key ? null : record.key;
        setExpandedRowKey(newExpandedRowKey);
    };

    const expandedRowRender = (record) => {
        return expandedRowKey === record.key ? (
            <div
                className={styles.expandContainer}
                style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    position: "relative !important",
                }}
            >
                <ExpandedIngotTable ingotList={record.ingotList} />
            </div>
        ) : null;
    };

    const paginationConfig = {
        current: currentPage,
        pageSize: 10, // Number of items per page
        total: totalNumberofPage, // Total number of items
        onChange: handlePageChange,
    };

    const downloadPdf = () => {
        api_call_token
            .get(`pdf/getHeatListExcelNew`)
            .then((res) => {
                if (res.status == 200 || res.status == 201) {
                    let datas = res.data.FilePath;
                    let urlParts = datas.split("/");
                    let publicIndex = urlParts.indexOf("heatxls");
                    var result = urlParts.slice(publicIndex).join("/");
                    window.open(`${base_url}${result}`);
                }
                // window.open(`${base_url}${pdfLink}`)
            })
            .catch((err) => console.log(err));
    };

    const scrollConfig = {
        y: "auto", // Set your desired max height for vertical scrolling
        x: "auto", // Set if you want horizontal scrolling
    };


    const handleFurnance = (event) => {
        console.log(event, "DDDDDsss");
        let value = event;
        let obj = filterObj;
        if (value) {
            obj.furnace = value;
            obj.page = 1;
            // setCurrentPage(1)
        } else delete obj.furnace;
        setFilterObj({ ...obj });
    };

    const handleBranch = (event) => {
        console.log(event, "DDDDDsss");
        // let value =  event.map((value) => value).join(',');
        let value = event;
        console.log(value, 'neu9868769');
        let obj = filterObj;
        if (value) {
            obj.status = value;
            obj.page = 1;
            // setCurrentPage(1)
        } else delete obj.status;
        setFilterObj({ ...obj });
    };

    const handleDATA = (e) => {
        console.log(e, "dfdf");
        let first = e[0];
        let last = e[1];
        let obj = filterObj;
        if (first && last) {
            obj.gradeStart = first;
            obj.gradeEnd = last;
            obj.page = 1;
        } else {
            delete obj.gradeStart;
            delete obj.gradeEnd;
        }
        setFilterObj({ ...obj });
        setRangedValue(e);
    };

    const handleMindata = (e) => {
        console.log(e.target.value, "98379837");
        let value = e.target.value;
        let obj = filterObj;
        if (value) obj.gradeStart = value;
        else delete obj.gradeStart;
        setFilterObj({ ...obj });
    }

    const handleMaxdata = (e) => {
        console.log(e, "98379837");
        setRenageValErr("")
        let value = e.target.value;
        let obj = filterObj;
        let compare = +obj.gradeStart > +value;
        console.log(compare, "handleMaxdata");
        if (compare) {
            // You can throw an error here or handle it in any other way you prefer
            setRenageValErr("Maximum number should be greater than or equal to minimum number");
            return; // Exit the function early
        }

        if (value) obj.gradeEnd = value;
        else delete obj.gradeEnd;
        setFilterObj({ ...obj });
    }

    const clearFilter = () => {
        let obj = filterObj;
        if (obj.gradeStart && obj.gradeEnd) {
            delete obj.gradeStart;
            delete obj.gradeEnd;
        }
        setFilterObj({ ...obj });
        let arr = [93, 98.5];
        setRangeData(arr)
        setRangedValue([0, 0])
    }

    const getStatus = () => {
        api_call_token
            .get(`/zipcode/status?statusName=Heat Stock`)
            .then((res) => {
                setInstock(res.data.Data);
            })
            .catch((err) => console.log(err));
    };
    return (
        <div>
            <div className={styles.main}>
                <div className={styles.searchData}>
                    <Input
                        size="medium"
                        placeholder="Search By Batch Number, Container Number, Product`"
                        prefix={<SearchOutlined />}
                        onChange={(e) => onSearch(e)}
                        style={{ width: "60%", height: "100%" }}
                    />
                    <div style={{ marginRight: "10px" }} >
                        {
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                    margin: "0 10px",
                                }}
                                placeholder="Filter By Furnance Name"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare((optionB?.label ?? "").toLowerCase())
                                }
                                allowClear
                                // bordered={false}
                                onChange={handleFurnance}
                                options={
                                    furnanceName &&
                                    furnanceName?.map((obj) => {
                                        const keys = Object.keys(obj);
                                        const convertedObj = {
                                            value: obj["furnaceName"],
                                            label: obj["furnaceName"],
                                        };

                                        for (let i = 2; i < keys.length; i++) {
                                            convertedObj[keys[i]] = obj[keys[i]];
                                        }

                                        return convertedObj;
                                    })
                                }
                            />
                        }
                        {/* {Instock.map((v, i) => (
            <div>{v?.statusName}</div>
          ))} */}
                    </div>
                    <div style={{ marginRight: "10px" }} >
                        {
                            <Select
                                showSearch
                                mode='multiple'
                                style={{
                                    width: "100%",
                                    margin: "0 10px",
                                    minWidth: "250px",
                                }}
                                placeholder="Filter By Status"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare((optionB?.label ?? "").toLowerCase())
                                }
                                allowClear
                                selectorBg="#000000"
                                // bordered={false}
                                onChange={handleBranch}
                                options={
                                    Instock &&
                                    Instock?.map((obj) => {
                                        const keys = Object.keys(obj);
                                        const convertedObj = {
                                            value: obj["statusID"],
                                            label: obj["statusName"],
                                        };

                                        for (let i = 2; i < keys.length; i++) {
                                            convertedObj[keys[i]] = obj[keys[i]];
                                        }

                                        return convertedObj;
                                    })
                                }
                            />
                        }
                        {/* {Instock.map((v, i) => (
            <div>{v?.statusName}</div>
          ))} */}
                    </div>
                    <Button
                        type="primary"
                        onClick={navigateToCreateForm}
                        style={{ margin: "0 10px" }}
                    >
                        {" "}
                        <PlusCircleTwoTone style={{ marginRight: "10px" }} /> Create New
                        Batch
                    </Button>
                    <Button
                        type="primary"
                        onClick={downloadPdf}
                        style={{ margin: "0 10px" }}
                    >
                        {" "}
                        Download xls
                    </Button>
                </div>
                <div className={styles.secondRow}>
                    <div className={styles.filterCss}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p>Filter By Range</p>
                            {/* <p className={styles.clearBtn} onClick={clearFilter}>
                            Clear
                        </p> */}
                        </div>
                        {/* <Slider
                        min={rangeData[0]}
                        max={rangeData[1]}
                        range={true}
                        value={rangedValue}
                        defaultValue={rangeData}
                        onChange={handleDATA}
                    /> */}
                        <div className={styles.rangeFields}>
                            <div>
                                <Input
                                    size="medium"
                                    type='number'
                                    placeholder="Minmum Number"
                                    // prefix={<SearchOutlined />}
                                    // onChange={(e) => onSearch(e)}
                                    onChange={(e) => handleMindata(e)}
                                    style={{ width: "200px" }}
                                />
                            </div>
                            <div>
                                <Input
                                    size="medium"
                                    type='number'
                                    placeholder="Maximum Number"
                                    // prefix={<SearchOutlined />}
                                    // onChange={(e) => onSearch(e)}
                                    onChange={(e) => handleMaxdata(e)}
                                    style={{ width: "200px" }}
                                />
                                {renageValErr ? <div style={{ fontSize: "12px", color: "red" }}>{renageValErr}</div> : ""}
                            </div>
                        </div>
                    </div>

                    {/* <div className={styles.indicatorCss}>
                        <div>
                            <p>Status Indicator</p>
                            <div>
                                <div className={styles.indicatortext} style={{ display: "flex", gap: '20px', alignItems: 'center' }}>
                                    <div className={styles.indicatorStyles} style={{ backgroundColor: "#087A17" }}></div>
                                    <p>In Stock</p>
                                </div>
                                <div className={styles.indicatortext}>
                                    <div className={styles.indicatorStyles} style={{ backgroundColor: "#F3AF0D" }}></div>
                                    <p>Partially Sold</p>
                                </div>
                                <div className={styles.indicatortext}>
                                    <div className={styles.indicatorStyles} style={{ backgroundColor: "#DE2326" }}></div>
                                    <p>Out of Stock</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p style={{ fontSize: "16px", fontWeight: "600" }}>Total Heat Count : {paginationData?.totalRowCount}</p>
                        </div>
                    </div> */}




                </div>

                {isLoading ? (
                    <div style={{ margin: "50px auto", textAlign: "center" }}>
                        <Spin size="large" />
                    </div>
                ) : (

                    <div className={styles.tableContent}>
                        <Table
                            rowClassName={getRowClassName}
                            columns={columns}
                            scroll={scrollConfig}
                            pagination={false} // Adjust as needed
                            expandable={{
                                expandedRowRender,
                                expandedRowKeys: [expandedRowKey],
                                onExpand: (_, record) => handleExpand(record),
                                expandIcon: ({ expanded, onExpand, record }) => (
                                    <span onClick={(e) => onExpand(record, e)}>
                                        {expanded ? (
                                            <span
                                                style={{
                                                    fontSize: "20px",
                                                    fontWeight: "550",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                -
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    fontSize: "20px",
                                                    fontWeight: "550",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                +
                                            </span>
                                        )}
                                    </span>
                                ),
                            }}
                            dataSource={dataList.map((item, index) => ({
                                ...item,
                                key: index,
                            }))}
                        // pagination={{
                        //     pageSize: 10, // Number of items per page
                        //     total: paginationData?.totalRowCount, // Total number of items
                        //     onChange: (page, pageSize) => {
                        //         console.log(page, 'pagenomfd89u');
                        //         setFilterObj({ ...filterObj, page: page });
                        //         // Handle page change here, e.g., fetch data for the new page
                        //     },
                        // }}
                        />
                        <div style={{ textAlign: 'right', margin: '10px' }}>
                            <Pagination
                                {...paginationConfig}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                )}
            </div>
            <Modals
                open={open}
                handleCancel={() => {
                    setOpen(false);
                    // handleform();
                    // setView(null);
                }}
            // handleOk={handleOk}

            >

                {/* <div style={{textAlign:"center",marginBottom:"10px" , fontSize:"28px"}}>
                    <ExclamationCircleFilled /> 
                    </div> */}

                <div style={{ textAlign: "center", marginBottom: "20px", fontSize: "22px" }}>Are you Sure You want to delete This</div>

                <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                    <Button onClick={() => { setOpen(false) }} >Cancel</Button>
                    <Button danger onClick={() => { handelDeleteBatch(valueforDelete) }}>Delete</Button>
                </div>

            </Modals>
            <>
                {contextHolder}
            </>
        </div>
    )
}

export default BatchListing