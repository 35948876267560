import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Modal
} from "antd";
import NumericInput from "../../../Components/NumericInput/NumericInput";
import moment from "moment";
import { api_call, api_call_token } from "../../../utils/Network";
import { MainContext } from "../../../utils/context";
import 'moment/locale/en-au';
import { alertMessage } from "../../../utils/helperFunctions";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { UserCredsContext } from "../../../utils/UserCredsContext";
import Causion from "../../../Assets/Popup/caution.png";
import styles from "../index.module.css"

const { confirm } = Modal;
function ConsignForm() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { base_items } = useContext(UserCredsContext);
  const [value, setValue] = useState("");
  const [fieldValues, setFieldValue] = useState([]);
  const [confirmShown, setConfirmShown] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    getField();
  }, [])

  const getField = () => {
    api_call_token
      .get(`product/field?search=consignment&formtype=create`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setFieldValue(res.data.data);
        } else {
          showConfirm();
        }
      })
      .catch(err => console.log(err))
  }


  const showConfirm = () => {
    if (!confirmShown) {
    confirm({
      title: '',
      icon: null,
      content: (
        <div style={{ textAlign: 'center' }}>
          <img src={Causion} alt="Custom Icon" style={{ width: '60px' }} />
          <p style={{fontWeight: 'bold'}}>You do not have access to view this. Kindly contact the admin to get access.</p>
        </div>
      ),
      // Remove onOk and onCancel
      cancelButtonProps: {
        style: {
          display: 'none', // Hide the cancel button
        },
      },
      okButtonProps: {
        // disabled: true,
      },
      okText: "Go Back",
      onOk() {
        navigate("/consignment");
      },

    });
    setConfirmShown(!confirmShown);
  }
  };

  const handleData = (values) => {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if (typeof value === 'object' && (value.$d instanceof Date || value._d instanceof Date)) {
          if(value.$d){
            values[key] = moment(value.$d).format('YYYY-MM-DD');
          }else{
          values[key] = moment(value._d).format('YYYY-MM-DD');
          }
        } else if (typeof value === 'string' && !isNaN(value)) {
          values[key] = Number(value);
        }
      }
    }
    console.log(values);
    // const {
    //   OutOfCharge,
    //   assessmentValue,
    //   billOfEntry,
    //   boePassingDate, bondNumber,
    //   cfsCharges,
    //   chaCharges,
    //   customCurrencyID,
    //   duty,
    //   eInvoiceDate,
    //   igmDate,
    //   igmNumber,
    //   lastDate,
    //   miscCharges,
    //   shippingCharges,
    //   totalQuantity,
    //   transportCharges,
    //   purchaseOrderNumber,
    //   containerNo
    // } = values;

    // let BoePassingDate = moment(boePassingDate?.$d).format("YYYY-MM-DD");
    // let EInvoiceDate = moment(eInvoiceDate?.$d).format("YYYY-MM-DD");
    // let IgmDate = moment(igmDate?.$d).format("YYYY-MM-DD");
    // let LastDate = moment(lastDate?.$d).format("YYYY-MM-DD");

    // let finalData = {
    //   outOfCharge: +OutOfCharge,
    //   assessmentValue: +assessmentValue,
    //   billOfEntry: +billOfEntry,
    //   boePassingDate: BoePassingDate,
    //   bondNumber: +bondNumber,
    //   cfsCharges: +cfsCharges,
    //   chaCharges: +chaCharges,
    //   customCurrencyID: +customCurrencyID,
    //   duty: +duty,
    //   eInvoiceDate: EInvoiceDate,
    //   igmDate: IgmDate,
    //   igmNumber: +igmNumber,
    //   lastDate: LastDate,
    //   miscCharges: +miscCharges,
    //   shippingCharges: +shippingCharges,
    //   totalQuantity: +totalQuantity,
    //   transportCharges: +transportCharges,
    //   purchaseOrderNumber: purchaseOrderNumber,
    //   containerNo: containerNo,
    // }
    if(state){
      api_call_token
      .patch(`/consignment/${state?.id}`, values)
      .then((res) => {
        if (res.status == 204 || res.status == 201 || res.status == 200) {
          alertMessage("Consignment Updated ")
          setTimeout(() => {
            navigate('/consignment')
          }, 2000);
        }
      })
    }else{
      api_call_token
      .post(`/consignment`, values)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          alertMessage("Consignment Created ")
          setTimeout(() => {
            navigate('/consignment')
          }, 2000);
        }
      })
    }
    // console.log(finalData, "finalDatafinalDatafinalData")
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  // console.log(state.igmDate, "statessss")
  return (
    <div style={{width: '100%'}}>
      <div><h2><ArrowLeftOutlined onClick={() => navigate(-1)} style={{marginRight: '10px'}}/>Create Consignment</h2></div>
      <div style={{ background: 'white', padding: '30px' }} className={styles.containerMain}>
        <Form
          form={form}
          onFinish={handleData}
          initialValues={{
            ...state?.data,
            igmDate: state?.data?.igmDate ? moment(state?.data?.igmDate.split('T')[0],"YYYY-MM-DD") : undefined,
            eInvoiceDate: state?.data?.eInvoiceDate ? moment(state?.data?.eInvoiceDate.split('T')[0],"YYYY-MM-DD") : undefined,
            billOfEntry: state?.data?.billOfEntry ? moment(state?.data?.billOfEntry.split('T')[0],"YYYY-MM-DD"): undefined,
            boePassingDate: state?.data?.boePassingDate ? moment(state?.data?.boePassingDate.split('T')[0],"YYYY-MM-DD") : undefined,
            lastDate: state?.data?.lastDate ? moment(state?.data?.lastDate.split('T')[0],"YYYY-MM-DD") : undefined,
            outOfCharge: state?.data?.outOfCharge ? moment(state?.data?.outOfCharge.split('T')[0],"YYYY-MM-DD") : undefined,
          }}
        >
          <Row gutter={[26, 7]}>
            {fieldValues.length > 0 && fieldValues.map(
              (val, i) =>
                val?.isForm && (
                  <>
                    {val?.fieldType == "int" ? (
                      <Col xs={24} md={14} lg={24}>
                        <span>
                          {val?.label} {val?.required && `*`}
                        </span>
                        <Form.Item
                          name={val?.apiName}
                          rules={[
                            {
                              required: val?.required,
                              message: `Please Enter ${val?.apiName}!`,
                            },
                          ]}
                        >
                          <NumericInput
                            style={{
                              width: "100%",
                            }}
                            value={value}
                            onChange={setValue}
                          />
                        </Form.Item>
                      </Col>
                    ) : val?.fieldType == "picklist_fk" ? (
                      <>
                        <Col xs={24} md={14} lg={24}>
                          <span>
                            {val?.label} {val?.required && `*`}
                          </span>
                          <Form.Item
                            name={val?.apiName}
                            rules={[
                              {
                                required: val?.required,
                                message: `Please Enter ${val?.apiName}!`,
                              },
                            ]}
                          >
                            { <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "").includes(input)
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              options={
                                base_items[val?.picklistData] &&
                                base_items[val?.picklistData]?.map(
                                  (obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj[keys[0]],
                                      label: obj[keys[1]],
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  }
                                )
                              }
                            />}
                          </Form.Item>
                        </Col>
                      </>
                    ) : val?.fieldType == "date" ? (
                      <Col xs={24} md={14} lg={24}>
                        <span>
                          {val?.label} {val?.required && `*`}
                        </span>
                        <Form.Item
                          name={val?.apiName}
                          rules={[
                            {
                              required: val?.required,
                              message: `Please Enter ${val?.apiName}!`,
                            },
                          ]}
                        >
                          <DatePicker
                            onChange={onChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col xs={24} md={14} lg={24}>
                        <span>
                          {val?.label} {val?.required && `*`}
                        </span>
                        <Form.Item
                          name={val?.apiName}
                          rules={[
                            {
                              required: val?.required,
                              message: `Please Enter ${val?.apiName}!`,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )
            )}
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {(state?.data) ? "Update" : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>

    </div>
  );
}

export default ConsignForm;
