import { Col, Form, Row, Select, Input, Button } from 'antd'
import React from 'react'

function ModalPops({ selected, setOpen = () => {}, setContactInfo = () => {},contactInfo, handleSelect }) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        contactInfo[handleSelect] = values;
        setContactInfo(contactInfo)
        setOpen(false);
    }
    console.log(selected)
    return (
        <div>
            <Form form={form} initialValues={selected} onFinish={onFinish}>
                <Row gutter={[24, 5]}>
                    <Col sm={24} lg={24}>
                        Contact Information
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Full Name</span>
                        <Form.Item
                            name="supplierContactInfoName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Email Id</span>
                        <Form.Item name="supplierContactInfoEmail">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Designation</span>
                        <Form.Item
                            name="supplierContactInfoDesignation"
                            rules={[
                                { required: true, message: "Please enter annual turnover" },
                            ]}
                        >
                            <Select
                                allowClear
                                options={[
                                    {
                                        value: 'Partner',
                                        label: 'Partner',
                                    }, {
                                        value: 'Owner',
                                        label: 'Owner',
                                    }, {
                                        value: 'Director',
                                        label: 'Director',
                                    }, {
                                        value: 'Sales Department',
                                        label: 'Sales Department',
                                    }, {
                                        value: 'Accounts Department & Finance Department',
                                        label: 'Accounts Department & Finance Department',
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Landline/Mobile</span>
                        <Form.Item name="supplierContactInfoLandlineNo">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>GIN</span>
                        <Form.Item name="supplierContactInfoGIN">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Mobile</span>
                        <Form.Item name="supplierContactInfoMobile">
                            <Input type="number" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Address</span>
                        <Form.Item name="supplierContactInfoAddress">
                            <Input />
                        </Form.Item>
                    </Col>

                </Row>
                <div style={{ textAlign: 'right' }}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>

                </div>
            </Form>
        </div>
    )
}

export default ModalPops