import React, { useEffect, useState } from 'react'
import styles from "./index.module.css"
import { Button, Input, Pagination, Table } from "antd";
import { PlusCircleTwoTone, SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import EditIcon from "../../Assets/ICONS/edit.png";
import { api_call_token } from '../../utils/Network';
// import Modals from "../../../Components/Modals/Modals"
import Modals from "../../Components/Modals/Modals"
import DownloadeFile from './DownloadeFile';

function ContainerInOutList() {
  let navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);  // modal opne
  const [selectedValue, setSelectedValue] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);

  const pageSize = 10; // Set your page size here
  const currentPages = currentPage || 1; // Current page from pagination object

  const onSearch = (event) => {
    let value = event.target.value;
    let obj = filterObj;
    if (value) {
      obj.search = value;
      obj.page = 1;
      setCurrentPage(1);
    } else delete obj.search;
    setFilterObj({ ...obj });
  };

  const handleData = (v) => {
    console.log(v, "handleDatav");
    navigate(`${v}`)
  }

  useEffect(() => {
    getDataValues();
  }, [filterObj]);

  const getDataValues = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`containerentry`, { params: { ...paramObj } })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setValues(res.data)
          setDataList(res.data.data)
          setCurrentPage(res.data.currentPage)
          setTotalNumberofPage(res.data.totalRowCount)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const columns = [
    {
      title: 'Sr No',
      dataIndex: 'containerEntryId',
      // render: (_, __, index) => (currentPages - 1) * pageSize + index + 1,
      // dataIndex: 'containerID',
      // sorter: {
      //     compare: (a, b) => a.containerID - b.containerID,
      //     multiple: 3,
      // },
      render: (_, { containerEntryId }, index) => {
        console.log(_, { containerEntryId }, index, "98387bfbhffhb")
        return <div style={{ cursor: "pointer" }} onClick={() => handleData(containerEntryId)}>{(currentPages - 1) * pageSize + index + 1}</div>
      }
    },
    {
      title: 'Container No',
      dataIndex: 'containerNo',
      sorter: {
        compare: (a, b) => a.containerNo - b.containerNo,
        multiple: 3,
      },
      render: (_, { containerEntryId }, index) => {
        console.log(_)
        return <div style={{ cursor: "pointer" }} onClick={() => handleData(containerEntryId)}>{_}</div>
      }
    },
    {
      title: 'Gross Weight',
      dataIndex: 'containerGrossWeight',
    },
    // {
    //   title: 'Port Of Loading',
    //   dataIndex: 'portOfLoadingName',
    // },
    // {
    //   title: 'Final Destination',
    //   dataIndex: 'portOfDepartureName',
    // },

    {
      title: 'In Date',
      dataIndex: 'containerInDate',
      render: (_, { containerInDate }) => {
        console.log(_, { containerInDate }, "containerInDate");
        const formattedDate = moment(containerInDate).format('DD-MMMM-YYYY');
        return (
          <div>
            {formattedDate}
            {/* {_} */}
          </div>
        );
      },

    },
    {
      title: 'In Time',
      dataIndex: 'containerInTime',
      render: (_, { containerInTime }) => {
        console.log(_, { containerInTime }, "containerInDate");
        const formattedDate = containerInTime ? moment(containerInTime, "HH:mm:ss").format('hh:mm a') : "N.A";

        return (
          <div>
            {formattedDate}
            {/* {_} */}
          </div>
        );
      },

    },
    {
      title: 'Out Date',
      dataIndex: 'containerOutDate',
      render: (_, { containerOutDate }) => {
        const formattedDate = moment(containerOutDate).format('DD-MMMM-YYYY');
        return (
          <div>
            {formattedDate}
            {/* {_} */}
          </div>
        );
      },

    },
    {
      title: 'Out Time',
      dataIndex: 'containerOutTime',
      render: (_, { containerOutTime }) => {
        console.log(_, { containerOutTime }, "containerInDate");
        const formattedDate = containerOutTime ? moment(containerOutTime, "HH:mm:ss").format('hh:mm a') : "N.A";
        return (
          <div>
            {formattedDate}
            {/* {_} */}
          </div>
        );
      },

    },
    {
      title: 'Action',
      dataIndex: '',
      render: (_, record) => {
        const { containerEntryId } = record;
        const handleViewClick = () => {
          // Handle view button click with the ID
          console.log('View clicked for ID:', containerEntryId);
          setOpen(true)
          setSelectedValue(containerEntryId);
          // redirectTo(containerEntryId);
        };
        return (
          <div>
            <div style={{ width: 'max-content', cursor: 'pointer', display: "flex", gap: "10px", alignItems: "center" }}>
              <img onClick={() => handleData(containerEntryId)} src={EditIcon} alt='consignment' />
              {/* <DownloadOutlined
                // onClick={() => download(record)}
                onClick={handleViewClick}
                style={{ fontSize: "30px" }}
              /> */}
            </div>
            {/* <button onClick={handleViewClick}>Edit</button> */}
          </div>
        );
      },
    },
  ];


  const redirectTo = (value) => {
    // api_call_token
    // .get(`container/${value}`)
    // .then((res) => {
    //     console.log(res.data);
    //     if(res.status == 200 || res.status ==201){
    //         navigate('createcontainer', { state: {data: res.data ,id: value}})
    //     }
    // })
    // .catch(err => console.log(err))
  }
  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa")
    setFilterObj({ ...filterObj, page: page })
    setCurrentPage(page);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };
  console.log(dataList, "dataList")


  const handelOpne = (val) => {
    setOpen(true)
    // setSelectedView(val)
  }

  const handelClose = () => {
    setOpen(false)
  }
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.rowContainer}>
          <h2>Container IN-OUT</h2>
          <div className={styles.searchData}>
        
        <Input
          size="medium"
          placeholder="Search By BOE, PO Number, Supplier Name, Port Name"
          prefix={<SearchOutlined />}
          onChange={(e) => onSearch(e)}
          style={{ width: "70%" }}
        />
          </div>
        </div>


        <Table
          columns={columns}
          dataSource={dataList}
          pagination={false} // Adjust as needed
          scroll={{ x: true }} // Enable horizontal scrolling if needed
        />

        <div style={{ textAlign: "right", margin: "10px" }}>
          <Pagination {...paginationConfig} showSizeChanger={false}/>
        </div>
      </div>

      <Modals
        open={open}
        handleCancel={() => {
          setOpen(false);
          // handleform();
          // setView(null);
        }}
      // handleOk={handleOk}
      >

        <DownloadeFile data={selectedValue} />
      </Modals>
    </>
  )
}

export default ContainerInOutList