import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useState, useContext, useEffect } from 'react'
import { api_call_token } from '../../../utils/Network';
import styles from './heatContainer.module.css';
import { UserCredsContext } from "../../../utils/UserCredsContext";
import { alertMessage } from '../../../utils/helperFunctions';
import { DeleteFilled } from '@ant-design/icons';


function HeatCharging({ setCurrentStep = () => { }, id, heatValues, consumableData, heatid }) {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const { base_items, set_Base } = useContext(UserCredsContext);
    const [consumableList, setConsumableList] = useState([]);
    const [contain, setContain] = useState([])

    useEffect(() => {
        getConsumables();
        getContainers(heatValues?.id)
    }, [])

    const getContainers = (vals) => {
        api_call_token
            .get(`AppHeatConsumableNew/${vals}`)
            .then((res) => {
                console.log(res.data, "DATAsssssssssssss")
                setContain(res.data);
            })
            .catch((err) => console.log(err))
    }

    const getConsumables = () => {
        api_call_token
            .get(`heatConsumableMaster?page=1`)
            .then((res) => {
                if (res.data.totalRowCount) {
                    getConsume(res.data.totalRowCount);
                }
            })
            .catch(err => console.log(err))
    }

    const getConsume = (v) => {
        api_call_token
            .get(`heatConsumableMaster?size=${v}`)
            .then((res) => {
                if (res.data.data) {
                    setConsumableList(res.data.data);
                }
            })
            .catch(err => console.log(err))
    }

    const handleSubmit = (values) => {
        console.log(values, "Values")
        const consumablesArray = [];

        const formattedData = consumableList.map((v) => ({
            heatConsumableName: v.heatConsumableName,
            heatConsumableValue: values[v?.heatConsumableName] || 0,
            heatConsumableRate: values[`${v?.heatConsumableName}Rate`] || 0,
            heatConsumableAmount: values[`${v?.heatConsumableName}Amount`],
        }));

        // Now 'formattedData' contains the data in the desired format
        console.log(formattedData, "Values");

        // for (const [key, value] of Object.entries(values)) {
        //     const heatConsumable = {
        //         heatConsumableName: key,
        //         heatConsumableValue: value ? parseFloat(value) : null
        //     };
        //     consumablesArray.push(heatConsumable);
        // }

        // console.log(consumablesArray, "Consumable")
        setIsLoading(true)

        // let data = {
        //     heatId: heatValues?.id,
        //     heatNumber: heatValues?.heatNumber,
        //     consumableList: consumablesArray,
        // }

        let data = {
            heatId: heatValues?.id,
            heatNumber: heatValues?.heatNumber,
            consumableList: formattedData,
        }

        // console.log(data, "DDDDDd")
        if (heatid) {
            if (consumableData?.length > 0 && consumableData[0]?.heatId) {
                api_call_token
                    .patch(`AppHeatConsumableNew/${heatid}`, data)
                    .then((res) => {
                        if (res.status == 200 || res.status == 201 || res.status == 204) {
                            setIsLoading(false)
                            setCurrentStep(prev => prev + 1);
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                api_call_token
                    .post(`AppHeatConsumableNew`, data)
                    .then((res) => {
                        if (res.status == 200 || res.status == 201) {
                            setIsLoading(false)
                            setCurrentStep(prev => prev + 1);
                        }
                    })
                    .catch(err => console.log(err))
            }

        } else {
            if (contain && contain?.length > 0) {
                api_call_token
                    .patch(`AppHeatConsumableNew/${heatValues?.id}`, data)
                    .then((res) => {
                        if (res.status == 200 || res.status == 201 || res.status == 204) {
                            setIsLoading(false)
                            setCurrentStep(prev => prev + 1);
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                api_call_token
                    .post(`AppHeatConsumableNew`, data)
                    .then((res) => {
                        if (res.status == 200 || res.status == 201) {
                            setIsLoading(false)
                            setCurrentStep(prev => prev + 1);
                        }
                    })
                    .catch(err => console.log(err))
            }


        }
    }

    const handleQuantityRateChange = (changedValues) => {
        const fieldName = Object.keys(changedValues)[0];
        const heatConsumableName = fieldName.replace(/(Rate)$/, '');
        // // Check if the changed field is either "Quantity" or "Rate"

        const heatConsumableValue = form.getFieldValue(`${heatConsumableName}`);
        const heatConsumableRate = form.getFieldValue(`${heatConsumableName}Rate`);

        if (heatConsumableValue !== undefined && heatConsumableRate !== undefined) {
            const heatConsumableAmount = heatConsumableValue * heatConsumableRate;
            form.setFieldsValue({ [`${heatConsumableName}Amount`]: heatConsumableAmount });
        }
    };



    const initialFormValues = {};
    if (consumableData.length > 0) {
        consumableData.length > 0 && consumableData.forEach(item => {
            initialFormValues[item.heatConsumableName] = item.heatConsumableValue;
            initialFormValues[`${item.heatConsumableName}Rate`] = item.heatConsumableRate;
            initialFormValues[`${item.heatConsumableName}Amount`] = item.heatConsumableAmount;
        });
    } else {
        consumableList.length > 0 && consumableList.forEach(item => {
            initialFormValues[`${item.heatConsumableName}Rate`] = item?.heatConsumableRate || 0;
        })
    }


    console.log(consumableData, initialFormValues, "soss")
    return (
        <div style={{ padding: '40px', width: "50%", margin: '10px' }} className={styles.mainHeatG}>
           {consumableList.length > 0 && <Form onFinish={handleSubmit}
                form={form}
                onValuesChange={handleQuantityRateChange}
                initialValues={initialFormValues}
            // initialValues={{ ...consumableData[0] }}
            >
                <div style={{ padding: '20px' }}>
                    <Row gutter={[16, 7]}>
                        {consumableList && consumableList.map((v, i) => (
                            <>

                                <Col xs={24} md={14} lg={8}>
                                    <span style={{ textTransform: 'capitalize' }}>
                                        {v?.heatConsumableName}
                                    </span>
                                    <Form.Item
                                        // label="Potash"
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter Heat Number!`,
                                            },
                                        ]}
                                        name={v?.heatConsumableName}
                                    >
                                        <Input placeholder='Quantity ' type='number' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={8}>
                                    <span style={{ textTransform: 'capitalize' }}>
                                        {v?.heatConsumableName} Rate
                                    </span>
                                    <Form.Item
                                        // label="Potash"
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter Heat Number!`,
                                            },
                                        ]}
                                        name={`${v?.heatConsumableName}Rate`}

                                    >
                                        <Input placeholder='Rate ' type='number' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14} lg={8}>
                                    <span style={{ textTransform: 'capitalize' }}>
                                        {v?.heatConsumableName} Total Amount
                                    </span>
                                    <Form.Item
                                        // label="Potash"
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter Heat Number!`,
                                            },
                                        ]}
                                        name={`${v?.heatConsumableName}Amount`}
                                    >
                                        <Input placeholder='Amount' type='number' />
                                    </Form.Item>
                                </Col>
                            </>
                        ))
                        }
                    </Row>
                </div>


                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Save & Next
                    </Button>
                </Form.Item>
            </Form>}
        </div>

    )
}

export default HeatCharging

// const submitFinalData = (vals) => {
//     form.resetFields();
//     console.log(vals, "VALS")
//     // const productExists = item.productList.some(product => product.productId === productname);


//     const productNames = consumableList.some(item => item?.heatConsumableName === vals?.heatConsumableName);

//     if (!productNames) {
//         setConsumableList([...consumableList, vals])
//     } else {
//         alertMessage("Product already added", 2)
//     }

// }

// const handleList = () => {

// }

// const onDelete = (v, i) => {
//     consumableList.splice(i, 1);
//     setConsumableList([...consumableList])
// }

// <div style={{ padding: '40px', width: "60%", margin: '60px auto' }} className={styles.mainHeatGen}>
//     <Form form={form} onFinish={submitFinalData}>
//         <table style={{ width: '100%', borderSpacing: '0' }}>
//             <tbody>
//                 <tr>
//                     <td style={{ width: '40%' }} className={`${styles.left} ${styles.top} ${styles.padding}`}>
//                         Heat Consumable
//                     </td>
//                     <td style={{ width: '40%' }} className={`${styles.left} ${styles.top} ${styles.padding}`}>
//                         Values
//                     </td>
//                     <td className={`${styles.left} ${styles.right} ${styles.top} ${styles.padding}`}>
//                         Action
//                     </td>
//                 </tr>
//                 <tr>
//                     <td className={`${styles.left}  ${styles.top} ${styles.bottom} ${styles.padding}`}>
//                         <Form.Item name="heatConsumableName">
//                             <Select
//                                 showSearch
//                                 style={{
//                                     width: "100%",
//                                 }}
//                                 placeholder="Search to Select"
//                                 optionFilterProp="children"
//                                 filterOption={(input, option) =>
//                                     (option?.label ?? "").includes(input)
//                                 }
//                                 filterSort={(optionA, optionB) =>
//                                     (optionA?.label ?? "")
//                                         .toLowerCase()
//                                         .localeCompare(
//                                             (optionB?.label ?? "").toLowerCase()
//                                         )
//                                 }
//                                 onChange={(e, val) => handleList(e, val)}
//                                 options={
//                                     base_items["heatConsumableMasterList"] &&
//                                     base_items["heatConsumableMasterList"]?.map(
//                                         (obj) => {
//                                             const keys = Object.keys(obj);
//                                             const convertedObj = {
//                                                 value: obj[keys[1]],
//                                                 label: obj[keys[1]],
//                                             };

//                                             for (let i = 2; i < keys.length; i++) {
//                                                 convertedObj[keys[i]] = obj[keys[i]];
//                                             }

//                                             return convertedObj;
//                                         }
//                                     )
//                                 }
//                             />
//                         </Form.Item>
//                     </td>
//                     <td className={`${styles.left} ${styles.top} ${styles.bottom} ${styles.padding}`}>
//                         <Form.Item name="heatConsumableValue">
//                             <Input />
//                         </Form.Item>
//                     </td>
//                     <td className={`${styles.left} ${styles.right} ${styles.bottom} ${styles.top} ${styles.padding}`}>
//                         <Button type='primary' htmlType='submit'>+</Button>
//                     </td>
//                 </tr>


//                 {consumableList?.length > 0 &&
//                     consumableList?.map((v, i) => (
//                         <tr key={i}>
//                             <td className={`${styles.left} ${styles.right} ${styles.bottom}  ${styles.widthTwenty} ${styles.padding}`}>
//                                 {v?.heatConsumableName}
//                             </td>
//                             <td className={`${styles.right} ${styles.bottom} ${styles.widthTwentyPercent} ${styles.padding}`}>{v?.heatConsumableValue}</td>
//                             <td className={`${styles.right} ${styles.bottom}  ${styles.widthTwentyPercent}`}>
//                                 <DeleteFilled style={{ fontSize: '25px', margin: '10px' }} onClick={() => onDelete(v?.heatConsumableName, i)} />
//                             </td>
//                         </tr>
//                     ))}
//             </tbody>
//         </table>
//     </Form>


//     <div style={{ margin: '10px' }}>
//         <Button onClick={() => handleSubmit()}>Save & Next</Button>
//     </div>
// </div>