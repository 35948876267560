import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import NumericInput from "../../Components/NumericInput/NumericInput";
import styles from "./index.module.css";
import { UserCredsContext } from "../../utils/UserCredsContext";
import { api_call_token, base_url, formDataApi } from "../../utils/Network";
import Upload from "antd/es/upload/Upload";
import {
  AlertFilled,
  AlertTwoTone,
  ArrowLeftOutlined,
  DeleteOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import ProductsCompo from "./ProductsCompo/ProductsCompo";
import Delete from "../../Assets/Delete.png";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { alertMessage } from "../../utils/helperFunctions";

function Invoice() {
  const formRef = useRef();
  let navigate = useNavigate();
  let { state } = useLocation();
  const { base_items } = useContext(UserCredsContext);
  const [fieldValues, setFieldValue] = useState([]);
  const [products, getProduct] = useState([]);
  const [productsDetails, getProductsDetails] = useState([]);
  const [productListing, setProductList] = useState([]);
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [sameValue, setSameValue] = useState(false);

  const [invoiceFile, setInvoiceFile] = useState([]);
  const [countryFile, setCountryFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({
    link: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInvoiceData();
    getInvoiceProductData();
    getProd();
  }, []);

  useEffect(() => {
    if (state?.data?.supplierId) {
      handleAddress(state?.data?.supplierId);
    }
    if (state?.data?.invoiceID) {
      InvoiceFile(state?.data?.filename);
      InvoiceCntFile(state?.data?.filename);
    }
    if (state?.data?.productList) {
      let arr = [];

      state?.data?.productList.map((v, i) => {
        const { boe, ...rest } = v;
        let data = {
          ...rest,
          boe: boe,
        };
        arr.push(data);
      });
      setProductList(arr);
    }
  }, [state?.data]);

  const InvoiceFile = (file) => {
    api_call_token
      .get(`pdf/readfile?search=invoice/invoiceDoc/${file}`)
      .then((res) => {
        console.log(res);
        let val = [];

        res.data.map((v, i) => {
          let content = v.split("public/");
          val.push(content[1]);
        });
        setInvoiceFile(val);
      })
      .catch((err) => console.log(err));
  };

  const InvoiceCntFile = (file) => {
    api_call_token
      .get(`pdf/readfile?search=invoice/invoiceorigin/${file}`)
      .then((res) => {
        console.log(res);

        let val = [];

        res.data.map((v, i) => {
          let content = v.split("public/");
          val.push(content[1]);
        });
        setCountryFile(val);
      })
      .catch((err) => console.log(err));
  };

  const getProd = () => {
    api_call_token
      .get(`product`)
      .then((res) => {
        setProduct(res.data.totalRowCount);
        // getProduct(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setProduct = (e) => {
    api_call_token
      .get(`product?size=${e}`)
      .then((res) => {
        console.log(res);
        getProduct(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getInvoiceProductData = () => {
    api_call_token
      .get(`product/field?search=invoiceproduct&formtype=create`)
      .then((res) => {
        getProductsDetails(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getInvoiceData = () => {
    api_call_token
      .get(`product/field?search=invoice&formtype=create`)
      .then((res) => {
        setFieldValue(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleArray = (v, idx) => {
    console.log(v, v.productName);
    // const index = productListing.findIndex(
    //   (obj) => obj.productName === v.productName
    // );
    // if (index !== -1)
    productListing.splice(idx, 1);
    setProductList([...productListing]);
  };

  const handleData = async (values) => {
    if (state?.data) {
      console.log(values, productListing.length, "PRODDATA");
      if (productListing.length > 0) {
        const { countryoforiginfiles, invoicefiles, filename, ...rest } =
          values;

        const datas = {
          countryoforiginfiles: countryoforiginfiles,
          invoicefiles: invoicefiles,
          filename: filename,
        };

        for (const key in rest) {
          if (rest.hasOwnProperty(key)) {
            const value = rest[key];
            if (
              typeof value === "object" &&
              (value.$d instanceof Date || value._d instanceof Date)
            ) {
              if (value.$d) {
                rest[key] = moment(value.$d).format("YYYY-MM-DD");
              } else {
                rest[key] = moment(value._d).format("YYYY-MM-DD");
              }
            } else if (typeof value === "string" && !isNaN(value)) {
              rest[key] = Number(value);
            }
          }
        }
        const fmData = new FormData();
        for (const key in rest) {
          if (rest.hasOwnProperty(key)) {
            const value = rest[key];
            if (value !== undefined) {
              if (Array.isArray(value)) {
                // Handle arrays separately
                value.forEach((item, index) => {
                  if (item !== undefined && item.originFileObj !== undefined) {
                    fmData.append(key, item.originFileObj, `${key}_${index}`);
                  }
                });
              } else {
                fmData.append(key, value);
              }
            }
          }
        }

        const fmDatas = new FormData();
        for (const key in datas) {
          if (datas.hasOwnProperty(key)) {
            const value = datas[key];
            if (value !== undefined) {
              if (Array.isArray(value)) {
                // Handle arrays separately
                value.forEach((item, index) => {
                  if (item !== undefined && item.originFileObj !== undefined) {
                    fmDatas.append(key, item.originFileObj, `${key}_${index}`);
                  }
                });
              } else {
                fmDatas.append(key, value);
              }
            }
          }
        }
        console.log(...fmDatas, "DDD", ...fmData, "FMDATAS");

        let hasBinaryFiles = false;

        // Check if a value in FormData is a binary file
        for (const [key, value] of fmDatas.entries()) {
          if (value instanceof Blob || value instanceof File) {
            hasBinaryFiles = true;
            break; // Exit the loop as soon as a binary file is found
          }
        }

        if (hasBinaryFiles) {
          let apiEnds = "";
          try {
            const res = await formDataApi(
              apiEnds,
              fmDatas,
              false,
              "invoice/uploadDocumentsToExistFile"
            );
            console.log(res, "ressss");
            if (res.status == 200 || res.status == 201) {
              // submitProduct(res)
              // alertMessage("Container Created ")
              // setTimeout(() => {
              //     navigate('/container/addProducts', { state: res?.data })
              // }, 2000);
              // navigate('/container/addProducts', { state: res?.data })
            }
          } catch (error) {
            console.log(error);
          }
        }

        api_call_token
          .patch(`invoice/${state?.id}`, { ...rest })
          .then((res) => {
            if (
              res.status ||
              res.status == 200 ||
              res.status == 201 ||
              res.status == 204
            ) {
              submitProduct(res, 1);
            }
          })
          .catch((err) => console.log(err));

        // let apiEnd = `/${state?.id}`;
        // try {
        //   const res = await formDataApi(apiEnd, fmData, true, "invoice")
        //   console.log(res, res.status, "ressss")
        //   if (res.status == 200 || res.status == 201) {
        //     submitProduct(res, 1)
        //     // alertMessage("Container Created ")
        //     // setTimeout(() => {
        //     //     navigate('/container/addProducts', { state: res?.data })
        //     // }, 2000);
        //     // navigate('/container/addProducts', { state: res?.data })
        //   }

        // } catch (error) {
        //   console.log(error);
        // }
      } else {
        alertMessage("Please Add Products ", 2);
      }
    } else {
      console.log(values, productListing.length, "PRODDATA");
      if (productListing.length > 0) {
        for (const key in values) {
          if (values.hasOwnProperty(key)) {
            const value = values[key];
            if (
              typeof value === "object" &&
              (value.$d instanceof Date || value._d instanceof Date)
            ) {
              if (value.$d) {
                values[key] = moment(value.$d).format("YYYY-MM-DD");
              } else {
                values[key] = moment(value._d).format("YYYY-MM-DD");
              }
            } else if (typeof value === "string" && !isNaN(value)) {
              values[key] = Number(value);
            }
          }
        }
        const fmData = new FormData();
        for (const key in values) {
          if (values.hasOwnProperty(key)) {
            const value = values[key];

            if (Array.isArray(value)) {
              // Handle arrays separately
              value.forEach((item, index) => {
                fmData.append(key, item.originFileObj, `${key}_${index}`);
              });
            } else {
              fmData.append(key, value);
            }
          }
        }

        console.log(...fmData, "FMDATAS");
        let apiEnd = "";
        try {
          const res = await formDataApi(apiEnd, fmData, false, "invoice");
          console.log(res, "ressss");
          if (res.status == 200 || res.status == 201) {
            submitProduct(res);
            // alertMessage("Container Created ")
            // setTimeout(() => {
            //     navigate('/container/addProducts', { state: res?.data })
            // }, 2000);
            // navigate('/container/addProducts', { state: res?.data })
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        alertMessage("Please Add Products ", 2);
      }
    }

    // for (const key in values) {
    //     if (values.hasOwnProperty(key)) {
    //         const value = values[key];
    //         fmData.append(key, value);
    //     }
    // }

    // if (state) {
    //     let apiEnd = `/${state.id}`;
    //     try {
    //         const res = await formDataApi(apiEnd, fmData, true)
    //         if (res) {
    //             alertMessage("Container Updated ")
    //             setTimeout(() => {
    //                 navigate('/container/addProducts', { state: res })
    //             }, 2000);
    //         }

    //     } catch (error) {
    //         console.log(error);
    //     }
    // } else {

    // let apiEnd = "";
    // try {
    //     const res = await formDataApi(apiEnd, fmData, false, "invoice")
    //     console.log(res, "ressss")
    //     if (res.status == 200 || res.status == 201) {
    //       alert(res)
    //         // alertMessage("Container Created ")
    //         // setTimeout(() => {
    //         //     navigate('/container/addProducts', { state: res?.data })
    //         // }, 2000);
    //         // navigate('/container/addProducts', { state: res?.data })
    //     }

    // } catch (error) {
    //     console.log(error);
    // }
    // }
  };

  const submitProduct = (val, num) => {
    console.log(val, "Val");

    const newArr = productListing.map((v) => ({
      ...v,
      invoiceID: val?.data?.invoiceID,
    }));

    if (num) {
      setLoading(true);
      api_call_token
        .post(`invoice/productPatch`, {
          invoiceID: state?.data?.invoiceID,
          productList: newArr,
        })
        .then((res) => {
          console.log(res);
          alertMessage("Invoice Updated ");
          setProductList([]);
          form.resetFields();

          setTimeout(() => {
            setLoading(false);
            navigate("/Invoice");
          }, 2000);
        })
        .catch((err) => console.log(err));
    } else {
      setLoading(true);
      api_call_token
        .post(`invoice/product`, { productList: newArr })
        .then((res) => {
          console.log(res);
          alertMessage("Invoice Added ");
          setProductList([]);
          form.resetFields();
          setTimeout(() => {
            setLoading(false);
            navigate("/Invoice");
          }, 2000);
        })
        .catch((err) => console.log(err));
    }
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleDelete = () => {
    if (deleteData?.link) {
      api_call_token
        .get(`/pdf/deletefile?search=${deleteData?.link}`)
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            alertMessage("Data Delete ");
            setOpen(false);
            window.location.reload();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleAddress = (e) => {
    api_call_token
      .get(`supplier/isblacklist/${e}`)
      .then((res) => {
        if(res.status == 200){
            if(res.data.isBlackList){
                alertMessage('This Supplier has been Black Listed', 2)
            }
        }
      })
      .catch((err) => console.log(err))
    console.log(e, "DataS");
    formRef?.current?.resetFields(["addressID"]);
    api_call_token
      .get(`/formData/supplierAddress/${e}`)
      .then((res) => {
        console.log(res, "res_DAta");
        setAddressList(res.data.supplierAddressList);
      })
      .catch((err) => console.log(err));
  };

  const validateField = async (rule, value) => {
    try {
      // Perform the API call
      const res = await api_call_token.get(`invoice/invoiceDuplicate/${value}`);
      if (state?.data?.filename == value) {
        return Promise.resolve();
      }
      // Assuming the API returns a boolean response
      const isDuplicate = !!res?.data;
      setSameValue(isDuplicate);

      if (!isDuplicate) {
        // Validation passes if the API response is false
        return Promise.resolve();
      } else {
        // Validation fails if the API response is true
        return Promise.reject("File Name should be unique");
      }
    } catch (err) {
      console.error("Error fetching API:", err);
      return Promise.reject(
        "Validation failed: An error occurred while fetching data."
      );
    }
  };

  // let data = base_items?.boeMasterList.find((val) => {
  //   return val.id == 1
  // })
  // console.log(base_items?.boeMasterList[base_items?.boeMasterList.findIndex((val) => {
  //   return val.id == 1
  // })]?.name, "DDDDDDdd")
  console.log(deleteData, productListing, "ProductListing");
  return (
    <div className={styles.mainData}>
      <h2>
        <ArrowLeftOutlined
          onClick={() => navigate(-1)}
          style={{ marginRight: "10px" }}
        />
        Purchase Invoice
      </h2>

      <div></div>
      {fieldValues.length > 0 && (
        <Form
          form={form}
          ref={formRef}
          onFinish={handleData}
          initialValues={{
            ...state?.data,
            invoiceDate: state?.data?.invoiceDate
              ? moment(state?.data?.invoiceDate?.split("T")[0], "YYYY-MM-DD")
              : undefined,
              danDate: state?.data?.danDate 
              ? moment(state?.data?.danDate?.split("T")[0], "YYYY-MM-DD")
              : null
          }}
        >
          <Row gutter={[16, 16]}>
            {fieldValues &&
              fieldValues.length > 0 &&
              fieldValues?.map(
                (val, i) =>
                  val?.isForm && (
                    <>
                      {val?.fieldType == "int" || val?.fieldType == "double" ? (
                        <Col xs={24} md={14} lg={8}>
                          <span>
                            {val?.fieldName} {val?.required && `*`}
                          </span>
                          <Form.Item
                            name={val?.apiName}
                            rules={[
                              {
                                required: val?.required,
                                message: `Please Enter ${val?.fieldName}!`,
                              },
                            ]}
                          >
                            <NumericInput
                              style={{
                                width: "100%",
                              }}
                              value={value}
                              onChange={setValue}
                            />
                          </Form.Item>
                        </Col>
                      ) : val?.fieldType == "picklist_fk" ? (
                        <>
                          <Col xs={24} md={14} lg={8}>
                            <span>
                              {val?.fieldName} {val?.required && `*`}
                            </span>
                            <Form.Item
                              name={val?.apiName}
                              rules={[
                                {
                                  required: val?.required,
                                  message: `Please Enter ${val?.fieldName}!`,
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                allowClear
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                onChange={
                                  val?.apiName == "supplierID" ||
                                  val?.apiName == "supplierId"
                                    ? handleAddress
                                    : (e) => console.log(e)
                                }
                                options={
                                  val?.apiName == "addressID"
                                    ? addressList?.map((obj) => {
                                        const keys = Object.keys(obj);
                                        const convertedObj = {
                                          value: obj[keys[0]],
                                          label: obj[keys[1]],
                                        };

                                        for (let i = 2; i < keys.length; i++) {
                                          convertedObj[keys[i]] = obj[keys[i]];
                                        }

                                        return convertedObj;
                                      })
                                    : base_items[val?.picklistData]?.map(
                                        (obj) => {
                                          const keys = Object.keys(obj);
                                          const convertedObj = {
                                            value: obj[keys[0]],
                                            label: obj[keys[1]],
                                          };

                                          for (
                                            let i = 2;
                                            i < keys.length;
                                            i++
                                          ) {
                                            convertedObj[keys[i]] =
                                              obj[keys[i]];
                                          }

                                          return convertedObj;
                                        }
                                      )
                                }
                              />
                            </Form.Item>
                          </Col>
                        </>
                      ) : val?.fieldType == "date" ? (
                        <Col xs={24} md={14} lg={8}>
                          <span>
                            {val?.fieldName} {val?.required && `*`}
                          </span>
                          <Form.Item
                            name={val?.apiName}
                            rules={[
                              {
                                required: val?.required,
                                message: `Please Enter ${val?.fieldName}!`,
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={onChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      ) : val?.fieldType == "Boolean" ? (
                        <Col xs={24} md={14} lg={8}>
                          <span>
                            {val?.fieldName} {val?.required && `*`}
                          </span>
                          <Form.Item name={val?.apiName}>
                            <Switch
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                            />
                          </Form.Item>
                        </Col>
                      ) : val?.fieldType == "file" ? (
                        <Col xs={24} md={14} lg={8}>
                          <span>
                            {val?.fieldName} {val?.required && `*`}
                          </span>
                          <Form.Item
                            name={val?.apiName}
                            // label={val.fieldName}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                          >
                            <Upload name="logo" listType="picture">
                              <Button icon={<UploadOutlined />}>
                                Click to upload
                              </Button>
                            </Upload>
                          </Form.Item>

                          {val?.apiName == "invoicefiles" ? (
                            <>
                              <div>
                                {invoiceFile.length > 0 &&
                                  invoiceFile.map((v, i) => {
                                    let name = v.split("/").pop();
                                    return (
                                      <div className={styles.linkBox}>
                                        <div
                                          onClick={() =>
                                            window.open(
                                              `${base_url}${v}`,
                                              "_blank"
                                            )
                                          }
                                        >{`${name}`}</div>
                                        <DeleteOutlined
                                          onClick={() => {
                                            setOpen(true);
                                            setDeleteData({
                                              link: v,
                                            });
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                {countryFile.length > 0 &&
                                  countryFile.map((v, i) => {
                                    let name = v.split("/").pop();
                                    return (
                                      <div className={styles.linkBox}>
                                        <div
                                          onClick={() =>
                                            window.open(
                                              `${base_url}${v}`,
                                              "_blank"
                                            )
                                          }
                                        >{`${name}`}</div>
                                        <DeleteOutlined
                                          onClick={() => {
                                            setOpen(true);
                                            setDeleteData({
                                              link: v,
                                            });
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                            </>
                          )}
                        </Col>
                      ) : val?.apiName == "filename" ? (
                        <Col xs={24} md={14} lg={8}>
                          <span>
                            {val?.fieldName} {val?.required && `*`}
                          </span>
                          <Form.Item
                            name={val?.apiName}
                            rules={[
                              {
                                required: val?.required,
                                message: `Please Enter ${val?.fieldName}!`,
                              },
                              {
                                validator: validateField,
                              },
                            ]}
                          >
                            <Input disabled={state?.id ? true : false} />
                          </Form.Item>
                        </Col>
                      ) : (
                        <Col xs={24} md={14} lg={8}>
                          <span>
                            {val?.fieldName} {val?.required && `*`}
                          </span>
                          <Form.Item
                            name={val?.apiName}
                            rules={[
                              {
                                required: val?.required,
                                message: `Please Enter ${val?.fieldName}!`,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )
              )}
          </Row>
          {productsDetails.length > 0 && (
            <div>
              <div>
                {products.length > 0 && (
                  <ProductsCompo
                    products={products}
                    productsDetails={productsDetails}
                    setProductList={setProductList}
                    productListing={productListing}
                  />
                )}
              </div>
              <Row>
                        <Col xs={24} md={14} lg={3}>
                          <div
                            className={styles.prodDetails}
                            style={{ marginRight: "10px" }}
                          >
                            Product Code
                            
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>
                          Qty
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={1}>
                          <div className={styles.prodDetails}>
                          UOM
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>
                          Unit Price 
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={3}>
                          <div className={styles.prodDetails}>
                          Total Amt
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={3}>
                          <div className={styles.prodDetails}>
                          Purchase Order
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={3}>
                        Origin
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>
                          BOE No
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>BOE Date</div>
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>Cont. No</div>
                        </Col>

                        <Col xs={24} md={14} lg={1}>
                          {/* <div
                            onClick={() => handleArray(v, i)}
                            className={styles.deleteBox}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={Delete} alt="delte" />
                          </div> */}
                          <div className={styles.prodDetails}>Action</div>
                        </Col>
                      </Row>
              <div>
                {Array.isArray(productListing) &&
                  productListing.length > 0 &&
                  productListing?.map((v, i) => (
                    <div className={styles?.nameList}>
                      <Row>
                        <Col xs={24} md={14} lg={3}>
                          <div
                            className={styles.prodDetails}
                            style={{ marginRight: "10px" }}
                          >
                            {v.productCode}
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>
                            {v.invoiceproductquantity?.toFixed(2)}
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={1}>
                          <div className={styles.prodDetails}>
                            {v.unitofmeasure}
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>
                          {v.pricePerUnit?.toFixed(2)}
                            {/* {v.purchaseOrderNumber} */}
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={3}>
                          <div className={styles.prodDetails}>
                          {/* {v.invoiceproductquantity?.toFixed(2) * v.pricePerUnit?.toFixed(2)} */}
                          {v?.amount?.toFixed(2)}
                            {/* {v.containerNo} */}
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={3}>
                          <div className={styles.prodDetails}>
                          {v.purchaseOrderNumber}
                            {/* {v.pricePerUnit} */}
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={3}>
                          <div className={styles.prodDetails}>
                            {/* {v.hsnCode} */}
                            {v.originName}
                            </div>
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>
                          {/* {
                          base_items?.boeMasterList[base_items?.boeMasterList.findIndex((val) => {
                            return val?.id == v?.boeId
                          })]?.name
                           } */}
                           {v.boe}
                            {/* {v.invoiceproductquantity * v.pricePerUnit} */}
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>
                            {/* {v.bl} */}
                            {v?.boeDate?.includes("T") ? v?.boeDate.split('T')[0] : v?.boeDate} 
                            </div>
                        </Col>
                        <Col xs={24} md={14} lg={2}>
                          <div className={styles.prodDetails}>
                            {/* {
                          base_items?.boeMasterList[base_items?.boeMasterList.findIndex((val) => {
                            return val?.id == v?.boeId
                          })]?.name
                           } */}
                           {v.containerNo}
                           </div>
                        </Col>

                        <Col xs={24} md={14} lg={1}>
                          <div
                            onClick={() => handleArray(v, i)}
                            className={styles.deleteBox}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={Delete} alt="delte" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
              </div>
            </div>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {state?.id ? "Update" : "Submit"}
              {/* Submit */}
            </Button>
          </Form.Item>
        </Form>
      )}

      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
          setDeleteData({ link: "" });
        }}
        onOk={handleDelete}
      >
        <div style={{ textAlign: "center" }}>
          <WarningOutlined style={{ fontSize: "50px", color: "red" }} />
          <h3>Are you sure you want to delete this file?</h3>
        </div>
      </Modal>
    </div>
  );
}

export default Invoice;
