import { Button, Col, DatePicker, Form, Input, Row, Select, Spin, Modal,message } from 'antd'
import React, { useEffect, useState, useContext, useRef } from 'react'
import NumericInput from '../../../Components/NumericInput/NumericInput'
import { api_call, api_call_token, formDataApi } from '../../../utils/Network'
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainContext } from "../../../utils/context";
import { alertMessage } from '../../../utils/helperFunctions';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { UserCredsContext } from '../../../utils/UserCredsContext';
import Causion from "../../../Assets/Popup/caution.png"
import styles from "../index.module.css"

const { RangePicker } = DatePicker;
const { confirm } = Modal;

function ChallanForm() {
  const formRef = useRef();
  const { state } = useLocation();
  const [fieldValues, setFieldValue] = useState([]);
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { base_items, set_Base } = useContext(UserCredsContext);
  const [disabledData, setDisabledData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [challanType, setChallanType] = useState();
  const [challanBOE, setChallanBOE] = useState();
  const [productStatus, setProductStatus] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  let navigate = useNavigate();

  // const success = () => {
  //   messageApi.open({
  //     type: 'success',
  //     content: 'Challan Cretated',
  //     className: 'custom-class',
  //     style: {
  //       marginTop: '20vh',
  //     },
  //   });
  // };

  useEffect(() => {
    getChallanType();
    checkProductStatus();
  }, [])

  useEffect(() =>{
      if(state){
        setChallanBOE(state?.deliveryChallanBOE)
      }
  },[state])

  const getChallanType = () => {
    api_call_token
      .get(`deliveryChallan/getDCType`)
      .then((res) => {
        // console.log(res?.data?.DC, 'getChallanType');
        setChallanType(res?.data?.DC)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const checkProductStatus = () => {
    api_call_token
    .get(`deliveryChallan/checkProduct/${state?.deliveryChallanContainerId}`)
    .then((res) => {
        // console.log(res?.data?.status);
        setProductStatus(res?.data?.status)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const handleChange = (e) => {
    console.log(e, 'handleChange');
  }
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };


  const handleData =  (values) => {
    // console.log(values,"han08979879");
        
    
      const {deliveryChallanTypeName, deliveryChallanDate, deliveryChallanBOE } = values
      let dateString = deliveryChallanDate?._i
    ? deliveryChallanDate?._i
    : moment(deliveryChallanDate.$d).format("YYYY-MM-DD");
      
      if(state?.deliveryChallanId){
        const data = {
          deliveryChallanTypeName: deliveryChallanTypeName,
          deliveryChallanDate: dateString,
          deliveryChallanBOE: deliveryChallanBOE,
          deliveryChallanId: state?.deliveryChallanId,
          deliveryChallanVoucherNo: state?.deliveryChallanVoucherNo,
          deliveryChallanContainer: state?.deliveryChallanContainer,
          deliveryChallanContainerId: state?.deliveryChallanContainerId,
        }

        console.log(data, "hit_patch");
        const success = () => {
          messageApi.open({
            type: 'success',
            content: 'Challan Updated',
            className: 'custom-class',
            style: {
              marginTop: '20vh',
            },
          });
        };

        api_call_token
        .patch(`deliveryChallan/${state?.deliveryChallanId}`, {deliveryChallans:data})
        .then((res)=>{
          console.log(res?.data?.data);
          // success();
          alertMessage("Delivery Challan Updated ");
          setTimeout(() =>{
            navigate(`/deliverychallan`)
          }, 3000)
          
        })
        .catch((err)=>{
          console.log(err);
        })

      }else{
        const data = {
          deliveryChallanTypeName: deliveryChallanTypeName,
          deliveryChallanDate: dateString,
          deliveryChallanBOE: deliveryChallanBOE,
          deliveryChallanContainer: state?.deliveryChallanContainer,
          deliveryChallanContainerId: state?.deliveryChallanContainerId,
        }

        console.log(data, "hit_post");
        const success = () => {
          messageApi.open({
            type: 'success',
            content: 'Challan Cretated',
            className: 'custom-class',
            style: {
              marginTop: '20vh',
            },
          });
        };

        api_call_token
        .post(`deliveryChallan`, {deliveryChallans:data})
        .then((res)=>{
          console.log(res?.data?.data);
          alertMessage("Delivery Challan Created ");
          // success();
          setTimeout(() =>{
            navigate(`/deliverychallan`)
          }, 3000)
        })
        .catch((err)=>{
          console.log(err);
        })
      }

     
}



  return (
    <div style={{ width: '100%', padding: '13px', boxSizing: 'border-box' }}>
      <div>
        <h2> <ArrowLeftOutlined onClick={() => navigate(-1)} style={{ marginRight: '10px' }} /> Delivery Challan</h2></div>
      {/* {isLoading ? <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <Spin size="large" />
        </div>

      </> : */}
      <div style={{ padding: '10px 30px', background: 'white' }} className={styles.containerMain}>
        <Form form={form} ref={formRef} onFinish={handleData}
          initialValues={{
            ...state?.data,
            deliveryChallanDate: state?.deliveryChallanDate ? moment(state?.deliveryChallanDate.split('T')[0], "YYYY-MM-DD") : undefined,
            deliveryChallanTypeName: state?.deliveryChallanTypeName,
            deliveryChallanBOE: state?.deliveryChallanBOE,
          }}
        >
          <div className={styles.Div}>
            <div className={styles.formDetails}>
              <h3>Challan Details</h3>

              <Row gutter={[26, 0]}>
                <Col xs={24} md={14} lg={24}>
                  <span>Delivery Challan Type</span>
                  <Form.Item name="deliveryChallanTypeName" rules={[
                    {
                      required: true,
                      message: `Please Select Delivery Challan Type`,
                    },
                  ]}>

                    <Select
                      // showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Delivery Challan Type"
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   (option?.title ?? "").includes(input)
                      // }
                      // filterSort={(optionA, optionB) =>
                      //   (optionA?.title ?? "")
                      //     .toLowerCase()
                      //     .localeCompare(
                      //       (optionB?.title ?? "").toLowerCase()
                      //     )
                      // }
                      onChange={handleChange}
                      options={
                        challanType &&
                        challanType?.map(
                          (obj) => {
                            const keys = Object.keys(obj);
                            const convertedObj = {
                              value: obj["title"],
                              label: obj["title"],
                            };

                            for (let i = 2; i < keys.length; i++) {
                              convertedObj[keys[i]] = obj[keys[i]];
                            }

                            return convertedObj;
                          }
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={14} lg={24}>
                  <span>Enter BOE</span>

                  <Form.Item
                            name="deliveryChallanBOE"
                            rules={[
                                { required: true, message: "Please enter BOE" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                </Col>
                <Col xs={24} md={14} lg={24}>
                  <span>Challan Date</span>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Please Enter Challan Date!`,
                      },
                    ]}
                    name="deliveryChallanDate"
                  >
                    <DatePicker
                      placeholder='Challan Date'
                      onChange={onChange}
                      // showTime
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>


          <Form.Item>
            {
              productStatus ?  <Button type="primary" htmlType="submit" disabled={disabledData} style={{ marginTop: '10px' }} loading={loading}>
              {
                // (state?.id) ? "Update and Next" : "Save and Next"
                (state?.deliveryChallanId) ? "Update" : "Save"
              }
              {/* Submit */}
            </Button> : 
            <p style={{color:"#de2326", fontSize:'18px'}}>*Container has no products. Delivery Challan cannot be Created.</p>
            }
            
          </Form.Item>
        </Form>
      </div>

      {contextHolder}
      {/* } */}
    </div>
  )
}

export default ChallanForm