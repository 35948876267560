import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { Tabs } from 'antd';
// import FieldList from "../FieldList";
// import DraftList from "../DraftList";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { api_call_token } from "../../utils/Network";
import ListingData from "./ListingData";
import Buyer from "../Master/MasterData/Buyer/Buyer";
const onChange = (key) => {
    console.log(key);
};

function HeatList() {
    let location = useLocation();
    const [buyers, setBuyers] = useState([]);
    const [item, setItem] = useState([]);

    useEffect(() => {
        getListBuyer();
    }, [])

    useEffect(() => {
        getBuyers();
    }, [buyers])

    const getListBuyer = () => {
        api_call_token
            .get(`/buyer`)
            .then((res) => {
                setBuyers(res.data.data)
            })
            .catch(err => console.log(err))
    }

    const getBuyers = () => {
        let items  = [];
        buyers.map((v, i) => {
            let itms = {
                key: "" + i,
                label: v.buyerName,
                children: <ListingData id={v.buyerID}/>
            }
            items.push(itms)
        })
        setItem(items)
    }

    console.log(buyers, "Buyers")


    return (
        <div className={styles.heatMain}>
            <h2 style={{ marginBottom: "30px", marginTop: '60px' }}>Batch</h2>
            <Tabs defaultActiveKey={(location?.state?.draftIndex) ? location?.state?.draftIndex + '' : '1'} items={item} onChange={onChange} />
        </div>
    );
}

export default HeatList;
