import React from 'react'
import Sidenav from '../Sidnav/Sidenav'

function Layout({ visible, setVisible = () => {}, children }) {
  return (
    <>
      <Sidenav visible={visible} setVisible={setVisible}/>
    </>
  )
}

export default Layout