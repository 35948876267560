import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  Checkbox,
  DatePicker,
  Select,
  Upload,
  Spin,
  Space,
} from "antd";
import { api_call_token, base_url, formDataApi } from "../../../utils/Network";
import moment from "moment";
import styles from "../index.module.css";
import {
  DownloadOutlined,
  PlusCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { alertMessage } from "../../../utils/helperFunctions";
import { UploadOutlined } from "@ant-design/icons";
import EditIcon from "../../../Assets/ICONS/edit.png";
import Modals from "../../../Components/Modals/Modals";
import EditForm from "./EditForm";
import { Slider, Switch } from "antd";
import SubmitForm from "./SubmitForm";

const data = [
  {
    ingot: "ROT1A",
    qty: 5000,
    soldqty: 0,
    Batch: "ROT1",
    vehical: [
      {
        qty: 500,
        Package: "Pack1",
        VehicalNo: "R756 29",
      },
      {
        qty: 600,
        Package: "Pack2",
        VehicalNo: "R756 29",
      },
    ],
  },
  {
    ingot: "ROT1B",
    qty: 6000,
    soldqty: 0,
    Batch: "ROT1",
    vehical: [],
  },
];

const IngotList = ({
  id,
  setActiveTab,
  selectedRows,
  setSelectedRows = () => {},
}) => {
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [checkoutVisible, setCheckoutVisible] = useState(false);
  const [vehicleNo, setVehicleNo] = useState("");
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [furnanceName, setFurnanceName] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [Instock, setInstock] = useState([]);
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openIngotEditModal, setOpenIngotEditModal] = useState(false);
  const [opendownloadEditModal, setOpenDownloadEditModal] = useState(false);
  const [packageDetails, setpackageDetails] = useState([]);
  const [currentForm, setcurrentForm] = useState([]);
  const [transport, setTransport] = useState([]);
  const [randomKey, setRandomKey] = useState(0);
  const [rangeData, setRangeData] = useState([]);
  const [rangedValue, setRangedValue] = useState([]);
  const [recordOfDownload, setRecordOfDownload] = useState({});
  const columns = [
    {
      title: "",
      dataIndex: "heatingotId",
      render: (_) => <div></div>,
      width: 0,
    },
    {
      title: "",
      dataIndex: "select",
      render: (_, record) => (
        <Checkbox
          onChange={(e) => handleCheckboxChange(e, record)}
          // checked={selectedRows.includes(record)}
          checked={selectedRows.some(
            (row) => row?.heatingotId === record?.heatingotId
          )}
        ></Checkbox>
      ),
      width: 50,
    },
    {
      title: "Ingot",
      dataIndex: "ingotName",
      key: "ingotName",
    },
    {
      title: "No. of Ingots",
      dataIndex: "ingotPiece",
      key: "ingotPiece",
    },
    {
      title: "Sold Ingots Qty",
      dataIndex: "ingotPieceSold",
      key: "ingotPieceSold",
    },
    {
      title: "Gross Weight",
      dataIndex: "ingotGrossWeight",
      key: "ingotGrossWeight",
    },
    {
      title: "Net Weight",
      dataIndex: "ingotNettWeight",
      key: "ingotNettWeight",
    },
    
    {
      title: "Al",
      dataIndex: "heatValueSecond",
      key: "heatValueSecond",
      render: (_) => {
        return <div>{_?.toFixed(3) || 0}</div>;
      },
    },
    {
      title: "Remaining Qty",
      dataIndex: "ingotPieceRemainig",
      key: "ingotPieceRemainig",
    },
    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
      render: (text) => {
        let backgroundColor = "";
        let borderRadius = "20px";
        let padding = "7px";
        let color = "white";
        let textAlign = "center";

        if (text === "In Stock") {
          color = "#087A17";
          borderRadius = "20.727px";
          backgroundColor = "#DAEBDB";
        } else if (text === "Partially Sold") {
          backgroundColor = "#FCEECC";
          borderRadius = "20.727px";
          color = "#F3AF0D";
        } else if (text === "Out of Stock") {
          backgroundColor = "#FCE6E8";
          borderRadius = "20.727px";
          color = "#DE2326";
        }

        const style = {
          backgroundColor,
          borderRadius,
          padding,
          color,
          width: "100%",
          textAlign,
        };

        return <div style={style}>{text}</div>;
      },
    },
    {
      title: "Batch",
      dataIndex: "heatNumber",
      key: "heatNumber",
    },
    {
      title: "Action",
      dataIndex: "",
      width: 100, // Set the width for this column
      fixed: "right",
      render: (_, record) => {
        const { heatId } = record;
        const handleViewClick = () => {
          // redirectTo(heatId);
          console.log("CheckClickView", record);
          // open modal on click
        };
        return (
          <div style={{ display: "flex" }}>
            <div
              onClick={() => handleEditClickIngot(record)}
              style={{
                width: "max-content",
                cursor: "pointer",
                fontSize: "20px",
                marginRight: "15px",
              }}
            >
              <img src={EditIcon} alt="edit" />
            </div>
            <DownloadOutlined
              onClick={() => download(record)}
              style={{ fontSize: "30px" }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getGrade();
    getFurnance();
  }, []);

  useEffect(() => {
    if (currentForm.length > 0) {
      forms.resetFields();
      console.log(currentForm, "CCCC");
      setRandomKey((prev) => prev + 1);
      setOpenIngotEditModal(true);
    }
  }, [currentForm]);

  const getGrade = () => {
    api_call_token
      .get(`/grade`)
      .then((res) => {
        console.log(res);
        const data = res.data.data;

        let arr = [];
        let last = data[0]?.gradeName;
        let first = data[data.length - 1]?.gradeName;

        arr.push(first);
        arr.push(last);
        console.log(arr, last, first, "dfasdads");
        setRangeData(arr);
      })
      .catch((err) => console.log(err));
  };

  const getFurnance = () => {
    api_call_token
      .get(`/furnace`)
      .then((res) => {
        setFurnanceName(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const download = (record) => {
    setRecordOfDownload(record);
    setOpenDownloadEditModal(true);

    // console.log(record, "recordsssss");
    // api_call_token
    //   .get(`pdf/getLabelOfIngot/${record?.heatingotId}`)
    //   .then((res) => {
    //     console.log(res, "RESDAFATA");
    //     let data = res.data.filepath;
    //     var inputString = data;
    //     var resultString = inputString.substring(inputString.indexOf("/") + 1);

    //     window.open(`${base_url}${resultString}`);
    //   })
    //   .catch((err) => console.log(err));
  };


  const onFinishDownload = (val, types) => {
    let key;
    if(types == 'pdf'){
      key = 'getLabelOfIngot'
    }else if (types == 'excel'){
      key = 'getExcelLabelOfIngot'
    }
    api_call_token
      .get(`pdf/${key}/${recordOfDownload?.heatingotId}/${val.supplier}/${(val.check) ? 1: 0}`)
      .then((res) => {
        if(!res.data.filepath){
          alertMessage("File Not Present", 2)
          return;
        }
        console.log(res, "RESDAFATA");
        let data = res.data.filepath;
        var inputString = data;
        var resultString = inputString.substring(inputString.indexOf("/") + 1);

        window.open(`${base_url}${resultString}`);
      })
      .catch((err) => console.log(err));
  };

  const handleEditClickIngot = (record) => {
    console.log(record, "Get Recordssss");
    if (
      record &&
      record?.packingList?.length > 0 &&
      record?.packingList[0]?.packingId
    ) {
      let data = [];
      let remPiece = record.ingotPiece;
      // let remWeight = record.ingotWeight;
      let remGrossWeight = record.ingotGrossWeight;
      let remNetWeight = record.ingotNettWeight;

      record.packingList.map((val, idx) => {
        let vals = {};
        const {
          grossWeight,
          ingotId,
          packingDetailsId,
          packingId,
          ingotNumber,
          netWeight,
        } = val;
        vals.ingotPiece = record.ingotPiece;
        // vals.ingotWeight = record.ingotWeight;
        vals.ingotGrossWeight = record.ingotGrossWeight;
        vals.ingotNettWeight = record.ingotNettWeight;
        vals.ingotPieceSold = ingotNumber;
        vals.ingotGrossWeightSold = grossWeight;
        vals.ingotNetWeightSold = netWeight;
        // vals.ingotWeightSold = grossWeight;
        vals.ingotPieceRemainig = remPiece - ingotNumber;
        vals.ingotGrossWeightRemainig = remGrossWeight - grossWeight;
        vals.ingotNetWeightRemainig = remNetWeight - netWeight;
        vals.ingotId = ingotId;
        vals.packingDetailsId = packingDetailsId;
        vals.packingId = packingId;

        data.push(vals);
        remPiece = vals.ingotPieceRemainig;
        // remWeight = vals.ingotWeightRemainig;
        remGrossWeight = vals.ingotGrossWeightRemainig;
        remNetWeight = vals.ingotNetWeightRemainig;
      });

      setcurrentForm(data);
      setpackageDetails(record);
    }
  };

  console.log("CheckClickViewOutside", packageDetails);

  useEffect(() => {
    getData();
    getStatus();
    getTransport();
  }, [filterObj, id]);

  const getStatus = () => {
    api_call_token
      .get(`/zipcode/status?statusName=Heat Stock`)
      .then((res) => {
        setInstock(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const getTransport = () => {
    api_call_token
      .get(`/zipcode/status?statusName=Heat Transport`)
      .then((res) => {
        setTransport(res.data.Data);
      })
      .catch((err) => console.log(err));
  };

  const getData = (paramObj = { ...filterObj }) => {
    setIsLoading(true);
    api_call_token
      .get(`packing/ingotlist`, { params: paramObj })
      .then((res) => {
        let datas = res.data.data;
        if (datas) {
          setPaginationData(res.data);
          setData(datas);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  console.log("INGOTPAGINATIONDATa", paginationData);

  const handleCheckboxChange = (e, record) => {
    console.log(e, record, "RECORD");
    // if (record.ingotPieceRemainig) {
    let data = record;
    // data.grossWeight = record.ingotWeightRemainig;
    data.netWeight = record.ingotNetWeightRemainig;
    data.grossWeight = record.ingotGrossWeightRemainig;
    data.noOfIngots = record.ingotPieceRemainig;
    if (e.target.checked) {
      const dataIndex = selectedRows.findIndex(
        (row) => row?.heatingotId === data?.heatingotId
      );

      if (dataIndex === -1) {
        // If data is not present, add it to selectedRows
        setSelectedRows([...selectedRows, data]);
      } else {
        // If data is already in selectedRows, update it
        const updatedSelectedRows = [...selectedRows];
        updatedSelectedRows[dataIndex] = data;
        setSelectedRows(updatedSelectedRows);
      }
    } else {
      setSelectedRows(
        selectedRows.filter((row) => row?.heatingotId !== data?.heatingotId)
      );
    }
  };

  const handleCheckout = () => {
    setCheckoutVisible(true);
    setSelectedData(selectedRows);
  };

  const handleCancelCheckout = () => {
    setCheckoutVisible(false);
    setVehicleNo("");
  };

  const clearFilter = () => {
    let obj = filterObj;
    if (obj.gradeStart && obj.gradeEnd) {
      delete obj.gradeStart;
      delete obj.gradeEnd;
    }
    setFilterObj({ ...obj });
    let arr = [93, 98.5];
    setRangeData(arr);
    setRangedValue([0, 0]);
  };

  const handleDATA = (e) => {
    console.log(e, "dfdf");
    let first = e[0];
    let last = e[1];
    let obj = filterObj;
    if (first && last) {
      obj.gradeStart = first;
      obj.gradeEnd = last;
      obj.page = 1;
    } else {
      delete obj.gradeStart;
      delete obj.gradeEnd;
    }
    setFilterObj({ ...obj });
    setRangedValue(e);
  };

  const handleBranch = (event) => {
    console.log(event, "DDDDDsss");
    let value = event;
    let obj = filterObj;
    if (value) {
      obj.currentStatus = value;
      obj.page = 1;
    } else delete obj.currentStatus;
    setFilterObj({ ...obj });
  };

  const handleCheckoutSubmit = (values) => {
    console.log(selectedData, values, "SelectedData");
    let data = {
      vehicleNumber: values?.vehicleNo,
      packingPartyName: values?.packingPartyName,
      status: values?.status,
      packingDateTime: moment().format("YYYY-MM-DD"),
    };

    let packages = [];
    selectedData.map((v, i) => {
      let data = {};
      data.ingotNumber = v.noOfIngots;
      data.grossWeight = v.grossWeight;
      data.netWeight = v.netWeight;
      // data.grossWeight = v.grossWeight;
      data.ingotId = v.heatingotId;
      data.ingotName = v.ingotName;
      packages.push(data);
    });
    const formData = new FormData();

    values?.files?.fileList.forEach((file, index) => {
      formData.append(`packingFiles`, file.originFileObj);
    });

    api_call_token
      .post(`packing`, data)
      .then((res) => {
        if (res.data.id) {
          packages.map((val, idx) => {
            val.packingId = res.data.id;
          });

          if (formData.has("packingFiles")) {
            formData.append(`packingId`, res.data.id);
            getFunction(formData);
          }
          api_call_token
            .post(`PackingDetail`, packages)
            .then((res) => {
              if (res.status == 200) {
                setCheckoutVisible(false);
                setSelectedData([]); // Clear selected data
                setSelectedRows([]);
                setActiveTab("3");
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const getFunction = async (val) => {
    console.log(...val, "VALSSSSSSSSSs");
    let apiEnd = "uploadDocumentsToPacking";
    try {
      const res = await formDataApi(apiEnd, val, false, "packing/");
      console.log(res, "ressss");
      if (res.status == 200 || res.status == 201) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const expandedRowRender = (record) => {
    return (
      <Table
        dataSource={record.packingList}
        columns={[
          {
            title: "Qty",
            dataIndex: "ingotNumber",
            key: "ingotNumber",
            width: 150,
          },
          {
            title: "Vehicle No",
            dataIndex: "vehicleNumber",
            key: "vehicleNumber",
            width: 150,
          },
          {
            title: "Bag",
            dataIndex: "bagNumber",
            key: "bagNumber",
            width: 150,
          },
        ]}
      />
    );
  };

  const handleExpand = (record) => {
    console.log(record, "RECORDS");
    if (expandedRowKey === record.heatingotId) {
      // If the row is already expanded, collapse it
      setExpandedRowKey(null);
    } else {
      setExpandedRowKey(record.heatingotId);
    }
  };

  const handleNoOfIngotsChange = (e, record, datas) => {
    // Update the 'noOfIngots' in the selected data
    console.log(e.target.value);
    const updatedData = selectedData.map((item) => {
      if (item.ingotName === record.ingotName) {
        return { ...item, [datas]: e?.target?.value };
      }
      return item;
    });
    setSelectedData([...updatedData]);
  };

  const handleGrossWeightChange = (e, record) => {
    // Update the 'grossWeight' in the selected data
    const updatedData = selectedData.map((item) => {
      if (item.ingotName === record.ingotName) {
        return { ...item, grossWeight: e.target.value };
      }
      return item;
    });
    setSelectedData(updatedData);
  };

  const handleInputChange = (index, fieldName, value) => {
    const numericValue = parseFloat(value);

    if (isNaN(numericValue)) {
      // Handle the case where the value is not a number (e.g., show an error message)
      alertMessage("Invalid numeric value", 2);
      return;
    }
    setcurrentForm((prevData) => {
      const updatedData = [...prevData];
      updatedData[index][fieldName] = +value;

      // Update remaining values
      let remPiece = updatedData[0].ingotPiece;
      let remWeight = updatedData[0].ingotWeight;

      updatedData.forEach((item) => {
        const { ingotPieceSold, ingotWeightSold } = item;

        item.ingotPieceRemainig = remPiece - +ingotPieceSold;
        item.ingotWeightRemainig = remWeight - +ingotWeightSold;

        remPiece = item.ingotPieceRemainig;
        remWeight = item.ingotWeightRemainig;
      });

      return updatedData;
    });
    setRandomKey((prev) => prev + 1);
  };

  const handleCloseModalDownload = () => {
    setOpenDownloadEditModal(false);
  };

  const handleCloseModal = () => {
    setcurrentForm([]);
    setOpenIngotEditModal(false);
  };

  const onFinishDynamic = () => {
    api_call_token
      .patch(`heatingot/updateHeatIngot`, currentForm)
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          getData();
          setOpenIngotEditModal(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const onSearch = (e) => {
    let value = e.target.value;
    let obj = filterObj;
    if (value) {
      obj.search = value;
      obj.page = 1;
    } else delete obj.search;
    setFilterObj({ ...obj });
  };

  const handleFurnance = (event) => {
    console.log(event, "DDDDDsss");
    let value = event;
    let obj = filterObj;
    if (value) {
      obj.furnace = value;
      obj.page = 1;
      // setCurrentPage(1)
    } else delete obj.furnace;
    setFilterObj({ ...obj });
  };

  console.log(transport, selectedRows, "SelectDATAssssss");
  return (
    <div>
      <div className={styles.searchData} style={{ display: "flex" }}>
        <Input
          size="medium"
          placeholder="Search By Ingot"
          prefix={<SearchOutlined />}
          onChange={(e) => onSearch(e)}
          style={{ width: "70%" }}
        />

        <div style={{ marginRight: "10px" }}>
          {
            <Select
              showSearch
              style={{
                width: "100%",
                margin: "0 10px",
              }}
              placeholder="Filter By Furnance Name"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              // bordered={false}
              onChange={handleFurnance}
              options={
                furnanceName &&
                furnanceName?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["furnaceName"],
                    label: obj["furnaceName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          }
          {/* {Instock.map((v, i) => (
            <div>{v?.statusName}</div>
          ))} */}
        </div>

        <div>
          {
            <Select
              showSearch
              style={{
                width: "100%",
                margin: "0 10px",
              }}
              placeholder="Filter By Status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              // bordered={false}
              onChange={handleBranch}
              options={
                Instock &&
                Instock?.map((obj) => {
                  const keys = Object.keys(obj);
                  const convertedObj = {
                    value: obj["statusID"],
                    label: obj["statusName"],
                  };

                  for (let i = 2; i < keys.length; i++) {
                    convertedObj[keys[i]] = obj[keys[i]];
                  }

                  return convertedObj;
                })
              }
            />
          }
          {/* {Instock.map((v, i) => (
            <div>{v?.statusName}</div>
          ))} */}
        </div>
      </div>
      <div className={styles.filterCss}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Filter By Range</p>
          <p className={styles.clearBtn} onClick={clearFilter}>
            Clear
          </p>
        </div>
        <Slider
          min={rangeData[0]}
          max={rangeData[1]}
          range={true}
          value={rangedValue}
          defaultValue={rangeData}
          onChange={handleDATA}
        />
      </div>

      {isLoading ? (
        <div style={{ margin: "50px auto", textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table
          dataSource={data}
          columns={columns}
          rowKey={(record) => record.heatingotId}
          expandable={{
            expandedRowRender,
            expandedRowKeys: [expandedRowKey],
            onExpand: (_, record) => handleExpand(record),
          }}
          pagination={{
            pageSize: filterObj?.size, // Number of items per page
            total: paginationData?.totalRowCount, // Total number of items
            current: paginationData?.currentPage,
            showSizeChanger:false,
            onChange: (page, pageSize) => {
              console.log("PAGSISIEIEIE", pageSize);
              setFilterObj({ ...filterObj, page: page, size: pageSize });
              // Handle page change here, e.g., fetch data for the new page
            },
          }}
        />
      )}
      {selectedRows.length > 0 && (
        <Button onClick={handleCheckout} className={styles.proceed}>
          Proceed to Packing
        </Button>
      )}
      <Modal
        title="Checkout"
        visible={checkoutVisible}
        onOk={form.submit}
        onCancel={handleCancelCheckout}
      >
        <Form
          form={form}
          onFinish={handleCheckoutSubmit}
          initialValues={{ status: 8 }}
        >
          <Form.Item
            name="vehicleNo"
            label="Enter Vehicle Number"
            rules={[
              {
                required: false,
                // message: `Please Enter Vehicle Number!`,
              },
            ]}
          >
            <Input
              value={vehicleNo}
              onChange={(e) => setVehicleNo(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="packingPartyName"
            label="Enter Party Name"
            rules={[
              {
                required: false,
                message: `Please Enter Vehicle Number!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Delivery Status">
            {transport && (
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={
                  transport &&
                  transport?.map((obj) => {
                    const keys = Object.keys(obj);
                    const convertedObj = {
                      value: obj["statusID"],
                      label: obj["statusName"],
                    };

                    for (let i = 2; i < keys.length; i++) {
                      convertedObj[keys[i]] = obj[keys[i]];
                    }

                    return convertedObj;
                  })
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Upload Files" name="files">
            <Upload name="logo" listType="picture">
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Table
            dataSource={selectedData}
            pagination={false}
            columns={[
              { title: "Ingot", dataIndex: "ingotName", key: "ingotName" },
              {
                title: "No. of Ingots",
                dataIndex: "noOfIngots",
                key: "noOfIngots",
                render: (_, record) => (
                  <Input
                    value={record.noOfIngots}
                    onChange={(e) =>
                      handleNoOfIngotsChange(e, record, "noOfIngots")
                    }
                  />
                ),
              },
              {
                title: "Ingot Net Weight",
                dataIndex: "netWeight",
                key: "netWeight",
                render: (_, record) => (
                  <Input
                    value={record.netWeight}
                    onChange={(e) =>
                      handleNoOfIngotsChange(e, record, "netWeight")
                    }
                  />
                ),
              },
              {
                title: "Ingot Gross Weight",
                dataIndex: "grossWeight",
                key: "grossWeight",
                render: (_, record) => (
                  <Input
                    value={record.grossWeight}
                    onChange={(e) =>
                      handleNoOfIngotsChange(e, record, "grossWeight")
                    }
                  />
                ),
              },
            ]}
            rowKey={(record) => record.ingot}
          />
        </Form>
      </Modal>

      {/* Modal popup for Download*/}
      <Modal
        title={"Ingot Selection List"}
        open={opendownloadEditModal}
        onCancel={() => handleCloseModalDownload(false)}
        className={styles.modalStyles}
        footer={false}
        // footer={[
        //   <Button key="cancel" onClick={() => handleCloseModalDownload(false)}>
        //     Cancel
        //   </Button>,
        //   <Button key="submit" type="primary" onClick={onFinishDownload}>
        //     Save Changes
        //   </Button>,
        // ]}
      >
        <SubmitForm onFinishDownload={onFinishDownload} setOpenDownloadEditModal={setOpenDownloadEditModal} />
      </Modal>

      <Modal
        title={"Ingot Details"}
        open={openIngotEditModal}
        onCancel={() => handleCloseModal(false)}
        className={styles.modalStyles}
        footer={[
          <Button key="cancel" onClick={() => handleCloseModal()}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={onFinishDynamic}>
            Save Changes
          </Button>,
        ]}
      >
        <EditForm
          currentForm={currentForm}
          handleInputChange={handleInputChange}
          key={randomKey}
        />
      </Modal>
    </div>
  );
};

export default IngotList;

/**
 * 

  const data = [
    {
      heatId:1,
      heatNumber:"SKL001"
      heatingotId:1
      ingotPiece:100
      ingotPieceRemainig:0
      ingotPieceSold:0
      ingotWeight:10
      ingotWeightRemainig:0
      ingotWeightSold:0
      packingList:[{packingDetailsId: 1, packingId: 1, ingotId: 1, ingotNumber: 90, bagNumber: 1, grossWeight: 99,…},…]
    },
    {
      heatId:1,
      heatNumber:"SKL001"
      heatingotId:1
      ingotPiece:100
      ingotPieceRemainig:0
      ingotPieceSold:0
      ingotWeight:10
      ingotWeightRemainig:0
      ingotWeightSold:0
      packingList: []
    },
    {
      heatId:1,
      heatNumber:"ROT001"
      heatingotId:1
      ingotPiece:100
      ingotPieceRemainig:0
      ingotPieceSold:0
      ingotWeight:10
      ingotWeightRemainig:0
      ingotWeightSold:0
      packingList: []
    }
  ]
  
 */

/**
   
  const data = [
    {
      ingotPiece: 200,
      ingotWeight: 20,
      packingList: [
        {
          grossWeight:10,
          ingotId:495,
          ingotNumber:100,
          packingDetailsId:43
          packingId:33
        },
        {
          grossWeight:10,
          ingotId:495,
          ingotNumber:100,
          packingDetailsId:44
          packingId:34
        }
      ]
    }
  ]


   */

/**
   * 
   * 
   * 
  
  let data = [{
ingotId
: 
495
ingotPiece
: 
200
ingotPieceRemainig
: 
100
ingotPieceSold
: 
100
ingotWeight
: 
20
ingotWeightRemainig
: 
10
ingotWeightSold
: 
10
packingDetailsId
: 
43
packingId
: 
33
  }]
  
   */
