import React from "react";
import styles from "./index.module.css";
import { Tabs } from 'antd';
import FieldList from "../FieldList";
import DraftList from "../DraftList";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: '1',
    label: `PO`,
    children: <FieldList />,
  },
  {
    key: '2',
    label: `Draft`,
    children: <DraftList />,
  },
];

function LocalMainPo() {
  let location = useLocation();
  let navigate = useNavigate();

  const handleData = () => {
    navigate('/purchaseMain')
  }
  return (
    <div className={styles.mainContainer}>
      <h2 style={{ marginBottom: "30px" }}><ArrowLeftOutlined style={{marginRight: '12px', cursor: 'pointer'}} onClick={() => handleData()} />Domestic Purchase Order</h2>
      <Tabs defaultActiveKey={(location?.state?.draftIndex) ? location?.state?.draftIndex+'' : '1'} items={items} onChange={onChange} />
    </div>
  );
}

export default LocalMainPo;
