import { Col, Row } from 'antd'
import React from 'react'
import styles from './index.module.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import India from "../../../Assets/PO/India.png";
import Internation from "../../../Assets/PO/International.png";

function PoIndex() {
    let navigate = useNavigate();

    const goTo = (v) => {
        navigate(v.redirect)
    }
  return (
    <div style={{width: '87%', padding: '20px'}}>
      <div style={{display: 'flex', alignItems: 'center'}}><h2>Purchase Order</h2></div>
      <div style={{marginTop: '30px'}}>
        <Row gutter={[16, 16]}>
          {masterList && masterList.map((v,i) => 
          <Col xs={24} md={14} lg={8} >
              <div className={styles.mainData} onClick={() => goTo(v)}>
                <div>
                  <img src={v?.images} style={{width: '55px', cursor: 'pointer'}}/>
                </div>
                <p>{v.masterName}</p>
              </div>
          </Col>)}
        </Row>
      </div>

      <Outlet />
    </div>
  )
}

export default PoIndex


const masterList = [
    {
        masterName: "Internation Po",
        masterCount: '2',
        redirect: "purchase",
        images: Internation,
    },
    {
        masterName: "Domestic Po",
        masterCount: '2',
        redirect: 'localpo',
        images: India,
    }
]