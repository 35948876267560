import React, { useEffect, useState, useContext, useRef } from "react";
import styles from "./CreatePo.module.css";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { UserCredsContext } from "../../utils/UserCredsContext";
import { api_call_token } from "../../utils/Network";
import ProductsCompo from "./ProductsCompoNew/ProductsCompo";
import { useLocation, useNavigate } from "react-router-dom";
import { alertMessage, getLocalData } from "../../utils/helperFunctions";
import moment from "moment";
import Delete from "../../Assets/Delete.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";

const CreatePoLc = () => {
  let navigate = useNavigate();
  const formRef = useRef();

  const today = moment();
  const { base_items } = useContext(UserCredsContext);
  const { state } = useLocation();
  const [address, setAddress] = useState([]);
  const [products, getProduct] = useState([]);
  const [productsDetails, getProductsDetails] = useState([]);
  const [productListing, setProductList] = useState([]);
  const [authorisedSignatoryName, setAuthorisedSignatoryName] = useState("");
  const [signatoryName, setSignatoryName] = useState("");
  const [poNumber, setPONumber] = useState("");

  const [addressData, setAddressData] = useState(null);
  const [buyerAddress, setBuyerAddress] = useState([]);
  const [shipTobuyerAddress, setshipToBuyerAddress] = useState([]);
  const [buyerAddressID, setBuyerAddressID] = useState(null);
  const [shipToAddressID, setshipToAddressID] = useState(null);
  const [form] = Form.useForm();
  const [draft, setDraft] = useState(1);
  const [poValue, setpoValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statess, setStatess] = useState(0);
  const [errors, setErrors] = useState([]);

  const [totalData, setTotalData] = useState({
    totalProduct: "",
    totalQuantity: "",
    avgamt: "",
    totalAmt: "",
  });
  // useEffect(() => {
  //     if (state?.data?.shipToBuyerAddressId) {
  //         setshipToAddressID(state?.data?.shipToBuyerAddressId)
  //     }
  // }, [state?.data?.shipToBuyerAddressId])

  useEffect(() => {
    setAddressData(null);
  }, [address]);

  useEffect(() => {
    setshipToAddressID(null);
    setStatess(1);
  }, [shipTobuyerAddress]);

  useEffect(() => {
    console.log(state, "STATAT");
    productsAdd();
    setSignatoryName(state?.data?.signatoryCompany);
    if (state?.data?.addressID) {
      setAddressData(state?.data?.addressID);
      handleAdrListNew(state?.data?.supplierID);
      handleAdrLists(state?.data?.addressID);
    }

    if (state?.data?.purchaseOrderNumber) {
      setPONumber(state?.data?.purchaseOrderNumber);
    }

    if (state?.data?.buyerID) {
      handleBuyers(state?.data?.buyerID);
    }
    if (state?.data?.buyerAddressId) {
      setBuyerAddressID(state?.data?.buyerAddressId);
      handleAdrListss(state?.data?.buyerAddressId);
      handleBuyers(state?.data?.buyerID);
    }

    if (state?.data?.shipToBuyerAddressId) {
      handleShipToAdr(state?.data?.shipToBuyerId);
      setshipToAddressID(state?.data?.shipToBuyerAddressId);
      handleAdrr(state?.data?.shipToBuyerAddressId);
    }
  }, [state, buyerAddressID]);

  const handleShipToAdr = (e) => {
    console.log(e);
    api_call_token
      .get(`formData/buyerAddress/${e}`)
      .then((res) => {
        setshipToBuyerAddress(res.data.buyerAddressList);
      })
      .catch((err) => console.log(err));
  };

  // useEffect(() => {
  //     setAddressData(state?.data?.addressID)
  // }, [state?.data?.addressID])

  const handleAdrListss = (e) => {
    console.log(e);
    setBuyerAddressID(e);
  };

  const handleAdrr = (e) => {
    setshipToAddressID(e);
  };
  const handleAdrLists = (e) => {
    console.log(e);
    setAddressData(e);
  };

  useEffect(() => {
    getauthorisedSignatoryName();
    getProd();
    getProducts();
    // getPONumber();
    getPO();
    // getIncoTerm();
  }, []);

  useEffect(() => {
    productSubTotal();
  }, [productListing]);

  // const getIncoTerm = () => {
  //     api_call_token
  //     .get(`/incoterm`)
  //     .then((res) => {
  //         if(res.totalRowCount){
  //             api
  //         }
  //     })
  // }
  const getPO = () => {
    let apiData = "domesticPurchaseOrder";

    api_call_token
      .get(`product/field?search=${apiData}&formtype=create`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setpoValue([...res.data.data]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const productsAdd = () => {
    if (state?.data?.productList) {
      setProductList(state?.data?.productList);
    }
  };

  const getProducts = () => {
    api_call_token
      .get(`product/field?search=purchaseorderproducts`)
      .then((res) => {
        console.log(res.data.data, "DAtassss");
        let data = res.data.data;
        getProductsDetails(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getauthorisedSignatoryName = () => {
    let data = getLocalData("phoolchand-user");
    if(state?.data?.authorisedSignatoryName){
        setAuthorisedSignatoryName(state?.data?.authorisedSignatoryName)
    }else{
        setAuthorisedSignatoryName(`${data?.firstName} ${data?.lastName}`);
    }
  };
  const handleBuyers = (e) => {
    console.log(e);
    api_call_token
      .get(`formData/buyerAddress/${e}`)
      .then((res) => {
        setBuyerAddress(res.data.buyerAddressList);
      })
      .catch((err) => console.log(err));
  };

  const handleBuyerAddress = (e) => {
    getPONumber(e);
  };

  const handleBuyer = (e) => {
    console.log(e, "Data");
    let data = base_items?.buyerList.findIndex((prod) => prod.id == e);
    setSignatoryName(base_items?.buyerList[data]?.name);
    formRef?.current?.resetFields(["buyerAddressId"]);
    // getPONumber(e)

    console.log(e);
    api_call_token
      .get(`formData/buyerAddress/${e}`)
      .then((res) => {
        setBuyerAddressID(null);
        setBuyerAddress(res.data.buyerAddressList);
        // setAddressData(null)
        // setAddress(res.data.supplierAddressList)
      })
      .catch((err) => console.log(err));
  };

  const handleShipTo = (e) => {
    console.log(e, "Data");
    setStatess(1);
    // let data = base_items?.buyerList.findIndex((prod) => prod.id == e)
    // setSignatoryName(base_items?.buyerList[data]?.name)
    formRef?.current?.resetFields(["shipToBuyerAddressId"]);
    // getPONumber(e)

    console.log(e);
    api_call_token
      .get(`formData/buyerAddress/${e}`)
      .then((res) => {
        setshipToAddressID(null);
        setshipToBuyerAddress(res.data.buyerAddressList);
        // setAddressData(null)
        // setAddress(res.data.supplierAddressList)
      })
      .catch((err) => console.log(err));
  };
  const productSubTotal = () => {
    let data = {
      totalProduct: "",
      totalQuantity: "",
      avgamt: "",
      totalAmt: "",
    };
    productListing.map((v, i) => {
      data.totalProduct = i + 1;
      data.totalQuantity = +data.totalQuantity + +v.quantity;
      data.avgamt = +data.avgamt + +v.pricePerUnit;
      data.totalAmt = +data.totalAmt + +v.quantity * v.pricePerUnit;
    });

    setTotalData(data);
  };

  const getPONumber = (e) => {
    api_call_token.get(`domesticPurchaseOrder/po/${e}`).then((res) => {
      console.log(res, "daat");
      let data = res.data.purchaseOrderNumber;
      setPONumber(data);
    });
  };

  const getProd = () => {
    api_call_token
      .get(`product`)
      .then((res) => {
        if (res.data.totalRowCount) {
          setProductLists(res?.data?.totalRowCount);
        } else {
          getProduct(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setProductLists = (v) => {
    api_call_token
      .get(`product?size=${v}`)
      .then((res) => {
        getProduct(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleArray = (v) => {
    console.log(v.productName);
    const index = productListing.findIndex(
      (obj) => obj.productName === v.productName
    );
    if (index !== -1) {
      productListing.splice(index, 1);
      setProductList([...productListing]);
    }
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleAdrListNew = (e) => {
    console.log(e, "DataS");
    formRef?.current?.resetFields(["addressID"]);

    console.log(e);
    api_call_token
      .get(`formData/supplierAddress/${e}`)
      .then((res) => {
        setAddress(res.data.supplierAddressList);
      })
      .catch((err) => console.log(err));
  };

  const handleAdrList = (e) => {
    api_call_token
      .get(`supplier/isblacklist/${e}`)
      .then((res) => {
        if(res.status == 200){
            if(res.data.isBlackList){
                alertMessage('This Supplier has been Black Listed', 2)
            }
        }
      })
      .catch((err) => console.log(err))
    formRef?.current?.resetFields(["addressID"]);
    console.log(e, addressData, "Inner");
    setAddressData(null);
    api_call_token
      .get(`formData/supplierAddress/${e}`)
      .then((res) => {
        setAddressData(null);
        setAddress(res.data.supplierAddressList);
      })
      .catch((err) => console.log(err));
  };

  const handleData = (values) => {
    // debugger;
    console.log(values, "Demos");

    let dateString = values?.purchaseOrderDate?._i
      ? values?.purchaseOrderDate?._i
      : moment(values.purchaseOrderDate?.$d).format("YYYY-MM-DD");
    let finalShipment = values?.shipmentDate?._i
      ? values?.shipmentDate?._i
      : moment(values?.shipmentDate?.$d).format("YYYY-MM-DD");
    let deliveryDate = values?.deliveryDate?._i
      ? values?.deliveryDate?._i
      : moment(values?.deliveryDate?.$d).format("YYYY-MM-DD");

    const { purchaseOrderDate, shipmentDate, active, ...rest } = values;
    let place = "";
    if (values?.placeOfDeliveryId) {
      place =
        base_items?.indianCityList[
          base_items?.indianCityList.findIndex(
            (prod) => prod?.id == values?.placeOfDeliveryId
          )
        ]?.name;
    }

    let datas = {
      ...rest,
      purchaseOrderDate: dateString,
      shipmentDate: finalShipment,
      deliveryDate: deliveryDate,
      placeOfDelivery: place,
      productList: productListing,
      totalProducts: totalData?.totalProduct,
      totalAmount: totalData?.totalAmt,
      totalQuantity: totalData?.totalQuantity,
      avgPricePerUnit: totalData?.avgamt,
      authorisedSignatoryName: authorisedSignatoryName,
      signatoryCompany: signatoryName,
      structureList: [],
      purchaseOrderNumber: poNumber,
    };

    console.log(datas, "DATAssssssssssssssssss");
    if (state?.addPO == "1") {
      if (draft == 3) {
        if (productListing.length > 0) {
          let dataArr = [];
          let objArr = {};
          poValue.map((v, i) => {
            if (v.required) {
              dataArr.push(v.apiName);
              objArr[v.apiName] = v.label;
            }
          });
          console.log(dataArr, objArr, "dataArrss");

          let Arr = [];
          for (let i = 0; i < dataArr.length; i++) {
            const property = dataArr[i];
            if (!datas[property]) {
              Arr.push(objArr[property]);
            }
          }
          setErrors(Arr);

          let isDataValid = true;
          for (let i = 0; i < dataArr.length; i++) {
            const property = dataArr[i];
            if (!datas[property]) {
              isDataValid = false;
              alertMessage("Please add all field in po ", 2);
              return;
            }
          }
        } else {
          alertMessage("Product may not be empty", 2);
          return;
        }
      }
    } else {
      if (draft == 2) {
      } else {
        if (productsDetails.length <= 0) {
        } else {
          if (productListing.length > 0) {
            let dataArr = [];
            let objArr = {};
            poValue.map((v, i) => {
              if (v.required) {
                dataArr.push(v.apiName);
                objArr[v.apiName] = v.label;
              }
            });
            // setErrors(dataArr)

            console.log(dataArr, objArr, "dataArr");
            // debugger;
            let Arr = [];
            for (let i = 0; i < dataArr.length; i++) {
              const property = dataArr[i];
              if (!datas[property]) {
                Arr.push(objArr[property]);
              }
            }
            setErrors(Arr);
            let isDataValid = true;
            for (let i = 0; i < dataArr.length; i++) {
              const property = dataArr[i];
              if (!datas[property]) {
                isDataValid = false;
                alertMessage("Please add all field in po ", 2);
                return;
              }
            }
          } else {
            alertMessage("Product may not be empty", 2);
            return;
          }
        }
      }
    }

    let apiEnd = "domesticPurchaseOrder";

    if (draft == 2) {
      apiEnd = "domesticdraft";
    } else if (state?.selectDraft == "draft") {
      apiEnd = "domesticdraft";
    }

    if (draft == 3) {
      setIsLoading(true);
      api_call_token
        .get(`domesticPurchaseOrder/po/${datas?.buyerAddressId}`)
        .then((res) => {
          console.log(res, "daat");
          let data = res.data.purchaseOrderNumber;
          datas.purchaseOrderNumber = data;
          if (data) {
            api_call_token.post(`/domesticPurchaseOrder`, datas).then((res) => {
              if (res.status == 200 || res.status == 201) {
                alertMessage("PO Added ");
                setDraft(1);
                deleteDraft(state?.id);
                setTimeout(() => {
                  setIsLoading(false);
                  navigate(`/purchaseMain/localpo`, {
                    state: { draftIndex: 1 },
                  });
                }, 2000);
              }
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      const filteredData = Object.entries(datas)
        .filter(([key, value]) => !!value)
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

      if (state?.id) {
        setIsLoading(true);
        console.log(filteredData);
        if (draft == 2) {
          if (!filteredData.hasOwnProperty("buyerAddressId")) {
            api_call_token
              .patch(`/${apiEnd}/${state?.id}`, { ...filteredData, active: 1 })
              .then((res) => {
                if (
                  res.status == 200 ||
                  res.status == 201 ||
                  res.status == 204
                ) {
                  alertMessage("PO Updated ");
                  setDraft(1);
                  setTimeout(() => {
                    // navigate('/purchaseMain/localpo')
                    setIsLoading(false);
                    navigate("/purchaseMain/localpo", {
                      state: { draftIndex: 2 },
                    });
                  }, 2000);
                }
              })
              .catch((err) => {
                setIsLoading(false);
              });
          } else {
            if (state?.data?.poNumber) {
              setIsLoading(true);
              api_call_token
                .patch(`/${apiEnd}/${state?.id}`, {
                  ...filteredData,
                  poNumber: state?.data?.poNumber || 0,
                  active: 1,
                })
                .then((res) => {
                  if (
                    res.status == 200 ||
                    res.status == 201 ||
                    res.status == 204
                  ) {
                    alertMessage("PO draft Updated ");
                    setDraft(1);
                    setTimeout(() => {
                      setIsLoading(false);

                      // navigate('/purchaseMain/localpo')
                      navigate("/purchaseMain/localpo", {
                        state: { draftIndex: 2 },
                      });
                    }, 2000);
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                });
            } else {
              setIsLoading(true);

              api_call_token
                // .get(`domesticPurchaseOrder/D/${filteredData?.buyerID}`)
                .get(`domesticPurchaseOrder/D/${filteredData?.buyerAddressId}`)
                .then((res) => {
                  console.log(res, "daat");
                  let data = res.data.purchaseOrderNumber;
                  let poNums = res.data.poNumber;
                  filteredData.purchaseOrderNumber = data;
                  filteredData.poNumber = poNums;
                  if (data) {
                    api_call_token
                      .patch(`/${apiEnd}/${state?.id}`, {
                        ...filteredData,
                        active: 1,
                      })
                      .then((res) => {
                        if (
                          res.status == 200 ||
                          res.status == 201 ||
                          res.status == 204
                        ) {
                          alertMessage("PO draft Updated ");
                          setDraft(1);
                          setTimeout(() => {
                            setIsLoading(false);

                            // navigate('/purchaseMain/localpo')
                            navigate("/purchaseMain/localpo", {
                              state: { draftIndex: 2 },
                            });
                          }, 2000);
                        }
                      })
                      .catch((err) => {
                        setIsLoading(false);
                      });
                  }
                });
            }
          }
        } else {
          setIsLoading(true);
          api_call_token
            .patch(`/${apiEnd}/${state?.id}`, { ...filteredData, active: 1 })
            .then((res) => {
              if (res.status == 200 || res.status == 201 || res.status == 204) {
                alertMessage("PO Updated ");
                setDraft(1);
                setTimeout(() => {
                  setIsLoading(false);

                  // navigate('/purchaseMain/localpo')
                  navigate("/purchaseMain/localpo", {
                    state: { draftIndex: 1 },
                  });
                }, 2000);
              }
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
      } else {
        if (draft == 2) {
          if (!filteredData.hasOwnProperty("buyerAddressId")) {
            setIsLoading(true);
            api_call_token
              .post(`/${apiEnd}`, filteredData)
              .then((res) => {
                if (res.status == 200 || res.status == 201) {
                  if (draft == 1) {
                    alertMessage("PO added ");
                  } else {
                    alertMessage("Po added to draft ");
                  }
                  setDraft(1);
                  setTimeout(() => {
                    setIsLoading(false);

                    if (draft == 1) {
                      navigate("/purchaseMain/localpo", {
                        state: { draftIndex: 1 },
                      });
                    } else {
                      navigate("/purchaseMain/localpo", {
                        state: { draftIndex: 2 },
                      });
                    }
                  }, 2000);
                }
              })
              .catch((err) => {
                setIsLoading(false);
              });
          } else {
            setIsLoading(true);

            api_call_token
              .get(`domesticPurchaseOrder/D/${filteredData?.buyerAddressId}`)
              .then((res) => {
                console.log(res, "daat");
                let data = res.data.purchaseOrderNumber;
                let poNums = res.data.poNumber;
                filteredData.purchaseOrderNumber = data;
                filteredData.poNumber = poNums;
                if (data) {
                  console.log(filteredData, "ff");
                  setIsLoading(true);
                  api_call_token
                    .post(`/${apiEnd}`, filteredData)
                    .then((res) => {
                      if (res.status == 200 || res.status == 201) {
                        if (draft == 1) {
                          alertMessage("PO added ");
                        } else {
                          alertMessage("Po added to draft ");
                        }
                        setDraft(1);
                        setTimeout(() => {
                          setIsLoading(false);

                          if (draft == 1) {
                            navigate("/purchaseMain/localpo", {
                              state: { draftIndex: 1 },
                            });
                          } else {
                            navigate("/purchaseMain/localpo", {
                              state: { draftIndex: 2 },
                            });
                          }
                        }, 2000);
                      }
                    })
                    .catch((err) => {
                      setIsLoading(false);
                    });
                }
              });
          }
        } else {
          setIsLoading(true);
          api_call_token
            .post(`/${apiEnd}`, filteredData)
            .then((res) => {
              if (res.status == 200 || res.status == 201) {
                if (draft == 1) {
                  alertMessage("PO added ");
                } else {
                  alertMessage("Po added to draft ");
                }
                setDraft(1);
                setTimeout(() => {
                  setIsLoading(false);

                  if (draft == 1) {
                    navigate("/purchaseMain/localpo", {
                      state: { draftIndex: 1 },
                    });
                  } else {
                    navigate("/purchaseMain/localpo", {
                      state: { draftIndex: 2 },
                    });
                  }
                }, 2000);
              }
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
        console.log(filteredData);
      }
    }

    console.log(datas, "Demos");
  };

  const deleteDraft = (v) => {
    api_call_token
      .delete(`domesticdraft/${v}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const handleChanges = (e) => {

      setAuthorisedSignatoryName(e.target.value)
  }
  console.log(poNumber, addressData, buyerAddressID, "SSSSSSSSSS");
  console.log(errors, "errors");

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <ArrowLeftOutlined
          onClick={() => navigate(-1)}
          style={{ marginRight: "10px" }}
        />
      </div>

      <div className={styles.POMain}>
        <h2 className={styles.headerTitle}>PURCHASE ORDER</h2>
        <div>
          {console.log("demo")}
          {
            <Form
              form={form}
              ref={formRef}
              onFinish={handleData}
              initialValues={{
                ...state?.data,
                // purchaseOrderNumber: state?.data?.purchaseOrderNumber ? state?.data?.purchaseOrderNumber : poNumber,
                poNumber: state?.data?.poNumber ? state?.data?.poNumber : 0,
                countryOfOrigin: state?.data?.countryOfOrigin
                  ? state?.data?.countryOfOrigin
                  : 99,
                authorisedSignatoryName: authorisedSignatoryName,
                status: state?.data?.status ? state?.data.status : 1,
                purchaseOrderDate: state?.data?.purchaseOrderDate
                  ? moment(
                      state?.data?.purchaseOrderDate.split("T")[0],
                      "YYYY-MM-DD"
                    )
                  : moment(today, "YYYY-MM-DD"),
                shipmentDate: state?.data?.shipmentDate
                  ? moment(
                      state?.data?.shipmentDate.split("T")[0],
                      "YYYY-MM-DD"
                    )
                  : undefined,
                deliveryDate: state?.data?.deliveryDate
                  ? moment(
                      state?.data?.deliveryDate.split("T")[0],
                      "YYYY-MM-DD"
                    )
                  : undefined,
                purchaseOrderNumber: state?.data?.purchaseOrderNumber
                  ? state?.data?.purchaseOrderNumber
                  : poNumber,
                currencyID: 1,
                addressID: addressData ? +addressData : null,
                buyerAddressId: buyerAddressID
                  ? +buyerAddressID
                  : state?.data?.buyerAddressId
                  ? state?.data?.buyerAddressId
                  : null,
                shipToBuyerAddressId:
                  shipToAddressID || statess
                    ? +shipToAddressID
                    : state?.data?.shipToBuyerAddressId
                    ? state?.data?.shipToBuyerAddressId
                    : null,
              }}
            >
              <div className={styles.mainContainer}>
                <div>
                  <div className={styles.InputFiledCon}>
                    <div
                      className={`${styles.purchaseOrderLeft} ${styles.borderRight}  ${styles.boderBottom}`}
                    >
                      <span>Purchase Order</span>
                      <Input bordered={false} value={poNumber} />
                      {/* <Form.Item
                                                        name="purchaseOrderNumber"
                                                    >
                                                        <Input
                                                            bordered={false}
                                                        />
                                                    </Form.Item> */}
                    </div>

                    <div
                      className={`${styles.purchaseOrderRight} ${styles.borderRight} ${styles.boderBottom} ${styles.paddingten}`}
                    >
                      <span>Purchase Order Date</span>
                      <Form.Item
                        name="purchaseOrderDate"

                        // rules={[
                        //     {
                        //         required: val?.required,
                        //         message: `Please Enter ${val?.label}!`,
                        //     },
                        // ]}
                      >
                        <DatePicker
                          onChange={onChange}
                          // showTime
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                          bordered={false}
                        />
                      </Form.Item>
                    </div>
                    <div
                      className={`${styles.purchaseOrderRight} ${styles.borderRight} ${styles.boderBottom} ${styles.paddingten}`}
                    >
                      <span>Dispatch Date</span>
                      <Form.Item
                        name="shipmentDate"

                        // rules={[
                        //     {
                        //         required: val?.required,
                        //         message: `Please Enter ${val?.label}!`,
                        //     },
                        // ]}
                      >
                        <DatePicker
                          onChange={onChange}
                          // showTime
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                          bordered={false}
                        />
                      </Form.Item>
                    </div>

                    <div
                      className={`${styles.purchaseOrderRight} ${styles.purchaseOrderLeft} ${styles.borderRight} ${styles.boderBottom} ${styles.paddingten}`}
                    >
                      <span>Agent</span>
                      <Form.Item
                        name="agentID"
                        // rules={[
                        //     {
                        //         required: val?.required,
                        //         message: `Please Enter ${val?.label}!`,
                        //     },
                        // ]}
                      >
                        {base_items && (
                          <Select
                            showSearch
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            bordered={false}
                            options={base_items.agentList?.map((obj) => {
                              const keys = Object.keys(obj);
                              const convertedObj = {
                                value: obj[keys[0]],
                                label: obj[keys[1]],
                              };

                              for (let i = 2; i < keys.length; i++) {
                                convertedObj[keys[i]] = obj[keys[i]];
                              }

                              return convertedObj;
                            })}
                          />
                        )}
                      </Form.Item>
                    </div>
                    <div
                      className={`${styles.purchaseOrderRight} ${styles.purchaseOrderLeft} ${styles.boderBottom} ${styles.paddingten}`}
                    >
                      <span>Delivery Date</span>
                      <Form.Item
                        name="deliveryDate"

                        // rules={[
                        //     {
                        //         required: val?.required,
                        //         message: `Please Enter ${val?.label}!`,
                        //     },
                        // ]}
                      >
                        <DatePicker
                          onChange={onChange}
                          // showTime
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                          bordered={false}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className={styles.inputFieldWrapper}>
                  <div className={styles.InputFiledCon}>
                    <div
                      className={`${styles.leftCon} ${styles.borderRight}  ${styles.boderBottom} ${styles.paddingten}  ${styles.borderBox}  `}
                    >
                      <div className={styles.inputFieldCon}>
                        <span>Bill To</span>

                        <Form.Item
                          name="buyerID"
                          // rules={[
                          //     {
                          //         required: val?.required,
                          //         message: `Please Enter ${val?.label}!`,
                          //     },
                          // ]}
                        >
                          {base_items && (
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              allowClear
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={handleBuyer}
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              bordered={false}
                              disabled={
                                state?.data?.buyerAddressId ? true : false
                              }
                              options={base_items.buyerList?.map((obj) => {
                                const keys = Object.keys(obj);
                                const convertedObj = {
                                  value: obj[keys[0]],
                                  label: obj[keys[1]],
                                };

                                for (let i = 2; i < keys.length; i++) {
                                  convertedObj[keys[i]] = obj[keys[i]];
                                }

                                return convertedObj;
                              })}
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div className={styles.inputFieldCon}>
                        <span>Buyer Address</span>
                        <Form.Item
                          name="buyerAddressId"
                          // rules={[
                          //     {
                          //         required: val?.required,
                          //         message: `Please Enter ${val?.label}!`,
                          //     },
                          // ]}
                        >
                          {base_items && (
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              onChange={handleBuyerAddress}
                              bordered={false}
                              disabled={
                                state?.data?.buyerAddressId ? true : false
                              }
                              options={buyerAddress?.map((obj) => {
                                const keys = Object.keys(obj);
                                const convertedObj = {
                                  value: obj[keys[0]],
                                  label: obj[keys[1]],
                                };

                                for (let i = 2; i < keys.length; i++) {
                                  convertedObj[keys[i]] = obj[keys[i]];
                                }

                                return convertedObj;
                              })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                    <div
                      className={`${styles.leftCon} ${styles.borderRight}  ${styles.boderBottom} ${styles.paddingten}  ${styles.borderBox}  `}
                    >
                      <div className={styles.inputFieldCon}>
                        <span>Ship To</span>

                        <Form.Item
                          name="shipToBuyerId"
                          // rules={[
                          //     {
                          //         required: val?.required,
                          //         message: `Please Enter ${val?.label}!`,
                          //     },
                          // ]}
                        >
                          {base_items && (
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              allowClear
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={handleShipTo}
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              bordered={false}
                              // disabled={state?.data?.buyerAddressId ? true : false}
                              options={base_items.buyerList?.map((obj) => {
                                const keys = Object.keys(obj);
                                const convertedObj = {
                                  value: obj[keys[0]],
                                  label: obj[keys[1]],
                                };

                                for (let i = 2; i < keys.length; i++) {
                                  convertedObj[keys[i]] = obj[keys[i]];
                                }

                                return convertedObj;
                              })}
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div className={styles.inputFieldCon}>
                        <span>Ship To Address</span>
                        <Form.Item
                          name="shipToBuyerAddressId"
                          // rules={[
                          //     {
                          //         required: val?.required,
                          //         message: `Please Enter ${val?.label}!`,
                          //     },
                          // ]}
                        >
                          {base_items && (
                            <Select
                              showSearch
                              allowClear
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              bordered={false}
                              options={shipTobuyerAddress?.map((obj) => {
                                const keys = Object.keys(obj);
                                const convertedObj = {
                                  value: obj[keys[0]],
                                  label: obj[keys[1]],
                                };

                                for (let i = 2; i < keys.length; i++) {
                                  convertedObj[keys[i]] = obj[keys[i]];
                                }

                                return convertedObj;
                              })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.InputFiledCon}>
                  <div
                    className={`${styles.leftCon} ${styles.borderRight} ${styles.paddingten} ${styles.borderBox}`}
                  >
                    <div className={styles.inputFieldCon}>
                      <span>Supplier</span>
                      <Form.Item
                        name="supplierID"
                        // rules={[
                        //     {
                        //         required: val?.required,
                        //         message: `Please Enter ${val?.label}!`,
                        //     },
                        // ]}
                      >
                        {base_items && (
                          <Select
                            showSearch
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            bordered={false}
                            onChange={handleAdrList}
                            options={base_items.supplierList?.map((obj) => {
                              const keys = Object.keys(obj);
                              const convertedObj = {
                                value: obj[keys[0]],
                                label: obj[keys[1]],
                              };

                              for (let i = 2; i < keys.length; i++) {
                                convertedObj[keys[i]] = obj[keys[i]];
                              }

                              return convertedObj;
                            })}
                          />
                        )}
                      </Form.Item>
                    </div>

                    <div className={styles.inputFieldCon}>
                      <span>Supplier Address</span>
                      <Form.Item
                        name="addressID"
                        // rules={[
                        //     {
                        //         required: val?.required,
                        //         message: `Please Enter ${val?.label}!`,
                        //     },
                        // ]}
                      >
                        {base_items && (
                          <Select
                            showSearch
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            bordered={false}
                            options={address?.map((obj) => {
                              const keys = Object.keys(obj);
                              const convertedObj = {
                                value: obj[keys[0]],
                                label: obj[keys[1]],
                              };

                              for (let i = 2; i < keys.length; i++) {
                                convertedObj[keys[i]] = obj[keys[i]];
                              }

                              return convertedObj;
                            })}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className={`${styles.rightCon} `}>
                    <div className={`${styles.borderBox}`}>
                      <div
                        className={` ${styles.InputFiledCon} ${styles.CountryFields}`}
                      >
                        <div
                          className={`${styles.purchaseOrderLeft} ${styles.borderRight}  ${styles.innerFields}`}
                        >
                          <span>Country of Origin of Goods</span>
                          <Form.Item
                            name="countryOfOrigin"
                            // rules={[
                            //     {
                            //         required: val?.required,
                            //         message: `Please Enter ${val?.label}!`,
                            //     },
                            // ]}
                          >
                            {base_items && (
                              <Select
                                showSearch
                                allowClear
                                style={{
                                  maxWidth: "250px",
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                // disabled
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                bordered={false}
                                options={base_items.countryList?.map((obj) => {
                                  const keys = Object.keys(obj);
                                  const convertedObj = {
                                    value: obj[keys[0]],
                                    label: obj[keys[1]],
                                  };

                                  for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                  }

                                  return convertedObj;
                                })}
                              />
                            )}
                          </Form.Item>
                        </div>

                        <div
                          className={`${styles.purchaseOrderRight} ${styles.paddingten}  ${styles.innerFields}`}
                        >
                          <span>Place of Delivery</span>
                          <Form.Item
                            name="placeOfDeliveryId"
                            // rules={[
                            //     {
                            //         required: val?.required,
                            //         message: `Please Enter ${val?.label}!`,
                            //     },
                            // ]}
                          >
                            {base_items && (
                              <Select
                                showSearch
                                allowClear
                                style={{
                                  maxWidth: "250px",
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                bordered={false}
                                options={base_items.indianCityList?.map(
                                  (obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj[keys[0]],
                                      label: obj[keys[1]],
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  }
                                )}
                              />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    <div className={`${styles.borderTop} ${styles.borderBox}`}>
                      <div
                        className={` ${styles.InputFiledCon} ${styles.CountryFields}`}
                      >
                        <div
                          className={`${styles.purchaseOrderLeft} ${styles.borderRight}  ${styles.innerFields}`}
                        >
                          <span>Insurance</span>
                          <Form.Item
                            name="insuranceId"
                            // rules={[
                            //     {
                            //         required: val?.required,
                            //         message: `Please Enter ${val?.label}!`,
                            //     },
                            // ]}
                          >
                            {base_items && (
                              <Select
                                showSearch
                                allowClear
                                style={{
                                  maxWidth: "250px",
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                // disabled
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                bordered={false}
                                options={base_items.insuranceMasterList?.map(
                                  (obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj[keys[0]],
                                      label: obj[keys[1]],
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  }
                                )}
                              />
                            )}
                          </Form.Item>
                        </div>

                        <div
                          className={`${styles.purchaseOrderRight} ${styles.paddingten}  ${styles.innerFields}`}
                        >
                          <span>Transporter</span>
                          <Form.Item
                            name="transporterId"
                            // rules={[
                            //     {
                            //         required: val?.required,
                            //         message: `Please Enter ${val?.label}!`,
                            //     },
                            // ]}
                          >
                            {base_items && (
                              <Select
                                showSearch
                                allowClear
                                style={{
                                  maxWidth: "250px",
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                bordered={false}
                                options={base_items.transporterMasterList?.map(
                                  (obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj[keys[0]],
                                      label: obj[keys[1]],
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  }
                                )}
                              />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={` ${styles.borderTop}  ${styles.paddingten}  ${styles.innerFields}`}
                >
                  <div className={`${styles.purchaseOrderRight}`}>
                    <span>Terms of Payment</span>
                    <Form.Item name="methodOfPayment">
                      {base_items && (
                        <Select
                          showSearch
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          // filterSort={(optionA, optionB) =>
                          //     (optionA?.label ?? "")
                          //         .toLowerCase()
                          //         .localeCompare(
                          //             (optionB?.label ?? "").toLowerCase()
                          //         )
                          // }
                          bordered={false}
                          options={base_items.paymentMethodList?.map((obj) => {
                            const keys = Object.keys(obj);
                            const convertedObj = {
                              value: obj[keys[0]],
                              label: obj[keys[1]],
                            };

                            for (let i = 2; i < keys.length; i++) {
                              convertedObj[keys[i]] = obj[keys[i]];
                            }

                            return convertedObj;
                          })}
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>
                {/* <div className={`${styles.InputFiledCon} ${styles.borderTop}  `} >
                                
                                <div className={`${styles.leftCon}  ${styles.borderRight} ${styles.InputFiledCon} `}>
                                    <div className={`${styles.purchaseOrderLeft} ${styles.borderRight}  ${styles.boderBottom}`}>
                                        <span>
                                            Method of Dispatch
                                        </span>
                                        <Form.Item
                                            name="dispatchMethodID"
                                        >
                                            {base_items && <Select
                                                showSearch
                                                style={{
                                                    width: "100%",
                                                }}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                }
                                                bordered={false}

                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? "")
                                                        .toLowerCase()
                                                        .localeCompare(
                                                            (optionB?.label ?? "").toLowerCase()
                                                        )
                                                }
                                                options={
                                                    base_items.dispatchMethodList?.map(
                                                        (obj) => {
                                                            const keys = Object.keys(obj);
                                                            const convertedObj = {
                                                                value: obj[keys[0]],
                                                                label: obj[keys[1]],
                                                            };

                                                            for (let i = 2; i < keys.length; i++) {
                                                                convertedObj[keys[i]] = obj[keys[i]];
                                                            }

                                                            return convertedObj;
                                                        }
                                                    )
                                                }
                                            />}
                                        </Form.Item>
                                    </div>

                                    <div className={`${styles.purchaseOrderRight} ${styles.boderBottom} ${styles.paddingten} `}>
                                        <span>
                                            Type of Shipment
                                        </span>
                                        <Form.Item
                                            name="shipmentTypeID"
                                        >
                                            {base_items && <Select
                                                showSearch
                                                style={{
                                                    width: "100%",
                                                }}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                }
                                                bordered={false}

                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? "")
                                                        .toLowerCase()
                                                        .localeCompare(
                                                            (optionB?.label ?? "").toLowerCase()
                                                        )
                                                }
                                                options={
                                                    base_items.shipmentTypeList?.map(
                                                        (obj) => {
                                                            const keys = Object.keys(obj);
                                                            const convertedObj = {
                                                                value: obj[keys[0]],
                                                                label: obj[keys[1]],
                                                            };

                                                            for (let i = 2; i < keys.length; i++) {
                                                                convertedObj[keys[i]] = obj[keys[i]];
                                                            }

                                                            return convertedObj;
                                                        }
                                                    )
                                                }
                                            />}
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className={`${styles.leftCon}  ${styles.borderRight} ${styles.InputFiledCon} `}>
                                    <div className={`${styles.purchaseOrderLeft} ${styles.borderRight}  ${styles.boderBottom}`}>
                                        <span>
                                            Port Of Discharge
                                        </span>
                                        <Form.Item
                                            name="portOfDispatch"
                                        >
                                            {base_items && <Select
                                                showSearch
                                                style={{
                                                    width: "100%",
                                                }}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? "")
                                                        .toLowerCase()
                                                        .localeCompare(
                                                            (optionB?.label ?? "").toLowerCase()
                                                        )
                                                }
                                                bordered={false}

                                                options={
                                                    base_items.portList?.map(
                                                        (obj) => {
                                                            const keys = Object.keys(obj);
                                                            const convertedObj = {
                                                                value: obj[keys[0]],
                                                                label: obj[keys[1]],
                                                            };

                                                            for (let i = 2; i < keys.length; i++) {
                                                                convertedObj[keys[i]] = obj[keys[i]];
                                                            }

                                                            return convertedObj;
                                                        }
                                                    )
                                                }
                                            />}
                                        </Form.Item>
                                    </div>

                                    <div className={`${styles.purchaseOrderRight} ${styles.boderBottom} ${styles.paddingten} `}>
                                        <span>
                                            Port Of Final Destination
                                        </span>
                                        <Form.Item
                                            name="portOfFinalDestination"
                                        >
                                            {base_items && <Select
                                                showSearch
                                                style={{
                                                    width: "100%",
                                                }}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                }
                                                bordered={false}

                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? "")
                                                        .toLowerCase()
                                                        .localeCompare(
                                                            (optionB?.label ?? "").toLowerCase()
                                                        )
                                                }
                                                options={
                                                    base_items.portList?.map(
                                                        (obj) => {
                                                            const keys = Object.keys(obj);
                                                            const convertedObj = {
                                                                value: obj[keys[0]],
                                                                label: obj[keys[1]],
                                                            };

                                                            for (let i = 2; i < keys.length; i++) {
                                                                convertedObj[keys[i]] = obj[keys[i]];
                                                            }

                                                            return convertedObj;
                                                        }
                                                    )
                                                }
                                            />}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div> */}

                <div>
                  <div>
                    {products.length > 0 && (
                      <ProductsCompo
                        products={products}
                        productsDetails={productsDetails}
                        setProductList={setProductList}
                        productListing={productListing}
                      />
                    )}
                  </div>
                  <div>
                    {Array.isArray(productListing) &&
                      productListing.length > 0 &&
                      productListing?.map((v, i) => (
                        <div
                          className={`${styles.prodData} ${styles.demoBorder}`}
                        >
                          <Row>
                            <Col xs={24} md={14} lg={5}>
                              <Tooltip title={v.productName}>
                                <div
                                  className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                                  style={{ width: "100%", height: "100%" }}
                                >
                                  <div>
                                    {v.productName}
                                    <p
                                      style={{
                                        margin: "2px 2px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {v?.productSpecification}
                                    </p>
                                  </div>
                                </div>
                              </Tooltip>
                            </Col>
                            <Col xs={24} md={14} lg={2}>
                              <div
                                className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div>{v.quantity}</div>
                              </div>
                            </Col>
                            <Col xs={24} md={14} lg={2}>
                              <div
                                className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div>
                                  {base_items?.unitOfMeasureList[
                                    base_items?.unitOfMeasureList.findIndex(
                                      (prod) => prod?.id == v?.unitOfMeasurement
                                    )
                                  ]?.name || "-"}
                                </div>
                              </div>
                            </Col>
                            <Col xs={24} md={14} lg={2}>
                              <div
                                className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div>{v.hsnCode}</div>
                              </div>
                            </Col>
                            <Col xs={24} md={14} lg={2}>
                              <div
                                className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div>{v.pricePerUnit}</div>
                              </div>
                            </Col>
                            <Col xs={24} md={14} lg={2}>
                              <div
                                className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div>{v?.note || "-"}</div>
                              </div>
                            </Col>
                            <Col xs={24} md={14} lg={3}>
                              <div
                                className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div>{v?.packing || "-"}</div>
                              </div>
                            </Col>
                            <Col xs={24} md={14} lg={2}>
                              <div
                                className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div>{v?.goodsDetail || "-"}</div>
                              </div>
                            </Col>
                            <Col xs={24} md={14} lg={2}>
                              <div
                                className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div>{v.quantity * v.pricePerUnit}</div>
                              </div>
                            </Col>
                            <Col xs={24} md={14} lg={2}>
                              <div
                                onClick={() => handleArray(v)}
                                className={`${styles.deleteBox} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                              >
                                <img
                                  src={Delete}
                                  alt="delte"
                                  style={{ width: "20px" }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </div>

                  <div style={{ marginTop: "60px" }}>
                    <Row>
                      <Col xs={24} md={14} lg={5}>
                        <div
                          className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                          style={{ width: "100%", height: "100%" }}
                        >
                          <span className={styles.headtext}>
                            Total Products
                          </span>
                          <div
                            style={{
                              textAlign: "left",
                              margin: "10px 0",
                              fontWeight: "bold",
                            }}
                          >
                            {totalData?.totalProduct || 0}
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} md={14} lg={6}>
                        <div
                          className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                          style={{ width: "100%", height: "100%" }}
                        >
                          <span className={styles.headtext}>
                            Total Quantity
                          </span>
                          <div
                            style={{
                              textAlign: "left",
                              margin: "10px 0",
                              fontWeight: "bold",
                            }}
                          >
                            {totalData?.totalQuantity || 0}
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} md={14} lg={9}>
                        <div
                          className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`}
                          style={{ width: "100%", height: "100%" }}
                        >
                          <span className={styles.headtext}>Avg Amount</span>
                          <div
                            style={{
                              textAlign: "left",
                              margin: "10px 0",
                              fontWeight: "bold",
                            }}
                          >
                            {totalData?.avgamt || 0}
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} md={14} lg={4}>
                        <div
                          className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderTop}`}
                          style={{ width: "100%", height: "100%" }}
                        >
                          <span className={styles.headtext}>Total</span>
                          <div
                            style={{
                              textAlign: "left",
                              margin: "10px 0",
                              fontWeight: "bold",
                            }}
                          >
                            {totalData?.totalAmt || 0}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div
                  className={`${styles.InputFiledCon} ${styles.borderTop}  `}
                >
                  <div className={`${styles.leftCon}`}>
                    <div
                      className={` ${styles.paddingten} ${styles.borderBox} ${styles.boderBottom}`}
                    >
                      <span>Purchase Instructions</span>
                      <Form.Item
                        name="remarks"
                        // rules={[
                        //     {
                        //         required: val?.required,
                        //         message: `Please Enter ${val?.label}!`,
                        //     },
                        // ]}
                      >
                        <Input bordered={false} />
                      </Form.Item>
                      <div></div>
                    </div>
                    <div
                      className={` ${styles.paddingten} ${styles.borderBox}`}
                    >
                      <span>Terms and Condition</span>
                      <Form.Item
                        name="termsConditions"
                        // rules={[
                        //     {
                        //         required: val?.required,
                        //         message: `Please Enter ${val?.label}!`,
                        //     },
                        // ]}
                      >
                        {base_items && (
                          <Select
                            showSearch
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            bordered={false}
                            options={base_items.termsList?.map((obj) => {
                              const keys = Object.keys(obj);
                              const convertedObj = {
                                value: obj[keys[0]],
                                label: obj[keys[1]],
                              };

                              for (let i = 2; i < keys.length; i++) {
                                convertedObj[keys[i]] = obj[keys[i]];
                              }

                              return convertedObj;
                            })}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className={`${styles.leftCon}`}>
                    <div className={styles.InputFiledCon}>
                      <div
                        className={`${styles.inputFieldCon}  ${styles.paddingten} ${styles.borderRight} ${styles.borderLeft} ${styles.boderBottom}`}
                      >
                        <span>Incoterm 2020</span>
                        <Form.Item
                          name="incoterms2020"
                          // rules={[
                          //     {
                          //         required: val?.required,
                          //         message: `Please Enter ${val?.label}!`,
                          //     },
                          // ]}
                        >
                          {base_items && (
                            <Select
                              showSearch
                              allowClear
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              // filterSort={(optionA, optionB) =>
                              //     (optionA?.label ?? "")
                              //         .toLowerCase()
                              //         .localeCompare(
                              //             (optionB?.label ?? "").toLowerCase()
                              //         )
                              // }
                              bordered={false}
                              options={base_items.icomerList?.map((obj) => {
                                const keys = Object.keys(obj);
                                const convertedObj = {
                                  value: obj[keys[0]],
                                  label: obj[keys[1]],
                                };

                                for (let i = 2; i < keys.length; i++) {
                                  convertedObj[keys[i]] = obj[keys[i]];
                                }

                                return convertedObj;
                              })}
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div
                        className={`${styles.inputFieldCon}  ${styles.paddingten} ${styles.borderRight}  ${styles.boderBottom}`}
                      >
                        <span>Status</span>
                        <Form.Item
                          name="status"
                          // rules={[
                          //     {
                          //         required: val?.required,
                          //         message: `Please Enter ${val?.label}!`,
                          //     },
                          // ]}
                        >
                          {base_items && (
                            <Select
                              showSearch
                              allowClear
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              bordered={false}
                              options={base_items.statusList?.map((obj) => {
                                const keys = Object.keys(obj);
                                const convertedObj = {
                                  value: obj[keys[0]],
                                  label: obj[keys[1]],
                                };

                                for (let i = 2; i < keys.length; i++) {
                                  convertedObj[keys[i]] = obj[keys[i]];
                                }

                                return convertedObj;
                              })}
                            />
                          )}
                        </Form.Item>
                      </div>

                      <div
                        className={`${styles.purchaseOrderRight} ${styles.boderBottom} ${styles.paddingten} `}
                      >
                        <span>Currency</span>
                        <Form.Item
                          name="currencyID"
                          // rules={[
                          //     {
                          //         required: val?.required,
                          //         message: `Please Enter ${val?.label}!`,
                          //     },
                          // ]}
                        >
                          {base_items && (
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              disabled
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              bordered={false}
                              options={base_items.currencyList?.map((obj) => {
                                const keys = Object.keys(obj);
                                const convertedObj = {
                                  value: obj[keys[0]],
                                  label: obj[keys[1]],
                                };

                                for (let i = 2; i < keys.length; i++) {
                                  convertedObj[keys[i]] = obj[keys[i]];
                                }

                                return convertedObj;
                              })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>

                    <div
                      className={` ${styles.paddingten} ${styles.borderBox} ${styles.boderBottom} ${styles.borderLeft}`}
                    >
                      <span>Signatory Company</span>
                      <Input bordered={false} value={signatoryName} />

                      {/* <Form.Item
                                        name="signatoryCompany"
                                        
                                    // rules={[
                                    //     {
                                    //         required: val?.required,
                                    //         message: `Please Enter ${val?.label}!`,
                                    //     },
                                    // ]}
                                    >
                                    </Form.Item> */}
                    </div>

                    <div
                      className={` ${styles.paddingten} ${styles.borderBox} ${styles.borderLeft}`}
                    >
                      <span>Authorised Signatory Name</span>
                      <Input
                        bordered={false}
                        value={authorisedSignatoryName}
                        onChange={handleChanges}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div style={{ marginTop: '20px' }}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </div> */}

              <Form.Item>
                {state?.addPO == "0" && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: "20px" }}
                    loading={isLoading}
                  >
                    {state?.id ? "Update" : "Submit"}
                  </Button>
                )}
                {state?.addPO == "1" && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: "20px" }}
                    loading={isLoading}
                    onClick={() => setDraft(3)}
                  >
                    Add PO
                  </Button>
                )}

                {state?.type == "DRAFT" && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: "20px" }}
                    loading={isLoading}
                    onClick={() => setDraft(2)}
                  >
                    {state?.id ? "Update Draft" : "Save as Draft"}
                  </Button>
                )}
                {state?.addPO == "1" && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: "20px" }}
                    loading={isLoading}
                    onClick={() => setDraft(2)}
                  >
                    {state?.id ? "Update Draft" : "Save as Draft"}
                  </Button>
                )}
              </Form.Item>
            </Form>
          }
        </div>

        {errors.length > 0 && (
          <div>
            <p>Please Fill All the Below Details</p>
            {errors.map((v, i) => (
              <div style={{ color: "red", margin: "5px" }}>{v}</div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CreatePoLc;
