import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Switch,
  Modal,
  Tooltip
} from "antd";
import React, { useState, useContext, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import NumericInput from "../../Components/NumericInput/NumericInput";
import { MainContext } from "../../utils/context";
import { api_call, api_call_token } from "../../utils/Network";
import ProductsCompo from "./ProductsCompo/ProductsCompo";
import styles from "./index.module.css";
import Delete from "../../Assets/Delete.svg";
import moment from "moment";
import { alertMessage, getLocalData } from "../../utils/helperFunctions";
import { UserCredsContext } from "../../utils/UserCredsContext";
import Causion from "../../Assets/Popup/caution.png"

const { confirm } = Modal;
function CreateForm() {
  const today = moment();
  const formRef = useRef();
  let navigate = useNavigate();
  const { base_items } = useContext(UserCredsContext);
  const { state } = useLocation();
  const [value, setValue] = useState("");
  const [products, getProduct] = useState([]);
  const [productsDetails, getProductsDetails] = useState([]);
  const [form] = Form.useForm();
  const [productListing, setProductList] = useState([]);
  const [fieldValues, setFieldValue] = useState([]);
  const [poValue, setpoValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmShown, setConfirmShown] = useState(false);
  const [signatoryName, setSignatoryName] = useState("");
  const [poNumber, setPONumber] = useState("");
  const [draft, setDraft] = useState(1);
  const [addressList, setAddressList] = useState([]);
  const [addressData, setAddressData] = useState(null);
  const [totalData, setTotalData] = useState({
    totalProduct: "",
    totalQuantity: "",
    avgamt: "",
    totalAmt: "",
  });
  const [authorisedSignatoryName, setAuthorisedSignatoryName] = useState("");

  useEffect(() => {
    getauthorisedSignatoryName()
    getProducts();
    getProd();
    getDataValues();
    if (state?.addPO == "0") {
      getPONumber();
    } else if (state?.addPO == "1") {
      getDraft();
    }
    getPO();
  }, []);

  useEffect(() => {
    productSubTotal();
  }, [productListing]);

  useEffect(() => {
    console.log(state, "STATAT")
    productsAdd();
    setSignatoryName(state?.data?.signatoryCompany)
    if (state?.data?.addressID) {
      setAddressData(state?.data?.addressID)
      handleAdrList(state?.data?.addressID)
      handleAddress(state?.data?.supplierID)
    }
  }, [state])

  const getauthorisedSignatoryName = () => {
    let data = getLocalData("phoolchand-user");
    console.log(data, "SETPRODUCTLIST")
    setAuthorisedSignatoryName(`${data?.firstName} ${data?.lastName}`)
  }
  const getPONumber = () => {
    api_call_token
      .get(`purchaseOrder/po`)
      .then((res) => {
        console.log(res, "daat")
        let data = res.data.purchaseOrderNumber;
        setPONumber(data);
      })
  }

  const getDraft = () => {
    api_call_token
      .get(`purchaseOrder/D`)
      .then((res) => {
        console.log(res, "daat")
        let data = res.data.purchaseOrderNumber;
        setPONumber(data);
      })
  }
  const productsAdd = () => {
    if (state?.data?.productList) {
      setProductList(state?.data?.productList)
    }
  }

  const getPO = () => {
    let apiData = "purchaseOrder";

    api_call_token
      .get(`product/field?search=${apiData}&formtype=create`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setpoValue([...res.data.data])
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getDataValues = () => {
    let apiData;
    if (state?.addPO == "1") {
      apiData = "draftPO";
    } else {
      apiData = "purchaseOrder";
    }

    api_call_token
      .get(`product/field?search=${apiData}&formtype=create`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setFieldValue([...res.data.data])
          setIsLoading(false);
        } else {
          showConfirm();
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const showConfirm = () => {
    if (!confirmShown) {
      confirm({
        title: '',
        icon: null,
        content: (
          <div style={{ textAlign: 'center' }}>
            <img src={Causion} alt="Custom Icon" style={{ width: '60px' }} />
            <p style={{ fontWeight: 'bold' }}>You do not have access to view this. Kindly contact the admin to get access.</p>
          </div>
        ),
        // Remove onOk and onCancel
        cancelButtonProps: {
          style: {
            display: 'none', // Hide the cancel button
          },
        },
        okButtonProps: {
          // disabled: true,
        },
        okText: "Go Back",
        onOk() {
          navigate("/purchase");
        },

      });
      setConfirmShown(!confirmShown);
    }
  };

  const productSubTotal = () => {
    let data = {
      totalProduct: "",
      totalQuantity: "",
      avgamt: "",
      totalAmt: "",
    };
    productListing.map((v, i) => {
      data.totalProduct = i + 1;
      data.totalQuantity = +data.totalQuantity + +v.quantity;
      data.avgamt = +data.avgamt + +v.pricePerUnit;
      data.totalAmt = +data.totalAmt + +v.quantity * v.pricePerUnit;
    });

    setTotalData(data);
  };

  const getProd = () => {
    api_call_token
      .get(`product`)
      .then((res) => {
        getProduct(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProducts = () => {
    api_call_token
      .get(`product/field?search=purchaseorderproducts`)
      .then((res) => {
        console.log(res.data.data, "DAtassss");
        let data = res.data.data;
        getProductsDetails(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleData = (values) => {
    console.log(values, "Demos")

    let dateString = moment(values.purchaseOrderDate?.$d).format("YYYY-MM-DD");
    let finalShipment = moment(values?.shipmentDate?.$d).format("YYYY-MM-DD");
    const { purchaseOrderDate, shipmentDate, active, ...rest } = values;

    let datas = {
      ...rest,
      purchaseOrderDate: dateString,
      shipmentDate: finalShipment,
      productList: productListing,
      totalProducts: totalData?.totalProduct,
      totalAmount: totalData?.totalAmt,
      totalQuantity: totalData?.totalQuantity,
      avgPricePerUnit: totalData?.avgamt,
      signatoryCompany: signatoryName,
      structureList: [],
    };

    if (state?.addPO == "1") {
      if (draft == 3) {

        if (productListing.length > 0) {
          let dataArr = [];
          poValue.map((v, i) => {
            if (v.required) {
              dataArr.push(v.apiName)
            }
          })

          let isDataValid = true;
          for (let i = 0; i < dataArr.length; i++) {
            const property = dataArr[i];
            if (!datas[property]) {
              isDataValid = false;
              alertMessage("Please add all field in po ", 2)
              return;
            }
          }
        } else {
          alertMessage("Product may not be empty", 2)
          return;
        }

      }
    } else {
      if (productsDetails.length <= 0) { }
      else {
        if (productListing.length > 0) {
          // console.log(datas, state, "matasssss")
        } else {
          alertMessage("Product may not be empty", 2)
          return;
        }
      }
    }



    let apiEnd = 'purchaseOrder';

    if (draft == 2) {
      
      apiEnd = 'draft';
    } else if (state?.selectDraft == "draft") {
      apiEnd = 'draft';
    }

    console.log(datas, "DDDDDDDDSSSSSSs")

    if (draft == 3) {
      setIsLoading(true);
      api_call_token
        .get(`purchaseOrder/po`)
        .then((res) => {
          console.log(res, "daat")
          let data = res.data.purchaseOrderNumber;
          datas.purchaseOrderNumber = data;
          if (data) {
            api_call_token
              .post(`/purchaseOrder`, datas)
              .then((res) => {
                if (res.status == 200 || res.status == 201) {
                  setDraft(1);
                  deleteDraft(state?.id)
                  setTimeout(() => {
                    navigate('/purchase')
                  }, 2000);
                }
              })
          }
        })
        .catch((err) => {
          setIsLoading(false);
        })

    } else {
      const filteredData = Object.entries(datas)
        .filter(([key, value]) => !!value)
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

      console.log(filteredData);
      if (state?.id) {
        setIsLoading(true);
        api_call_token
          .patch(`/${apiEnd}/${state?.id}`, { ...filteredData, active: 1 })
          .then((res) => {
            if (res.status == 200 || res.status == 201 || res.status == 204) {
              alertMessage("PO Updated ");
              setDraft(1);
              setTimeout(() => {
                navigate('/purchase')
              }, 2000);
            }
          })
          .catch((err) => {
            setIsLoading(false);
          })
      } else {
        setIsLoading(true);
        api_call_token
          .post(`/${apiEnd}`, filteredData)
          .then((res) => {
            if (res.status == 200 || res.status == 201) {

              if (draft == 1) {
                alertMessage("PO added ")
              } else {
                alertMessage("Po added to draft ")
              }
              setDraft(1);
              setTimeout(() => {
                navigate('/purchase')
              }, 2000);
            }
          })
          .catch((err) => {
            setIsLoading(false);
          })
      }
    }
  };


  const deleteDraft = (v) => {
    api_call_token
      .delete(`draft/${v}`)
      .then((res) => {
        console.log(res)
      })
      .catch(err => console.log(err))
  }

  const handleArray = (v) => {
    console.log(v.productName);
    const index = productListing.findIndex(
      (obj) => obj.productName === v.productName
    );
    if (index !== -1) {
      productListing.splice(index, 1);
      setProductList([...productListing]);
    }
  };

  const handleAddress = (e) => {
    console.log(e, "DataS")
    formRef?.current?.resetFields(['addressID']);

    api_call_token
      .get(`/formData/supplierAddress/${e}`)
      .then((res) => {
        console.log(res, "res_DAta")
        setAddressData(null)
        setAddressList(res.data.supplierAddressList)
      })
      .catch(err => console.log(err))
  }

  const handleAdrList = (e) => {
    console.log(e);
    setAddressData(e);
  }

  const handleBuyer = (e) => {
    console.log(e, "Data")
    let data = base_items?.buyerList.findIndex((prod) => prod.id == e)
    setSignatoryName(base_items?.buyerList[data]?.name)
  }

  console.log(authorisedSignatoryName, "SETPRODUCTLIST");
  return (
    <div className={styles.formData} style={{ width: '100%', padding: '20px' }}>
      <div>
        <h2><ArrowLeftOutlined onClick={() => navigate(-1)} style={{ marginRight: '10px' }} />Create Purchase Order</h2></div>
      {isLoading ? <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <Spin size="large" />
        </div>

      </> :
        <>
          {
            fieldValues.length > 0 &&
            <Form form={form} ref={formRef} onFinish={handleData}
              initialValues={{
                ...state?.data,
                purchaseOrderNumber: state?.data?.purchaseOrderNumber ? state?.data?.purchaseOrderNumber : poNumber,
                authorisedSignatoryName: authorisedSignatoryName,
                status: state?.data?.status ? state?.data.status : 1,
                purchaseOrderDate: state?.data?.purchaseOrderDate ? moment(state?.data?.purchaseOrderDate.split('T')[0], "YYYY-MM-DD") : moment(today, "YYYY-MM-DD"),
                shipmentDate: state?.data?.shipmentDate ? moment(state?.data?.shipmentDate.split('T')[0], "YYYY-MM-DD") : undefined,
                addressID: (addressData) ? addressData : null,
              }}
            >
              <Row gutter={[16, 16]}>
                {fieldValues && fieldValues.length > 0 && fieldValues?.map(
                  (val, i) =>
                    val?.isForm && (
                      <>
                        {val?.fieldType == "int" ? (
                          <Col xs={24} md={14} lg={8}>
                            <span>
                              {val?.label} {val?.required && `*`}
                            </span>
                            <Form.Item
                              name={val?.apiName}
                              rules={[
                                {
                                  required: val?.required,
                                  message: `Please Enter ${val?.label}!`,
                                },
                              ]}
                            >
                              <NumericInput
                                style={{
                                  width: "100%",
                                }}
                                value={value}
                                onChange={setValue}
                              />
                            </Form.Item>
                          </Col>
                        ) : val?.fieldType == "picklist_fk" ? (
                          <>
                            <Col xs={24} md={14} lg={8}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.label}!`,
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                      .toLowerCase()
                                      .localeCompare(
                                        (optionB?.label ?? "").toLowerCase()
                                      )
                                  }
                                  onChange={(val?.apiName == "buyerID") ? handleBuyer : (val?.apiName == "supplierID") ? handleAddress : (val?.apiName == "addressID") ? handleAdrList : (e) => console.log(e)}
                                  options={
                                    (val?.apiName == "addressID") ?
                                      addressList?.map(
                                        (obj) => {
                                          const keys = Object.keys(obj);
                                          const convertedObj = {
                                            value: obj[keys[0]],
                                            label: obj[keys[1]],
                                          };

                                          for (let i = 2; i < keys.length; i++) {
                                            convertedObj[keys[i]] = obj[keys[i]];
                                          }

                                          return convertedObj;
                                        }
                                      )
                                      :

                                      base_items[val?.picklistData]?.map(
                                        (obj) => {
                                          const keys = Object.keys(obj);
                                          const convertedObj = {
                                            value: obj[keys[0]],
                                            label: obj[keys[1]],
                                          };

                                          for (let i = 2; i < keys.length; i++) {
                                            convertedObj[keys[i]] = obj[keys[i]];
                                          }

                                          return convertedObj;
                                        }
                                      )
                                  }
                                />
                              </Form.Item>

                            </Col>
                          </>
                        ) : val?.fieldType == "date" ? (
                          <Col xs={24} md={14} lg={8}>
                            <span>
                              {val?.label} {val?.required && `*`}
                            </span>
                            <Form.Item
                              name={val?.apiName}
                              rules={[
                                {
                                  required: val?.required,
                                  message: `Please Enter ${val?.label}!`,
                                },
                              ]}
                            >
                              <DatePicker
                                onChange={onChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                        ) : val?.fieldType == "Boolean" ? (
                          <Col xs={24} md={14} lg={8}>
                            <span>
                              {val?.label} {val?.required && `*`}
                            </span>
                            <Form.Item name={val?.apiName}>
                              <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={state?.data[val?.apiName]} />
                            </Form.Item>
                          </Col>
                        ) :
                          (
                            <Col xs={24} md={14} lg={8}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              {(val?.apiName == "signatoryCompany") ?
                                <Input value={signatoryName} /> :
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >

                                  <Input />
                                </Form.Item>}
                            </Col>
                          )}
                      </>
                    )
                )}
              </Row>
              {productsDetails.length > 0 && <div>

                <div>
                  {products.length > 0 && (
                    <ProductsCompo
                      products={products}
                      productsDetails={productsDetails}
                      setProductList={setProductList}
                      productListing={productListing}
                    />
                  )}
                </div>
                <div>
                  {Array.isArray(productListing) && productListing.length > 0 &&
                    productListing?.map((v, i) => (
                      <div className={styles.prodData}>
                        <Row>
                          <Col xs={24} md={14} lg={4}>
                            <Tooltip title={v.productName}>
                              <div className={styles.prodDetails}>
                                <div className={styles.names}>Product Name</div>
                                <div>
                                  {v.productName}
                                </div>
                              </div>
                            </Tooltip>


                          </Col>
                          <Col xs={24} md={14} lg={2}>
                            <div className={styles.prodDetails}>
                              <div className={styles.names}>Quantity</div>
                              <div>
                                {v.quantity}
                              </div>

                            </div>
                          </Col>
                          <Col xs={24} md={14} lg={2}>
                            <div className={styles.prodDetails}>
                              <div className={styles.names}>Unit of measure</div>
                              <div>
                                {
                                  base_items?.unitOfMeasureList[base_items?.unitOfMeasureList.findIndex((prod) => prod?.id == v?.unitOfMeasurement)]?.name || "-"
                                }
                              </div>


                            </div>
                          </Col>
                          <Col xs={24} md={14} lg={2}>
                            <div className={styles.prodDetails}>
                              <div className={styles.names}>
                                HSN code
                              </div>
                              <div>
                                {v.hsnCode}
                              </div>

                            </div>
                          </Col>
                          {/* <Col xs={24} md={14} lg={3}>
                      <div className={styles.prodDetails}>
                        {v.details}

                      </div>
                    </Col> */}
                          <Col xs={24} md={14} lg={2}>
                            <div className={styles.prodDetails}>
                              <div className={styles.names}>
                                Price Per Unit
                              </div>
                              <div>

                                {v.pricePerUnit}
                              </div>

                            </div>
                          </Col>
                          <Col xs={24} md={14} lg={2}>
                            <div className={styles.prodDetails}>
                              <div className={styles.names}>
                                Total
                              </div>
                              <div>
                                {v.quantity * v.pricePerUnit}
                              </div>

                            </div>
                          </Col>
                          <Col xs={24} md={14} lg={2}>
                            <div className={styles.prodDetails}>
                              <div className={styles.names}>
                                Goods Details
                              </div>
                              <div>
                                {v?.goodsDetail || "-"}

                              </div>

                            </div>
                          </Col>
                          <Col xs={24} md={14} lg={2}>
                            <div className={styles.prodDetails}>
                              <div className={styles.names}>
                                Note
                              </div>
                              <div>
                                {v?.note || "-"}

                              </div>

                            </div>
                          </Col>
                          <Col xs={24} md={14} lg={2}>
                            <div className={styles.prodDetails}>
                              <div className={styles.names}>
                                Packing
                              </div>
                              <div>
                                {v?.packing || "-"}
                              </div>

                            </div>
                          </Col>
                          <Col xs={24} md={14} lg={2}>
                            <div onClick={() => handleArray(v)} className={styles.deleteBox}>
                              <img src={Delete} alt="delte" style={{ width: '20px' }} />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                </div>

                <div className={`${styles.totalDetails} ${styles.subtotalView}`}>
                  <div className={styles.totalDetails}>
                    <div className={styles.totals}>
                      <span className={styles.headtext}>Total Products</span>
                      <div style={{ textAlign: "center", margin: "10px 0", fontWeight: 'bold' }}>
                        {totalData?.totalProduct || 0}
                      </div>
                    </div>
                    <div className={styles.totals}>
                      <span className={styles.headtext}>Total Quantity</span>
                      <div style={{ textAlign: "center", margin: "10px 0", fontWeight: 'bold' }}>
                        {totalData?.totalQuantity || 0}
                      </div>
                    </div>
                    <div className={styles.totals}>
                      <span className={styles.headtext}>Avg. Price per MT</span>
                      <div style={{ textAlign: "center", margin: "10px 0", fontWeight: 'bold' }}>
                        {totalData?.avgamt || 0}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.totalAmt}>
                      <span className={styles.headtext}>Total Amount</span>
                      <div className={styles.amt} style={{ textAlign: "center", margin: "10px 0", fontWeight: 'bold' }}>
                        {totalData?.totalAmt || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              <Form.Item>
                {state?.addPO == "0" && <Button type="primary" htmlType="submit" style={{ margin: '20px' }}>
                  {(state?.id) ? "Update" : "Submit"}
                </Button>}

                {
                  state?.addPO == "1" && <Button type="primary" htmlType="submit" style={{ margin: '20px' }} onClick={() => setDraft(2)}>
                    {(state?.id) ? "Update Draft" : "Save as Draft"}
                  </Button>
                }

                {state?.addPO == "1" && <Button type="primary" htmlType="submit" style={{ margin: '20px' }} onClick={() => setDraft(3)}>
                  Add PO
                </Button>
                }

              </Form.Item>
            </Form>
          }
        </>
      }
    </div>
  );
}

export default CreateForm;