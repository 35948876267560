import React, { useState } from 'react'
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Switch,
    Modal,
    TimePicker
} from "antd";
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { api_call_token } from '../../../utils/Network';

function ConInForm({ data,handelClose,getContainerData }) {
    console.log(data, "ConInForm");
    let { id } = useParams();
    const [form] = Form.useForm();
    const today = moment();
    const [isLoading, setIsLoading] = useState(false);
    const handleData = (values) => {
        console.log(values, "handleData");
        let dateString = values?.containerInDate?._i
            ? values?.containerInDate?._i
            : moment(values.containerInDate?.$d).format("YYYY-MM-DD");
        let timeString = values?.containerInTime?._i ? values?.containerInTime?._i : moment(values?.containerInTime?.$d).format("hh:mm")
            
        const {containerInDate, containerInTime,containerWeightAtInTime , ...rest} = values
        let newDatas = {
            ...rest,
            containerInDate: dateString,
            containerInTime:timeString,
            containerWeightAtInTime: containerWeightAtInTime,
            containerEntryId:data?.containerEntryId,
            containerId:data?.containerId,

        }
        console.log(newDatas, "dnaudh9676567tfgvbsdakj");
            // handelClose()
        api_call_token
        .patch(`containerentry/${id}`, newDatas)
        .then((res) =>{
            console.log(res?.data?.data);
            if(res?.status == 200 || res?.status == 201 || res?.status == 204){
                // alert('update')
                handelClose();
                getContainerData();
            }
        })
        .catch((err) =>{
            console.log(err);
        })

    }

    const onChange = (date, dateString) => {
        console.log(date, dateString, "onChange123");
    };
    return (
        <>
            <div>Container In Details </div>

            <Form form={form} initialValues={{
                ...data,
                containerInDate: data?.containerInDate ? moment(data?.containerInDate, "YYYY-MM-DD") : moment(today, "YYYY-MM-DD"),
                containerInTime: data?.containerInTime ? moment(data?.containerInTime, "HH:mm:ss") : moment(today, "hh:mm"),
                containerWeightAtInTime: data?.containerWeightAtInTime
            }} onFinish={handleData}>
                <Row gutter={[26, 7]}>
                    <Col xs={24} md={14} lg={12}>
                        <span>In Date</span>
                        <Form.Item
                            name="containerInDate"
                            rules={[
                                { required: true, message: "Please enter In Time" },
                            ]}
                        >
                            <DatePicker
                                onChange={onChange}
                                format="YYYY-MM-DD"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={14} lg={12}>
                        <span>In Time</span>
                        <Form.Item
                            name="containerInTime"
                            rules={[
                                { required: true, message: "Please enter In Time" },
                            ]}
                        >
                            <TimePicker
                                format="HH:mm " />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={14} lg={12}>
                        <span>In Time Weight in kgs</span>
                        <Form.Item
                            name="containerWeightAtInTime"
                            rules={[
                                { required: true, message: "Please enter In Time" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ margin: "20px" }}
                            loading={isLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Row>

            </Form>
        </>

    )
}

export default ConInForm