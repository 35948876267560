import React, { useEffect, useState, useContext } from 'react'
import { api_call, api_call_token, formDataApi } from '../../../utils/Network';
import styles from "./index.module.css";
import { Col, Row, Form, Select, DatePicker, Input, Button } from 'antd';
import NumericInput from '../../../Components/NumericInput/NumericInput';
import { MainContext } from "../../../utils/context";
// import Delete from "../../../Assets/Fields/Delete.png";
import Delete from "../../../Assets/Delete.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { alertMessage } from '../../../utils/helperFunctions';
import { UserCredsContext } from '../../../utils/UserCredsContext';

function AddProducts() {
    let { state } = useLocation();
    let navigate = useNavigate();
    const { base_items } = useContext(UserCredsContext);
    const [fieldName, setFieldName] = useState([]);
    const [form] = Form.useForm();
    const [value, setValue] = useState("");
    const [productDetails, setProductDetails] = useState([]);
    const [totalProducts, setProducts] = useState({
        totalProduct: '',
        totalQuantity: '',
        avgPriceMt: '',
        totalAmount: "",
    })
    useEffect(() => {
        getProductsList();
        getProd();
    }, []);



    useEffect(() => {
        calculateTotalProduct();
    }, [productDetails])


    const getProd = () => {
        api_call_token
            .get(`container/product/${state?.containerID}`)
            .then((res) => {
                console.log(res, "data")
                setProductDetails(res.data.data)
            })
            .catch(err => console.log(err))
    }


    const calculateTotalProduct = () => {
        let totalprod = "";
        let totalqty = "";
        let price = "";

        productDetails.map((v, i) => {
            totalprod = i + 1;
            totalqty = +totalqty + +v.totalQuantity;
            price = +price + +v.cost;
        })


        let avg = price / totalqty;
        console.log(price, totalqty, avg, "AAAAA")

        setProducts({
            totalProduct: totalprod,
            totalQuantity: totalqty,
            avgPriceMt: parseInt(avg),
            totalAmount: price,
        })
    }

    const getProductsList = () => {
        api_call_token
            .get(`product/field?search=containerProducts&formtype=create`)
            .then((res) => {
                if (res.data.data) {
                    setFieldName(res.data.data)
                }
            })
            .catch(err => console.log(err))
    }

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    const handleData = (value) => {
        console.log(value, "Valuesss")
        let { containerID, cost, totalQuantity, ...rest } = value;
        let data = {
            containerId: state?.containerID,
            containerNo: state?.containerNumber + "",
            cost: +cost,
            totalQuantity: +totalQuantity,
            ...rest
        }

        setProductDetails([...productDetails, data])
        form.resetFields();
    }

    const handleArray = (v) => {

        productDetails.splice(v, 1);
        setProductDetails([...productDetails])
    };

    const handleSubmit = () => {
        if (productDetails.length > 0) {

            api_call_token
                .delete(`container/product/${state?.containerID}`)
                .then((res) => {
                    console.log(res)
                })
                .catch(err => console.log(err))

            let data = {
                productList: productDetails
            }
            api_call_token
                .post(`container/product`, data)
                .then((res) => {
                    if (res.status == 200 || res.status == 201) {
                        // navigate('/container')
                        addQty();
                    }
                })
                .catch(err => console.log(err))

        } else {
            alertMessage("Please add products", 2)
        }
    }

    const addQty = async () => {
        const fmData = new FormData();

        for (const key in totalProducts) {
            if (totalProducts.hasOwnProperty(key)) {
                const value = totalProducts[key];
                fmData.append(key, value);
            }
        }

        let apiEnd = `/${state?.containerID}`;

        try {

            const res = await formDataApi(apiEnd, fmData, true)
            console.log(res, "ressss")
            if (res) {
                navigate('/container')
            }

        } catch (error) {
            console.log(error);
        }
    }

    console.log(productDetails, base_items, state, "JJJJJJJJ")

    return (
        <div style={{ width: '100%', padding: '20px' }}>
            <div><h2>
                Container Products: {state?.containerID}</h2></div>

            <div>
                <div className={styles.productHead}>
                    <Row gutter={[16, 16]}>
                        {fieldName &&
                            fieldName.map(
                                (val, i) =>
                                    val?.isForm && (
                                        <Col xs={24} md={14} lg={3}>
                                            <p className={styles.cssClass}>
                                                {val?.fieldName} {val?.required && `*`}
                                            </p>
                                        </Col>))}
                        <Col xs={24} md={14} lg={4}><p className={styles.cssClass}>Action</p></Col>
                    </Row>
                </div>
                <Form
                    form={form}
                    onFinish={handleData}
                    initialValues={{
                        containerNo: state?.containerNo
                    }}
                >
                    <div className={styles.textData}>

                        <Row gutter={[16, 16]}>
                            {fieldName.length > 0 && fieldName.map(
                                (val, i) =>
                                    val?.isForm && (
                                        <>
                                            {val?.fieldType == "int" ? (
                                                <Col xs={24} md={14} lg={3}>
                                                    {/* <span>
                                                        {val?.fieldName} {val?.required && `*`}
                                                    </span> */}
                                                    <Form.Item
                                                        name={val?.apiName}
                                                        rules={[
                                                            {
                                                                required: val?.required,
                                                                message: `Please Enter ${val?.fieldName}!`,
                                                            },
                                                        ]}
                                                    >

                                                        <NumericInput
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            value={value}
                                                            onChange={setValue}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ) : val?.fieldType == "picklist_fk" ? (
                                                <>
                                                    <Col xs={24} md={14} lg={3}>
                                                        {/* <span>
                                                            {val?.fieldName} {val?.required && `*`}
                                                        </span> */}
                                                        <Form.Item
                                                            name={val?.apiName}
                                                            rules={[
                                                                {
                                                                    required: val?.required,
                                                                    message: `Please Enter ${val?.fieldName}!`,
                                                                },
                                                            ]}
                                                        >
                                                             <Select
                                                                showSearch
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    (option?.label ?? "").includes(input)
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    (optionA?.label ?? "")
                                                                        .toLowerCase()
                                                                        .localeCompare(
                                                                            (optionB?.label ?? "").toLowerCase()
                                                                        )
                                                                }
                                                                options={
                                                                    base_items[val?.picklistData].length > 0 &&
                                                                    base_items[val?.picklistData]?.map(
                                                                        (obj) => {
                                                                            const keys = Object.keys(obj);
                                                                            const convertedObj = {
                                                                                value: obj[keys[0]],
                                                                                label: obj[keys[1]],
                                                                            };

                                                                            for (let i = 2; i < keys.length; i++) {
                                                                                convertedObj[keys[i]] = obj[keys[i]];
                                                                            }

                                                                            return convertedObj;
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ) : val?.fieldType == "date" ? (
                                                <Col xs={24} md={14} lg={3}>
                                                    {/* <span>
                                                        {val?.fieldName} {val?.required && `*`}
                                                    </span> */}
                                                    <Form.Item
                                                        name={val?.apiName}
                                                        rules={[
                                                            {
                                                                required: val?.required,
                                                                message: `Please Enter ${val?.fieldName}!`,
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            onChange={onChange}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ) : (
                                                <Col xs={24} md={14} lg={3}>
                                                    {/* <span>
                                                        {val?.fieldName} {val?.required && `*`}
                                                    </span> */}
                                                    <Form.Item
                                                        name={val?.apiName}
                                                        rules={[
                                                            {
                                                                required: val?.required,
                                                                message: `Please Enter ${val?.fieldName}!`,
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </>
                                    )
                            )}
                            <Col xs={24} md={14} lg={3}> <Form.Item>
                                <Button type="primary" htmlType="submit" className={styles.btnsgreen} >
                                    +
                                </Button>
                            </Form.Item></Col>
                        </Row>

                    </div>
                </Form>

                <div>
                    {Array.isArray(productDetails) && productDetails.length > 0 &&
                        productDetails.map((v, i) => (
                            <div>
                                <Row>
                                    <Col xs={24} md={14} lg={3}>
                                        <div className={styles.prodDetails}>
                                            {v.itemCode}
                                        </div>

                                    </Col>

                                    <Col xs={24} md={14} lg={3}>
                                        <div className={styles.prodDetails}>
                                            {v.productID}

                                        </div>
                                    </Col>
                                    <Col xs={24} md={14} lg={3}>
                                        <div className={styles.prodDetails}>
                                            {v.cost}

                                        </div>
                                    </Col>
                                    <Col xs={24} md={14} lg={3}>
                                        <div className={styles.prodDetails}>
                                            {v.totalQuantity}

                                        </div>
                                    </Col>
                                    <Col xs={24} md={14} lg={3}>
                                        <div className={styles.prodDetails}>
                                            {v.containerNo}

                                        </div>
                                    </Col>

                                    <Col xs={24} md={14} lg={4}>
                                        <div onClick={() => handleArray(i)} className={styles.deleteBox}>
                                            <img src={Delete} alt="delte" style={{width: '30px'}}/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                </div>


                <div className={`${styles.totalDetails} ${styles.subtotalView}`}>
                    <div className={styles.totalDetails}>
                        <div className={styles.totals}>
                            <span className={styles.headtext}>Total Products</span>
                            <div style={{ textAlign: "center", margin: "10px 0", fontWeight: 'bold' }}>
                                {totalProducts?.totalProduct || 0}
                            </div>
                        </div>
                        <div className={styles.totals}>
                            <span className={styles.headtext}>Total Quantity</span>
                            <div style={{ textAlign: "center", margin: "10px 0", fontWeight: 'bold' }}>
                                {totalProducts?.totalQuantity || 0}
                            </div>
                        </div>
                        <div className={styles.totals}>
                            <span className={styles.headtext}>Avg. Price per MT</span>
                            <div style={{ textAlign: "center", margin: "10px 0", fontWeight: 'bold' }}>
                                {parseInt(totalProducts?.avgPriceMt) || 0}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span className={styles.headtext}>Total Amount</span>
                            <div style={{ textAlign: "center", margin: "10px 0", fontWeight: 'bold' }}>
                                {totalProducts?.totalAmount || 0}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Button className={styles.btns} onClick={handleSubmit}>Submit</Button>
            </div>
        </div>
    )
}

export default AddProducts