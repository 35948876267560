import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { api_call } from "../../../utils/Network";
import NumericInput from "../../../Components/NumericInput/NumericInput";
import { MainContext } from "../../../utils/context";
import styles from "./index.module.css";
import { checkEmptyObject } from "../../../utils/Utils";
import { alertMessage } from "../../../utils/helperFunctions";
import { UserCredsContext } from "../../../utils/UserCredsContext";

function ProductsCompo({
  products,
  productsDetails,
  setProductList = () => {},
  productListing,
}) {
  const { base_items } = useContext(UserCredsContext);

  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [dataValues, setDataValues] = useState({});
  const [productsData, setProductsData] = useState({});
  const [productsList, setProductsList] = useState({
    invoiceproductName: null,
    unitofmeasureId: null,
    containerId: null,
    purchaseOrderNumber: null,
  });

  useEffect(() => {
    getProductData();
  }, [productsDetails]);

  const getProductData = () => {
    let data = {};
    productsDetails.map((v, i) => {
      if (v.isForm) {
        data[v.apiName] = "";
      }
    });
    setProductsData(data);
    console.log(data, "DDDDDDdd");
  };

  const handleData = () => {
    // debugger;
    console.log(productsData);
    const { invoiceproductName, ...restdata } = productsData;
    let vals = { ...restdata };
    let dataArr = [];
    productsDetails.map((v, i) => {
      if (v.required) {
        dataArr.push(v.apiName);
      }
    });

    let isDataValid = true;
    for (let i = 0; i < dataArr.length; i++) {
      const property = dataArr[i];
      if (property === "invoiceproductName") {
        continue;
      }

      if (!vals[property]) {
        isDataValid = false;
        alertMessage("Please add all field ", 2);
        return;
      }
    }

    const {
      invoiceproductquantity,
      pricePerUnit,
      amount,
      unitofmeasureId,
      ...restValues
    } = productsData;
    const productNames = findProductById(restValues.productId);
    const unitMeasure = findUnitMeasure(unitofmeasureId);
    console.log(productListing, "PRODDDD");
    let newArr = [];
    if (productListing.length > 0) {
      newArr = [
        ...productListing,
        {
          ...restValues,
          invoiceproductName: productNames,
          invoiceproductquantity: +invoiceproductquantity,
          pricePerUnit: +pricePerUnit,
          amount: +amount?.toFixed(2),
          unitofmeasure: unitMeasure,
          unitofmeasureId: +unitofmeasureId,
        },
      ];
    } else {
      newArr = [
        {
          ...restValues,
          invoiceproductName: productNames,
          invoiceproductquantity: +invoiceproductquantity,
          pricePerUnit: +pricePerUnit,
          amount: +amount?.toFixed(2),
          unitofmeasure: unitMeasure,
          unitofmeasureId: +unitofmeasureId,
        },
      ];
    }
    setProductList(newArr);
    setProductsData({});
    setProductsList({
      invoiceproductName: null,
      unitofmeasureId: null,
      containerNo: null,
      purchaseOrderNumber: null,
    });
  };

  function findUnitMeasure(id) {
    const index = base_items?.unitOfMeasureList.findIndex(
      (product) => product.id === id
    );
    if (index !== -1) {
      return base_items?.unitOfMeasureList[index].name;
    }
    return null; // Return null if product with given id is not found
  }

  function findProductById(id) {
    const index = products.findIndex((product) => product.productID === id);
    if (index !== -1) {
      return products[index].productName;
    }
    return null; // Return null if product with given id is not found
  }

  const onChange = (date, dateString, e) => {
    console.log(date, dateString,e, 'jhyu867');
    setProductsData({...productsData, boeDate:dateString })
  };

  const handleAuto = (value, data) => {
    // debugger;
    console.log(value, data, "FFFF");
    if (data.productID) {
      let hsn = data.hsnCode;
      setProductsData({ ...productsData, hsnCode: hsn, productId: value, invoiceproductName: data?.productName ,productCode:data?.productCode,   });
      setProductsList({ ...productsList, invoiceproductName: data?.productName, productCode:data?.productCode,});
    } else if (data.keys == 1) {
      setProductsData({
        ...productsData,
        containerId: value,
        containerNo: data?.label,
      });
      setProductsList({ ...productsList, containerNo: value });
    } else if (data.keys == 2) {
      setProductsData({ ...productsData, purchaseOrderNumber: data?.label, purchaseOrderId: value, });
      setProductsList({ ...productsList, purchaseOrderNumber: data?.label, purchaseOrderId: value, });
    } else if (data.name == "boeMasterList") {
      setProductsData({ ...productsData, boeId: value, boe:data?.label, });
      setProductsList({ ...productsList, boeId: value, boe:data?.label, });
    } else if (data.name == "unitOfMeasureList") {
      setProductsData({ ...productsData, unitofmeasureId: value });
      setProductsList({ ...productsList, unitofmeasureId: value });
    } else if(data.name == "countryList"){
      setProductsData({ ...productsData, originId: value, originName:data?.label , });
      setProductsList({ ...productsList, originId: value, originName:data?.label , });
    }
    setDataValues(data);
  };

  const changePrice = (e) => {
    // debugger;
    let data = e * productsData.invoiceproductquantity;
    setProductsData({ ...productsData, pricePerUnit: e, amount: data });
  };

  const Details = (e, value) => {
    console.log(e, value);
    setProductsData({ ...productsData, [value]: e.target.value });
  };
  const onChangeNumber = (e, value) => {
    // debugger;
    console.log(e, "value");
    let data = e * productsData.pricePerUnit;
    // setProductsData({...productsData, [value]: e})
    setProductsData({
      ...productsData,
      invoiceproductquantity: e,
      amount: data,
    });
  };

  console.log(productListing, "productsDataproductsData");
  return (
    <div>
      <div>
        <h2>Product Data</h2>
      </div>
      <div>
        {/* <div className={styles.productHead}>
          <div style={{ display: "flex", gap: "25px" }}>
            {productsDetails &&
              productsDetails.map(
                (val, i) =>
                  val?.isForm && (
                    <div
                      key={i}
                      style={{
                        width:
                          val?.fieldType === "int" ||
                          val?.fieldType === "text" ||
                          val?.fieldType === "double"
                            ? "100%"
                            : "100px",
                      }}
                    >
                      <p className={styles.cssClass}>
                        {val?.fieldName} {val?.required && `*`}
                      </p>
                    </div>
                  )
              )}
            <div style={{ flex: "0 0 auto" }}>
              <p className={styles.cssClass}>Action</p>
            </div>
          </div>
        </div> */}
        <div className={styles.textData}>
          {/* <Row gutter={[16, 16]}> */}
          <div className={styles.containerInputesfielsds} >
            {productsDetails &&
              productsDetails.map(
                (val, i) =>
                  val?.isForm && (
                    <>
                      {(val?.fieldType == "int" ||
                        val?.fieldType == "double") &&
                      val?.apiName == "invoiceproductquantity" ? (
                        <div className={styles.feildswithname}>
                          <div>{val?.fieldName} {val?.required && `*`}</div>
                          <NumericInput
                            style={{
                              width: "100%",
                            }}
                            value={productsData[val?.apiName]}
                            onChange={onChangeNumber}
                            name={productsData[val?.apiName]}
                          />
                        </div>
                      ) : (val?.fieldType == "int" ||
                          val?.fieldType == "double") &&
                        val?.apiName == "pricePerUnit" ? (
                        <div className={styles.feildswithname}>
                          <div>{val?.fieldName} {val?.required && `*`}</div>
                          <NumericInput
                            style={{
                              width: "100%",
                            }}
                            value={productsData[val?.apiName]}
                            onChange={changePrice}
                            name={productsData[val?.apiName]}
                          />
                        </div>
                      ) : (val?.fieldType == "int" ||
                          val?.fieldType == "double") &&
                        val?.apiName == "amount" ? (
                        <div className={styles.feildswithname}>
                          <div>{val?.fieldName} {val?.required && `*`}</div>
                          <Input
                            name={productsData[val?.apiName]}
                            value={productsData[val?.apiName]}
                          />
                        </div>
                      ) : val?.fieldType == "int" ||
                        val?.fieldType == "double" ? (
                        <div className={styles.feildswithname}>
                          <div>{val?.fieldName} {val?.required && `*`}</div>
                          <NumericInput
                            style={{
                              width: "100%",
                            }}
                            value={productsData[val?.apiName]}
                            onChange={setValue}
                            name={productsData[val?.apiName]}
                          />
                        </div>
                      ) : val?.fieldType == "picklist_fk" ? (
                        <>
                          <div className={styles.feildswithname}>
                          <div>{val?.fieldName} {val?.required && `*`}</div>
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              value={productsList[val?.apiName]}
                              name={productsData[val?.apiName]}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              onChange={handleAuto}
                              options={
                                val?.apiName == "invoiceproductName"
                                  ? products.map((obj) => {
                                      const convertedObj = {
                                        value: obj.productID,
                                        label: obj.productCode,
                                        ...obj,
                                      };
                                      return convertedObj;
                                    }) // Add your condition for productID here
                                  : val?.apiName == "containerNo"
                                  ? base_items[val?.picklistData] &&
                                    base_items[val?.picklistData]?.map(
                                      (obj) => {
                                        const keys = Object.keys(obj);
                                        const convertedObj = {
                                          value: obj[keys[0]],
                                          label: obj[keys[1]],
                                          keys: 1,
                                        };

                                        for (let i = 2; i < keys.length; i++) {
                                          convertedObj[keys[i]] = obj[keys[i]];
                                        }

                                        return convertedObj;
                                      }
                                    )
                                  : val?.apiName == "purchaseOrderNumber"
                                  ? base_items[val?.picklistData] &&
                                    base_items[val?.picklistData]?.map(
                                      (obj) => {
                                        const keys = Object.keys(obj);
                                        const convertedObj = {
                                          value: obj[keys[0]],
                                          label: obj[keys[1]],
                                          keys: 2,
                                        };

                                        for (let i = 2; i < keys.length; i++) {
                                          convertedObj[keys[i]] = obj[keys[i]];
                                        }

                                        return convertedObj;
                                      }
                                    )
                                  : base_items[val?.picklistData] &&
                                    base_items[val?.picklistData]?.map(
                                      (obj) => {
                                        const keys = Object.keys(obj);
                                        const convertedObj = {
                                          value: obj[keys[0]],
                                          label: obj[keys[1]],
                                          name: val?.picklistData,
                                        };

                                        for (let i = 2; i < keys.length; i++) {
                                          convertedObj[keys[i]] = obj[keys[i]];
                                        }

                                        return convertedObj;
                                      }
                                    )
                              }
                            />
                          </div>
                        </>
                      ) : val?.fieldType == "date" ? (
                        <div className={styles.feildswithname}>
                          <div>{val?.fieldName} {val?.required && `*`}</div>
                          <DatePicker
                            name={productsData[val?.apiName]}
                            onChange={onChange}
                            style={{ width: "100%" }}
                          />
                        </div>
                      ) : val?.fieldType == "text" ? (
                        <div className={styles.feildswithname}>
                          <div>{val?.fieldName}</div>
                          <Input
                            name={productsData[val?.apiName]}
                            value={productsData[val?.apiName]}
                            onChange={(e) => Details(e, val?.apiName)}
                          />
                        </div>
                      ) : (
                        <div className={styles.feildswithname}>
                          {/* <div>{val?.fieldName} {val?.required && `*`}</div> */}
                          <Input
                            name={productsData[val?.apiName]}
                            value={productsData[val?.apiName]}
                          />
                        </div>
                      )}
                    </>
                  )
              )}
            <div>
              {" "}
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleData}
                  className={styles.btnsgreen}
                >
                  +
                </Button>
              </Form.Item>
            </div>
                      </div>
          {/* </Row> */}
        </div>
      </div>
    </div>
  );
}

export default ProductsCompo;

/**
           <Row gutter={[16, 16]}>
            {productsDetails &&
              productsDetails.map(
                (val, i) =>
                  val?.isForm && (
                    <>
                      {val?.fieldType == "int" ||
                      val?.fieldType == "text" ||
                      val?.fieldType == "double" ? (
                        <Col xs={24} md={14} lg={2}>
                          <p className={styles.cssClass}>
                            {val?.fieldName} {val?.required && `*`}
                          </p>
                        </Col>
                      ) : (
                        <Col xs={24} md={14} lg={3}>
                          <p className={styles.cssClass}>
                            {val?.fieldName} {val?.required && `*`}
                          </p>
                        </Col>
                      )}
                    </>
                  )
              )}
            <Col xs={24} md={14} lg={2}>
              <p className={styles.cssClass}>Action</p>
            </Col>
          </Row>
 */

/**
 * 
 * 
 <div style={{ display: "flex", gap: "16px" }}>
            {productsDetails &&
              productsDetails.map(
                (val, i) =>
                  val?.isForm && (
                    <div
                      key={i}
                      // style={{
                      //   flex:
                      //     val?.fieldType === "int" ||
                      //     val?.fieldType === "double"
                      //       ? "0 0 7%"
                      //       : "0 0 10%",
                      // }}
                    >
                      {val?.fieldType === "int" &&
                      val?.apiName === "invoiceproductquantity" ? (
                        <NumericInput
                          style={{ width: "100%" }}
                          value={productsData[val?.apiName]}
                          onChange={onChangeNumber}
                          name={productsData[val?.apiName]}
                        />
                      ) : val?.fieldType === "int" &&
                        val?.apiName === "pricePerUnit" ? (
                        <NumericInput
                          style={{ width: "100%" }}
                          value={productsData[val?.apiName]}
                          onChange={changePrice}
                          name={productsData[val?.apiName]}
                        />
                      ) : val?.fieldType === "int" &&
                        val?.apiName === "amount" ? (
                        <Input
                          name={productsData[val?.apiName]}
                          value={productsData[val?.apiName]}
                        />
                      ) : val?.fieldType === "int" ||
                        val?.fieldType === "double" ? (
                        <NumericInput
                          style={{ width: "100%" }}
                          value={productsData[val?.apiName]}
                          onChange={setValue}
                          name={productsData[val?.apiName]}
                        />
                      ) : val?.fieldType === "picklist_fk" ? (
                        <Select
                          showSearch
                          style={{ width: "100px" }}
                          value={productsList[val?.apiName]}
                          name={productsData[val?.apiName]}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          onChange={handleAuto}
                          options={
                            val?.apiName === "invoiceproductName"
                              ? products.map((obj) => ({
                                  value: obj.productID,
                                  label: obj.productName,
                                  ...obj,
                                }))
                              : val?.apiName === "containerNo"
                              ? base_items[val?.picklistData]?.map((obj) => ({
                                  value: obj[Object.keys(obj)[0]],
                                  label: obj[Object.keys(obj)[1]],
                                  keys: 1,
                                  ...obj,
                                }))
                              : val?.apiName === "purchaseOrderNumber"
                              ? base_items[val?.picklistData]?.map((obj) => ({
                                  value: obj[Object.keys(obj)[0]],
                                  label: obj[Object.keys(obj)[1]],
                                  keys: 2,
                                  ...obj,
                                }))
                              : base_items[val?.picklistData]?.map((obj) => ({
                                  value: obj[Object.keys(obj)[0]],
                                  label: obj[Object.keys(obj)[1]],
                                  name: val?.picklistData,
                                  ...obj,
                                }))
                          }
                        />
                      ) : val?.fieldType === "date" ? (
                        <DatePicker
                          name={productsData[val?.apiName]}
                          onChange={onChange}
                          style={{ width: "100%" }}
                        />
                      ) : val?.fieldType === "text" ? (
                        <Input
                          name={productsData[val?.apiName]}
                          value={productsData[val?.apiName]}
                          onChange={(e) => Details(e, val?.apiName)}
                        />
                      ) : (
                        <Input
                          name={productsData[val?.apiName]}
                          value={productsData[val?.apiName]}
                        />
                      )}
                    </div>
                  )
              )}
            <div style={{ flex: "0 0 auto" }}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleData}
                  className={styles.btnsgreen}
                >
                  +
                </Button>
              </Form.Item>
            </div>
          </div>
 * -----------------
 <Row gutter={[16, 16]}>
            {productsDetails &&
              productsDetails.map(
                (val, i) =>
                  val?.isForm && (
                    <>
                      {(val?.fieldType == "int" ||
                        val?.fieldType == "double") &&
                      val?.apiName == "invoiceproductquantity" ? (
                        <Col xs={24} md={14} lg={2}>

                        <NumericInput
                        style={{
                          width: "100%",
                        }}
                        value={productsData[val?.apiName]}
                        onChange={onChangeNumber}
                        name={productsData[val?.apiName]}
                      />
                    </Col>
                  ) : (val?.fieldType == "int" ||
                      val?.fieldType == "double") &&
                    val?.apiName == "pricePerUnit" ? (
                    <Col xs={24} md={14} lg={2}>

                      <NumericInput
                        style={{
                          width: "100%",
                        }}
                        value={productsData[val?.apiName]}
                        onChange={changePrice}
                        name={productsData[val?.apiName]}
                      />
                    </Col>
                  ) : (val?.fieldType == "int" ||
                      val?.fieldType == "double") &&
                    val?.apiName == "amount" ? (
                    <Col xs={24} md={14} lg={2}>
                      <Input
                        name={productsData[val?.apiName]}
                        value={productsData[val?.apiName]}
                      />
                    </Col>
                  ) : val?.fieldType == "int" ||
                    val?.fieldType == "double" ? (
                    <Col xs={24} md={14} lg={2}>

                      <NumericInput
                        style={{
                          width: "100%",
                        }}
                        value={productsData[val?.apiName]}
                        onChange={setValue}
                        name={productsData[val?.apiName]}
                      />
                    </Col>
                  ) : val?.fieldType == "picklist_fk" ? (
                    <>
                      <Col xs={24} md={14} lg={3}>

                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          value={productsList[val?.apiName]}
                          name={productsData[val?.apiName]}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          onChange={handleAuto}
                          options={
                            val?.apiName == "invoiceproductName"
                              ? products.map((obj) => {
                                  const convertedObj = {
                                    value: obj.productID,
                                    label: obj.productName,
                                    ...obj,
                                  };
                                  return convertedObj;
                                }) // Add your condition for productID here
                              : val?.apiName == "containerNo"
                              ? base_items[val?.picklistData] &&
                                base_items[val?.picklistData]?.map(
                                  (obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj[keys[0]],
                                      label: obj[keys[1]],
                                      keys: 1,
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  }
                                )
                              : val?.apiName == "purchaseOrderNumber"
                              ? base_items[val?.picklistData] &&
                                base_items[val?.picklistData]?.map(
                                  (obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj[keys[0]],
                                      label: obj[keys[1]],
                                      keys: 2,
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  }
                                )
                              : base_items[val?.picklistData] &&
                                base_items[val?.picklistData]?.map(
                                  (obj) => {
                                    const keys = Object.keys(obj);
                                    const convertedObj = {
                                      value: obj[keys[0]],
                                      label: obj[keys[1]],
                                      name: val?.picklistData,
                                    };

                                    for (let i = 2; i < keys.length; i++) {
                                      convertedObj[keys[i]] = obj[keys[i]];
                                    }

                                    return convertedObj;
                                  }
                                )
                          }
                        />
                      </Col>
                    </>
                  ) : val?.fieldType == "date" ? (
                    <Col xs={24} md={14} lg={2}>

                      <DatePicker
                        name={productsData[val?.apiName]}
                        onChange={onChange}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  ) : val?.fieldType == "text" ? (
                    <Col xs={24} md={14} lg={2}>

                      <Input
                        name={productsData[val?.apiName]}
                        value={productsData[val?.apiName]}
                        onChange={(e) => Details(e, val?.apiName)}
                      />
                    </Col>
                  ) : (
                    <Col xs={24} md={14} lg={2}>

                      <Input
                        name={productsData[val?.apiName]}
                        value={productsData[val?.apiName]}
                      />
                    </Col>
                  )}
                </>
              )
          )}
        <Col xs={24} md={14} lg={2}>
          {" "}
          <Form.Item>
            <Button
              type="primary"
              onClick={handleData}
              className={styles.btnsgreen}
            >
              +
            </Button>
          </Form.Item>
        </Col>
      </Row>
 */
