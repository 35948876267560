import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../utils/Network';
import styles from "./index.module.css";
import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';


function ViewShipping() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`shippingdoc/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data)
      })
      .catch(err => console.log(err));
  }
  console.log(viewPurchase, "ViewPurchase")


  return (


    <div style={{ width: "100%" }}>
      <div>
        <p className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate(-1)} /> Shipping Doc Details</p>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Shipping Doc ID</p>
              <p>{viewPurchase?.shippingDocID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Bill Of Loading</p>
              <p>{viewPurchase?.billOfLoading}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Bill Of Loading Date</p>
              <p>{moment(viewPurchase?.billOfLoadingDate).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Consignment No</p>
              <p>{viewPurchase?.consignmentNo}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Container No.</p>
              <p>{viewPurchase?.containerNo}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Country Of Origin</p>
              <p>{viewPurchase?.countryOfOriginName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Currency Name</p>
              <p>{viewPurchase?.currencyName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Incoterms </p>
              <p>{viewPurchase?.incotermsName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Invoice Date</p>
              <p>{moment(viewPurchase?.invoiceDate).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Invoice No</p>
              <p>{viewPurchase?.invoiceNo}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Packing</p>
              <p>{viewPurchase?.packing}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Port Of Loading</p>
              <p>{viewPurchase?.portOfLoadingName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Purchase Code</p>
              <p>{viewPurchase?.purchaseCode}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Purchase Order Number</p>
              <p>{viewPurchase?.purchaseOrderNumber}</p>
            </div>

            <div className={styles.contentWrapper}>
              <p>Sales Confirmation Date</p>
              <p>{moment(viewPurchase?.salesConfirmationDate).format("YYYY-MM-DD")}</p>
            </div>

            <div className={styles.contentWrapper}>
              <p>Total Rate</p>
              <p>{viewPurchase?.totalRate}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Total Quantity</p>
              <p>{viewPurchase?.totalQuantity}</p>
            </div>
            <div className={styles.contentWrapper}>
            <p>Total Amount</p>
            <p>{viewPurchase?.totalAmount}</p>
          </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default ViewShipping
