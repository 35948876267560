import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api_call, api_call_token } from "../../../../../utils/Network";
import styles from "./index.module.css";
// import CustomTable from '../../../Components/CustomTable/CustomTable';
import {
  ArrowLeftOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import Modals from "../../../../../Components/Modals/Modals";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Modal,
} from "antd";
import { UserCredsContext } from "../../../../../utils/UserCredsContext";
import NumericInput from "../../../../../Components/NumericInput/NumericInput";
import TextArea from "antd/es/input/TextArea";

function MyForm({
  fieldSupplierAddress,
  selected,
  getViewAddress = () => {},
  id,
  setOpen = () => {},
}) {
  const [form] = Form.useForm();
  const { base_items, set_Base } = useContext(UserCredsContext);
  const [value, setValue] = useState("");

  const handleData = (values) => {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if (
          typeof value === "object" &&
          (value.$d instanceof Date || value._d instanceof Date)
        ) {
          if (value.$d) {
            values[key] = moment(value.$d).format("YYYY-MM-DD");
          } else {
            values[key] = moment(value._d).format("YYYY-MM-DD");
          }
        } else if (typeof value === "string" && !isNaN(value)) {
          values[key] = Number(value);
        }
      }
    }

    let country = base_items["countryList"].findIndex((demo) => {
      return demo.id == values?.country;
    });
    let datas = {
      ...values,
      country: base_items["countryList"][country]?.name,
    };
    console.log(datas, "djdjjd");
    if (selected?.addressID) {
      api_call_token
        .patch(`buyeraddress/${selected?.addressID}`, {
          ...datas,
          buyerID: +id,
        })
        .then((res) => {
          set_Base();
          form.resetFields();
          getViewAddress();
          setOpen(false);
        })
        .catch((err) => console.log(err));
    } else {
      api_call_token
        .post(`buyeraddress`, { ...datas, buyerID: +id })
        .then((res) => {
          set_Base();
          form.resetFields();
          getViewAddress();
          setOpen(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const onChange = () => {
    console.log("Hello");
  };

  console.log(selected, "SELT");
  return (
    <div>
      <Form form={form} initialValues={selected} onFinish={handleData}>
        <Row gutter={[26, 0]}>
          <div style={{ display: "flex" }}>
            <div>
              {fieldSupplierAddress.length > 0 &&
                fieldSupplierAddress.map((val, i) =>
                  i < 5 ? (
                    <>
                      {val?.isForm && (
                        <>
                          {(val?.fieldType == "int" ||
                            val?.fieldType == "double") &&
                          val?.apiName == "pincode" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                  {
                                    pattern: /^\d{6}$/,
                                    message:
                                      "Please enter a valid 6-digit pin code",
                                  },
                                ]}
                              >
                                <NumericInput
                                  style={{
                                    width: "100%",
                                  }}
                                  value={value}
                                  onChange={setValue}
                                />
                              </Form.Item>
                            </Col>
                          ) : val?.fieldType == "int" ||
                            val?.fieldType == "double" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                ]}
                              >
                                <NumericInput
                                  style={{
                                    width: "100%",
                                  }}
                                  value={value}
                                  onChange={setValue}
                                />
                              </Form.Item>
                            </Col>
                          ) : val?.fieldType == "picklist_fk" ? (
                            <>
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.apiName}!`,
                                    },
                                  ]}
                                >
                                  {base_items && (
                                    <Select
                                      showSearch
                                      style={{
                                        width: "100%",
                                      }}
                                      placeholder="Search to Select"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      options={
                                        base_items[val?.picklistData] &&
                                        base_items[val?.picklistData]?.map(
                                          (obj) => {
                                            const keys = Object.keys(obj);
                                            const convertedObj = {
                                              value: obj[keys[0]],
                                              label: obj[keys[1]],
                                            };

                                            for (
                                              let i = 2;
                                              i < keys.length;
                                              i++
                                            ) {
                                              convertedObj[keys[i]] =
                                                obj[keys[i]];
                                            }

                                            return convertedObj;
                                          }
                                        )
                                      }
                                    />
                                  )}
                                </Form.Item>
                              </Col>
                            </>
                          ) : val?.fieldType == "date" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                ]}
                              >
                                <DatePicker
                                  onChange={onChange}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                          ) : val?.fieldType == "boolean" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item name={val?.apiName}>
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                />
                              </Form.Item>
                            </Col>
                          ) : val?.apiName == "addressLine1" ||
                            val?.apiName == "addressLine2" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                ]}
                              >
                                <Input />

                                {/* <TextArea rows={2} /> */}
                              </Form.Item>
                            </Col>
                          ) : (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          )}
                        </>
                      )}{" "}
                    </>
                  ) : (
                    <></>
                  )
                )}
            </div>

            <div>
              {fieldSupplierAddress.length > 0 &&
                fieldSupplierAddress.map((val, i) =>
                  i > 4 ? (
                    <>
                      {val?.isForm && (
                        <>
                          {(val?.fieldType == "int" ||
                            val?.fieldType == "double") &&
                          val?.apiName == "pincode" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                  {
                                    pattern: /^\d{6}$/,
                                    message:
                                      "Please enter a valid 6-digit pin code",
                                  },
                                ]}
                              >
                                <NumericInput
                                  style={{
                                    width: "100%",
                                  }}
                                  value={value}
                                  onChange={setValue}
                                />
                              </Form.Item>
                            </Col>
                          ) : val?.fieldType == "int" ||
                            val?.fieldType == "double" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                ]}
                              >
                                <NumericInput
                                  style={{
                                    width: "100%",
                                  }}
                                  value={value}
                                  onChange={setValue}
                                />
                              </Form.Item>
                            </Col>
                          ) : val?.fieldType == "picklist_fk" ? (
                            <>
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.apiName}!`,
                                    },
                                  ]}
                                >
                                  {base_items && (
                                    <Select
                                      showSearch
                                      style={{
                                        width: "100%",
                                      }}
                                      placeholder="Search to Select"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      options={
                                        base_items[val?.picklistData] &&
                                        base_items[val?.picklistData]?.map(
                                          (obj) => {
                                            const keys = Object.keys(obj);
                                            const convertedObj = {
                                              value: obj[keys[0]],
                                              label: obj[keys[1]],
                                            };

                                            for (
                                              let i = 2;
                                              i < keys.length;
                                              i++
                                            ) {
                                              convertedObj[keys[i]] =
                                                obj[keys[i]];
                                            }

                                            return convertedObj;
                                          }
                                        )
                                      }
                                    />
                                  )}
                                </Form.Item>
                              </Col>
                            </>
                          ) : val?.fieldType == "date" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                ]}
                              >
                                <DatePicker
                                  onChange={onChange}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                          ) : val?.fieldType == "boolean" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item name={val?.apiName}>
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                />
                              </Form.Item>
                            </Col>
                          ) : val?.apiName == "addressLine1" ||
                            val?.apiName == "addressLine2" ? (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                ]}
                              >
                                <Input />

                                {/* <TextArea rows={2} /> */}
                              </Form.Item>
                            </Col>
                          ) : (
                            <Col xs={24} md={14} lg={24}>
                              <span>
                                {val?.label} {val?.required && `*`}
                              </span>
                              <Form.Item
                                name={val?.apiName}
                                rules={[
                                  {
                                    required: val?.required,
                                    message: `Please Enter ${val?.apiName}!`,
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          )}
                        </>
                      )}{" "}
                    </>
                  ) : (
                    <></>
                  )
                )}
            </div>
          </div>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default MyForm;
