import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { api_call_token } from "../../../utils/Network";
import { alertMessage } from "../../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { falseChecker, isAnyKeyTruthy } from "../../../utils/Utils";
import styles from "./index.module.css";

function HeatIngot({
  setCurrentStep = () => {},
  id,
  heatValues,
  ingots,
  fillings,
  heatid,
}) {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [apiOne, setapiOne] = useState(false);
  const [apiTwo, setapiOTwo] = useState(false);
  const [apiOnes, setapiOnes] = useState(false);
  const [apiTwos, setapiOTwos] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [averages, setAverages] = useState([]);
  const [initialIngot, setInitialIngot] = useState([]);
  const [initialFill, setInitialFill] = useState({});
  const [totals, setTotals] = useState({
    ingotPiece: 0,
    ingotWeight: 0,
  });
  useEffect(() => {
    getCurrentStatus();
  }, [apiOne, apiTwo]);

  useEffect(() => {
    getUpdateCurrentStatus();
  }, [apiOnes, apiTwos]);

  useEffect(() => {
    getIngots(heatValues?.id);
    getFillings(heatValues?.id);
  }, []);

  const getIngots = (vals) => {
    api_call_token
      .get(`heatingot/${vals}`)
      .then((res) => {
        console.log(res, "FFFFFFssss");
        setInitialIngot(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getFillings = (vals) => {
    api_call_token
      .get(`heatfilling/${vals}`)
      .then((res) => {
        console.log(res, "FFFFFFssss");
        setInitialFill(res.data[0]);
      })
      .catch((err) => console.log(err));
  };
  const getUpdateCurrentStatus = () => {
    if (apiOnes && apiTwos) {
      // alertMessage("Batch Process Updated ")
      setTimeout(() => {
        setIsLoading(false);
        setCurrentStep((prev) => prev + 1);
        // navigate('/heat')
      }, 1000);
    }
  };

  const getCurrentStatus = () => {
    if (apiOne && apiTwo) {
      // alertMessage("Batch Process Created ")
      setTimeout(() => {
        setIsLoading(false);
        setCurrentStep((prev) => prev + 1);
        // navigate('/heat')
      }, 1000);
    }
  };

  const handleFinish = (value) => {
    setIsLoading(true);
    const { ingotList, ...rest } = value;
    if (ingotList.length > 0) {
      postIngot(ingotList);
      heatFilling(rest);
    } else {
      setIsLoading(false);
      alertMessage("Ingot cannot be empty", 2);
    }
  };

  const postIngot = (value) => {
    console.log(value, "VALUESsss");
    // const modifiedData = value.map((item) => ({
    //   heatingotId: item.heatingotId === undefined ? null : item.heatingotId,
    //   ingotPiece: +item.ingotPiece,
    //   ingotNettWeight: +item.ingotNettWeight,
    //   ingotGrossWeight: +item.ingotGrossWeight,
    // }));

    const isInvalidValue = (val) =>
      val === undefined || val === null || val === "";

    const modifiedData = value
      .map((item) => {
        const { heatingotId, ingotPiece, ingotNettWeight, ingotGrossWeight } =
          item;

        if (
          isInvalidValue(ingotPiece) ||
          isInvalidValue(ingotNettWeight) ||
          isInvalidValue(ingotGrossWeight)
        ) {
          return null;
        }

        return {
          heatingotId: isInvalidValue(heatingotId) ? null : heatingotId,
          ingotPiece: +ingotPiece,
          ingotNettWeight: +ingotNettWeight,
          ingotGrossWeight: +ingotGrossWeight,
        };
      })
      .filter((item) => item !== null);
    let heatList;
    if (modifiedData?.length > 0) {
      heatList = modifiedData;
    } else {
      alertMessage("Ingot cannot be empty", 2);
    }
    let data = {
      heatId: heatValues?.id,
      heatNumber: heatValues?.heatNumber,
      ingotList: heatList,
    };

    console.log(data, "DATASSSSSSSSS");
    if (heatid) {
      if (initialIngot.length > 0) {
        api_call_token
          .patch(`heatingot/${initialIngot[0]?.heatId}`, data)
          .then((res) => {
            if (res.status == 200 || res.status == 201 || res.status == 204) {
              setapiOnes(true);
            }
          })
          .catch((err) => console.log(err));
      } else {
        api_call_token
          .post(`heatingot`, data)
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              setapiOnes(true);
            }
          })
          .catch((err) => console.log(err));
      }
      // api_call_token
      //   .patch(`heatingot/${heatid}`, data)
      //   .then((res) => {
      //     if (res.status == 200 || res.status == 201 || res.status == 204) {
      //       setapiOnes(true);
      //     }
      //   })
      //   .catch((err) => console.log(err));
    } else {
      if (initialIngot.length > 0) {
        api_call_token
          .patch(`heatingot/${initialIngot[0]?.heatId}`, data)
          .then((res) => {
            if (res.status == 200 || res.status == 201 || res.status == 204) {
              setapiOne(true);
            }
          })
          .catch((err) => console.log(err));
      } else {
        api_call_token
          .post(`heatingot`, data)
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              setapiOne(true);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const heatFilling = (rests) => {
    console.log(fillings, rests, "RRRRRRRRRrr");
    let falseCheck = isAnyKeyTruthy(rests);

    console.log(falseCheck, "FFFFFFff");
    const { ...rest } = rests;
    // let time = (endTime?.$d) ? moment(endTime.$d).format('HH:mm:ss') : (endTime?._d) ? moment(endTime._d).format('HH:mm:ss') : null;;
    let data = {
      heatId: heatValues?.id,
      heatNumber: heatValues?.heatNumber,
      // endTime: time,
      ...rest,
    };

    if (heatid) {
      if (falseCheck) {
        if (fillings?.length > 0 && fillings[0]?.heatId) {
          api_call_token
            .patch(`heatfilling/${heatid}`, data)
            .then((res) => {
              if (res.status == 200 || res.status == 201 || res.status == 204) {
                setapiOTwos(true);
              }
            })
            .catch((err) => console.log(err));
        } else {
          api_call_token
            .post(`heatfilling`, data)
            .then((res) => {
              if (res.status == 200 || res.status == 201) {
                setapiOTwos(true);
              }
            })
            .catch((err) => console.log(err));
        }
      } else {
        setapiOTwos(true);
      }
    } else {
      console.log(initialFill, "INITT");
      if (initialFill?.heatId) {
        api_call_token
          .patch(`heatfilling/${initialFill?.heatId}`, data)
          .then((res) => {
            if (res.status == 200 || res.status == 201 || res.status == 204) {
              setapiOTwo(true);
            }
          })
          .catch((err) => console.log(err));
      } else {
        api_call_token
          .post(`heatfilling`, data)
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              setapiOTwo(true);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const calculateAverages = (fields) => {
    console.log(fields, "fields");
    const newAverages = fields.map((field) => {
      const ingotWeightValue = form.getFieldValue([
        field.name,
        "ingotNettWeight",
      ]);
      const ingotPieceValue = form.getFieldValue([field.name, "ingotPiece"]);
      return ingotWeightValue / ingotPieceValue;
    });
    setAverages(newAverages);
  };

  const calculateRowAverage = (fields, name) => {
    const ingotPiece = form.getFieldValue(["ingotList", name, "ingotPiece"]);
    const ingotWeight = form.getFieldValue([
      "ingotList",
      name,
      "ingotNettWeight",
    ]);

    if (ingotPiece !== undefined && ingotWeight !== undefined) {
      return (ingotWeight / ingotPiece).toFixed(2);
    } else {
      return 0;
    }
  };

  const calculateAveragesss = () => {
    // console.log(v,"VVVVVVV")
    let ingotPiece = 0;
    let data = form.getFieldValue("ingotList");

    console.log(form.getFieldValue(), "HHHHH");
    data &&
      data.map((x, i) => {
        if (x?.ingotPiece) {
          ingotPiece = +ingotPiece + +x?.ingotPiece || 0;
        }
      });

    return ingotPiece;
  };

  const calculateAverageWeight = () => {
    // console.log(v,"VVVVVVV")
    let ingotWeight = 0;
    let data = form.getFieldValue("ingotList");

    console.log(form.getFieldValue(), "HHHHH");
    data &&
      data.map((x, i) => {
        if (x?.ingotNettWeight) {
          ingotWeight = +ingotWeight + +x?.ingotNettWeight || 0;
        }
      });

    return ingotWeight;
  };
  console.log(ingots, averages, "FFFFFF");
  return (
    <div style={{ padding: "12px" }}>
      <Form
        onFinish={handleFinish}
        form={form}
        onFieldsChange={(changedFields, allFields) => {
          calculateAverages(allFields);
        }}
        initialValues={{
          rough: fillings[0]?.rough,
          dross: fillings[0]?.dross,
          part: fillings[0]?.part,
          other: fillings[0]?.other,
          // endTime: fillings[0]?.endTime ? moment(fillings[0]?.endTime, "HH:mm:ss") : undefined,
          ingotList:
            ingots?.length > 0 &&
            ingots?.map(
              ({
                heatingotId,
                ingotPiece,
                ingotNettWeight,
                ingotGrossWeight,
              }) => ({
                heatingotId,
                ingotPiece,
                ingotNettWeight,
                ingotGrossWeight,
              })
            ),
        }}
      >
        <div className={styles.ingotView}>
          <table style={{ borderSpacing: "0" }}>
            <tbody>
              <tr>
                <td
                  className={`${styles.left} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}
                >
                  <p>Rough</p>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                        message: `Please Enter rough!`,
                      },
                    ]}
                    name="rough"
                    // label="Start Oil Usage (cm)"
                  >
                    <Input placeholder="Enter Rough" type="number" />
                  </Form.Item>
                </td>
                <td
                  className={`${styles.left} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}
                >
                  <p>Dross</p>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                        message: `Please Enter Dross!`,
                      },
                    ]}
                    name="dross"
                    // label="Start Oil Usage (cm)"
                  >
                    <Input placeholder="Enter in Dross" type="number" />
                  </Form.Item>
                </td>
                <td
                  className={`${styles.left} ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}
                >
                  <p>Part</p>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                        message: `Please Enter part!`,
                      },
                    ]}
                    name="part"
                    // label="Start Oil Usage (cm)"
                  >
                    <Input placeholder="Enter Part" type="number" />
                  </Form.Item>
                </td>
                <td
                  className={`${styles.left}  ${styles.bottom} ${styles.top} ${styles.widthTwenty} ${styles.right} ${styles.padding}`}
                >
                  <p>Other</p>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                        message: `Please Enter oil!`,
                      },
                    ]}
                    name="other"
                    // label="Start Oil Usage (cm)"
                  >
                    <Input placeholder="Enter Other" type="number" />
                  </Form.Item>
                </td>
                {/* <td className={`${styles.left}  ${styles.bottom} ${styles.right} ${styles.top} ${styles.widthTwenty} ${styles.padding}`}>
                                    <p>End Time</p>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: false,
                                                message: `Please Enter oilEnd!`,
                                            },
                                        ]}
                                        name="endTime"
                                    // label="Start Oil Usage (cm)"
                                    >
                                        <TimePicker placeholder="Select End Time" style={{ width: "100%" }} />
                                    </Form.Item>
                                </td> */}
              </tr>
              <tr>
                <td
                  colspan="5"
                  style={{ width: "70%", margin: "0 auto" }}
                  className={`${styles.left}  ${styles.bottom} ${styles.right} ${styles.padding}`}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        marginBottom: "20px",
                        display: "block",
                      }}
                    >
                      Enter Ingot Values
                    </span>
                    <div style={{ fontWeight: "bold" }}>
                      <div style={{ margin: "0 10px" }}>
                        Total Piece: {calculateAveragesss()}
                      </div>
                      Total Net Weight (kg): {calculateAverageWeight()}
                    </div>
                  </div>
                  <Form.List name="ingotList">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <div style={{ display: "none" }}>
                              <span>Ingot Id</span>
                              <Form.Item
                                {...restField}
                                name={[name, "heatingotId"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "Missing ingot",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="heatingotId"
                                  type="number"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <span>Ingot Number</span>
                              <Form.Item
                                {...restField}
                                name={[name, "ingotPiece"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing ingot",
                                  },
                                ]}
                              >
                                <Input placeholder="ingot" type="number" />
                              </Form.Item>
                            </div>
                            <div>
                              <span>Ingot Net Weight (kg)</span>
                              <Form.Item
                                {...restField}
                                name={[name, "ingotNettWeight"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing ingotQuantity",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="ingotQuantity"
                                  type="number"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <span>Ingot Gross Weight (kg)</span>
                              <Form.Item
                                {...restField}
                                name={[name, "ingotGrossWeight"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing ingot Gross Weight",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="ingot Gross Weight"
                                  type="number"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              Average: {calculateRowAverage(fields, name)}
                            </div>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add field
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </td>
                {/* <Col xs={24} md={14} lg={8}>
                    
                </Col> */}
              </tr>
            </tbody>
          </table>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default HeatIngot;

/*
 
**/
