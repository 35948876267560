import React, { useState, useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Modal,
} from "antd";
import NumericInput from "../../../../../Components/NumericInput/NumericInput";
import moment from "moment";
import { api_call, api_call_token } from "../../../../../utils/Network";
import { alertMessage } from "../../../../../utils/helperFunctions";
import { ArrowLeftOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { UserCredsContext } from "../../../../../utils/UserCredsContext";
import Causion from "../../../../../Assets/Popup/caution.png";
import styles from "./index.module.css";
import { LoadingOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { confirm } = Modal;
function TCform() {
  let { state } = useLocation();
  let navigate = useNavigate();
  const [value, setValue] = useState("");
  const [fieldValues, setFieldValue] = useState([]);
  const { base_items, set_Base } = useContext(UserCredsContext);
  const [confirmShown, setConfirmShown] = useState(false);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [richValue, setRichValue] = useState("");
  useEffect(() => {
    getField();
  }, []);

  useEffect(() => {
    setRichValue(state?.data[0]?.content)
  }, [state])

  const getField = () => {
    api_call_token
      .get(`product/field?search=terms&formtype=create`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setFieldValue(res.data.data);
        } else {
          showConfirm();
        }
      })
      .catch((err) => console.log(err));
  };

  const showConfirm = () => {
    if (!confirmShown) {
      confirm({
        title: "",
        icon: null,
        content: (
          <div style={{ textAlign: "center" }}>
            <img src={Causion} alt="Custom Icon" style={{ width: "60px" }} />
            <p style={{ fontWeight: "bold" }}>
              You do not have access to view this. Kindly contact the admin to
              get access.
            </p>
          </div>
        ),
        // Remove onOk and onCancel
        cancelButtonProps: {
          style: {
            display: "none", // Hide the cancel button
          },
        },
        okButtonProps: {
          // disabled: true,
        },
        okText: "Go Back",
        onOk() {
          navigate("/master/TC");
        },
      });
      setConfirmShown(!confirmShown);
    }
  };

  const handleData = (values) => {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if (
          typeof value === "object" &&
          (value.$d instanceof Date || value._d instanceof Date)
        ) {
          if (value.$d) {
            values[key] = moment(value.$d).format("YYYY-MM-DD");
          } else {
            values[key] = moment(value._d).format("YYYY-MM-DD");
          }
        } else if (typeof value === "string" && !isNaN(value)) {
          values[key] = Number(value);
        }
      }
    }
    console.log(values, richValue, "djdjjd");
    setIsLoading(true);

    if (state?.id) {
      api_call_token
        .patch(`/terms/${state?.id}`, { ...values, content: richValue })
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            set_Base();
            form.resetFields();
            alertMessage("Terms and Condition Updated ");
            setTimeout(() => {
              setIsLoading(false);
              navigate("/master/TC");
            }, 2000);
          } else {
            throw new Error("Invalid response status: " + res.status);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      api_call_token
        .post(`/terms`, { ...values, content: richValue })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            set_Base();
            form.resetFields();
            alertMessage("Terms and Condition Added ");
            setTimeout(() => {
              setIsLoading(false);
              navigate("/master/TC");
            }, 2000);
          } else {
            throw new Error("Invalid response status: " + res.status);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  console.log(state, "STATA");
  return (
    <div style={{ padding: "20px" }}>
      <div>
        <h2>
          <ArrowLeftOutlined
            onClick={() => navigate("/master/TC")}
            style={{ marginRight: "10px" }}
          />
          Create Terms and Condition
        </h2>
      </div>
      <div
        style={{ background: "white", padding: "30px" }}
        className={styles.containerMain}
      >
        <Form
          form={form}
          onFinish={handleData}
          initialValues={{
            ...state?.data[0],
          }}
        >
          <Row gutter={[26, 7]}>
            {fieldValues.length > 0 &&
              fieldValues.map(
                (val, i) =>
                  val?.isForm && (
                    <>
                      {val?.fieldType == "int" || val?.fieldType == "double" ? (
                        <Col xs={24} md={14} lg={24}>
                          <span>
                            {val?.label} {val?.required && `*`}
                          </span>
                          <Form.Item
                            name={val?.apiName}
                            rules={[
                              {
                                required: val?.required,
                                message: `Please Enter ${val?.apiName}!`,
                              },
                            ]}
                          >
                            <NumericInput
                              style={{
                                width: "100%",
                              }}
                              value={value}
                              onChange={setValue}
                            />
                          </Form.Item>
                        </Col>
                      ) : val?.fieldType == "picklist_fk" ? (
                        <>
                          <Col xs={24} md={14} lg={24}>
                            <span>
                              {val?.label} {val?.required && `*`}
                            </span>
                            <Form.Item
                              name={val?.apiName}
                              rules={[
                                {
                                  required: val?.required,
                                  message: `Please Enter ${val?.apiName}!`,
                                },
                              ]}
                            >
                              {base_items && (
                                <Select
                                  showSearch
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                      .toLowerCase()
                                      .localeCompare(
                                        (optionB?.label ?? "").toLowerCase()
                                      )
                                  }
                                  options={
                                    base_items[val?.picklistData] &&
                                    base_items[val?.picklistData]?.map(
                                      (obj) => {
                                        const keys = Object.keys(obj);
                                        const convertedObj = {
                                          value: obj[keys[0]],
                                          label: obj[keys[1]],
                                        };

                                        for (let i = 2; i < keys.length; i++) {
                                          convertedObj[keys[i]] = obj[keys[i]];
                                        }

                                        return convertedObj;
                                      }
                                    )
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </>
                      ) : val?.fieldType == "date" ? (
                        <Col xs={24} md={14} lg={24}>
                          <span>
                            {val?.label} {val?.required && `*`}
                          </span>
                          <Form.Item
                            name={val?.apiName}
                            rules={[
                              {
                                required: val?.required,
                                message: `Please Enter ${val?.apiName}!`,
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={onChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      ) : val?.fieldType == "boolean" ? (
                        <Col xs={24} md={14} lg={24}>
                          <span>
                            {val?.label} {val?.required && `*`}
                          </span>
                          {state?.data[0]?.active ? (
                            <Form.Item name={val?.apiName}>
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                defaultChecked
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item name={val?.apiName}>
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                              />
                            </Form.Item>
                          )}
                        </Col>
                      ) : val?.fieldType == "richtext" ? (
                        <Col xs={24} md={14} lg={24}>
                          <span>
                            {val?.label} {val?.required && `*`}
                          </span>
                          <ReactQuill
                            style={{ width: "100%", marginBottom: "20px" }}
                            theme="snow"
                            value={richValue}
                            onChange={setRichValue}
                          />
                        </Col>
                      ) : (
                        <Col xs={24} md={14} lg={24}>
                          <span>
                            {val?.label} {val?.required && `*`}
                          </span>
                          <Form.Item
                            name={val?.apiName}
                            rules={[
                              {
                                required: val?.required,
                                message: `Please Enter ${val?.apiName}!`,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )
              )}
          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={isLoading ? <LoadingOutlined /> : null}
              loading={isLoading}
            >
              {isLoading ? "Loading..." : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default TCform;
