import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function Breadcrumbs() {
    let navigate = useNavigate();

    let location = useLocation();
    console.log(location, "location")

    let currentLink = '';


    const crumbs = location.pathname.split('/')
        .filter(crumb => crumb !== '')
        .map(crumb => {
            currentLink += `/${crumb}`;
            return (
                <div key={crumb}>
                    <button onClick={() => {
                        let data = currentLink;
                        console.log(data, "dataData")
                        navigate(data)
                    }
                    }>{crumb}</button>
                    {/* {
                        let data = currentLink;
                        <Link to={currentLink}>{crumb}</Link>

                    } */}
                </div>
            )

        })
    return (
        <div>
            {crumbs}
        </div>
  )
}

export default Breadcrumbs