import React, { useState } from "react";
import styles from "./index.module.css";
import { Button, Col, Descriptions, Drawer, Row, Space, Tooltip } from "antd";
import moment from "moment";

const DescriptionItem = ({ title, content }) => (
  <div className={styles.Conatiner}>
    <p className={styles.description}>{title}:</p>
    {content}
  </div>
);

const DrawerComponent = ({ open, setDrawerOpne, data }) => {
  // const [open, setOpen] = useState(false);
  //   const showDrawer = () => {
  //     setOpen(true);
  //   };
  const onClose = () => {
    setDrawerOpne(false);
  };
  const title = () => {
    return (
      <>
        <h3 className={styles.mainTitle}>{data?.purchaseOrderNumber}</h3>
        <h3 className={styles.mainTitle}>
          {data?.purchaseOrderDate
            ? moment(data?.purchaseOrderDate).format("DD-MMMM-YYYY")
            : ""}
        </h3>
      </>
    );
  };
  return (
    <>
      <Drawer
        size="large"
        title={title()}
        onClose={onClose}
        // closable={false}
        open={open}
        extra={
          <Space>
            <div className={styles.tablestatuslist}>
              <Tooltip title="Indent PO">
                <span className={`${styles.boxStyling} ${styles.statusTrue}`}>
                  IP
                </span>
              </Tooltip>
              <Tooltip title="Shipment">
                <span
                  className={`${styles.boxStyling} ${
                    data?.isShippment ? styles.statusTrue : styles.statusFalse
                  }`}
                >
                  SH
                </span>
              </Tooltip>
              <Tooltip title="Document Present">
                <span
                  className={`${styles.boxStyling} ${
                    data?.isDocPresent ? styles.statusTrue : styles.statusFalse
                  }`}
                >
                  DO
                </span>
              </Tooltip>
              {/* <Tooltip title="Bank Payment">
                <span className={`${styles.boxStyling} ${record?.isShippment ? styles.statusTrue : styles.statusFalse }`}>BP</span>
              </Tooltip> */}
              <Tooltip title="BOE Files">
                <span
                  className={`${styles.boxStyling} ${
                    data?.isBOEFile ? styles.statusTrue : styles.statusFalse
                  }`}
                >
                  BO
                </span>
              </Tooltip>
              <Tooltip title="IGM Date">
                <span
                  className={`${styles.boxStyling} ${
                    data?.isIGMDatePresent
                      ? styles.statusTrue
                      : styles.statusFalse
                  }`}
                >
                  IG
                </span>
              </Tooltip>
            </div>
          </Space>
        }
      >
        <div className={styles.mainContainer}>
          <Row>
            <Col span={8}>
              <DescriptionItem
                title="Description"
                layout="vertical"
                content={data?.invoiceproductName}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Size"
                layout="vertical"
                content={data?.containerType}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Wt (in MT)"
                layout="vertical"
                content={data?.invoiceproductquantity}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <DescriptionItem
                title="BE No"
                layout="vertical"
                content={data?.boeNo}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="B/E DT"
                layout="vertical"
                content={
                  data?.boeDate
                    ? moment(data?.boeDate).format("DD-MM-YYYY")
                    : ""
                }
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Company"
                layout="vertical"
                content={data?.buyerName}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <DescriptionItem
                title="Supplier"
                layout="vertical"
                content={data?.supplierName}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Container No"
                layout="vertical"
                content={data?.containerNo}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="BL No"
                layout="vertical"
                content={data?.bl}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <DescriptionItem
                title="Shipping Line"
                layout="vertical"
                content={data?.shpgLine}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="ETA Mun"
                layout="vertical"
                content={
                  data?.etaMun ? moment(data?.etaMun).format("DD-MM-YYYY") : ""
                }
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="IGM Date"
                layout="vertical"
                content={
                  data?.igmDate
                    ? moment(data?.igmDate).format("DD-MM-YYYY")
                    : ""
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <DescriptionItem
                title="Free Days"
                layout="vertical"
                content={data?.freeDay}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Detention Date"
                layout="vertical"
                content={
                  data?.detentionDate
                    ? moment(data?.detentionDate).format("DD-MM-YYYY")
                    : ""
                }
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Days Remaining"
                layout="vertical"
                content={data?.bdaysRemaining}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <DescriptionItem
                title="Vessel"
                layout="vertical"
                content={data?.vessel}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Copy Doc"
                layout="vertical"
                content={data?.copyDoc}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Ori Docs"
                layout="vertical"
                content={data?.oriDoc}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <DescriptionItem
                title="Remarks"
                layout="vertical"
                content={data?.remark}
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Arrival Date"
                layout="vertical"
                content={
                  data?.igmDate
                    ? moment(data?.igmDate).format("DD-MM-YYYY")
                    : ""
                }
              />
            </Col>
            <Col span={8}>
              <DescriptionItem
                title="Port of Final Destination"
                layout="vertical"
                content={data?.finalDestinationName}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <DescriptionItem
                title="CHA"
                layout="vertical"
                content={data?.customHouseAgentName}
              />
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerComponent;
