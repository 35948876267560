import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  api_call,
  api_call_token,
  base_url,
} from "../../../../../utils/Network";
import styles from "./index.module.css";
// import CustomTable from '../../../Components/CustomTable/CustomTable';
import {
  ArrowLeftOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import Modals from "../../../../../Components/Modals/Modals";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Modal,
} from "antd";
import { UserCredsContext } from "../../../../../utils/UserCredsContext";
import NumericInput from "../../../../../Components/NumericInput/NumericInput";
import MyForm from "./MyForm";
import SupplierForm from "./SupplierForm";
import SupplierContact from "./SupplierContact";
import UploadDoc from "./UploadDoc";

function SupplierView() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [value, setValue] = useState("");
  const { base_items } = useContext(UserCredsContext);
  const [viewPurchase, setViewPurchase] = useState({});
  const [viewSupplierAddres, setViewSupplierAddress] = useState([]);
  const [viewSupplierContactAddres, setViewSupplierContactAddress] = useState(
    []
  );
  const [viewData, setView] = useState(0);
  const [open, setOpen] = useState(false);
  const [fieldSupplier, setFieldSupplier] = useState([]);
  const [fieldSupplierAddress, setFieldSupplierAddress] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedIDVal, setSelectedIDVal] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    getListForm();
    getListAddr();
    getViewofPurchase();
    getViewAddress();
    getViewContactAddress();
  }, [id]);

  const getViewofPurchase = () => {
    api_call_token
      .get(`supplier/${id}`)
      .then((res) => {
        console.log(res, "resss");
        setViewPurchase(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getViewAddress = () => {
    api_call_token
      .get(`supplieraddress/${id}`)
      .then((res) => {
        console.log(res, "resss");
        setViewSupplierAddress(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getViewContactAddress = () => {
    api_call_token
      .get(`suppliercontactinfo/${id}`)
      .then((res) => {
        console.log(res, "resss");
        setViewSupplierContactAddress(res.data);
      })
      .catch((err) => console.log(err));
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleOpen = (val) => {
    if (val == 0) {
      setSelected({});
      getViewofPurchase();
    } else if (val == 1) {
      getViewAddress();
      setSelected({});
    } else if (val == 2) {
      getViewAddress();
      setSelected({});
    } else if (val == 3) {
    }
    setView(val);
    setOpen(true);
  };

  const handleDatas = (vals) => {
    let data = vals;
    setSelected(data);
    setSelectedIDVal(1);
    setView(1);
    setOpen(true);
  };

  const handleData = (vals) => {
    let data = vals;
    setSelected(data);
    setSelectedIDVal(2);
    setView(2);
    setOpen(true);
  };

  const getListForm = () => {
    api_call_token
      .get(`product/field?search=supplierEdit&formtype=create`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setFieldSupplier(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getListAddr = () => {
    api_call_token
      .get(`product/field?search=supplierAddress&formtype=create`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setFieldSupplierAddress(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleform = () => {
    form.resetFields();
    setSelected({});
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        for (const key in values) {
          if (values.hasOwnProperty(key)) {
            const value = values[key];
            if (
              typeof value === "object" &&
              (value.$d instanceof Date || value._d instanceof Date)
            ) {
              if (value.$d) {
                values[key] = moment(value.$d).format("YYYY-MM-DD");
              } else {
                values[key] = moment(value._d).format("YYYY-MM-DD");
              }
            } else if (typeof value === "string" && !isNaN(value)) {
              values[key] = Number(value);
            }
          }
        }
        if (values?.supplierContactName) {
          if (selectedIDVal == 1) {
            console.log(values, "ddddddddddd");
            api_call_token
              .patch(`supplieraddress/${selected?.addressID}`, {
                ...values,
                supplierID: +id,
              })
              .then((res) => {
                form.resetFields();
                setOpen(false);
                getViewofPurchase();
                getViewAddress();
              })
              .catch((err) => console.log(err));
          } else {
            api_call_token
              .post(`supplieraddress`, { ...values, supplierID: +id })
              .then((res) => {
                form.resetFields();
                setOpen(false);
                getViewofPurchase();
                getViewAddress();
              })
              .catch((err) => console.log(err));
          }
        } else {
          api_call_token
            .patch(`supplier/${id}`, {
              ...values,
              active: values?.active ? 1 : 0,
            })
            .then((res) => {
              form.resetFields();
              setOpen(false);
              getViewofPurchase();
              getViewAddress();
            })
            .catch((err) => console.log(err));
        }
        console.log(values, "DDDEAT");
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleChange = (val) => {
    console.log(val, "vals");
    api_call_token
      .patch(`supplier/blacklist/${id}`, { blackList: val ? 1 : 0 })
      .then((res) => {
        if(res.status == 200){
          getViewofPurchase();
        }
      })
      .catch((err) => console.log(err));
  };

  console.log(selected, "VALS");

  return (
    <div style={{ width: "100%" }}>
      <div>
        <h2 className={styles.demodata}>
          <ArrowLeftOutlined
            onClick={() => navigate("/master/Supplier")}
            style={{ marginRight: "10px" }}
          />{" "}
          Supplier Domestic Details
        </h2>

        <div className={styles.wrapperContainer}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", margin: "10px" }}>
              <Switch
                checked={viewPurchase?.blackList ? true : false}
                onChange={handleChange}
              />
              <p style={{ margin: "0 10px" }}>BlackList Supplier</p>
            </div>
            <div style={{ textAlign: "right", padding: "10px" }}>
              <EditOutlined onClick={() => handleOpen(0)} />
            </div>
          </div>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Supplier Id</p>
              <p>{viewPurchase?.supplierID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Supplier Name</p>
              <p>{viewPurchase?.supplierName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Supplier Agent Name</p>
              <p>{viewPurchase?.agentName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Manufacturing</p>
              <p>{viewPurchase?.manufacturing}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Annual Turnover</p>
              <p>{viewPurchase?.annualTurnover}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Supplier Currency</p>
              <p>{viewPurchase?.currencyName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Supplier PAN</p>
              <p>{viewPurchase?.supplierPAN}</p>
            </div>
            {/* supplierPAN    */}
          </div>
        </div>

        <div className={styles.wrapperContainer}>
          <div style={{ textAlign: "right", padding: "10px" }}>
            <EditOutlined onClick={() => handleOpen(3)} />
          </div>

          <div className={styles.Container}>
            <div className={styles.contentWrapper} style={{ width: "47%" }}>
              <p>GST Certificate</p>

              {viewPurchase?.copyUploadGSTINCertificate && (
                <p
                  className={styles.btnDownload}
                  onClick={() =>
                    window.open(
                      `${base_url}${
                        viewPurchase?.copyUploadGSTINCertificate?.split(
                          "public/"
                        )[1]
                      }`
                    )
                  }
                >
                  Download File
                </p>
              )}
            </div>
            <div className={styles.contentWrapper} style={{ width: "47%" }}>
              <p>PAN of Certificate</p>
              {viewPurchase?.copyUploadPANofCompany && (
                <p
                  className={styles.btnDownload}
                  onClick={() =>
                    window.open(
                      `${base_url}${
                        viewPurchase?.copyUploadPANofCompany?.split(
                          "public/"
                        )[1]
                      }`
                    )
                  }
                >
                  Download File
                </p>
              )}
            </div>
            <div className={styles.contentWrapper} style={{ width: "47%" }}>
              <p>Company Brochure</p>
              {viewPurchase?.copyUploadCompanyBrochure && (
                <p
                  className={styles.btnDownload}
                  onClick={() =>
                    window.open(
                      `${base_url}${
                        viewPurchase?.copyUploadCompanyBrochure?.split(
                          "public/"
                        )[1]
                      }`
                    )
                  }
                >
                  Download File
                </p>
              )}
            </div>
            <div className={styles.contentWrapper} style={{ width: "47%" }}>
              <p>Bank Details</p>
              {viewPurchase?.copyUploadBankDetail && (
                <p
                  className={styles.btnDownload}
                  onClick={() =>
                    window.open(
                      `${base_url}${
                        viewPurchase?.copyUploadBankDetail?.split("public/")[1]
                      }`
                    )
                  }
                >
                  Download File
                </p>
              )}
            </div>
          </div>
        </div>

        <div className={styles.wrapperContainer}>
          <p style={{ padding: "20px" }}>Address</p>
          <div className={styles.mainAddress}>
            <div className={`${styles.mainAddress}`}>
              {viewSupplierAddres.map((v, i) => (
                <div className={styles.mainAdr}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{ margin: "5px 0" }}>{v.addressLine1}</p>
                    <EditOutlined onClick={() => handleDatas(v)} />
                  </div>
                  <p style={{ margin: "14px 0" }}>{v.addressLine2}</p>
                  <p style={{ margin: "14px 0" }}>
                    {v?.state}, {v?.city} {v?.country}
                  </p>
                  <p style={{ margin: "14px 0" }}>{v?.supplierEmail}</p>
                  <p style={{ margin: "14px 0" }}>{v?.supplierPhone}</p>
                </div>
              ))}
            </div>
            <div className={styles.addSupplier}>
              <PlusOutlined onClick={() => handleOpen(1)} />
            </div>
          </div>
        </div>

        <div className={styles.wrapperContainer}>
          <p style={{ padding: "20px" }}>Contact Info</p>
          <div className={styles.mainAddress}>
            <div className={`${styles.mainAddress}`}>
              {viewSupplierContactAddres.map((v, i) => (
                <div className={styles.mainAdr}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{ margin: "5px 0" }}>
                      {v?.supplierContactInfoName}
                    </p>
                    <EditOutlined onClick={() => handleData(v)} />
                  </div>
                  <p style={{ margin: "14px 0" }}>
                    <span style={{ fontWeight: "500" }}>Address :</span>
                    {v?.supplierContactInfoAddress}
                  </p>
                  <p style={{ margin: "14px 0" }}>
                    <span style={{ fontWeight: "500" }}>Contact Info:</span>{" "}
                    {v?.supplierContactInfoDesignation}
                  </p>
                  <p style={{ margin: "14px 0" }}>
                    <span style={{ fontWeight: "500" }}>GIN :</span>{" "}
                    {v?.supplierContactInfoGIN}
                  </p>
                  <p style={{ margin: "14px 0" }}>
                    <span style={{ fontWeight: "500" }}>Mobile :</span>{" "}
                    {v?.supplierContactInfoMobile}
                  </p>
                </div>
              ))}
            </div>
            <div className={styles.addSupplier}>
              <PlusOutlined onClick={() => handleOpen(2)} />
            </div>
          </div>
        </div>
      </div>

      {open && (
        <Modals
          open={open}
          handleCancel={() => {
            setOpen(false);
            handleform();
            setView(null);
          }}
          handleOk={handleOk}
        >
          {viewData == 0 && (
            <SupplierForm
              fieldSupplierAddress={fieldSupplier}
              selected={viewPurchase}
              getViewAddress={getViewofPurchase}
              id={id}
              setOpen={setOpen}
            />
          )}
          {viewData == 1 && (
            <MyForm
              fieldSupplierAddress={fieldSupplierAddress}
              selected={selected}
              getViewAddress={getViewAddress}
              id={id}
              setOpen={setOpen}
            />
          )}
          {viewData == 2 && (
            <SupplierContact
              selected={selected}
              getViewContactAddress={getViewContactAddress}
              id={id}
              setOpen={setOpen}
            />
          )}
          {viewData == 3 && (
            <UploadDoc
              viewPurchase={viewPurchase}
              getViewofPurchase={getViewofPurchase}
              id={id}
              setOpen={setOpen}
            />
          )}
        </Modals>
      )}
    </div>
  );
}

export default SupplierView;
