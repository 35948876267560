import React, { useState, useContext, useEffect } from "react";
import { UserCredsContext } from "../../../utils/UserCredsContext";
import styles from './index.module.css';
import { Navigate, useNavigate } from "react-router-dom";

function MainHeat() {
    const { base_items } = useContext(UserCredsContext);
    let navigate = useNavigate();

    const redirectUrl = (v) => {
        navigate(`list/${v?.id}`)
    }

    console.log(base_items, "BaseITEM")
    return (
        <div>
            <div>
                <p className={styles.textCss}>Select a Company to proceed</p>
            </div>
            {/* <div className={styles.boxContainer}>

            {
                base_items && base_items.buyerList.map((v, i) => (
                    <div className={styles.box} onClick={() => redirectUrl(v)}>
                        <p style={{color: "#8C2E32"}}>{v?.name}</p>
                    </div>
                ))
            }
        </div> */}

            <div className={styles.boxContainer}>
                {base_items &&
                    base_items.buyerList.map((v, i) => (
                        <div
                            className={styles.box}
                            onClick={() => {
                                if (v.id === 3) {
                                    redirectUrl(v);
                                }
                            }}
                            style={{ cursor: v.id === 3 ? "pointer" : "not-allowed" }}
                        >
                            <p style={{ color: "#8C2E32" }}>{v?.name}</p>
                        </div>
                    ))}
            </div>

        </div>
    )
}

export default MainHeat