import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Modal,
} from "antd";
import React, { useEffect, useState, useContext, useRef } from "react";
import NumericInput from "../../../Components/NumericInput/NumericInput";
import { api_call, api_call_token, formDataApi } from "../../../utils/Network";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { MainContext } from "../../../utils/context";
import { alertMessage } from "../../../utils/helperFunctions";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { UserCredsContext } from "../../../utils/UserCredsContext";
import Causion from "../../../Assets/Popup/caution.png";
import styles from "./index.module.css";
import ContainerComp from "../ContainerComp/ContainerComp";
// import Delete from "../../Assets/Delete.png";
import Delete from "../../../Assets/Delete.png";
const { RangePicker } = DatePicker;
const { confirm } = Modal;

function CreateContainer() {
  const formRef = useRef();
  const { state } = useLocation();
  const [fieldValues, setFieldValue] = useState([]);
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { base_items, set_Base } = useContext(UserCredsContext);
  const [confirmShown, setConfirmShown] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [basicDetails, setBasicDetails] = useState([]);
  const [containerDetails, setContainerDetails] = useState([]);
  const [otherDetails, setOtherDetails] = useState([]);
  const [disabledData, setDisabledData] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [sameVal, setSameValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contaienrInnerfrom, setContainerInnerfrom] = useState();
  const [productListing, setProductList] = useState([]);
  //   const [productsDetails, getProductsDetails] = useState([]);
  //   const [products, getProduct] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    getDataContainer();
    getContainerFrom();
  }, []);

  const getDataContainer = () => {
    api_call_token
      .get(`product/section?search=container&formtype=create`)
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          setFieldValue(res?.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getContainerFrom = () => {
    api_call_token
      .get(`product/section?search=containerInternalForm&formtype=create`)
      .then((res) => {
        if (res?.data) {
          setContainerInnerfrom(res?.data[`Container Data`]);
        }
      });
  };

  const handleArray = (v, idx) => {
    console.log(v, v.productName);
    // const index = productListing.findIndex(
    //   (obj) => obj.productName === v.productName
    // );
    // if (index !== -1)
    productListing.splice(idx, 1);
    setProductList([...productListing]);
  };
  // const getDataContainer = () => {
  //     api_call_token
  //         .get(`product/field?search=container&formtype=create`)
  //         .then((res) => {
  //             if (res?.data?.data) {
  //                 if (res.data.data.length > 0) {
  //                     setIsLoading(false);
  //                     setFieldValue(res?.data?.data)
  //                 } else {
  //                     showConfirm();
  //                 }

  //             }
  //         })
  //         .catch((err) => console.log(err))
  // }

  const showConfirm = () => {
    if (!confirmShown) {
      confirm({
        title: "",
        icon: null,
        content: (
          <div style={{ textAlign: "center" }}>
            <img src={Causion} alt="Custom Icon" style={{ width: "60px" }} />
            <p style={{ fontWeight: "bold" }}>
              You do not have access to view this. Kindly contact the admin to
              get access.
            </p>
          </div>
        ),
        // Remove onOk and onCancel
        cancelButtonProps: {
          style: {
            display: "none", // Hide the cancel button
          },
        },
        okButtonProps: {
          // disabled: true,
        },
        okText: "Go Back",
        onOk() {
          navigate("/container");
        },
      });
      setConfirmShown(!confirmShown);
    }
  };
  const handleData = async (values) => {
    // debugger;
    // let estTimeOfArrival = values['estTimeOfArrival'].format('YYYY-MM-DD HH:mm:ss');
    // console.log({...values, estTimeOfArrival}, "Datas")
    if (sameVal) {
      if (state?.data?.containerNo == values.containerNo) {
      } else {
        alertMessage("The Consignment number should be unique", 2);
        return;
      }
    }
    // setDisabledData(true);
    console.log({ ...values }, "DD");
    const filterTruthyValues = (obj) => {
      const result = {};
      Object.entries(obj).forEach(([key, value]) => {
        if (value) {
          result[key] = value;
        }
      });
      return result;
    };

    const filteredData = filterTruthyValues(values);
    console.log(filteredData);

    for (const key in filteredData) {
      if (filteredData.hasOwnProperty(key)) {
        const value = filteredData[key];
        if (
          [
            "estTimeOfArrival",
            "estTimeOfDeparture",
            "timeIn",
            "timeOut",
          ].includes(key)
        ) {
          // Assuming the values for these keys are in a specific date and time format
          if (value?.$d) {
            filteredData[key] = moment(value?.$d, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            );
          } else {
            filteredData[key] = moment(value?._d, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        } else if (
          typeof value === "object" &&
          (value?.$d instanceof Date || value?._d instanceof Date)
        ) {
          if (value?.$d) {
            filteredData[key] = moment(value.$d).format("YYYY-MM-DD");
          } else {
            filteredData[key] = moment(value._d).format("YYYY-MM-DD");
          }
        } else if (typeof value === "string" && !isNaN(value)) {
          filteredData[key] = Number(value);
        }
      }
    }

    var data = {
      ...filteredData,
      containerdata: productListing,
    };

    console.log(data, "newdatatouploade");

    api_call_token
      .post(`container/addcontainer`, data)
      .then((res) => {
        console.log(res?.data);
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setDisabledData(false);
          alertMessage("Container Updated ");
          setTimeout(() => {
            setLoading(false);

            set_Base();

            // navigate('/container/addProducts', { state: res })
            navigate(`/container`);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        setDisabledData(false);
      });

    // const fmData = new FormData();
    // for (const key in filteredData) {
    //   if (filteredData.hasOwnProperty(key)) {
    //     const value = filteredData[key];
    //     fmData.append(key, value);
    //   }
    // }

    // console.log(...fmData, "DDDAT");
    // if (state) {
    //   setLoading(true);
    //   let apiEnd = `/${state.id}`;
    //   try {
    //     const res = await formDataApi(apiEnd, fmData, true);
    //     if (res) {
    //       setDisabledData(false);
    //       alertMessage("Container Updated ");
    //       setTimeout(() => {
    //         setLoading(false);

    //         set_Base();

    //         // navigate('/container/addProducts', { state: res })
    //         navigate(`/container`);
    //       }, 2000);
    //     }
    //   } catch (error) {
    //     setDisabledData(false);
    //     console.log(error);
    //   }
    // } else {
    //   setLoading(true);
    //   let apiEnd = "";
    //   try {
    //     const res = await formDataApi(apiEnd, fmData);

    //     console.log(res.status, "ressss");
    //     if (res.status == 200 || res.status == 201) {
    //       alertMessage("Container Created ");
    //       setTimeout(() => {
    //         setLoading(false);
    //         set_Base();
    //         // navigate('/container/addProducts', { state: res })
    //         navigate(`/container`);
    //       }, 2000);
    //     }
    //   } catch (error) {
    //     setDisabledData(false);
    //     console.log(error);
    //   }
    // }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // const handleInputChange = (event) => {
  //     const { value } = event.target;
  //     setInputValue(value);
  //     api_call_token
  //     .get(`container/containerDuplicate/${value}`)
  //     .then((res) => {
  //         if(res?.data){
  //             setSameValue(prev => res.data)
  //         }else{
  //             setSameValue(prev => res.data)
  //         }
  //     })
  //     .catch(err => console.log(err));

  //     // Debounce the API call to avoid making too many requests in a short time
  //     // const debouncedAPICall = debounce(handleAPICall, 500);
  //     // debouncedAPICall(value);
  //   };

  const validateField = async (rule, value) => {
    try {
      // Perform the API call
      const res = await api_call_token.get(
        `container/containerDuplicate/${value}`
      );
      if (state?.data?.containerNo == value) {
        return Promise.resolve();
      }
      // Assuming the API returns a boolean response
      const isDuplicate = !!res?.data;
      setSameValue(isDuplicate);

      if (!isDuplicate) {
        // Validation passes if the API response is false
        return Promise.resolve();
      } else {
        // Validation fails if the API response is true
        return Promise.reject("Container Number should be unique");
      }
    } catch (err) {
      console.error("Error fetching API:", err);
      return Promise.reject(
        "Validation failed: An error occurred while fetching data."
      );
    }
  };

  //   const validateField = (rule, value) => {
  //     return new Promise((resolve, reject) => {
  //       // Validation passes if sameValue is false
  //       if (!sameVal) {
  //         resolve();
  //       } else {
  //         reject('Validation failed: Set your custom error message here');
  //       }
  //     });
  //   };

  const handleAddress = (e) => {
    console.log(e, "DataS");
    formRef?.current?.resetFields(["addressID"]);
    api_call_token
      .get(`/formData/supplierAddress/${e}`)
      .then((res) => {
        console.log(res, "res_DAta");
        setAddressList(res.data.supplierAddressList);
      })
      .catch((err) => console.log(err));
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  // console.log(state, fieldValues, "state")
  console.log(sameVal, fieldValues, "SETPRODUCTLIST");
  return (
    <div style={{ width: "100%", padding: "13px", boxSizing: "border-box" }}>
      <div>
        <h2>
          {" "}
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            style={{ marginRight: "10px" }}
          />{" "}
          Add Container
        </h2>
      </div>
      {isLoading ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        </>
      ) : (
        <div
          style={{ padding: "10px 30px", background: "white" }}
          className={styles.containerMain}
        >
          <Form
            form={form}
            ref={formRef}
            onFinish={handleData}
            initialValues={{
              ...state?.data,

              deStuff: state?.data?.deStuff
                ? moment(state?.data?.deStuff.split("T")[0], "YYYY-MM-DD")
                : undefined,
              // esimateTimeOfArrivalAtFinalDestination: state?.data?.esimateTimeOfArrivalAtFinalDestination ? moment(state?.data?.esimateTimeOfArrivalAtFinalDestination.split('T')[0] + '' + state?.data?.esimateTimeOfArrivalAtFinalDestination.split('T')[1], "YYYY-MM-DD HH:mm:ss") : undefined,
              blDate: state?.data?.blDate
                ? moment(state?.data?.blDate.split("T")[0], "YYYY-MM-DD")
                : undefined,
              // esimateTimeOfArrivalAtPortOfDispatch: state?.data?.esimateTimeOfArrivalAtPortOfDispatch ? moment(state?.data?.esimateTimeOfArrivalAtPortOfDispatch.split('T')[0] + '' + state?.data?.esimateTimeOfArrivalAtPortOfDispatch.split('T')[1], "YYYY-MM-DD HH:mm:ss") : undefined,
              esimateTimeOfArrivalAtFinalDestination: state?.data
                ?.esimateTimeOfArrivalAtFinalDestination
                ? moment(
                    state?.data?.esimateTimeOfArrivalAtFinalDestination.split(
                      "T"
                    )[0],
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : undefined,
              esimateTimeOfArrivalAtPortOfDispatch: state?.data
                ?.esimateTimeOfArrivalAtPortOfDispatch
                ? moment(
                    state?.data?.esimateTimeOfArrivalAtPortOfDispatch.split(
                      "T"
                    )[0],
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : undefined,
              estTimeOfArrival: state?.data?.estTimeOfArrival
                ? moment(
                    state?.data?.estTimeOfArrival.split("T")[0],
                    "YYYY-MM-DD"
                  )
                : undefined,
              estTimeOfDeparture: state?.data?.estTimeOfDeparture
                ? moment(
                    state?.data?.estTimeOfDeparture.split("T")[0],
                    "YYYY-MM-DD"
                  )
                : undefined,
              outOfCharge: state?.data?.outOfCharge
                ? moment(state?.data?.outOfCharge.split("T")[0], "YYYY-MM-DD")
                : undefined,
              timeIn: state?.data?.timeIn
                ? moment(
                    state?.data?.timeIn.split("T")[0] +
                      " " +
                      state?.data?.timeIn.split("T")[1],
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : undefined,
              timeOut: state?.data?.timeOut
                ? moment(
                    state?.data?.timeOut.split("T")[0] +
                      " " +
                      state?.data?.timeOut.split("T")[1],
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : undefined,
              purchaseOrderNumber: state?.data?.purchaseOrderNumber
                ? +state?.data?.purchaseOrderNumber
                : null,
              igmDate: state?.data?.igmDate
                ? moment(state?.data?.igmDate.split("T")[0], "YYYY-MM-DD")
                : undefined,
              shippingDate: state?.data?.shippingDate
                ? moment(state?.data?.shippingDate.split("T")[0], "YYYY-MM-DD")
                : undefined,
              placeOfReceipt: state?.data?.placeOfReceipt
                ? +state?.data?.placeOfReceipt
                : null,
              // billOfEntry: state?.data?.billOfEntry ? moment(state?.data?.billOfEntry.split('T')[0], "YYYY-MM-DD") : undefined,
            }}
          >
            <div className={styles.Div}>
              <div className={styles.formDetails}>
                <h3>Basic Details</h3>

                <Row gutter={[32, 0]}>
                  {Object.keys(fieldValues).length !== 0 &&
                    fieldValues["Basic Details"].slice(0, 7).map(
                      (val, i) =>
                        val?.isForm && (
                          <>
                            {val?.fieldType == "int" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <NumericInput
                                    style={{
                                      width: "100%",
                                    }}
                                    value={value}
                                    onChange={setValue}
                                  />
                                </Form.Item>
                              </Col>
                            ) : val?.fieldType == "picklist_fk" ? (
                              <>
                                <Col xs={24} md={14} lg={24}>
                                  <span>
                                    {val?.label} {val?.required && `*`}
                                  </span>
                                  <Form.Item
                                    name={val?.apiName}
                                    rules={[
                                      {
                                        required: val?.required,
                                        message: `Please Enter ${val?.label}!`,
                                      },
                                    ]}
                                  >
                                    {base_items && (
                                      <Select
                                        showSearch
                                        style={{
                                          width: "100%",
                                        }}
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                          (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                              (
                                                optionB?.label ?? ""
                                              ).toLowerCase()
                                            )
                                        }
                                        onChange={
                                          val?.apiName == "supplierID"
                                            ? handleAddress
                                            : (e) => console.log(e)
                                        }
                                        options={
                                          val?.apiName == "addressID"
                                            ? addressList?.map((obj) => {
                                                const keys = Object.keys(obj);
                                                const convertedObj = {
                                                  value: obj[keys[0]],
                                                  label: obj[keys[1]],
                                                };

                                                for (
                                                  let i = 2;
                                                  i < keys.length;
                                                  i++
                                                ) {
                                                  convertedObj[keys[i]] =
                                                    obj[keys[i]];
                                                }

                                                return convertedObj;
                                              })
                                            : base_items[
                                                val?.picklistData
                                              ]?.map((obj) => {
                                                const keys = Object.keys(obj);
                                                const convertedObj = {
                                                  value: obj[keys[0]],
                                                  label: obj[keys[1]],
                                                };

                                                for (
                                                  let i = 2;
                                                  i < keys.length;
                                                  i++
                                                ) {
                                                  convertedObj[keys[i]] =
                                                    obj[keys[i]];
                                                }

                                                return convertedObj;
                                              })
                                        }
                                      />
                                    )}
                                  </Form.Item>
                                </Col>
                              </>
                            ) : val?.fieldType === "date" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    onChange={onChange}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            ) : val?.fieldType === "datetime" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    onChange={onChange}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            ) : val?.fieldType === "text" &&
                              val?.apiName == "containerNo" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                    {
                                      validator: (rule, value) => {
                                        if (
                                          !/^[A-Z]{4}\d{7}\d?[A-Z]?$/i.test(
                                            value
                                          )
                                        ) {
                                          // if (!/^[A-Z]{4}\d{6}\d$/i.test(value)) {
                                          return Promise.reject(
                                            "Invalid Container Identification Format"
                                          );
                                        }
                                        // If the input matches the regex, return Promise.resolve();
                                        return Promise.resolve();
                                      },
                                    },
                                    {
                                      validator: validateField,
                                    },
                                    // {
                                    //     validator: (_, value) => {
                                    //       // Dynamic rule based on the API response
                                    //       //   debugger;
                                    //       if (sameVal) {
                                    //         return Promise.reject(`Container Number can't be same`);

                                    //       }else{
                                    //         return Promise.resolve();

                                    //       }
                                    //     },
                                    //   },
                                  ]}
                                >
                                  {/* onChange={handleInputChange} */}
                                  <Input
                                    disabled={
                                      state?.data?.containerNo ? true : false
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            ) : (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            )}
                          </>
                        )
                    )}
                </Row>
              </div>

              <div className={styles.formDetails}>
                <div style={{ marginTop: "60px" }}>
                  <Row gutter={[32, 0]}>
                    {Object.keys(fieldValues).length !== 0 &&
                      fieldValues["Basic Details"]
                        .slice(7, fieldValues["Basic Details"].length)
                        .map(
                          (val, i) =>
                            val?.isForm && (
                              <>
                                {val?.fieldType == "int" ? (
                                  <Col xs={24} md={14} lg={24}>
                                    <span>
                                      {val?.label} {val?.required && `*`}
                                    </span>
                                    <Form.Item
                                      name={val?.apiName}
                                      rules={[
                                        {
                                          required: val?.required,
                                          message: `Please Enter ${val?.label}!`,
                                        },
                                      ]}
                                    >
                                      <NumericInput
                                        style={{
                                          width: "100%",
                                        }}
                                        value={value}
                                        onChange={setValue}
                                      />
                                    </Form.Item>
                                  </Col>
                                ) : val?.fieldType == "picklist_fk" ? (
                                  <>
                                    <Col xs={24} md={14} lg={24}>
                                      <span>
                                        {val?.label} {val?.required && `*`}
                                      </span>
                                      <Form.Item
                                        name={val?.apiName}
                                        rules={[
                                          {
                                            required: val?.required,
                                            message: `Please Enter ${val?.label}!`,
                                          },
                                        ]}
                                      >
                                        {base_items && (
                                          <Select
                                            showSearch
                                            style={{
                                              width: "100%",
                                            }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                            }
                                            filterSort={(optionA, optionB) =>
                                              (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare(
                                                  (
                                                    optionB?.label ?? ""
                                                  ).toLowerCase()
                                                )
                                            }
                                            onChange={
                                              val?.apiName == "supplierID"
                                                ? handleAddress
                                                : (e) => console.log(e)
                                            }
                                            options={
                                              val?.apiName == "addressID"
                                                ? addressList?.map((obj) => {
                                                    const keys =
                                                      Object.keys(obj);
                                                    const convertedObj = {
                                                      value: obj[keys[0]],
                                                      label: obj[keys[1]],
                                                    };

                                                    for (
                                                      let i = 2;
                                                      i < keys.length;
                                                      i++
                                                    ) {
                                                      convertedObj[keys[i]] =
                                                        obj[keys[i]];
                                                    }

                                                    return convertedObj;
                                                  })
                                                : base_items[
                                                    val?.picklistData
                                                  ]?.map((obj) => {
                                                    const keys =
                                                      Object.keys(obj);
                                                    const convertedObj = {
                                                      value: obj[keys[0]],
                                                      label: obj[keys[1]],
                                                    };

                                                    for (
                                                      let i = 2;
                                                      i < keys.length;
                                                      i++
                                                    ) {
                                                      convertedObj[keys[i]] =
                                                        obj[keys[i]];
                                                    }

                                                    return convertedObj;
                                                  })
                                            }
                                          />
                                        )}
                                      </Form.Item>
                                    </Col>
                                  </>
                                ) : val?.fieldType === "date" ? (
                                  <Col xs={24} md={14} lg={24}>
                                    <span>
                                      {val?.label} {val?.required && `*`}
                                    </span>
                                    <Form.Item
                                      name={val?.apiName}
                                      rules={[
                                        {
                                          required: val?.required,
                                          message: `Please Enter ${val?.label}!`,
                                        },
                                      ]}
                                    >
                                      <DatePicker
                                        onChange={onChange}
                                        style={{ width: "100%" }}
                                      />
                                    </Form.Item>
                                  </Col>
                                ) : val?.fieldType === "datetime" ? (
                                  <Col xs={24} md={14} lg={24}>
                                    <span>
                                      {val?.label} {val?.required && `*`}
                                    </span>
                                    <Form.Item
                                      name={val?.apiName}
                                      rules={[
                                        {
                                          required: val?.required,
                                          message: `Please Enter ${val?.label}!`,
                                        },
                                      ]}
                                    >
                                      <DatePicker
                                        onChange={onChange}
                                        showTime
                                        format="YYYY-MM-DD HH:mm:ss"
                                        style={{ width: "100%" }}
                                      />
                                    </Form.Item>
                                  </Col>
                                ) : val?.fieldType === "text" &&
                                  val?.apiName == "containerNo" ? (
                                  <Col xs={24} md={14} lg={24}>
                                    <span>
                                      {val?.label} {val?.required && `*`}
                                    </span>
                                    <Form.Item
                                      name={val?.apiName}
                                      rules={[
                                        {
                                          required: val?.required,
                                          message: `Please Enter ${val?.label}!`,
                                        },
                                        {
                                          validator: (rule, value) => {
                                            if (
                                              !/^[A-Z]{4}\d{6}\d$/i.test(value)
                                            ) {
                                              return Promise.reject(
                                                "Invalid Container Identification Format"
                                              );
                                            }
                                            // If the input matches the regex, return Promise.resolve();
                                            return Promise.resolve();
                                          },
                                        },
                                        {
                                          validator: validateField,
                                        },
                                        // {
                                        //     validator: (_, value) => {
                                        //       // Dynamic rule based on the API response
                                        //       //   debugger;
                                        //       if (sameVal) {
                                        //         return Promise.reject(`Container Number can't be same`);

                                        //       }else{
                                        //         return Promise.resolve();

                                        //       }
                                        //     },
                                        //   },
                                      ]}
                                    >
                                      {/* onChange={handleInputChange} */}
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                ) : (
                                  <Col xs={24} md={14} lg={24}>
                                    <span>
                                      {val?.label} {val?.required && `*`}
                                    </span>
                                    <Form.Item
                                      name={val?.apiName}
                                      rules={[
                                        {
                                          required: val?.required,
                                          message: `Please Enter ${val?.label}!`,
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                )}
                              </>
                            )
                        )}
                  </Row>
                </div>

                <h3>Container Details</h3>

                <Row gutter={[32, 5]}>
                  {Object.keys(fieldValues).length !== 0 &&
                    fieldValues["Container Details"].map(
                      (val, i) =>
                        val?.isForm && (
                          <>
                            {val?.fieldType == "int" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <NumericInput
                                    style={{
                                      width: "100%",
                                    }}
                                    value={value}
                                    onChange={setValue}
                                  />
                                </Form.Item>
                              </Col>
                            ) : val?.fieldType == "picklist_fk" ? (
                              <>
                                <Col xs={24} md={14} lg={24}>
                                  <span>
                                    {val?.label} {val?.required && `*`}
                                  </span>
                                  <Form.Item
                                    name={val?.apiName}
                                    rules={[
                                      {
                                        required: val?.required,
                                        message: `Please Enter ${val?.label}!`,
                                      },
                                    ]}
                                  >
                                    {base_items && (
                                      <Select
                                        showSearch
                                        style={{
                                          width: "100%",
                                        }}
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                          (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                              (
                                                optionB?.label ?? ""
                                              ).toLowerCase()
                                            )
                                        }
                                        onChange={
                                          val?.apiName == "supplierID"
                                            ? handleAddress
                                            : (e) => console.log(e)
                                        }
                                        options={
                                          val?.apiName == "addressID"
                                            ? addressList?.map((obj) => {
                                                const keys = Object.keys(obj);
                                                const convertedObj = {
                                                  value: obj[keys[0]],
                                                  label: obj[keys[1]],
                                                };

                                                for (
                                                  let i = 2;
                                                  i < keys.length;
                                                  i++
                                                ) {
                                                  convertedObj[keys[i]] =
                                                    obj[keys[i]];
                                                }

                                                return convertedObj;
                                              })
                                            : base_items[
                                                val?.picklistData
                                              ]?.map((obj) => {
                                                const keys = Object.keys(obj);
                                                const convertedObj = {
                                                  value: obj[keys[0]],
                                                  label: obj[keys[1]],
                                                };

                                                for (
                                                  let i = 2;
                                                  i < keys.length;
                                                  i++
                                                ) {
                                                  convertedObj[keys[i]] =
                                                    obj[keys[i]];
                                                }

                                                return convertedObj;
                                              })
                                        }
                                      />
                                    )}
                                  </Form.Item>
                                </Col>
                              </>
                            ) : val?.fieldType === "date" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    onChange={onChange}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            ) : val?.fieldType === "datetime" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    onChange={onChange}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            ) : (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            )}
                          </>
                        )
                    )}
                </Row>
              </div>

              <div className={styles.formDetails}>
                <h3 style={{ marginBottom: "20px" }}>Other Details</h3>

                <Row gutter={[32, 0]}>
                  {Object.keys(fieldValues).length !== 0 &&
                    fieldValues["Other Details"].map(
                      (val, i) =>
                        val?.isForm && (
                          <>
                            {val?.fieldType == "int" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <NumericInput
                                    style={{
                                      width: "100%",
                                    }}
                                    value={value}
                                    onChange={setValue}
                                  />
                                </Form.Item>
                              </Col>
                            ) : val?.fieldType == "picklist_fk" ? (
                              <>
                                <Col xs={24} md={14} lg={24}>
                                  <span>
                                    {val?.label} {val?.required && `*`}
                                  </span>
                                  <Form.Item
                                    name={val?.apiName}
                                    rules={[
                                      {
                                        required: val?.required,
                                        message: `Please Enter ${val?.label}!`,
                                      },
                                    ]}
                                  >
                                    {base_items && (
                                      <Select
                                        showSearch
                                        style={{
                                          width: "100%",
                                        }}
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                          (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                              (
                                                optionB?.label ?? ""
                                              ).toLowerCase()
                                            )
                                        }
                                        onChange={
                                          val?.apiName == "supplierID"
                                            ? handleAddress
                                            : (e) => console.log(e)
                                        }
                                        options={
                                          val?.apiName == "addressID"
                                            ? addressList?.map((obj) => {
                                                const keys = Object.keys(obj);
                                                const convertedObj = {
                                                  value: obj[keys[0]],
                                                  label: obj[keys[1]],
                                                };

                                                for (
                                                  let i = 2;
                                                  i < keys.length;
                                                  i++
                                                ) {
                                                  convertedObj[keys[i]] =
                                                    obj[keys[i]];
                                                }

                                                return convertedObj;
                                              })
                                            : base_items[
                                                val?.picklistData
                                              ]?.map((obj) => {
                                                const keys = Object.keys(obj);
                                                const convertedObj = {
                                                  value: obj[keys[0]],
                                                  label: obj[keys[1]],
                                                };

                                                for (
                                                  let i = 2;
                                                  i < keys.length;
                                                  i++
                                                ) {
                                                  convertedObj[keys[i]] =
                                                    obj[keys[i]];
                                                }

                                                return convertedObj;
                                              })
                                        }
                                      />
                                    )}
                                  </Form.Item>
                                </Col>
                              </>
                            ) : val?.fieldType === "date" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    onChange={onChange}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            ) : val?.fieldType === "datetime" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    onChange={onChange}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            ) : val?.apiName == "containerType" ? (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").includes(input)
                                    }
                                    filterSort={(optionA, optionB) =>
                                      (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare(
                                          (optionB?.label ?? "").toLowerCase()
                                        )
                                    }
                                    options={[
                                      {
                                        value: "20 foot",
                                        label: "20 foot",
                                      },
                                      {
                                        value: "40 foot",
                                        label: "40 foot",
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                            ) : (
                              <Col xs={24} md={14} lg={24}>
                                <span>
                                  {val?.label} {val?.required && `*`}
                                </span>
                                <Form.Item
                                  name={val?.apiName}
                                  rules={[
                                    {
                                      required: val?.required,
                                      message: `Please Enter ${val?.label}!`,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            )}
                          </>
                        )
                    )}
                </Row>
              </div>
            </div>
            {contaienrInnerfrom?.length > 0 && (
              <div>
                <div>
                  <ContainerComp
                    contaienrInnerfrom={contaienrInnerfrom}
                    setProductList={setProductList}
                    productListing={productListing}
                  />
                </div>
                <div style={{padding:"0 20px"}}>
                  {Array.isArray(productListing) &&
                    productListing.length > 0 &&
                    productListing?.map((v, i) => (
                      <div className={styles?.nameList}>
                        <div
                          className={styles.prodDetails}
                          style={{ marginRight: "10px" }}
                        >
                          {v.containerNo}
                        </div>

                        <div className={styles.prodDetails}>{v.sealNo}</div>

                        <div className={styles.prodDetails}>
                          {v.outOfCharge}
                        </div>

                        <div className={styles.prodDetails}>
                          {v.grossWeight}
                        </div>

                        <div className={styles.prodDetails}>{v.tareWeight}</div>

                        <div
                          onClick={() => handleArray(v, i)}
                          className={styles.deleteBox}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={Delete} alt="delte" />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabledData}
                style={{ marginTop: "10px" }}
                loading={loading}
              >
                {
                  // (state?.id) ? "Update and Next" : "Save and Next"
                  state?.id ? "Update" : "Save"
                }
                {/* Submit */}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}

export default CreateContainer;
