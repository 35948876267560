import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Pagination, Select, Table, Tabs, Tooltip } from "antd";
import styles from "./index.module.css";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { api_call_token, base_url } from "../../utils/Network";
import moment from "moment";
import Modals from "../../Components/Modals/Modals";
import {
  InfoCircleFilled,
  PlusCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { UserCredsContext } from "../../utils/UserCredsContext";
import DrawerComponent from "../../Components/DrawerComponent/DrawerComponent";
const { TabPane } = Tabs;

const Dsr = () => {
  const [dsrData, setDsrData] = useState([]);
  const [filterObj, setFilterObj] = useState({
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberofPage, setTotalNumberofPage] = useState(null);
  const [activeTab, setActiveTab] = useState("0");
  const [dsrStatuslist, setDsrStatusList] = useState();
  const [open, setOpen] = useState(false); // modal opne
  const { base_items } = useContext(UserCredsContext);
  const [drawerOpen, setDrawerOpne] = useState(false)
  const [ drawerData, setDrawerData] = useState({})
  // const [products, getProduct] = useState([]);

  // console.log(base_items?.productList, "base_items");

  // useEffect(() => {
  //   getProd();
  // },[])

  // const getProd = () => {
  //   api_call_token
  //     .get(`product`)
  //     .then((res) => {
  //       setProduct(res.data.totalRowCount);
  //       // getProduct(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const setProduct = (e) => {
  //   api_call_token
  //     .get(`product?size=${e}`)
  //     .then((res) => {
  //       console.log(res);
  //       getProduct(res.data.data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const handleTabChange = (key, tab) => {
    setActiveTab(key);

    console.log(key, "index");
    let obj = filterObj;
    // if (value == 9) {
    //   obj.dsrstatusId = value;
    //   obj.page = 1;
    //   setCurrentPage(1);
    // } else if(value){
    //    delete obj.dsrstatusId};
    // setFilterObj({ ...obj });

    if (key == 0) {
      delete obj.dsrstatusId;
    } else if (key) {
      obj.dsrstatusId = key;
      obj.page = 1;
      setCurrentPage(1);
    } else {
      delete obj.dsrstatusId;
    }
    setFilterObj({ ...obj });
  };

  const items = [
    // {
    //   key: "1",
    //   label: "Batch List",
    //   children: <ListingData id={id} />,
    // },
    {
      key: "1",
      label: "Batch List",
      // children: <BatchListing id={id}/>
    },
    {
      key: "2",
      label: "Ingot",
      // children: <IngotList id={id} setActiveTab={setActiveTab} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>,
    },
    {
      key: "3",
      label: "Packing List",
      // children: <PackagingList />,
    },
    // {
    //   key: "4",
    //   label: "Batch List New",
    //   children: <BatchListing id={id}/>
    // },
  ];

  useEffect(() => {
    getListDsr();
  }, [filterObj]);

  const getListDsr = (paramObj = { ...filterObj }) => {
    api_call_token
      .get(`dsrlist`, { params: { ...paramObj } })
      .then((res) => {
        console.log(res, "REsData");

        setDsrData(res.data.data);
        setCurrentPage(res.data.currentPage);
        setTotalNumberofPage(res.data.totalRowCount);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDsrListing();
  }, []);

  const getDsrListing = () => {
    api_call_token
      .get(`dsrlist/getdsrStatusList`)
      .then((res) => {
        console.log(res?.data?.data);

        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          const newData = res?.data?.data;

          newData.reverse().unshift({
            dsrstatusId: 0,
            dsrstatusName: "All",
            // Add other properties if needed
          });

          setDsrStatusList(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearch = (event) => {
    let value = event.target.value;
    let obj = filterObj;
    if (value) {
      obj.search = value;
      obj.page = 1;
      setCurrentPage(1);
    } else delete obj.search;
    setFilterObj({ ...obj });
  };

  const handlePageChange = (page) => {
    console.log(page, "aaaaaaaaa");
    setFilterObj({ ...filterObj, page: page });
    setCurrentPage(page);
  };

  const navigateToCreateForm = () => {
    let { page, ...rest } = filterObj;
    api_call_token
      .get(`dsrlist/getdsrreport`, { params: { ...rest } })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          let data = res.data.FilePath;
          if (data) {
            window.open(`${base_url}${data.split("public/")[1]}`);
          } else {
            alert("No file");
          }
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: 10, // Number of items per page
    total: totalNumberofPage, // Total number of items
    onChange: handlePageChange,
  };

  const getRowClassName = (record) => {
    switch (record?.dsrstatusName) {
      case "Indent PO":
        return `${styles.Indent_PO}`;
      case "Shipment":
        return `${styles.Shipment}`;
      case "Doc Present":
        return `${styles.Doc_Present}`;
      case "Bank Payment":
        return `${styles.Bank_Payment}`;
      case "BOE":
        return `${styles.BOE_}`;
      case "IGM Date":
        return `${styles.IGM_Date}`;
      case "Out of Charge":
        return `${styles.Out_of_Charge}`;
      case "Detention":
        return `${styles.Detention}`;
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "dsrstatusName",
      key: "dsrstatusName",
      render: (_, record) => {
        return (
          <>
            {/* <div style={{ width: "150px", color: `${record.dsrTextColor}` }}>{_}</div> */}
            <div className={styles.tablestatuslist}>
              <Tooltip title="Indent PO">
                <span className={`${styles.boxStyling} ${styles.statusTrue}`}>
                  IP
                </span>
              </Tooltip>
              <Tooltip title="Shipment">
                <span
                  className={`${styles.boxStyling} ${
                    record?.isShippment ? styles.statusTrue : styles.statusFalse
                  }`}
                >
                  SH
                </span>
              </Tooltip>
              <Tooltip title="Document Present">
                <span
                  className={`${styles.boxStyling} ${
                    record?.isDocPresent
                      ? styles.statusTrue
                      : styles.statusFalse
                  }`}
                >
                  DO
                </span>
              </Tooltip>
              {/* <Tooltip title="Bank Payment">
                <span className={`${styles.boxStyling} ${record?.isShippment ? styles.statusTrue : styles.statusFalse }`}>BP</span>
              </Tooltip> */}
              <Tooltip title="BOE Files">
                <span
                  className={`${styles.boxStyling} ${
                    record?.isBOEFile ? styles.statusTrue : styles.statusFalse
                  }`}
                >
                  BO
                </span>
              </Tooltip>
              <Tooltip title="IGM Date">
                <span
                  className={`${styles.boxStyling} ${
                    record?.isIGMDatePresent
                      ? styles.statusTrue
                      : styles.statusFalse
                  }`}
                >
                  IG
                </span>
              </Tooltip>
            </div>
          </>
        );
      },
    },
    {
      title: "PO Number",
      dataIndex: "purchaseOrderNumber",
      key: "purchaseOrderNumber",
      render: (_, record) => {
        console.log(record);
        return (
          <div onClick={() => handelDrawerOpne(record)}>
            <div style={{ width: "150px" }}>{_}</div>
            <div>
              {record?.purchaseOrderDate
                ? moment(record?.purchaseOrderDate).format("DD-MMMM-YYYY")
                : ""}
            </div>
          </div>
        );
      },
    },
    // {
    //   title: 'PO Date',
    //   dataIndex: 'purchaseOrderDate',
    //   key: 'purchaseOrderDate',
    //   render: (_) => {
    //     return <div style={{ width: "150px" }}>{_ ? moment(_).format("DD-MM-YYYY") : ""}</div>;
    //   },
    // },
    {
      title: "DESCRIPTION",
      dataIndex: "invoiceproductName",
      key: "invoiceproductName",
      render: (_) => {
        return <div style={{ width: "200px" }}>{_}</div>;
      },
    },
    {
      title: "SIZE",
      dataIndex: "containerType",
      key: "containerType",
      render: (_) => {
        return <div style={{ width: "100px" }}>{_}</div>;
      },
    },
    {
      title: "WEIGHT (in MT)",
      dataIndex: "invoiceproductquantity",
      key: "invoiceproductquantity",
    },
    {
      title: "B/E NO",
      dataIndex: "boeNo",
      key: "boeNo",
      flex: 1,
      render: (_) => {
        return <div style={{ width: "100px" }}>{_}</div>;
      },
    },
    {
      title: "B/E DT",
      dataIndex: "boeDate",
      key: "boeDate",
      render: (_) => {
        return (
          <div style={{ width: "100px" }}>
            {_ ? moment(_).format("DD-MM-YYYY") : ""}
          </div>
        );
      },
    },
    // {
    //   title: "Company",
    //   dataIndex: "buyerName",
    //   key: "buyerName",
    //   render: (_) => {
    //     return <div style={{ width: "150px" }}>{_}</div>;
    //   },
    // },
    {
      title: "SUPPLIER",
      dataIndex: "supplierName",
      key: "supplierName",
      render: (_) => {
        return <div style={{ width: "150px" }}>{_}</div>;
      },
    },

    {
      title: "CONT NO",
      dataIndex: "containerNo",
      key: "containerNo",
    },
    {
      title: "BL NO",
      dataIndex: "bl",
      key: "bl",
    },
    {
      title: "SHPG LINE",
      dataIndex: "shpgLine",
      key: "shpgLine",
    },
    {
      title: "ETA MUN",
      dataIndex: "etaMun",
      key: "etaMun",
      render: (_) => {
        return (
          <div style={{ width: "100px" }}>
            {_ ? moment(_).format("DD-MM-YYYY") : ""}
          </div>
        );
      },
    },
    {
      title: "IGM Date",
      dataIndex: "igmDate",
      key: "igmDate",
      render: (_) => {
        return (
          <div style={{ width: "150px" }}>
            {_ ? moment(_).format("DD-MM-YYYY") : ""}
          </div>
        );
      },
    },
    {
      title: "Free Days",
      dataIndex: "freeDay",
      key: "freeDay",
      render: (_) => {
        return (
          <div style={{ width: "100px" }}>
            {/* {_ ? moment(_).format("DD-MM-YYYY") : ""} */}
            {_}
          </div>
        );
      },
    },
    {
      title: "Detention Date",
      dataIndex: "detentionDate",
      key: "detentionDate",
      render: (_) => {
        return (
          <div style={{ width: "150px" }}>
            {_ ? moment(_).format("DD-MM-YYYY") : ""}
          </div>
        );
      },
    },
    {
      title: "Days Remaining",
      dataIndex: "daysRemaining",
      key: "daysRemaining",
      render: (_) => {
        return (
          <div style={{ width: "100px" }}>
            {/* {_ ? moment(_).format("DD-MM-YYYY") : ""} */}
            {_}
          </div>
        );
      },
    },
    {
      title: "VESSEL",
      dataIndex: "vessel",
      key: "vessel",
      render: (_) => {
        return <div style={{ width: "100px" }}>{_}</div>;
      },
    },
    {
      title: "COPY DOC",
      dataIndex: "copyDoc",
      key: "copyDoc",
      render: (_) => {
        return (
          <div style={{ width: "100px" }}>
            {_ ? moment(_).format("DD-MM-YYYY") : ""}
          </div>
        );
      },
    },
    {
      title: "ORI DOCS",
      dataIndex: "oriDoc",
      key: "oriDoc",
      render: (_) => {
        return (
          <div style={{ width: "100px" }}>
            {_ ? moment(_).format("DD-MM-YYYY") : ""}
          </div>
        );
      },
    },
    {
      title: "REMARK",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "Arrival_Date",
      dataIndex: "igmDate",
      key: "igmDate",
      render: (_) => {
        return (
          <div style={{ width: "100px" }}>
            {_ ? moment(_).format("DD-MM-YYYY") : ""}
          </div>
        );
      },
    },
    {
      title: "Port of Final Destination",
      dataIndex: "finalDestinationName",
      key: "finalDestinationName",
    },
    {
      title: "CHA",
      dataIndex: "customHouseAgentName",
      key: "customHouseAgentName",
    },
  ];

  const data = [
    {
      "B/E NO": 8993047,
      "B/E DT": "29-Nov-2023",
      Company: "PB",
      SUPPLIER: "SAFI TECHNOLOGIES",
      SIZE: "09X40'",
      DESCRIPTION: "ALUMINIUM SCRAP TALDON",
      "WEIGHT (in MT)": 147.6,
      "CONT NO": "HLBU3291413",
      "BL NO": "HLCULIV231004220",
      "SHPG LINE": "HAPAG",
      "ETA MUN": "1-Dec-2023",
      "Free Days": 12,
      VESSEL: "CSCL JUPITER",
      "COPY DOC": "20-Nov-2023",
      "ORI DOCS": "1-Dec-2023",
      REMARK: "",
      Arrival_Date: "20-Nov-2023",
    },
    {
      "B/E NO": 9028401,
      "B/E DT": "1-Dec-2023",
      Company: "PB",
      SUPPLIER: "SAFI TECHNOLOGIES",
      SIZE: "02X40'",
      DESCRIPTION: "ALUMINIUM SCRAP TALDON",
      "WEIGHT (in MT)": 34.8,
      "CONT NO": "TGBU9632333",
      "BL NO": "HLCULIV231047743",
      "SHPG LINE": "HAPAG",
      "ETA MUN": "2-Dec-2023",
      "Free Days": 14,
      VESSEL: "ONE MANCHESTER",
      "COPY DOC": "8-Nov-2023",
      "ORI DOCS": "2-Dec-2023",
      REMARK: "",
      Arrival_Date: "8-Nov-2023",
    },
    // Add more shipment data as needed...
  ];

  const openPhassesBox = () => {
    setOpen(true);
  };

  const handleChanges = (e) => {
    console.log(e, "DDDDDD");
    let value = e;
    let obj = filterObj;
    if (value) {
      obj.product = value;
      obj.page = 1
      setCurrentPage(1);
    } else delete obj.product;
    setFilterObj({ ...obj });
  };

const handelDrawerOpne = (record, index) => {

  console.log(record, index, "handelDrawerOpne");
  setDrawerOpne(true)
  setDrawerData(record)
}

  return (
    <>
      <div className={styles.mainContainer}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="text" onClick={openPhassesBox}>
            <InfoCircleFilled /> Know the Phases
          </Button>
        </div>
        <h2>DSR</h2>

        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          {dsrStatuslist?.map((v, i) => (
            <TabPane tab={v.dsrstatusName} key={v?.dsrstatusId}>
              {/* {v.children} */}
              {activeTab === v?.dsrstatusId ? v.children : null}
            </TabPane>
          ))}
        </Tabs>
        <div className={styles.searchTab}>
        <Input
          size="medium"
          placeholder="Search By BOE, PO Number, Supplier Name, Port Name"
          prefix={<SearchOutlined />}
          onChange={(e) => onSearch(e)}
          style={{ width: "20%" }}
        />
        {base_items?.productList && (
          <Select
            showSearch
            style={{
              width: "20%",
              margin: "0 10px",
            }}
            placeholder="Filter By Product"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            allowClear
            // bordered={false}
            onChange={handleChanges}
            options={base_items?.productList?.map((obj) => {
              const keys = Object.keys(obj);
              const convertedObj = {
                value: obj[keys[0]],
                label: obj[keys[1]],
              };

              for (let i = 2; i < keys.length; i++) {
                convertedObj[keys[i]] = obj[keys[i]];
              }

              return convertedObj;
            })}
          />
        )}
        {base_items?.portList && (
          <Select
            showSearch
            style={{
              width: "20%",
              margin: "0 10px",
            }}
            placeholder="Filter By Port"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            allowClear
            // bordered={false}
            onChange={(e) => {
              let value = e;
              let obj = filterObj;
              if (value) {
                obj.port = value;
                obj.page = 1
                setCurrentPage(1);
              } else delete obj.port;
              setFilterObj({ ...obj });
            }}
            options={base_items?.portList?.map((obj) => {
              const keys = Object.keys(obj);
              const convertedObj = {
                value: obj[keys[0]],
                label: obj[keys[1]],
              };

              for (let i = 2; i < keys.length; i++) {
                convertedObj[keys[i]] = obj[keys[i]];
              }

              return convertedObj;
            })}
          />
        )}
        {base_items?.supplierList && (
          <Select
            showSearch
            style={{
              width: "20%",
              margin: "0 10px",
            }}
            placeholder="Filter By Supplier"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            allowClear
            // bordered={false}
            onChange={(e) => {
              let value = e;
              let obj = filterObj;
              if (value) {
                obj.supplier = value;
                obj.page = 1
                setCurrentPage(1);
              } else delete obj.supplier;
              setFilterObj({ ...obj });
            }}
            options={base_items?.supplierList?.map((obj) => {
              const keys = Object.keys(obj);
              const convertedObj = {
                value: obj[keys[0]],
                label: obj[keys[1]],
              };

              for (let i = 2; i < keys.length; i++) {
                convertedObj[keys[i]] = obj[keys[i]];
              }

              return convertedObj;
            })}
          />
        )}
        <Button
          type="primary"
          onClick={navigateToCreateForm}
          style={{ margin: "0 10px" }}
        >
          {" "}
          <PlusCircleTwoTone style={{ marginRight: "10px" }} />
          Download Reports
        </Button>
        </div>
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={dsrData}
          pagination={false} // Adjust as needed
          scroll={{ x: true }} // Enable horizontal scrolling if needed
          // onRow={(record, index) => handelDrawerOpne(record, index)}
        />

        <div style={{ textAlign: "right", margin: "10px" }}>
          <Pagination {...paginationConfig} showSizeChanger={false} />
        </div>
      </div>
      <Modals
        open={open}
        handleCancel={() => {
          setOpen(false);
        }}
      >
        <div>
          <h2 style={{ textAlign: "center" }}>Phases</h2>
          <div className={styles.fullnamebox}>
            <h4>1. Indent PO</h4>
            <h4>2. Shipment</h4>
            <h4>3. Document Present</h4>
            <h4>4. Bank Payment</h4>
            <h4>5. BOE Files</h4>
            <h4>6. IGM Date</h4>
          </div>
          <div className={styles.StatusIndicator}>
            <div className={styles.name}>
              <span className={styles.green}></span> Completed
            </div>
            <div className={styles.name}>
              <span className={styles.dark}></span> Not Completed
            </div>
          </div>
        </div>
      </Modals>
      <DrawerComponent open={drawerOpen} setDrawerOpne={setDrawerOpne} data={drawerData}/>
    </>
  );
};

export default Dsr;
