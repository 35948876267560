import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token, base_url } from '../../../utils/Network';
import styles from "./index.module.css";
import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import EditIcon from "../../../Assets/ICONS/edit.png";


function ViewPoLc() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});
  const [visible, setVisible] = useState(true);
  const [pdfLink, setPDFLink] = useState("");
  const [buyerAddress, setBuyerAddress] = useState([]);
  const [address, setAddress] = useState([]);
  const [shipToBuyerAddress, setshipToBuyerAddress] = useState([]);

  useEffect(() => {
    getViewofPurchase();
    getPDFview();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`domesticPurchaseOrder/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data)
        if (res.data.buyerID) {
          handleBuyers(res.data.buyerID)
        }
        if (res.data.supplierID) {
          handleAdrListNew(res.data.supplierID)
        }

        if (res.data.shipToBuyerId) {
          handleShipToAdr(res.data.shipToBuyerId)
        }
      })
      .catch(err => console.log(err));
  }

  const handleShipToAdr = (e) => {
    console.log(e)
    api_call_token
      .get(`formData/buyerAddress/${e}`)
      .then((res) => {
        setshipToBuyerAddress(res.data.buyerAddressList)
      })
      .catch(err => console.log(err))
  }

  const handleAdrListNew = (e) => {
    api_call_token
      .get(`formData/supplierAddress/${e}`)
      .then((res) => {
        setAddress(res.data.supplierAddressList)
      })
      .catch(err => console.log(err))
  }

  const handleBuyers = (e) => {
    console.log(e)
    api_call_token
      .get(`formData/buyerAddress/${e}`)
      .then((res) => {
        setBuyerAddress(res.data.buyerAddressList)
      })
      .catch(err => console.log(err))
  }

  const getPDFview = () => {
    api_call_token
      .get(`/pdf/getDomesticPurchaseOrderPDF/${id}`)
      .then((res) => {

        if (res.status == 200 || res.status == 201) {
          setVisible(false)
          setPDFLink(res.data)
        }

        console.log(res, "DATASSSSSSSSSS")
      })
      .catch(err => console.log(err))
  }
  console.log(viewPurchase?.productList, "ViewPurchase")

  const redirectTo = (value) => {
    api_call_token
      .get(`domesticPurchaseOrder/${value}`)
      .then((res) => {
        console.log(res.data);
        if (res.status == 200 || res.status == 201) {
          navigate('/purchaseMain/localpo/createform', { state: { data: res.data, id: value, type: "PO", addPO: "0" } })
        }
      })
      .catch(err => console.log(err))
  }
  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'productName',
      render: (text, record) => (
        <div>
          <p style={{margin: '5px', fontSize: '16px'}}>{text}</p>
          <span style={{ marginLeft: '10px', fontSize: '14px' }}>
            {record?.productSpecification}
          </span>
        </div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Unit of Measure',
      dataIndex: 'unitOfMeasureName',
    },
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode',
    }, {
      title: 'Grade/Quality',
      dataIndex: 'goodsDetail',
    }, {
      title: 'Quotation',
      dataIndex: 'note',
    },
    {
      title: 'Pro rata',
      dataIndex: 'packing'
    },
    {
      title: 'Price per Unit',
      dataIndex: 'pricePerUnit',
    },
    {
      title: 'Total',
      dataIndex: 'totalProductAmount',
    },
  ]

  const handleOpen = () => {
    window.open(`${base_url}${pdfLink}`)
  }
  const orderRedirect = () => {
    navigate(`/purchaseMain/localpo`, { state: { draftIndex: 1 } })
  }


  console.log(pdfLink, "pdfLinkpdfLink")

  return (
    <div style={{ width: "100%" }}>
      <div>
        <div className={`${styles.topContent} ${styles.demodata}`}>
          <p ><ArrowLeftOutlined onClick={() => orderRedirect()} /> Order Details</p>
          <Button disabled={visible} onClick={() => handleOpen()}>Download PDF</Button>
        </div>

        {/* <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>PO No.</p>
              <p>{viewPurchase?.purchaseOrderNumber}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Date</p>
              <p>{moment(viewPurchase?.purchaseOrderDate).format('DD-MMMM-YYYY')}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Indentor/Agent</p>
              <p>{viewPurchase?.agentName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Buyer</p>
              <p>{viewPurchase?.buyerName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Supplier</p>
              <p>{viewPurchase?.supplierName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Authorised Signatory Name</p>
              <p>{viewPurchase?.authorisedSignatoryName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Signatory Company</p>
              <p>{viewPurchase?.signatoryCompany}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Total Qty</p>
              <p>{viewPurchase?.totalQuantity}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Total Amount</p>
              <p>{viewPurchase?.totalAmount}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Country of Origin</p>
              <p>{viewPurchase?.countryOfOriginName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Port of Loading</p>
              <p>{viewPurchase?.portOfDispatchName}</p>
            </div>

            <div className={styles.contentWrapper}>
              <p>Port of Destination</p>
              <p>{viewPurchase?.portOfFinalDestinationame}</p>
            </div>

            <div className={styles.contentWrapper}>
              <p>Payment Terms</p>
              <p>{viewPurchase?.methodOfPaymentName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Mode of Shipment</p>
              <p>{viewPurchase?.shipmentName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Shipment Date</p>
              <p>{viewPurchase?.shipmentDate}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Incoterms</p>
              <p>{viewPurchase?.incotermsname}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Currency</p>
              <p>{viewPurchase?.currencyName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Sales Contact No.</p>
              <p>{viewPurchase?.salesContractNo}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Remark</p>
              <p>{viewPurchase?.remarks}</p>
            </div>
          </div>
        </div> */}


        {/* <div>
          <p style={{ fontSize: "23px", marginLeft: '100px' }}>Products Details</p>
          <div className={styles.wrapperContainer}>
            <CustomTable columns={columns} data={viewPurchase?.productList} />
          </div>
        </div> */}

        <div className={`${styles.wrapperContainer}`}>
          <div style={{ margin: '15px 0', textAlign: 'right' }}>
            <img src={EditIcon} alt='data' onClick={() => redirectTo(id)} style={{ cursor: 'pointer' }} />
          </div>
          <div className={styles.mainContainer}>
            <div>
              <div className={styles.InputFiledCon}>
                <div className={`${styles.purchaseOrderLeft} ${styles.borderRight}  ${styles.boderBottom}`}>
                  <span>
                    Purchase Order
                  </span>
                  <p>{viewPurchase?.purchaseOrderNumber}</p>

                </div>

                <div className={`${styles.purchaseOrderRight} ${styles.borderRight} ${styles.boderBottom} ${styles.paddingten}`}>
                  <span>
                    Purchase Order Date
                  </span>
                  <p>{moment(viewPurchase?.purchaseOrderDate).format('DD-MMMM-YYYY')}</p>

                </div>
                <div className={`${styles.purchaseOrderRight} ${styles.borderRight} ${styles.boderBottom} ${styles.paddingten}`}>
                  <span>
                    Dispatch Date
                  </span>
                  <p>{moment(viewPurchase?.shipmentDate).format('DD-MMMM-YYYY')}</p>

                </div>

                <div className={`${styles.purchaseOrderRight} ${styles.purchaseOrderLeft} ${styles.borderRight} ${styles.boderBottom} ${styles.paddingten}`}>
                  <span>
                    Agent
                  </span>
                  <p>{viewPurchase?.agentName}</p>

                </div>
                <div className={`${styles.purchaseOrderRight} ${styles.purchaseOrderLeft} ${styles.boderBottom} ${styles.paddingten}`}>
                  <span>
                    Delivery Date
                  </span>
                  <p>{viewPurchase?.deliveryDate}</p>

                </div>
              </div>
            </div>


            <div className={styles.inputFieldWrapper}>
              <div className={styles.InputFiledCon}>
                <div className={`${styles.leftCon} ${styles.borderRight}  ${styles.boderBottom} ${styles.paddingten}  ${styles.borderBox}  `}>
                  <div className={styles.inputFieldCon}>
                    <span>
                      Bill To
                    </span>
                    <p>{viewPurchase?.buyerName}</p>
                    <p style={{ textTransform: 'capitalize' }}>{buyerAddress[buyerAddress.findIndex((v) => v.id === viewPurchase?.buyerAddressId)]?.name}</p>
                  </div>
                </div>
                <div className={`${styles.leftCon} ${styles.borderRight}  ${styles.boderBottom} ${styles.paddingten}  ${styles.borderBox}  `}>
                  <div className={styles.inputFieldCon}>
                    <span>
                      Ship To
                    </span>
                    <p>{viewPurchase?.shiptobuyername}</p>
                    <p style={{ textTransform: 'capitalize' }}>{shipToBuyerAddress[shipToBuyerAddress.findIndex((v) => v.id === viewPurchase?.shipToBuyerAddressId)]?.name}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.InputFiledCon}>
              <div className={`${styles.leftCon} ${styles.borderRight} ${styles.paddingten} ${styles.borderBox}`}>
                <div className={styles.inputFieldCon}>
                  <span>
                    Supplier
                  </span>
                  <p>{viewPurchase?.supplierName}</p>
                  <p style={{ textTransform: 'capitalize' }}>{address[address.findIndex((v) => v.id === viewPurchase?.addressID)]?.name}</p>

                </div>
              </div>

              <div className={`${styles.rightCon} ${styles.borderBox}`}>
                <div className={`${styles.borderBox}`} >
                  <div className={` ${styles.InputFiledCon} ${styles.CountryFields}`}>
                    <div className={`${styles.purchaseOrderLeft} ${styles.borderRight}  ${styles.innerFields}`}>
                      <span>
                        Country of Origin
                      </span>
                      <p>{viewPurchase?.countryOfOriginName}</p>
                    </div>

                    <div className={`${styles.purchaseOrderRight} ${styles.paddingten}  ${styles.innerFields}`}>
                      <span>
                        Place of Delivery
                      </span>
                      <p>{viewPurchase?.placeOfDelivery}</p>
                    </div>
                  </div>
                </div>

                <div className={`${styles.borderTop} ${styles.borderBox}`} >
                  <div className={` ${styles.InputFiledCon} ${styles.CountryFields}`}>
                    <div className={`${styles.purchaseOrderLeft} ${styles.borderRight}  ${styles.innerFields}`}>
                      <span>
                        Insurance
                      </span>
                      <p>{viewPurchase?.insurancePolicyNumber}</p>
                    </div>

                    <div className={`${styles.purchaseOrderRight} ${styles.paddingten}  ${styles.innerFields}`}>
                      <span>
                        Transporter
                      </span>
                      <p>{viewPurchase?.transporterCompanyName}</p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className={` ${styles.borderTop}  ${styles.paddingten}  ${styles.innerFields}`}>
              <div className={`${styles.purchaseOrderRight}`}>
                <span>
                  Terms of Payment
                </span>
                <p>{viewPurchase?.methodOfPaymentName}</p>
              </div>
            </div>


            <div className={` ${styles.borderTop}`}>
              <div>

                <CustomTable columns={columns} data={viewPurchase?.productList} />
                <Row>
                  <Col xs={24} md={14} lg={3}>
                    <div className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`} style={{ width: '100%', height: '100%' }}>
                      <span className={styles.headtext}>Total Products</span>
                      <div style={{ textAlign: "left", margin: "10px 0", fontWeight: 'bold' }}>
                        {viewPurchase?.totalProducts || 0}
                      </div>
                    </div>


                  </Col>
                  <Col xs={24} md={14} lg={18}>
                    <div className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderRight} ${styles.borderTop}`} style={{ width: '100%', height: '100%' }}>
                      <span className={styles.headtext}>Total Quantity</span>
                      <div style={{ textAlign: "left", margin: "10px 0", fontWeight: 'bold' }}>
                        {viewPurchase?.totalQuantity || 0}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={14} lg={3}>
                    <div className={`${styles.prodDetails} ${styles.leftCon} ${styles.paddingten} ${styles.borderBox} ${styles.borderTop}`} style={{ width: '100%', height: '100%' }}>
                      <div className={styles.headtext} style={{ textAlign: 'right' }}>Total</div>
                      <div style={{ textAlign: "right", margin: "10px 0", fontWeight: 'bold' }}>
                        &#x20b9;{viewPurchase?.totalAmount || 0}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className={`${styles.InputFiledCon} ${styles.borderTop}  `} >
              <div className={`${styles.leftCon}`}>
                <div className={` ${styles.paddingten} ${styles.borderBox} ${styles.boderBottom}`}>
                  <span>
                    Purchase Instructions
                  </span>
                  <p>{viewPurchase?.remarks}</p>
                  <div>

                  </div>
                </div>
                <div className={` ${styles.paddingten} ${styles.borderBox}`}>
                  <span>
                    Terms and Condition
                  </span>
                  <p>{(viewPurchase?.termsConditions) ? "TERMS & CONDITION" : ""}</p>

                </div>
              </div>


              <div className={`${styles.leftCon}`} >
                <div className={styles.InputFiledCon}>
                  <div className={`${styles.inputFieldCon}  ${styles.paddingten} ${styles.borderRight} ${styles.borderLeft} ${styles.boderBottom}`}>
                    <span>
                      Incoterm 2020
                    </span>
                    <p>{viewPurchase?.incotermsname}</p>

                  </div>

                  <div className={`${styles.purchaseOrderRight} ${styles.boderBottom} ${styles.paddingten} `}>
                    <span>
                      Currency
                    </span>
                    <p>{viewPurchase?.currencyName}</p>

                  </div>
                </div>

                <div className={` ${styles.paddingten} ${styles.borderBox} ${styles.boderBottom} ${styles.borderLeft}`}>
                  <span>
                    Signatory Company
                  </span>
                  <p>{viewPurchase?.buyerName}</p>

                </div>

                <div className={` ${styles.paddingten} ${styles.borderBox} ${styles.borderLeft}`}>
                  <span>
                    Authorised Signatory Name
                  </span>
                  <p>{viewPurchase?.authorisedSignatoryName}</p>

                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}

export default ViewPoLc