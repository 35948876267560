import React from 'react'
import { Steps } from 'antd';

function Stepper({ currentState, items }) {
    return (
        <div>
            <Steps
                progressDot
                current={currentState}
                items={items}
            />
        </div>
    )
}

export default Stepper