import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Upload } from 'antd'
import React from 'react'
import { api_call_token, formDataApi } from '../../../../../utils/Network';

function UploadDoc({ getViewofPurchase = () => {}, setOpen = () => { }, viewPurchase, id }) {
    const [form] = Form.useForm();

    const handleData = async (datas) => {

        const fmDatas = new FormData();
        for (const key in datas) {
            if (datas.hasOwnProperty(key)) {
                const value = datas[key];
                if (value !== undefined) {
                    if (Array.isArray(value)) {
                        // Handle arrays separately
                        value.forEach((item, index) => {
                            if (item !== undefined && item.originFileObj !== undefined) {
                                fmDatas.append(key, item.originFileObj, `${key}_${index}`);
                            }
                        });
                    } else {
                        fmDatas.append(key, value);
                    }
                }
            }
        }
        let apiEnds = "";
       
        const formDataKeys = [];
        for (const key of fmDatas.keys()) {
            formDataKeys.push(key);
        }

        if(formDataKeys.length > 0){
            formDataKeys.map((valss, idx) => {
                if(viewPurchase[valss]){
                    api_call_token
                    .get(`pdf/deletefile?search=${viewPurchase[valss]?.split(`public/`)[1]}`)
                    .then((res) => {

                    })
                    .catch(err => console.log(err))
                }
                
            })
        }

        console.log(...fmDatas, formDataKeys, "DDD");
        const res = await formDataApi(
            apiEnds,
            fmDatas,
            true,
            `supplier/uploadSupplierFiles/${id}`
        );
        console.log(res, "ressss");
        if (res.status == 200 || res.status == 201 || res.status == 204 || res.supplierId) {
            setOpen(false)
            getViewofPurchase();
            // submitProduct(res)
            // alertMessage("Container Created ")
            // setTimeout(() => {
            //     navigate('/container/addProducts', { state: res?.data })
            // }, 2000);
            // navigate('/container/addProducts', { state: res?.data })
        }
    }

    const normFile = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    return (
        <div>
            <Form form={form} onFinish={handleData}>
                <Row>
                    <Col span={12}>
                        <span>PAN Doc</span>
                        <Form.Item
                            name="copyUploadPANofCompany"
                            // label={val.fieldName}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload name="logo" listType="picture">
                                <Button icon={<UploadOutlined />}>
                                    Click to upload
                                </Button>
                            </Upload>
                        </Form.Item></Col>
                    <Col span={12}>
                        <span>GSTIN</span>
                        <Form.Item
                            name="copyUploadGSTINCertificate"
                            // label={val.fieldName}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload name="logo" listType="picture">
                                <Button icon={<UploadOutlined />}>
                                    Click to upload
                                </Button>
                            </Upload>
                        </Form.Item></Col><Col span={12}>
                        <span>Company Brochure</span>
                        <Form.Item
                            name="copyUploadCompanyBrochure"
                            // label={val.fieldName}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload name="logo" listType="picture">
                                <Button icon={<UploadOutlined />}>
                                    Click to upload
                                </Button>
                            </Upload>
                        </Form.Item></Col><Col span={12}>
                        <span>Bank Details</span>

                        <Form.Item
                            name="copyUploadBankDetail"
                            // label={val.fieldName}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload name="logo" listType="picture">
                                <Button icon={<UploadOutlined />}>
                                    Click to upload
                                </Button>
                            </Upload>
                        </Form.Item></Col>
                </Row>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>

        </div>
    )
}

export default UploadDoc