import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../utils/Network';
import styles from "./index.module.css";
import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';


function ViewInvoice() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`invoice/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data)
      })
      .catch(err => console.log(err));
  }
  console.log(viewPurchase?.productList, "ViewPurchase")

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'invoiceproductName',
    },
    {
      title: 'Product Quantity',
      dataIndex: 'invoiceproductquantity',
    },
    {
      title: 'Price Per unit',
      dataIndex: 'pricePerUnit',
    },
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode',
    },
    {
      title: 'Purchase Order No.',
      dataIndex: 'purchaseOrderNumber',
    },
    {
      title: 'Unit of Measure',
      dataIndex: 'unitofmeasure',
    },{
      title: 'BL',
      dataIndex: 'bl',
    },{
      title: 'Container',
      dataIndex: 'containerNo',
    },{
      title: 'Amount',
      dataIndex: 'amount',
    },
  ]

  return (
    <div style={{ width: "100%" }}>
      <div>
        <p className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate(-1)} />Purchase Invoice Details</p>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Invoice ID</p>
              <p>{viewPurchase?.invoiceID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Invoice No.</p>
              <p>{viewPurchase?.invoiceNo}</p>
            </div>    
            <div className={styles.contentWrapper}>
              <p>Invoice Date</p>
              <p>{moment(viewPurchase?.invoiceDate).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Buyer Name</p>
              <p>{viewPurchase?.buyerName}</p>
            </div>  
            <div className={styles.contentWrapper}>
              <p>Buyer ID</p>
              <p>{viewPurchase?.buyerId}</p>
            </div> 
            <div className={styles.contentWrapper}>
              <p>Supplier Name</p>
              <p>{viewPurchase?.supplierName}</p>
            </div>     
          </div>
        </div>


        <div>
          <p className={styles.demodata}>Purchase Invoice Details</p>
          <div className={styles.wrapperContainer}>
            <CustomTable columns={columns} data={viewPurchase?.productList} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewInvoice