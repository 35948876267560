import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../../../utils/Network';
import styles from "./index.module.css";
// import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { Button, Col, Form, Input, Row, Select } from 'antd';


function ProductView() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});
  const [supplierData, setSupplierData] = useState([]);
  const [allSupplier, setAllSupplier] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getSupplier()
    getAllList();
  }, [])

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getAllList = () => {
    api_call_token
      .get(`product/supplier/${id}`)
      .then((res) => {
        if(res.status == 200 || res.status == 201 || res.status == 204){
          setAllSupplier(res.data);
        }
      })
      .catch((err) => console.log(err))
  }

  const getSupplier = () => {
    api_call_token
      .get(`supplier?size=1000`)
      .then((res) => {
        setSupplierData(res.data.data);
      })
      .catch((err) => console.log(err))
  }

  const getViewofPurchase = () => {
    api_call_token
      .get(`product/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data[0])
      })
      .catch(err => console.log(err));
  }
  console.log(viewPurchase, "ViewPurchase")

  const submitFinalData = (vals) => {
    console.log(vals)

    let data = {
      productId: viewPurchase.productID,
      productName: viewPurchase.productName,
      ...vals
    }

    api_call_token
      .post(`product/supplier`, data)
      .then((res) => {
        if(res.status == 200 || res.status == 201 || res.status == 204){
          getAllList();
        }
      })
      .catch(err => console.log(err))

    console.log(data, "DATASS")
  }

  return (


    <div style={{ width: "100%" }}>
      <div>
        <h2 className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate("/master/Products")} style={{ marginRight: '10px' }} /> Product Details</h2>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Product Id</p>
              <p>{viewPurchase?.productID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Product Name</p>
              <p>{viewPurchase?.productName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Product Unit</p>
              <p>{viewPurchase?.productUnit}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Product Code</p>
              <p>{viewPurchase?.productCode}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Product Details</p>
              <p>{viewPurchase?.productDetails}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>HSN code</p>
              <p>{viewPurchase?.hsnCode}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>ED cess</p>
              <p>{viewPurchase?.edCess}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>cvd</p>
              <p>{viewPurchase?.cvd}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Basic Duty</p>
              <p>{viewPurchase?.basicDuty}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Conversion</p>
              <p>{viewPurchase?.conversion}</p>
            </div>
          </div>
        </div>


        {/* <div>
          <h3>Products Maped with Supplier</h3>

          <div>
            <Form form={form} onFinish={submitFinalData}>
              <Row gutter={[12, 12]}>
                <Col xl={6} lg={6} sm={6}>
                  <Form.Item name="supplierId">
                    <Select
                      showSearch
                      allowClear={true}
                      style={{
                        width: "100%",
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toLowerCase()
                          )
                      }
                    
                      options={
                        supplierData && supplierData.length > 0 &&
                        supplierData?.map((obj) => {
                          const keys = Object.keys(obj);
                          const convertedObj = {
                            value: obj["supplierID"],
                            label: obj["supplierName"],
                          };

                          for (let i = 2; i < keys.length; i++) {
                            convertedObj[keys[i]] = obj[keys[i]];
                          }

                          return convertedObj;
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col  xl={6} lg={6} sm={6}>
                  <Form.Item name="supplierProductComment">
                    <Input />
                  </Form.Item>
                </Col>
                <Col  xl={2} lg={2} sm={2}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: "0 10px" }}
                  >
                    {" "}
                    <PlusCircleTwoTone style={{ marginRight: "10px" }} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>

          <div>
          {
            allSupplier && allSupplier.length > 0 && allSupplier.map((val) => (
              <div>
                <Row>
                  <Col>
                  {}
                  </Col>
                  <Col>
                  {val?.supplierProductComment}
                  </Col>
                  <Col>
                  </Col>
                </Row>
                </div>
            ))
          }
          </div>
        </div> */}
      </div>



    </div>
  )
}

export default ProductView