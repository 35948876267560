import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../../../utils/Network';
import styles from "./index.module.css";
// import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';


function PaymentMethodView() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`paymentterm/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data[0])
      })
      .catch(err => console.log(err));
  }
  console.log(viewPurchase, "ViewPurchase")


  return (


    <div style={{ width: "100%" }}>
      <div>
        <h2 className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate("/master/Payment")} style={{marginRight: '10px'}} /> Payment Method Details</h2>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Payment Method Id</p>
              <p>{viewPurchase?.paymentMethodID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Payment Term</p>
              <p>{viewPurchase?.paymentTerm}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Payment Mode</p>
              <p>{viewPurchase?.paymentMode}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Payment Against</p>
              <p>{viewPurchase?.paymentAgainst}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Payment Advance</p>
              <p>{(viewPurchase?.paymentAdvance) ? "Done" : "Pending"}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Cancellation Allowed</p>
              <p>{(viewPurchase?.cancellationAllowed) ? "Cancelled" : "Not Cancelled"}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Advance Percentage</p>
              <p>{viewPurchase?.advancePercent}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Balance Percentage</p>
              <p>{viewPurchase?.balancePercent}</p>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default PaymentMethodView