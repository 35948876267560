import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../utils/Network';
import styles from "./index.module.css";
import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';


function ViewContainer() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`container/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data)
      })
      .catch(err => console.log(err));
  }

  const columns = [
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode',
    },
    {
      title: 'UOM',
      dataIndex: 'unitofmeasure',
    },
    {
      title: 'BL',
      dataIndex: 'bl',
    },
    {
      title: "PO Number",
      dataIndex: "purchaseOrderNumber"
    },
    {
      title: "Container no",
      dataIndex: "containerNo"
    },
    {
      title: 'Invoice Product',
      dataIndex: 'invoiceproductName',
    },
    {
      title: 'Invoice Product Qty',
      dataIndex: 'invoiceproductquantity',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    }
  ]

  console.log(viewPurchase, "ViewPurchase")


  return (


    <div style={{ width: "100%" }}>
      <div>
        <p className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate(-1)} /> Container Details</p>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Container Id</p>
              <p>{viewPurchase?.containerID}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>CHA Name</p>
              <p>{viewPurchase?.chaName}</p>
            </div>
            
            <div className={styles.contentWrapper}>
              <p>Container No</p>
              <p>{viewPurchase?.containerNo}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Port of Loading</p>
              <p>{viewPurchase?.portOfLoadingName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Port of Departure</p>
              <p>{viewPurchase?.portOfDepartureName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>DeStuff</p>
              <p>{moment(viewPurchase?.deStuff).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Est Time Of Arrival</p>
              <p>{moment(viewPurchase?.estTimeOfArrival).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Est Time Of Departure</p>
              <p>{moment(viewPurchase?.estTimeOfDeparture).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Out Of Charge</p>
              <p>{moment(viewPurchase?.outOfCharge).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>IGM Number</p>
              <p>{viewPurchase?.igmNumber}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>IGM Date</p>
              <p>{moment(viewPurchase?.igmDate).format("YYYY-MM-DD")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Time In</p>
              <p>{moment(viewPurchase?.timeIn).format("MMM D, YYYY h:mm:ss a")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Time Out</p>
              <p>{moment(viewPurchase?.timeOut).format("MMM D, YYYY h:mm:ss a")}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Seal No</p>
              <p>{viewPurchase?.sealNo}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
          <p style={{ fontSize: "23px", marginLeft: '100px' }}>Products Details</p>
          <div className={styles.wrapperContainer}>
            <CustomTable columns={columns} data={viewPurchase?.productList} />
          </div>
        </div>

    </div>
  )
}

export default ViewContainer