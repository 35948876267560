import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../../../utils/Network';
import styles from "./index.module.css";
// import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Modals from '../../../../../Components/Modals/Modals';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Switch,
    Modal
} from "antd";
import { UserCredsContext } from "../../../../../utils/UserCredsContext";
import NumericInput from "../../../../../Components/NumericInput/NumericInput";

function SupplierForm({ fieldSupplierAddress, selected, getViewAddress = () => { }, id, setOpen = () => { } }) {
    const [form] = Form.useForm();
    const { base_items } = useContext(UserCredsContext);
    const [value, setValue] = useState("");

    const handleData = (values) => {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                const value = values[key];
                if (typeof value === 'object' && (value.$d instanceof Date || value._d instanceof Date)) {
                    if (value.$d) {
                        values[key] = moment(value.$d).format('YYYY-MM-DD');
                    } else {
                        values[key] = moment(value._d).format('YYYY-MM-DD');
                    }
                } else if (typeof value === 'string' && !isNaN(value)) {
                    values[key] = Number(value);
                }
            }
        }
        console.log(values, "djdjjd");
        api_call_token
            .patch(`supplier/${id}`, { ...values })
            .then((res) => {
                form.resetFields();
                getViewAddress();
                setOpen(false);
            })
            .catch((err) => console.log(err))

    };
    const onChange = () => {
        console.log("Hello")
    }
    return (
        <div>

            <Form form={form} initialValues={selected} onFinish={handleData}>
                <Row gutter={[26, 7]}>
                    <Col xs={24} md={14} lg={12}>
                        <span>Supplier Name</span>
                        <Form.Item
                            name="supplierName"
                            rules={[
                                { required: true, message: "Please enter Supplier name" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={14} lg={12}>
                        <span>Supplier Agent</span>
                        <Form.Item
                            name="supplierAgent"
                            rules={[
                                { required: true, message: "Please select currency" },
                            ]}
                        >
                            {base_items && (
                                <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare((optionB?.label ?? "").toLowerCase())
                                    }
                                    options={
                                        base_items["agentList"] &&
                                        base_items["agentList"]?.map((obj) => {
                                            const keys = Object.keys(obj);
                                            const convertedObj = {
                                                value: obj[keys[0]],
                                                label: obj[keys[1]],
                                            };

                                            for (let i = 2; i < keys.length; i++) {
                                                convertedObj[keys[i]] = obj[keys[i]];
                                            }

                                            return convertedObj;
                                        })
                                    }
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={14} lg={12}>
                        <span>Currency</span>
                        <Form.Item
                            name="currencyId"
                            rules={[
                                { required: true, message: "Please select currency" },
                            ]}
                        >
                            {base_items && (
                                <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare((optionB?.label ?? "").toLowerCase())
                                    }
                                    options={
                                        base_items["currencyList"] &&
                                        base_items["currencyList"]?.map((obj) => {
                                            const keys = Object.keys(obj);
                                            const convertedObj = {
                                                value: obj[keys[0]],
                                                label: obj[keys[1]],
                                            };

                                            for (let i = 2; i < keys.length; i++) {
                                                convertedObj[keys[i]] = obj[keys[i]];
                                            }

                                            return convertedObj;
                                        })
                                    }
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <span>Pan No</span>
                        <Form.Item
                            name="supplierPAN"
                            rules={[
                                { required: true, message: "Please enter PAN number" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} lg={12}>
                        <span>Supplier CIN</span>
                        <Form.Item name="supplierCIN">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <span>Annual Turnover</span>
                        <Form.Item
                            name="annualTurnover"
                            rules={[
                                { required: true, message: "Please enter annual turnover" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <span>Manufacturing / trading</span>
                        <Form.Item
                            name="manufacturing"
                            rules={[
                                { required: true, message: "Please enter annual turnover" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form></div>
    )
}

export default SupplierForm