import { Switch } from 'antd'
import React from 'react'
import { Route, BrowserRouter, Routes, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import Layout from '../Components/Layout/Layout'
import ConsignmentList from '../Pages/Consignment/ConsignmentList'
import ConsignForm from '../Pages/Consignment/ConsignForm/ConsignForm'
import CreateField from '../Pages/FieldList/CreateField'
import CreateForm from '../Pages/FieldList/CreateForm'
import FieldList from '../Pages/FieldList/FieldList'
import Container from '../Pages/Container/Container'
import ContainerForm from '../Pages/Container/ContainerForm/ContainerForm'
import AddProducts from '../Pages/Container/AddProducts/AddProducts'
import ShippingList from '../Pages/Shipping/ShippingList'
import ShippingForm from '../Pages/Shipping/ShippingForm/ShippingForm'
import BOEList from '../Pages/BOE/BOEList'
import BoeForm from '../Pages/BOE/BoeForm/BoeForm'
import ViewPo from '../Pages/FieldList/ViewPurchase/ViewPo'
import RouteLayouts from '../Layout/RouteLayouts'
import POLayout from '../Layout/POLayout'
import ConsignmentLayout from '../Layout/ConsignmentLayout'
import ContainerLayout from '../Layout/ContainerLayout'
import { AgentLayout, BOELayout, BuyerLayout, CHALayout, CODLayout, ContainerInOutLayout, CurrencyLayout, DeliveryChallanLayout, DispatchLayout, DsrLayout, ElementLayout, HeatConLayout, HeatMainLayout, InsuranceLayout, IntercomLayout, InvoiceLayout, LocalLayout, MasterLayout, PaymentLayout, PlaceOfrecLayout, PoMainLayout, PortInnerLayout, PortLayout, ProductLayout, ShippingLayout, ShippingLineLayout, ShippmentLayout, SupplierLayout, TCLayout, TermsLayout, TransporterLayout, UmoLayout } from '../Layout/BOELayout'
import ViewConsignment from '../Pages/Consignment/ViewConsignment/ViewConsignment'
import ViewContainer from '../Pages/Container/ViewContainer/ViewContainer'
import ViewBoe from '../Pages/BOE/ViewBoe/ViewBoe'
import ViewShipping from '../Pages/Shipping/ViewShipping/ViewShipping'
import MasterList from '../Pages/Master/MasterList/MasterList'
import Buyer from '../Pages/Master/MasterData/Buyer/Buyer'
import CurrencyList from '../Pages/Master/MasterData/Currency/CurrencyList/CurrencyList'
import CurrencyForm from '../Pages/Master/MasterData/Currency/CurrencyForm/CurrencyForm'
import BuyerList from '../Pages/Master/MasterData/Buyer/BuyerList/BuyerList'
import SupplierList from '../Pages/Master/MasterData/Supplier/SupplierList/SupplierList'
import SupplierForm from '../Pages/Master/MasterData/Supplier/SupplierForm/SupplierForm'
import PortList from '../Pages/Master/MasterData/Port/PortList/PortList'
import PortForm from '../Pages/Master/MasterData/Port/PortForm/PortForm'
import ProductList from '../Pages/Master/MasterData/Product/ProductList/ProductList'
import ProductForm from '../Pages/Master/MasterData/Product/ProductForm/ProductForm'
import DispatchList from '../Pages/Master/MasterData/Dispatch/DispatchList/DispatchList'
import DispatchForm from '../Pages/Master/MasterData/Dispatch/DispatchForm/DispatchForm'
import PaymentList from '../Pages/Master/MasterData/PaymentMethod/PaymentList/PaymentList'
import PaymentForm from '../Pages/Master/MasterData/PaymentMethod/PaymentForm/PaymentForm'
import IntercomList from '../Pages/Master/MasterData/Intercom/IntercomList/IntercomList'
import IntercomForm from '../Pages/Master/MasterData/Intercom/IntercomForm/IntercomForm'
import ShippmentList from '../Pages/Master/MasterData/Shippment/ShippmentList/ShippmentList'
import TermsForm from '../Pages/Master/MasterData/Terms/TermsForm/TermsForm'
import ShippmentForm from '../Pages/Master/MasterData/Shippment/ShippmentForm/ShippmentForm'
import Notfound from '../Pages/Notfound/Notfound'
import Invoice from '../Pages/Invoice/Invoice'
import CurrencyView from '../Pages/Master/MasterData/Currency/CurrencyView/CurrencyView'
import SupplierView from '../Pages/Master/MasterData/Supplier/SupplierView/SupplierView'
import PortView from '../Pages/Master/MasterData/Port/PortView/PortView'
import ProductView from '../Pages/Master/MasterData/Product/ProductView/ProductView'
import DispatchView from '../Pages/Master/MasterData/Dispatch/DispatchView/DispatchView'
import PaymentMethodView from '../Pages/Master/MasterData/PaymentMethod/PaymentMethodView/PaymentMethodView'
import IncoTermView from '../Pages/Master/MasterData/Intercom/IncoTermView/IncoTermView'
import ShippmentView from '../Pages/Master/MasterData/Shippment/ShippmentView/ShippmentView'
import InvoiceList from '../Pages/Invoice/InvoiceList'
import ViewInvoice from '../Pages/Invoice/ViewInvoice/ViewInvoice'
import MainPo from '../Pages/FieldList/MainPo'
import UmoList from '../Pages/Master/MasterData/UMO/UmoList/UmoList'
import UmoForm from '../Pages/Master/MasterData/UMO/Umoform/UmoForm'
import UmoView from '../Pages/Master/MasterData/UMO/UmoView/UmoView'
import ShippingLineForm from '../Pages/Master/MasterData/ShippingLine/ShippingLineForm/ShippingLineForm'
import ShippingLineView from '../Pages/Master/MasterData/ShippingLine/ShippingLineView/ShippingLineView'
import ShippingLineList from '../Pages/Master/MasterData/ShippingLine/ShippingList/ShippingLineList'
import TCList from '../Pages/Master/MasterData/TermsCondition/TCList/TCList'
import TCform from '../Pages/Master/MasterData/TermsCondition/TCform/TCform'
import TCView from '../Pages/Master/MasterData/TermsCondition/TCView/TCView'
import BuyerForm from '../Pages/Master/MasterData/Buyer/BuyerForm/BuyerForm'
import DraftPo from '../Pages/FieldList/ViewPurchase/DraftPo'
import BuyerView from '../Pages/Master/MasterData/Buyer/BuyerView/BuyerView'
import CreatePo from '../Pages/FieldList/CreatePo'
import CHAList from '../Pages/Master/MasterData/CHA/CHAList/CHAList'
import CHAForm from '../Pages/Master/MasterData/CHA/CHAForm/CHAForm'
import CHAView from '../Pages/Master/MasterData/CHA/CHAView/CHAView'
import AgentList from '../Pages/Master/MasterData/Agent/AgentList/AgentList'
import AgentForm from '../Pages/Master/MasterData/Agent/AgentForm/AgentForm'
import AgentView from '../Pages/Master/MasterData/Agent/AgentView/AgentView'
import PortMain from '../Pages/Master/MasterData/PortMain/PortMain'
import PRList from '../Pages/Master/MasterData/PR/PRList/PRList'
import PRForm from '../Pages/Master/MasterData/PR/PRForm/PRForm'
import PRView from '../Pages/Master/MasterData/PR/PRView/PRView'
import PoIndex from '../Pages/POMain/POIndex/PoIndex'
import LocalMainPo from '../Pages/POMain/LocalMainPo/LocalMainPo'
import CreatePoLc from '../Pages/POMain/CreatePoLc'
import ViewPoLc from '../Pages/POMain/ViewPurchase/ViewPoLc';
import DraftPoLc from '../Pages/POMain/ViewPurchase/DraftPoLc';
import HeatMain from '../Pages/Heat/HeatMain'
import HeatList from '../Pages/Heat/HeatList'
import HeatView from '../Pages/Heat/HeatView'
import CODList from '../Pages/Master/MasterData/COD/CODList/CODList'
import CODForm from '../Pages/Master/MasterData/COD/CODForm/CODForm'
import CODView from '../Pages/Master/MasterData/COD/CODView/CODView'
import HeatConsumableList from '../Pages/Master/MasterData/HeatConsumable/HeatConsumableList/HeatConsumableList'
import HeatConsumableForm from '../Pages/Master/MasterData/HeatConsumable/HeatConsumableform/HeatConsumableForm'
import HeatConsumableView from '../Pages/Master/MasterData/HeatConsumable/HeatConsumableView/HeatConsumableView'
import TransporterList from '../Pages/Master/MasterData/Transporter/TransporterList/TransporterList'
import TransporterForm from '../Pages/Master/MasterData/Transporter/Transporterform/TransporterForm'
import TransporterView from '../Pages/Master/MasterData/Transporter/TransporterView/TransporterView'
import InsuranceList from '../Pages/Master/MasterData/Insurance/InsuranceList/InsuranceList'
import InsuranceForm from '../Pages/Master/MasterData/Insurance/Insuranceform/InsuranceForm'
import InsuranceView from '../Pages/Master/MasterData/Insurance/InsuranceView/InsuranceView'
import ElementList from '../Pages/Master/MasterData/Elements/ElementList/ElementList'
import ElementForm from '../Pages/Master/MasterData/Elements/Elementform/ElementForm'
import ElementView from '../Pages/Master/MasterData/Elements/ElementView/ElementView'
import MainHeat from '../Pages/Heat/Main/MainHeat'
import ListingHeat from '../Pages/Heat/ListingHeat/ListingHeat'
import Dsr from '../Pages/DSR/Dsr'
import SupplierFormStatic from '../Pages/Master/MasterData/Supplier/SupplierFormStatic/SupplierFormStatic'
import SupplierMain from '../Pages/Master/MasterData/Supplier/SupplierMain/SupplierMain'
import SupplierInternFormStatic from '../Pages/Master/MasterData/Supplier/SupplierInternFormStatic/SupplierInternFormStatic'
import SupplierInternView from '../Pages/Master/MasterData/Supplier/SupplierInternView/SupplierInternView'
import ContainerInOutList from '../Pages/ContainerInOut/ContainerInOutList'
import ContainerInOutView from '../Pages/ContainerInOut/ContainerInOutView/ContainerInOutView'
import DeliveryChallan from '../Pages/DeliveryChallan/DeliveryChallan'
import ChallanForm from '../Pages/DeliveryChallan/ChallanForm/ChallanForm'
import CreateContainer from '../Pages/Container/CreateContainer/CreateContainer'


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RouteLayouts />}>
            <Route path='purchaseMain' element={<PoMainLayout />}>
                <Route index element={<PoIndex />} />
                <Route path='purchase' element={<POLayout />} >
                    <Route index element={<MainPo />} />
                    <Route path="create" element={<CreateField />} />
                    <Route path="createforms" element={<CreateForm />} />
                    <Route path="createform" element={<CreatePo />} />
                    <Route path="purchaseform/:id" element={<ViewPo />} />
                    <Route path="draftform/:id" element={<DraftPo />} />
                </Route>
                <Route path='localpo' element={<LocalLayout />}>
                    <Route index element={<LocalMainPo />} />
                    <Route path="createform" element={<CreatePoLc />} />
                    <Route path="purchaseforms/:id" element={<ViewPoLc />} />
                    <Route path="draftform/:id" element={<DraftPoLc />} />
                </Route>
            </Route>

            <Route path='consignment' element={<ConsignmentLayout />}>
                <Route index element={<ConsignmentList />} />
                <Route path='createconsignment' element={<ConsignForm />} />
                <Route path=':id' element={<ViewConsignment />} />
            </Route>
            <Route path='container' element={<ContainerLayout />}>
                <Route index element={<Container />} />
                <Route path='addcontainer' element={<CreateContainer />} />
                <Route path='editcontainer' element={<ContainerForm />} />
                <Route path="addProducts" element={<AddProducts />} />
                <Route path=':id' element={<ViewContainer />} />
            </Route>
            <Route path="boe" element={<BOELayout />}>
                <Route index element={<BOEList />} />
                <Route path="createboe" element={<BoeForm />} />
                <Route path=':id' element={<ViewBoe />} />
            </Route>
            <Route path="shipping" element={<ShippingLayout />}>
                <Route index element={<ShippingList />} />
                <Route path="createshipping" element={<ShippingForm />} />
                <Route path=':id' element={<ViewShipping />} />
            </Route>
            <Route path="heat" element={<HeatMainLayout />}>
                <Route index element={<MainHeat />} />
                <Route path='list/:id' element={<ListingHeat />} />
                {/* <Route index element={<HeatList />} /> */}
                <Route path="create/:id" element={<HeatMain />} />
                <Route path=':id' element={<HeatView />} />
            </Route>
            <Route path="master" element={<MasterLayout />}>
                <Route index element={<MasterList />} />
                <Route path='Buyer' element={<BuyerLayout />} >
                    <Route index element={<BuyerList />} />
                    <Route path="buyerform" element={<BuyerForm />} />
                    <Route path=':id' element={<BuyerView />} />
                </Route>
                <Route path='Currency' element={<CurrencyLayout />}>
                    <Route index element={<CurrencyList />} />
                    <Route path='currencyform' element={<CurrencyForm />} />
                    <Route path=':id' element={<CurrencyView />} />
                </Route>
                <Route path='Agents' element={<AgentLayout />}>
                    <Route index element={<AgentList />} />
                    <Route path='agentform' element={<AgentForm />} />
                    <Route path=':id' element={<AgentView />} />
                </Route>
                <Route path='Supplier' element={<SupplierLayout />}>
                    <Route index element={<SupplierMain />} />
                    <Route path='supplierList' element={<SupplierList />} />
                    <Route path='supplierform' element={<SupplierFormStatic />} />
                    <Route path='supplierInternform' element={<SupplierInternFormStatic />} />
                    {/* <Route path='supplierform' element={<SupplierForm />} /> */}
                    <Route path=':id' element={<SupplierView />} />
                    <Route path='intern/:id' element={<SupplierInternView />} />
                </Route>
                <Route path='Ports' element={<PortLayout />}>
                    <Route index element={<PortMain />} />
                    <Route path='port' element={<PortInnerLayout />} >
                        <Route index element={<PortList />} />
                        <Route path='portform' element={<PortForm />} />
                        <Route path=':id' element={<PortView />} />
                    </Route>
                    <Route path='pr' element={<PlaceOfrecLayout />}>
                        <Route index element={<PRList />} />
                        <Route path='prform' element={<PRForm />} />
                        <Route path=':id' element={<PRView />} />
                    </Route>
                </Route>
                <Route path='CHA' element={<CHALayout />}>
                    <Route index element={<CHAList />} />
                    <Route path='chaform' element={<CHAForm />} />
                    <Route path=':id' element={<CHAView />} />

                </Route>
                <Route path='Products' element={<ProductLayout />}>
                    <Route index element={<ProductList />} />
                    <Route path='productform' element={<ProductForm />} />
                    <Route path=':id' element={<ProductView />} />
                </Route>
                <Route path='Dispatch' element={<DispatchLayout />}>
                    <Route index element={<DispatchList />} />
                    <Route path='dispatchform' element={<DispatchForm />} />
                    <Route path=':id' element={<DispatchView />} />
                </Route>
                <Route path='Payment' element={<PaymentLayout />}>
                    <Route index element={<PaymentList />} />
                    <Route path='paymentform' element={<PaymentForm />} />
                    <Route path=':id' element={<PaymentMethodView />} />
                </Route>
                <Route path='Intercom' element={<IntercomLayout />}>
                    <Route index element={<IntercomList />} />
                    <Route path='incotermform' element={<IntercomForm />} />
                    <Route path=':id' element={<IncoTermView />} />
                </Route>
                <Route path='Terms' element={<TermsLayout />}>
                    <Route index element={<IntercomList />} />
                    <Route path='incotermform' element={<IntercomForm />} />
                </Route>
                <Route path='Shippment' element={<ShippmentLayout />}>
                    <Route index element={<ShippmentList />} />
                    <Route path='shippmentform' element={<ShippmentForm />} />
                    <Route path=':id' element={<ShippmentView />} />
                </Route>
                <Route path='heatconsumable' element={<HeatConLayout />}>
                    <Route index element={<HeatConsumableList />} />
                    <Route path='heatConsumeform' element={<HeatConsumableForm />} />
                    <Route path=':id' element={<HeatConsumableView />} />
                </Route>
                <Route path='Umo' element={<UmoLayout />}>
                    <Route index element={<UmoList />} />
                    <Route path='umoform' element={<UmoForm />} />
                    <Route path=':id' element={<UmoView />} />
                </Route>
                <Route path='shippingLine' element={<ShippingLineLayout />}>
                    <Route index element={<ShippingLineList />} />
                    <Route path='shippingLineForm' element={<ShippingLineForm />} />
                    <Route path=':id' element={<ShippingLineView />} />
                </Route>
                <Route path='TC' element={<TCLayout />}>
                    <Route index element={<TCList />} />
                    <Route path='TCform' element={<TCform />} />
                    <Route path=':id' element={<TCView />} />
                </Route>
                <Route path='cod' element={<CODLayout />}>
                    <Route index element={<CODList />} />
                    <Route path='codform' element={<CODForm />} />
                    <Route path=':id' element={<CODView />} />
                </Route>
                <Route path='Transporter' element={<TransporterLayout />}>
                    <Route index element={<TransporterList />} />
                    <Route path='Transporterform' element={<TransporterForm />} />
                    <Route path=':id' element={<TransporterView />} />
                </Route>
                <Route path='Insurance' element={<InsuranceLayout />}>
                    <Route index element={<InsuranceList />} />
                    <Route path='Insuranceform' element={<InsuranceForm />} />
                    <Route path=':id' element={<InsuranceView />} />
                </Route>
                <Route path='elements' element={<ElementLayout />}>
                    <Route index element={<ElementList />} />
                    <Route path='elementsform' element={<ElementForm />} />
                    <Route path=':id' element={<ElementView />} />
                </Route>
            </Route>
            <Route path='dsr' element={<DsrLayout />}>
                <Route index element={<Dsr />} />
            </Route>
            <Route path="Invoice" element={<InvoiceLayout />}>
                <Route index element={<InvoiceList />} />
                <Route path='createInvoice' element={<Invoice />} />
                <Route path=':id' element={<ViewInvoice />} />
            </Route>

            <Route path="inout" element={<ContainerInOutLayout />}>
                <Route index element={<ContainerInOutList />} />
                {/* <Route path='createInvoice' element={<Invoice />} /> */}
                <Route path=':id' element={<ContainerInOutView />} /> 
            </Route>
            <Route path="deliverychallan" element={<DeliveryChallanLayout />}>
                <Route index element={< DeliveryChallan/>} />
                <Route path='challanform' element={<ChallanForm />} />
                {/* <Route index element={<ContainerInOutList />} />
                <Route path=':id' element={<ContainerInOutView />} />  */}
            </Route>
            <Route path='*' element={<Notfound />} />

        </Route>
    )
)

function Mainroutes() {
    return (
        <RouterProvider router={router} />
    )
}

export default Mainroutes