import { Button, Col, Form, Row, Select, Switch } from "antd";
import React, { useContext, useState } from "react";
import { UserCredsContext } from "../../../utils/UserCredsContext";

function SubmitForm({ onFinishDownload = () => { }, setOpenDownloadEditModal = () => { } }) {
  const [form] = Form.useForm();
  const [boolVal, setBoolVal] = useState(true);
  const { base_items, set_Base } = useContext(UserCredsContext);
  const [downloadType, setDownloadType] = useState(''); // State to store the selected download type

  const handleFinish = (value) => {
    console.log(value,downloadType, "vakssssssss")
    // console.log({...value, check: boolVal});
    let data = { ...value, check: boolVal }
    onFinishDownload(data, downloadType);
    form.resetFields();
    setOpenDownloadEditModal(false)
  };

  const handleButtonClick = (type) => {
    setDownloadType(type); // Update the selected download type
  };

  const handleChange = (e) => {
    console.log(e)
    setBoolVal(e)
  }

  return (
    <div>
      <Form name="editForm" form={form} onFinish={handleFinish}>
        <Row gutter={[30, 12]}>
          <Col lg={12}>
            <span>Supplier</span>
            <Form.Item
              name="supplier"
              rules={[
                {
                  required: true,
                  message: `Please Enter supplier!`,
                },
              ]}
            >
              {base_items && (
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    base_items["buyerList"] &&
                    base_items["buyerList"]?.map((obj) => {
                      const keys = Object.keys(obj);
                      const convertedObj = {
                        value: obj[keys[1]],
                        label: obj[keys[1]],
                      };

                      for (let i = 2; i < keys.length; i++) {
                        convertedObj[keys[i]] = obj[keys[i]];
                      }

                      return convertedObj;
                    })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item name="check" valuePropName="checked">
              <span>Analysis : </span>
              <Switch onChange={(e) => handleChange(e)} checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={() => handleButtonClick('pdf')} style={{marginRight:'10px'}}>
            Download as Pdf
          </Button>
          <Button type="primary" htmlType="submit" onClick={() => handleButtonClick('excel')}>
            Download as Excel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SubmitForm;
