import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { api_call_token, base_url } from "../../../utils/Network";
import styles from "./index.module.css";

function MasterList() {
  const [masterList, setMasterList] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    getMasterList();
  }, []);

  const getMasterList = () => {
    api_call_token
      .get(`masterlist`)
      .then((res) => {
        if (res.data) {
          setMasterList(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const goTo = (v) => {
      console.log(v)
      if(v?.masterName == "Dispatch Method"){
        navigate("Dispatch")
      } else if (v?.masterName == "Payment Method") {
        navigate("Payment")
      }else if (v?.masterName == "Incoterm @2020") {
        navigate("Intercom")
      }else if (v?.masterName == "Types Of Shipments") {
        navigate("Shippment")
      }else if (v?.masterName == "Unit Of Measure") {
        navigate("Umo")
      }else if (v?.masterName == "Shipping Line") {
        navigate("shippingLine")
      }else if( v?.masterName == "Terms & Conditions"){
        navigate("TC");
      }else if( v?.masterName == "Clearing House Agent"){
        navigate("CHA");
      } else if (v?.masterName == "City Of Delivery"){
        navigate("cod");
      } else if (v?.masterName == "Heat Consumable"){
        navigate("heatconsumable")
      } else if (v?.masterName == "Element Master"){
        navigate("elements")
      }
      else{
        navigate(v?.masterName);
      }
  }

  console.log(masterList, "masterList");
  return (
    <div style={{width: '87%', padding: '20px'}}>
      <div><h2>Master</h2></div>
      <div style={{marginTop: '30px'}}>
        <Row gutter={[16, 16]}>
          {masterList && masterList.map((v,i) => 
          <Col xs={24} md={14} lg={8} >
              <div className={styles.mainData} onClick={() => goTo(v)}>
                <img src={`${base_url}${v.masterImage}`} alt={v.masterImage}/> 
                <p>{v.masterName}</p>
                <p>{v.masterCount}</p>
              </div>
          </Col>)}

        </Row>
      </div>

      <Outlet />
    </div>
  );
}

export default MasterList;
