import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../../../utils/Network';
import styles from "./index.module.css";
// import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';


function TransporterView() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`transporter/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data[0])
      })
      .catch(err => console.log(err));
  }
  console.log(viewPurchase, "ViewPurchase")


  return (


    <div style={{ width: "100%" }}>
      <div>
        <h2 className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate("/master/Transporter")} style={{marginRight: '10px'}}/> Transporter Details</h2>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>Transporter ID</p>
              <p>{viewPurchase?.transporterId}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Transporter Company Name</p>
              <p>{viewPurchase?.transporterCompanyName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Transporter Contact Number</p>
              <p>{viewPurchase?.transporterContactNumber}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Transporter Contact Person</p>
              <p>{viewPurchase?.transporterContactPerson}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Transporter GST Number</p>
              <p>{viewPurchase?.transporterGSTNumber}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Transporter Address</p>
              <p>{viewPurchase?.transporterAddress}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransporterView