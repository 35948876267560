// EditForm.jsx
import React from 'react';
import { Form, Input, Space, Row, Col } from 'antd';

const EditForm = ({ currentForm, handleInputChange, key, form }) => {
  return (
    <Form name="editForm" key={key} form={form}>
      <Row gutter={[10, 7]}>
      <div style={{display: 'flex'}}>
        {["Ingot Piece","Ingot Gross Weight","Ingot Net Weight", "Ingot Piece Sold", "Ingot Gross Weight Sold", "Ingot Net Weight Sold", "Ingot Piece Remaining", "Ingot Gross Weight Remaining", "Ingot Net Weight Remaining"].map((v,i) => (
          <span style={{margin: '10px', width: '100%'}}>
            {v}
          </span>
        ))}
        </div>
        {currentForm.map((item, index) => (
          <Space key={index} style={{ marginBottom: 16 }}>
            <Col xs={24} md={14} lg={24}>
              {/* <span>
                Ingot Piece
              </span> */}
              <Form.Item name={['ingotPiece', index]} initialValue={item.ingotPiece}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={14} lg={24}>
              {/* <span>
                Ingot Weight
              </span> */}
              <Form.Item name={['ingotGrossWeight', index]} initialValue={item.ingotGrossWeight}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={14} lg={24}>
              {/* <span>
                Ingot Weight
              </span> */}
              <Form.Item name={['ingotNettWeight', index]} initialValue={item.ingotNettWeight}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={14} lg={24}>
              {/* <span>
                Ingot Piece Sold
              </span> */}
              <Form.Item name={['ingotPieceSold', index]} initialValue={item.ingotPieceSold}>
                <Input onChange={(e) => handleInputChange(index, 'ingotPieceSold', e.target.value)} />
              </Form.Item>
            </Col><Col xs={24} md={14} lg={24}>
              {/* <span>
                Ingot
                Weight Sold
              </span> */}
              <Form.Item name={['ingotGrossWeightSold', index]} initialValue={item.ingotGrossWeightSold}>
                <Input onChange={(e) => handleInputChange(index, 'ingotGrossWeightSold', e.target.value)} />
              </Form.Item>

            </Col><Col xs={24} md={14} lg={24}>
              {/* <span>
                Ingot
                Weight Sold
              </span> */}
              <Form.Item name={['ingotNetWeightSold', index]} initialValue={item.ingotNetWeightSold}>
                <Input onChange={(e) => handleInputChange(index, 'ingotNetWeightSold', e.target.value)} />
              </Form.Item>

            </Col><Col xs={24} md={14} lg={24}>
            {/* <span>
                Ingot Piece Remaining
              </span> */}
              <Form.Item name={['ingotPieceRemainig', index]} initialValue={item.ingotPieceRemainig}>
                <Input disabled />
              </Form.Item>
            </Col><Col xs={24} md={14} lg={24}>
            {/* <span>
                Ingot Weight Remaining
              </span> */}
              <Form.Item name={['ingotGrossWeightRemainig', index]} initialValue={item.ingotGrossWeightRemainig}>
                <Input disabled />
              </Form.Item>
              </Col>
              <Col xs={24} md={14} lg={24}>
              <Form.Item name={['ingotNetWeightRemainig', index]} initialValue={item.ingotNetWeightRemainig}>
                <Input disabled />
              </Form.Item>
            </Col>





          </Space>
        ))}
      </Row>

    </Form>
  );
};

export default EditForm;
