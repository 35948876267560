import React, { Component, createContext } from "react";
// import { createTheme } from "@mui/material/styles";
// import { api_open, api_token } from "../../Utils/Network";
import { api_call_token } from "./Network";
export const UserCredsContext = createContext();

const localstorage_state = localStorage.getItem("user_cred_context");

function getLocalData(keyname) {
  // Function to return values from local storage

  let object = null;

  try {
    object = JSON.parse(localstorage_state);
  } catch {
    console.error("There was error while parsing data from localstorage.");
  }

  if (object) {
    if (object[keyname]) {
      return object[keyname];
    }
  }

  if (keyname === "themeMode") return "dark";
  if (keyname === "user_state") return {};
  if (keyname === "token") return { access: "", refresh: "" };
  if (keyname === "nftData") return {};
  return "";
}


class UserCredsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_item: 
        {
          agentList: [],
          buyerList: [],
          countryList: [],
          currencyList: [],
          dispatchMethodList: [],
          icomerList: [],
          paymentMethodList: [],
          portList: [],
          productList: [],
          shipmentTypeList: [],
          supplierList: [],
          termsList: [],
          unitOfMeasureList: [],
      }
    };
    this.setBase = this.setBase.bind(this);
  }




  componentDidMount() {
    this.setBase();
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("user_cred_context", JSON.stringify(this.state));
    });

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user_state !== this.state.user_state) {
      // localStorage.setItem("ed5_user_cred_context", JSON.stringify(this.state));
    }
  }


  componentWillUnmount() { }


  setBase = () => {
    api_call_token.get(`formData/dynamic`).then((res) => {
      console.log(res, "DDDDDDDDDDDDDDDDRRRR")
      this.setState({ base_item: res.data })
    } )
  }

  

  logout = () => {
    this.setState(
      {
        themeMode: "dark",
        user_state: {},
        token: { access: "", refresh: "" },
      },
      () => {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/login";
        }, 500);
      }
    );
  };

  render() {
    return (
      <UserCredsContext.Provider
        value={{
          base_items: this.state.base_item,
          set_Base: this.setBase,
        }}
      >
        {this.props.children}
      </UserCredsContext.Provider>
    );
  }
}

export default UserCredsContextProvider;
