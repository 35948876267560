import React, { useState } from 'react'
import Stepper from '../../Components/Stepper/Stepper'
import styles from "./index.module.css";
import Twelve from "../../Assets/Fields/12.png";
import Bool from "../../Assets/Fields/boolean.png";
import Decimal from "../../Assets/Fields/decimal.png";
import Number from "../../Assets/Fields/number.png";
import Para from "../../Assets/Fields/para.png";
import Rich from "../../Assets/Fields/rich.png";
import Text from "../../Assets/Fields/text.png";
import { Button, Col, Form, Input, Row, Switch } from 'antd';


function CreateField() {
    const [currentState, setState] = useState(0);

    return (
        <div className={styles.mainContainer}>
            <h2>Adding New Field</h2>
            <div>
                <Stepper currentState={currentState} items={item} />
            </div>

            {currentState == 0 &&
                <div>
                    <p>Select a type for your Field</p>

                    {
                        listingData.map((value, index) => (
                            <div className={styles.fieldListing}>
                                <div>
                                    <div className={styles.fieldIcons}>
                                        <img src={value?.icons} alt="images" />
                                    </div>
                                </div>
                                <div>
                                    <p className={styles.field}>{value?.title}</p>
                                    <p className={styles.descriptions}>{value?.description}</p>
                                </div>
                            </div>
                        ))
                    }

                </div>}

            {
                currentState == 1 &&
                <div>
                    <div>
                        <h2>General Information</h2>

                        <Form>
                            <div>
                                <Row style={{ padding: "1% 1%", rowGap: '10px' }} >

                                    <Col xs={24} md={14} lg={14}>
                                        <span>Field Name*</span>
                                        <Form.Item name="title" rules={[{ required: true, message: 'Please Enter Title!', },]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={24} lg={14}>
                                        <span>Description</span>
                                        <Form.Item name="description" rules={[{ required: true, message: 'Please Enter description!', },]}>
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={24} lg={14}>
                                        <Form.Item valuePropName="checked" name="status" noStyle>
                                            <Switch checkedChildren="Yes" unCheckedChildren="No" />
                                        </Form.Item>
                                        <span className="ml-2">Status Enabled</span>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </div>
            }

            <div className={styles.cancelNext}>
                <Button>Cancel</Button>
                <Button type='primary' style={{ margin: '0 10px' }} onClick={() => setState(1)}>Next</Button>
            </div>
        </div>
    )
}

export default CreateField

const item = [
    {
        title: "Choose the field type"
    },
    {
        title: "Add Details"
    }
];


const listingData = [
    {
        title: "Tiny Int",
        description: "Used to represent small numerical values.",
        icons: Twelve,
    },
    {
        title: "Boolean",
        description: "Used to represent small numerical values.",
        icons: Bool,
    },
    {
        title: "Text",
        description: "Used to store alphanumeric characters and short sentences.",
        icons: Text,
    },
    {
        title: "Paragraph Text",
        description: "Used to store longer text entries or paragraphs.",
        icons: Para,
    },
    {
        title: "Numbers - Integers",
        description: "Used to store whole numbers without decimal places.",
        icons: Number,
    },
    {
        title: "Number - Decimal",
        description: "Used to store numeric values with decimal places.",
        icons: Decimal,
    }
]