import { Form, Input, Select } from 'antd';
import React from 'react'

function Packing({Instock, handleCheckoutSubmit = () => {}, form}) {

  return (
    <div style={{padding: '20px'}}>
      
          <Form form={form} onFinish={handleCheckoutSubmit}>
            <Form.Item
              name="vehicleNumber"
              label="Enter Vehicle Number"
              rules={[
                {
                  required: true,
                  message: `Please Enter Vehicle Number!`,
                },
              ]}
            >
              <Input
              // value={vehicleNo}
              // onChange={(e) => setVehicleNo(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="packingPartyName"
              label="Enter Party Name"
              rules={[
                {
                  required: false,
                  message: `Please Enter Vehicle Number!`,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="status" label="Delivery Status">
              {Instock && (
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    Instock &&
                    Instock?.map((obj) => {
                      const keys = Object.keys(obj);
                      const convertedObj = {
                        value: obj["statusID"],
                        label: obj["statusName"],
                      };

                      for (let i = 2; i < keys.length; i++) {
                        convertedObj[keys[i]] = obj[keys[i]];
                      }

                      return convertedObj;
                    })
                  }
                />
              )}
            </Form.Item>
          </Form>
        </div>
  )
}

export default Packing