import { message } from "antd";

export function alertMessage(msg = "Product Added", type=0){
    if(type == 1) return message.warning({content: msg})
    else if (type == 2) return message.error({content: msg})
    return message.success({content: msg + "Successfully"})
}

export const getLocalData = (type) => {
    const value = localStorage.getItem(type);
    return JSON.parse(value);
  };

export const saveLocalData = (type, value) => {
    localStorage.setItem(type, JSON.stringify(value));
  };

  export const formatTime = (inputTime) => {
    if (!inputTime || typeof inputTime !== 'string') {
      return <p>&nbsp;</p> ; 
    }
  
    const [hours, minutes] = inputTime?.split(":").map(Number);
    const period = hours >= 12 ? "pm" : "am";
    const formattedHours = hours > 12 ? hours - 12 : hours;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
    return formattedTime;
  };
  
  export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear().toString().slice(-2);
    const monthAbbreviations = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthAbbreviations[date.getMonth()];
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${day} ${month}'${year}`;
    return formattedDate;
  }