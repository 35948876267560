import React, { useEffect, useState } from 'react'
import { api_call_token } from '../../../utils/Network';
import { Button, Input, Table, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { alertMessage } from '../../../utils/helperFunctions';

function HeatElements({ setCurrentStep = () => { }, id, heatValues, getHeat = () => { } }) {

    const [heatElement, setHeatElement] = useState([]);
    const [heatElementCheck, setHeatElementCheck] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [messageData, setMessageData] = useState("");
    let navigate = useNavigate();

    useEffect(() => {
        getAnalysis();
        // getElement();
    }, [])

    // const getElement = () => {
    //     api_call_token
    //         .get(`elementmaster?size=35`)
    //         .then((res) => {
    //             // debugger;
    //             if (res.data.data) {
    //                 let newArray = [];

    //                 res.data.data.map((v, i) => {
    //                     newArray.push({
    //                         elementFullName: v?.elementFullName || "",
    //                         elementGrade: v?.elementGrade || "",
    //                         elementName: v?.elementName || "",
    //                         heatValueSecond: ""
    //                     })
    //                 })

    //                 setHeatElement(newArray)
    //             }
    //         })
    //         .catch(err => console.log(err))
    // }

    const getMasterElements = (heat) => {
        api_call_token
            .get(`elementmaster?size=35`)
            .then((res) => {
                // debugger;
                if (res.data.data) {

                    heat.length > 0 && heat.forEach(element => {
                        const matchingHeat = res.data.data.find(heatItem => heatItem.elementName === element.elementName);
                        if (matchingHeat) {
                            element.elementFullName = matchingHeat.elementFullName;
                            element.elementGrade = matchingHeat.elementGrade;
                        } else {
                            element.elementFullName = "";
                            element.elementGrade = "";
                        }
                    });

                    console.log(heat, "HEATDATA")
                    setHeatElement(heat)
                    // heatElement.map((v, i) => {
                    //     if(v?.elementName == )
                    // })
                }
            })
            .catch(err => console.log(err))
    }

    const getAnalysis = () => {
        api_call_token
            .get(`analyse/${heatValues?.id}`)
            // .get(`analyse/1`)
            .then((res) => {
                // debugger;
                if (res.data.data && res.data.data.length > 0) {

                    setHeatElementCheck(res.data.data);
                    getMasterElements(res.data.data);
                    // fetchAnalysis();

                } else {
                    fetchAnalysis();
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const fetchAnalysis = () => {
        api_call_token
            .get(`parse?heatId=${heatValues?.id}&heatNumber=${heatValues?.heatNumber}`) // original 
            .then((res) => {

                console.log(res.data, "DDHHHH")
                if (res.data.status) {
                    getAnalysis();
                } else {
                    // setMessageData(res?.data?.message);
                    api_call_token
                        .get(`elementmaster?size=35`)
                        .then((res) => {
                            // debugger;
                            if (res.data.data) {
                                let newArray = [];

                                res.data.data.map((v, i) => {
                                    newArray.push({
                                        elementFullName: v?.elementFullName || "",
                                        elementGrade: v?.elementGrade || "",
                                        elementName: v?.elementName || "",
                                        heatValueSecond: ""
                                    })
                                })
                                setHeatElement(newArray)

                            }
                        })
                        .catch(err => console.log(err))
                }
            })
            .catch(err => console.log(err))
    }


    const columns = [
        {
            title: 'Element',
            dataIndex: 'elementName',
            key: 'elementName',
        },
        {
            title: 'Name',
            dataIndex: 'elementFullName',
            key: 'elementFullName',
        },
        {
            title: 'Value',
            dataIndex: 'heatValueSecond',
            key: 'heatValueSecond',
            render: (_, record) => (
                <Input
                    type='number'
                    // value={record.heatValueSecond.toFixed(4) || null}
                    value={record.heatValueSecond !== null ? record.heatValueSecond : ''}
                    onChange={e => handleValueChanges(record.elementName, 'heatValueSecond', parseFloat(e.target.value))}

                // onChange={e => handleValueChanges(record.xmlDataId, 'heatValueSecond', parseFloat(e.target.value))}
                // onBlur={e => handleValueChange(record.xmlDataId, 'heatValueSecond', parseFloat(e.target.value))}
                />
            ),
        },
        {
            title: 'Grade',
            dataIndex: 'elementGrade',
            key: 'elementGrade',
        },
    ];

    const handleValueChanges = (xml, field, value) => {
        const updatedData = heatElement.map(item => {
            if (item.elementName === xml) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setHeatElement([...updatedData]);
    }

    const handleValueChange = (xml, field, value) => {
        console.log(xml, field, value)

        api_call_token
            .patch(`analyse/${xml}`, {
                heatValueSecond: value
            })
            .then((res) => {
                getAnalysis();
            })
            .catch(err => {
                console.log(err)
            })

    }

    const handleData = () => {
        setIsLoading(true)
        const elementsWithHeatInfo = heatElement.map((element) => {
            const { elementFullName, ...rest } = element; // Destructure and exclude elementFullName
            const heatId = heatValues?.id;
            const heatNumber = heatValues?.heatNumber;
            return { ...rest, heatId, heatNumber };
        });

        console.log(elementsWithHeatInfo, "Element")

        api_call_token
            .post(`processHeatData`, elementsWithHeatInfo)
            .then((res) => {
                console.log(res, "RES")
                setIsLoading(false)
                // navigate('')
                alertMessage("Batch Process Completed ")
                setTimeout(() => {
                    navigate('/heat')
                }, 1000)
            })
            .catch(err => { console.log(err) })

    }

    const fetchXML = () => {
        api_call_token
            .get(`parse?heatId=${heatValues?.id}&heatNumber=${heatValues?.heatNumber}`) // original 
            .then((res) => {

                if (res.data.status) {
                    getHeat();
                    getAnalysis();
                } else {
                    setMessageData(res?.data?.message);

                }
            })
            .catch((err) => console.log(err))
    }



    console.log(id, heatValues, "HeatDATA")
    console.log(heatElement, "heatElement")
    return (
        <div>

            <div>
                <p style={{ color: 'red', margin: '10px' }}>{messageData}</p>

                {/* {!heatValues?.fetchFromXml && */}
                <Button type='primary' style={{ marginTop: '10px', marginLeft: '10px' }} onClick={() => fetchXML()} >Fetch XML</Button>
                {/* {
                    !heatElementCheck.length > 0 &&
                    <Button type='primary' style={{ marginTop: '10px', marginLeft: '10px' }} onClick={() => fetchXML()} >Add Manually</Button>
                } */}
                {/* } */}
            </div>

            <div style={{ width: '70%', margin: '10px' }}>
                <Table dataSource={heatElement} columns={columns} bordered pagination={false} />
            </div>

            <Button type='primary' style={{ marginTop: '10px', marginLeft: '10px' }} onClick={() => handleData()} loading={isLoading}>Save</Button>
        </div>
    )
}

export default HeatElements