import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Modal, Form } from 'antd';
// import Modals from "../../../../../Components/Modals/Modals";
import Modals from "../../../Components/Modals/Modals"
import styles from "./index.module.css";
import moment from 'moment';
import EditIcon from "../../../Assets/ICONS/edit.png";
import { api_call_token } from '../../../utils/Network';
import ConInForm from '../ConInForm/ConInForm';
import ConOutForm from '../ConInForm/ConOutForm';
// import EditIcon from "../../../Assets/ICONS/edit.png";
// import EditIcon from "../../../Assets/ICONS/edit.png";

function ContainerInOutView() {
    let navigate = useNavigate();
    let { id } = useParams();
    const [ContainerData, setContainerData] = useState({})
    const [selectedView, setSelectedView] = useState()
    const [containerIn, setContainerIn] = useState(false)
    const [containerOut, setContainerOut] = useState(false)
    const [open, setOpen] = useState(false);
    const getContainerData = () => {
        api_call_token
            .get(`containerentry/${id}`)
            .then((res) => {
                console.log(res?.data?.data, "getContainerData");
                setContainerData(res?.data?.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getContainerData()
    }, [id])

    const handelOpne = (val) => {
        setOpen(true)
        setSelectedView(val)
    }

    const handelClose = () => {
        setOpen(false)
    }

    useEffect(() =>{
        if(ContainerData?.containerInDate ){
            setContainerIn(true)
        }
        if(ContainerData?.containerOutDate){
            setContainerOut(true)
        }
    },[ContainerData?.containerInDate,ContainerData?.containerOutDate ])
    return (
        <>
            <div style={{ width: "100%" }}>
                <div className={`${styles.topContent} ${styles.demodata}`}>
                    <p ><ArrowLeftOutlined onClick={() => navigate(-1)} /> Container Details</p>
                    <div style={{ display: "flex", gap: "20px" }}>
                        <Button disabled={containerIn} onClick={() => handelOpne(1)}>Container In Add</Button>
                        <Button disabled={containerOut}  onClick={() => handelOpne(2)}>Container Out Add</Button>
                    </div>
                </div>

                <div className={styles.wrapperContainer}>
                    <div className={styles.Container}>
                        <div className={styles.contentWrapper}>
                            <p>Container Id</p>
                            <p>{ContainerData?.containerEntryId}</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Container No.</p>
                            <p>{ContainerData?.containerNo}</p>
                        </div>
                        <div className={styles.contentWrapper}>
                            <p>Gross Weight</p>
                            <p>{ContainerData?.containerGrossWeight}</p>
                        </div>

                        <div className={styles.contentWrapper}>
                            <p>Net Weight</p>
                            <p>{ContainerData?.containerNetWeight}</p>
                        </div>


                    </div>
                </div>
                {
                    ContainerData?.containerInDate && (

                        <>
                            <div className={`${styles.topContent} ${styles.demodata}`}>
                                <p >In Time Details</p>

                            </div>
                            <div className={styles.wrapperContainer}>
                                <div onClick={() => handelOpne(1)} className={styles.EditButton}>
                                    <img src={EditIcon} />
                                </div>
                                <div className={styles.Container}>
                                    <div className={styles.contentWrapper}>
                                        <p>In Date</p>
                                        <p>
                                            {/* {ContainerData?.containerInDate} */}
                                            {ContainerData?.containerInDate ? moment(ContainerData?.containerInDate).format("YYYY-MM-DD") : "N.A"}
                                        </p>
                                    </div>
                                    <div className={styles.contentWrapper}>
                                        <p>In Time</p>
                                        <p>
                                            {/* {ContainerData?.containerInTime} */}
                                            {ContainerData?.containerInTime ? moment(ContainerData?.containerInTime, "HH:mm:ss").format('hh:mm a') : "N.A"}
                                        </p>
                                    </div>
                                    <div className={styles.contentWrapper}>
                                        <p>Container In at Weight</p>
                                        <p>{ContainerData?.containerWeightAtInTime}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    ContainerData?.containerOutDate && (
                        <>
                            <div className={`${styles.topContent} ${styles.demodata}`}>
                                <p >Out Time Details</p>

                            </div>
                            <div className={styles.wrapperContainer}>
                            <div onClick={() => handelOpne(2)} className={styles.EditButton}>
                                    <img src={EditIcon} />
                                </div>
                                <div className={styles.Container}>
                                    <div className={styles.contentWrapper}>
                                        <p>Out Date</p>
                                        <p>
                                            {/* {ContainerData?.containerOutDate} */}
                                            {ContainerData?.containerOutDate ? moment(ContainerData?.containerOutDate).format("YYYY-MM-DD") : "N.A"}
                                        </p>
                                    </div>
                                    <div className={styles.contentWrapper}>
                                        <p>Out Time</p>
                                        <p>
                                            {/* {ContainerData?.containerOutTime} */}
                                            {ContainerData?.containerOutTime ? moment(ContainerData?.containerOutTime, "HH:mm:ss").format('hh:mm a') : "N.A"}
                                        </p>
                                    </div>

                                    <div className={styles.contentWrapper}>
                                        <p>Container Out at Weight</p>
                                        <p>{ContainerData?.containerWeightAtOutTime}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }

            </div>

            <Modals
                open={open}
                handleCancel={() => {
                    setOpen(false);
                    // handleform();
                    // setView(null);
                }}
            // handleOk={handleOk}
            >
                {
                    selectedView == 1 && (
                        <>
                            <ConInForm
                                data={ContainerData}
                                handelClose={handelClose}
                                getContainerData={getContainerData}
                            />
                        </>
                    )
                }
                {
                    selectedView == 2 && (
                        <>
                            <ConOutForm
                                data={ContainerData}
                                handelClose={handelClose}
                                getContainerData={getContainerData}
                            />
                        </>
                    )
                }
            </Modals>
        </>
    )
}

export default ContainerInOutView