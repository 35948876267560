import React from "react";
import { Tabs } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import SupplierList from "../SupplierList/SupplierList";
import SupplierInterlist from "../SupplierInterlist/SupplierInterlist";
const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: "1",
    label: "Domestic",
    children: <SupplierList />,
  },
  {
    key: "2",
    label: "International",
    children: <SupplierInterlist />,
  }
];
const SupplierMain = () => {
  let navigate = useNavigate();
  return (
    <div>
      <h2>
        <ArrowLeftOutlined
          onClick={() => navigate("/master")}
          style={{ marginRight: "10px" }}
        />
        Supplier
      </h2>

      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
};

export default SupplierMain;
