import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api_call, api_call_token } from '../../../../../utils/Network';
import styles from "./index.module.css";
// import CustomTable from '../../../Components/CustomTable/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';


function CODView() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [viewPurchase, setViewPurchase] = useState({});

  useEffect(() => {
    getViewofPurchase();
  }, [id])

  const getViewofPurchase = () => {
    api_call_token
      .get(`cityofdelivery/${id}`)
      .then((res) => {
        console.log(res, "resss")
        setViewPurchase(res.data[0])
      })
      .catch(err => console.log(err));
  }
  console.log(viewPurchase, "ViewPurchase")


  return (


    <div style={{ width: "100%" }}>
      <div>
        <h2 className={styles.demodata}><ArrowLeftOutlined onClick={() => navigate("/master/cod")} style={{marginRight: '10px'}} /> City of Delivery Details</h2>

        <div className={styles.wrapperContainer}>

          <div className={styles.Container}>
            <div className={styles.contentWrapper}>
              <p>COD Id</p>
              <p>{viewPurchase?.indianCityId}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Indian State Name</p>
              <p>{viewPurchase?.indiaStateName}</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Indian City Name</p>
              <p>{viewPurchase?.indianCityName}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CODView