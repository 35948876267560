import React, { useState, useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Modal
} from "antd";
import NumericInput from "../../../Components/NumericInput/NumericInput";
import moment from "moment";
import { api_call, api_call_token } from "../../../utils/Network";
import { alertMessage } from "../../../utils/helperFunctions";
import { MainContext } from "../../../utils/context";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { UserCredsContext } from "../../../utils/UserCredsContext";
import Causion from "../../../Assets/Popup/caution.png"

const { confirm } = Modal;
function ShippingForm() {
  const { state } = useLocation();
  let navigate = useNavigate();
  const [value, setValue] = useState("");
  const [fieldValues, setFieldValue] = useState([]);
  const [form] = Form.useForm();
  const { base_items } = useContext(UserCredsContext);
  const [confirmShown, setConfirmShown] = useState(false);
  useEffect(() => {
    getField();
  }, [])

  const getField = () => {
    api_call_token
    .get(`product/field?search=shippingdoc&formtype=create`)
    .then((res) => {
      if (res.data.data.length > 0) {
        setFieldValue(res.data.data);
      } else {
        showConfirm();
      }
    })
    .catch(err => console.log(err))
  }

  const showConfirm = () => {
    if (!confirmShown) {
    confirm({
      title: '',
      icon: null,
      content: (
        <div style={{ textAlign: 'center' }}>
          <img src={Causion} alt="Custom Icon" style={{ width: '60px' }} />
          <p style={{fontWeight: 'bold'}}>You do not have access to view this. Kindly contact the admin to get access.</p>
        </div>
      ),
      // Remove onOk and onCancel
      cancelButtonProps: {
        style: {
          display: 'none', // Hide the cancel button
        },
      },
      okButtonProps: {
        // disabled: true,
      },
      okText: "Go Back",
      onOk() {
        navigate("/shipping");
      },

    });
    setConfirmShown(!confirmShown);
  }
  };

  const handleData = (values) => {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
          const value = values[key];
          if (typeof value === 'object' && (value.$d instanceof Date || value._d instanceof Date)) {
            if(value.$d){
              values[key] = moment(value.$d).format('YYYY-MM-DD');
            }else{
            values[key] = moment(value._d).format('YYYY-MM-DD');
            }
          } else if (typeof value === 'string' && !isNaN(value)) {
            values[key] = Number(value);
          }
      }
  }
    console.log(values, "djdjjd");
    if(state){
      api_call_token
      .patch(`/shippingdoc/${state?.id}`, values)
      .then((res) => {
        if (res.status == 204 || res.status == 201 || res.status == 200) {
          alertMessage("Shipping Doc Updated")
          setTimeout(() => {
            navigate('/shipping')
          }, 2000);
        }
      })
    }else{
      api_call_token
      .post(`/shippingdoc`, values)
      .then((res) => {
        if(res.status == 200 || res.status == 201){
          form.resetFields();
          alertMessage("Shipping Doc Added ");
          setTimeout(() => {
            navigate('/shipping')
          }, 2000);
        }
      })
    }
    
    // console.log(finalData, "finalDatafinalDatafinalData")
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  console.log(base_items, "State")
  return (
    <div>
      <div><h2><ArrowLeftOutlined onClick={() => navigate(-1)} style={{marginRight: '10px'}}/>Create Shipping Doc</h2></div>
      <div style={{background: 'white', padding: '30px'}}>
      <Form 
      form={form} 
      onFinish={handleData}
      initialValues={{
        ...state?.data,
        billOfLoadingDate: state?.data?.billOfLoadingDate ? moment(state?.data?.billOfLoadingDate.split('T')[0],"YYYY-MM-DD") : undefined,
        invoiceDate: state?.data?.invoiceDate ? moment(state?.data?.invoiceDate.split('T')[0],"YYYY-MM-DD") : undefined,
        salesConfirmationDate: state?.data?.salesConfirmationDate ? moment(state?.data?.salesConfirmationDate.split('T')[0],"YYYY-MM-DD"): undefined,
      }}
      >
        <Row gutter={[26, 7]}>
          {fieldValues.length > 0 && fieldValues.map(
            (val, i) =>
              val?.isForm && (
                <>
                  {val?.fieldType == "int" ? (
                    <Col xs={24} md={14} lg={8}>
                      <span>
                        {val?.fieldName} {val?.required && `*`}
                      </span>
                      <Form.Item
                        name={val?.apiName}
                        rules={[
                          {
                            required: val?.required,
                            message: `Please Enter ${val?.apiName}!`,
                          },
                        ]}
                      >
                        <NumericInput
                          style={{
                            width: "100%",
                          }}
                          value={value}
                          onChange={setValue}
                        />
                      </Form.Item>
                    </Col>
                  ) : val?.fieldType == "picklist_fk" ? (
                    <>
                      <Col xs={24} md={14} lg={8}>
                        <span>
                          {val?.fieldName} {val?.required && `*`}
                        </span>
                        <Form.Item
                          name={val?.apiName}
                          rules={[
                            {
                              required: val?.required,
                              message: `Please Enter ${val?.apiName}!`,
                            },
                          ]}
                        >
                         {base_items && <Select
                            showSearch
                            style={{
                              width: "100%",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={
                              
                              base_items[val?.picklistData] &&
                              base_items[val?.picklistData]?.map(
                                (obj) => {
                                  const keys = Object.keys(obj);
                                  const convertedObj = {
                                    value: obj[keys[0]],
                                    label: obj[keys[1]],
                                  };

                                  for (let i = 2; i < keys.length; i++) {
                                    convertedObj[keys[i]] = obj[keys[i]];
                                  }

                                  return convertedObj;
                                }
                              )
                            }
                          />}
                        </Form.Item>
                      </Col>
                    </>
                  ) : val?.fieldType == "date" ? (
                    <Col xs={24} md={14} lg={8}>
                      <span>
                        {val?.fieldName} {val?.required && `*`}
                      </span>
                      <Form.Item
                        name={val?.apiName}
                        rules={[
                          {
                            required: val?.required,
                            message: `Please Enter ${val?.apiName}!`,
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={onChange}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col xs={24} md={14} lg={8}>
                      <span>
                        {val?.fieldName} {val?.required && `*`}
                      </span>
                      <Form.Item
                        name={val?.apiName}
                        rules={[
                          {
                            required: val?.required,
                            message: `Please Enter ${val?.apiName}!`,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                </>
              )
          )}
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {
              (state)? "Update" : "Submit"
            }
            {/* Submit */}
          </Button>
        </Form.Item>
      </Form>
      </div>
      
    </div>
  );
}

export default ShippingForm;
