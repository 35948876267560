import { FilterOutlined, PlusCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Tag } from 'antd';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../Components/CustomTable/CustomTable';
import { api_call, api_call_token } from '../../utils/Network';
import styles from './index.module.css';
import moment from 'moment';
import EditIcon from "../../Assets/ICONS/edit.png";


function Container() {
    let navigate = useNavigate();
    const [fieldValues, setFieldValue] = useState([]);
    const [values, setValues] = useState({});
    const [dataList, setDataList] = useState([]);
    const [filterObj, setFilterObj] = useState({
        page: 1,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberofPage, setTotalNumberofPage] = useState(null);
    const pageSize = 10; // Set your page size here
    const currentPages = currentPage || 1; // Current page from pagination object
    
    useEffect(() => {
        getDataValues();
    }, [filterObj]);

    const getDataValues = (paramObj = { ...filterObj}) => {
        api_call_token
            .get(`container`, { params: {...paramObj}})
            .then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setValues(res.data)
                    setDataList(res.data.data)
                    setCurrentPage(res.data.currentPage)
                    setTotalNumberofPage(res.data.totalRowCount)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const navigateToCreate = () => {
        navigate('/create')
    }

    const redirectTo = (value) => {
        api_call_token
        .get(`container/${value}`)
        .then((res) => {
            console.log(res.data);
            if(res.status == 200 || res.status ==201){
                navigate('editcontainer', { state: {data: res.data ,id: value}})
            }
        })
        .catch(err => console.log(err))
    }

    const navigateToCreateForm = () => {
        navigate('addcontainer')
    }

    const handleData = (v) => {
        navigate(`${v}`)
    }




    const columns = [
        {
            title: 'Sr No',
            dataIndex: 'containerID',
            // render: (_, __, index) => (currentPages - 1) * pageSize + index + 1,
            // dataIndex: 'containerID',
            // sorter: {
            //     compare: (a, b) => a.containerID - b.containerID,
            //     multiple: 3,
            // },
            render: (_, { containerID }, index) => {
                console.log(_)
                return <div style={{cursor:"pointer"}} onClick={() => handleData(containerID)}>{(currentPages - 1) * pageSize + index + 1}</div>}
        },
        {
            title: 'Container No',
            dataIndex: 'containerNo',
            sorter: {
                compare: (a, b) => a.containerNo - b.containerNo,
                multiple: 3,
            },
            render: (_, { containerID }, index) => {
                console.log(_)
                return <div style={{cursor:"pointer"}} onClick={() => handleData(containerID)}>{_}</div>}
        },
        {
            title: 'Gross Weight',
            dataIndex: 'grossWeight',
        },
        {
            title: 'Port Of Loading',
            dataIndex: 'portOfLoadingName',
        },
        {
            title: 'Final Destination',
            dataIndex: 'portOfDepartureName',
        },
        {
            title: 'Time of Departure',
            dataIndex: 'estTimeOfDeparture',
            render: (_, { estTimeOfDeparture }) => {
                const formattedDate = moment(estTimeOfDeparture).format('DD-MMMM-YYYY');
                return (
                  <div>
                    {formattedDate}
                  </div>
                );
              },
                
        },
        {
            title: 'Time of Arrival',
            dataIndex: 'estTimeOfArrival',
            render: (_, { estTimeOfArrival }) => {
                const formattedDate = moment(estTimeOfArrival).format('DD-MMMM-YYYY');
                return (
                  <div>
                    {formattedDate}
                  </div>
                );
              },
                
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
              const { containerID } = record;
              const handleViewClick = () => {
                // Handle view button click with the ID
                console.log('View clicked for ID:', containerID);
                redirectTo(containerID);
              };
              return (
                <div>
                    <div onClick={handleViewClick} style={{width: 'max-content', cursor: 'pointer'}}>
                        <img src={EditIcon} alt='consignment' />
                    </div>
                  {/* <button onClick={handleViewClick}>Edit</button> */}
                </div>
              );
            },
          },
    ];

    const onSearch = (event) => {
        let value = event.target.value;
    let obj = filterObj;
    if (value) {
      obj.search = value;
      obj.page = 1;
      setCurrentPage(1);
    } else delete obj.search;
    setFilterObj({ ...obj });
    }

    const handlePageChange = (page) => {
        console.log(page, "aaaaaaaaa")
        setFilterObj({...filterObj, page: page})
        setCurrentPage(page);
    };

    const paginationConfig = {
        current: currentPage,
        pageSize: 10, // Number of items per page
        total: totalNumberofPage, // Total number of items
        onChange: handlePageChange,
    };
    console.log(dataList, "dataList")

  return (
    <div className={styles.mainContainer}>
            <div className={styles.rowContainer}>
                <h2>Container</h2>
                <div className={styles.searchData}>

                    <Input size="medium" placeholder="Search By Container No, Products, HSN code, BL" prefix={<SearchOutlined />} onChange={(e) => onSearch(e)} style={{width: '70%'}}/>
                    <Button type="primary" onClick={navigateToCreateForm} style={{ margin: '0 10px' }}> <PlusCircleTwoTone style={{ marginRight: '10px' }} /> Add Container</Button>
                </div>
            </div>
            <div>
                <CustomTable columns={columns} data={dataList} />
                <div style={{ textAlign: 'right', margin: '10px' }}>
                    <Pagination {...paginationConfig} showSizeChanger={false}/>
                </div>
            </div>
        </div>
  )
}
export default Container